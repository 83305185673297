<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [onBoardingStepCompleted]="onBoardingCompleted"
  id="printed-slips-component"
>
  <div class="radio_buttons">
    <app-radio
      name="printedSlips"
      [options]="options"
      [horizontal]="false"
      [selected]="getSelectedOption()"
      (selectedChange)="setSelectedOption($event)"
      [disabled]="isSaving"
    >
    </app-radio>
  </div>

  <app-modal id="integrations-page" [dismissable]="false" size="small">
    <h1 class="font-24 text-almost-black font-700">
      "Integrations" page will be deactivated
  
      <div class="load-icon p-absolute" *ngIf="isLoading">
        <app-loader size="medium"></app-loader>
      </div>
    </h1>
  
    <div class="modal-wrap">
      <p class="font-normal font-14 text-almost-black">
        By selecting "No", you have indicated that you will not be sending
        shipping slips with Returbo. We will therefore deactivate the 
        Integrations page. To activate the page again, select "Yes" in the 
        Return shipping slips block. Do you wish to proceed?
      </p>
  
      <div class="d-flex">
        <button
          class="btn btn-secondary btn-md mr-16"
          (click)="cancel()"
          [disabled]="isLoading"
        >
          Go back
        </button>
        <button
          class="btn btn-primary btn-md mr-16"
          (click)="confirm()"
          [disabled]="isLoading"
        >
          Proceed
        </button>
      </div>
    </div>
  </app-modal>
</app-settings-section>
