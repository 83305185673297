import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/components/modal/modal.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-tracking-error-popup',
  templateUrl: './tracking-error-popup.component.html',
})
export class TrackingErrorPopupComponent implements OnInit {

  @Output()
  actionTaken: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private modalService: ModalService,
    private popupService: PopupService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.popupService.getTrackingErrorPopup().subscribe((res: boolean) => {
      if (res) this.openModal();
    });
  }

  openModal(): void {
    this.modalService.open('tracking-error');
  }

  closeModal(): void {
    this.actionTaken.emit(true);
    this.modalService.dismiss();
  }

  goToPlans(): void {
    this.actionTaken.emit(true);
    this.router.navigate(['/settings/account']);
  }
}
