import { Component, OnInit } from '@angular/core';
import { ChecklistTask } from 'src/app/models/checklist-task.model';
import { AccountSettings } from 'src/app/settings/models/account-settings.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { ReturnReason } from 'src/app/settings/models/return-reason.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShopifySettings } from 'src/app/settings/models/shopify-settings.model';
import { SupportSettings } from 'src/app/settings/models/support-settings.model';
import { WarehouseSettings } from 'src/app/settings/models/warehouse-settings.model';
import { OnboardingStage } from 'src/app/shared/enums/onboarding-stage.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ReasonSettingsService } from 'src/app/shared/services/settings/reason-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { SupportSettingsService } from 'src/app/shared/services/settings/support-settings.service';
import { WarehouseSettingsService } from 'src/app/shared/services/settings/warehouse-settings.service';

@Component({
  selector: 'app-onboarding-accordion',
  templateUrl: './onboarding-accordion.component.html',
  styleUrls: ['./onboarding-accordion.component.scss'],
})
export class OnboardingAccordionComponent implements OnInit {
  step = 1;
  tasks: ChecklistTask[] = [];
  completedLength = 0;
  supportEmail = '';
  isSettingsLoaded = true;

  savedOnboarding = false;
  doneWithOnboarding = false;
  onboardingLength = 8;
  onboardingCompleted: OnboardingStage = OnboardingStage.Uncompleted;
  firstLoad = true;
  decision = false;

  hasReturnSettingsLoaded = false;
  hasSupportSettingsLoaded = false;
  hasAccountSettingsLoaded = false;
  hasFormSettingsLoaded = false;
  hasReasonSettingsLoaded = false;
  hasShopifySettingsLoaded = false;
  hasWarehouseSettingsLoaded = false;
  hasSelectedPricePlanLoaded = false;

  returnSettings = new ReturnSettings();
  supportSettings = new SupportSettings();
  accountSettings = new AccountSettings();
  formSettings = new ReturnFormSettings();
  reasons: ReturnReason[] = [];
  shopifySettings = new ShopifySettings();
  warehouseSettings = new WarehouseSettings();
  selectedPricePlan = new PricePlan();

  constructor(
    private returnSettingsService: ReturnSettingsService,
    private accountSettingsService: AccountSettingsService,
    private supportSettingsService: SupportSettingsService,
    private formSettingsService: FormSettingsService,
    private reasonSettingsService: ReasonSettingsService,
    private shopifySettingsService: ShopifySettingsService,
    private warehouseSettingsService: WarehouseSettingsService,
    private planSettingsService: PricePlanSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe((settings: ReturnSettings) => {
        this.returnSettings = settings;
        this.hasReturnSettingsLoaded = this.returnSettingsService.hasReturnSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.supportSettingsService
      .getSubjectSupportSettings()
      .subscribe((settings: SupportSettings) => {
        this.supportSettings = settings;
        this.hasSupportSettingsLoaded = this.supportSettingsService.hasSupportSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.accountSettingsService
      .getSubjectAccountSettings()
      .subscribe((settings: AccountSettings) => {
        this.accountSettings = settings;
        this.hasAccountSettingsLoaded = this.accountSettingsService.hasAccountSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.formSettingsService
      .getSubjectFormSettings()
      .subscribe((settings: ReturnFormSettings) => {
        this.formSettings = settings;
        this.hasFormSettingsLoaded = this.formSettingsService.hasFormSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.reasonSettingsService
      .getSubjectReasonSettings()
      .subscribe((reasons: ReturnReason[]) => {
        this.reasons = reasons;
        this.hasReasonSettingsLoaded = this.reasonSettingsService.hasReasonSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.shopifySettingsService
      .getSubjectShopifySettings()
      .subscribe((settings: ShopifySettings) => {
        this.shopifySettings = settings;
        this.hasShopifySettingsLoaded = this.shopifySettingsService.hasShopifySettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.warehouseSettingsService
      .getSubjectWarehouseSettings()
      .subscribe((settings: WarehouseSettings) => {
        this.warehouseSettings = settings;
        this.hasWarehouseSettingsLoaded = this.warehouseSettingsService.hasWarehouseSettingsLoaded();
        this.updateHasSettingsLoaded();
    });

    this.planSettingsService
      .getSubjectSelectedPricePlan()
      .subscribe((settings: PricePlan) => {
        this.selectedPricePlan = settings;
        this.hasSelectedPricePlanLoaded = this.planSettingsService.hasSelectedPricePlanLoaded();
        this.updateHasSettingsLoaded();
    });
  }

  updateHasSettingsLoaded(): void {
    if (this.hasReturnSettingsLoaded &&
        this.hasSupportSettingsLoaded &&
        this.hasAccountSettingsLoaded &&
        this.hasFormSettingsLoaded &&
        this.hasReasonSettingsLoaded &&
        this.hasShopifySettingsLoaded &&
        this.hasWarehouseSettingsLoaded &&
        this.hasSelectedPricePlanLoaded
    ) {
      this.isSettingsLoaded = true;
      this.setUpTasks();
    }
  }

  setUpTasks(): void {
    this.completedLength = 0;
    this.supportEmail =
      this.supportSettings.merchant_support_email;
    this.doneWithOnboarding = this.shopifySettings.onboarding_stage == OnboardingStage.Completed;
    this.tasks = [
      {
        title: 'Visit Account Settings',
        description:
          'Check out the Account Settings page to manage your Returbo account. ',
        optional: false,
        items: [
          {
            title: 'Select plan',
            completed: this.checkPlan(
              this.selectedPricePlan
            ),
          },
          {
            title: 'Activate Returbo',
            completed: this.checkValue(
              this.formSettings.is_form_embedded,
              'checkbox'
            ),
          },
          {
            title: 'Contact information',
            completed: this.checkValue(
              [
                this.accountSettings.company_name,
                this.supportSettings.customer_support_email,
                this.supportSettings.customer_support_phone,
                this.supportSettings.merchant_support_email,
              ],
              'multiple'
            ),
          }
        ],
      },
      {
        title: 'Visit Request Settings',
        description:
          'Check out the Request Settings page to set up your return flow.',
        optional: false,
        items: [
          {
            title: 'Accepting requests',
            completed: this.checkValue(
              this.returnSettings.automatically_accept_requests,
              'boolean'
            ),
          },
          {
            title: 'Outsourcing to third party',
            completed: this.checkValue(
              this.returnSettings.is_using_3pl,
              'boolean'
            ),
          },
          {
            title: 'Return & restock locations',
            completed: this.checkLocation(
              this.warehouseSettings.restock_to_warehouse_id
            ),
          },
          {
            title: 'Return shipping slips',
            completed: this.checkValue(
              this.returnSettings.printed_return_slips,
              'boolean'
            ),
          },
          {
            title: 'Return reasons',
            completed: this.checkValue(
              this.reasons,
              'return_reason'
            ),
          },
        ],
      },
      {
        title: 'Additional Settings pages (optional)',
        description:
          'Take a look at other optional Settings pages to customize your set up.',
        optional: true,
        items:[
          {
            title: "Customization page"
          },
          {
            title: "Integration page"
          },
          {
            title: "Attach image & comment to all requests"
          },
          {
            title: "Complaints function"
          },
          {
            title: "Exchanges function"
          }
        ]
      },
    ];
  }

  checkValue(value: any, type: string): boolean {
    let check = false;

    if (type === 'return_reason') {
      check = value.some((x: ReturnReason) => x.is_enabled);
    }

    if (type === 'multiple') {
      let hasNull = false;
      for (const element of value) {
        if (element === undefined || element === null || element.length === 0) {
          hasNull = true;
          break;
        }
      }
      check = hasNull ? false : true;
    }
    if (type === 'boolean') {
      check = value !== undefined && value !== null ? true : false;
    }

    if (type === 'checkbox') {
      check = value ? true : false;
    }
    this.getCompletedLength(check);
    return check;
  }

  checkLocation(id: number): boolean {
    const hasRestockLocation = id !== -1;
    this.getCompletedLength(hasRestockLocation);
    return hasRestockLocation;
  }

  checkPlan(pricePlan: PricePlan): boolean {
    const hasBilling =
      pricePlan &&
      pricePlan.name
        ? true
        : false;
    this.getCompletedLength(hasBilling);
    return hasBilling;
  }

  setStep(index: number) {
    this.step = index;
  }

  getCompletedLength(value: boolean): void {
    if (this.onboardingCompleted !== OnboardingStage.Uncompleted || !value) {
      return;
    }

    this.completedLength += 1;

    if (!this.doneWithOnboarding && this.completedLength === 8) {
      setTimeout(() => {
        this.decision = true;
      }, 5000);
      return;
    }
  }

  getTasks(): ChecklistTask[] {
    if (this.completedLength < 8) {
      return this.tasks;
    }
    if (
      this.isSettingsLoaded &&
      this.completedLength === 8 &&
      (this.decision || !this.firstLoad)
    ) {
      return this.tasks.filter((tasks) => tasks.optional);
    }
    return [];
  }

  finishOnboarding(): void {
    this.doneWithOnboarding = true;
    this.decision = false;

    this.shopifySettings.onboarding_stage =
      OnboardingStage.Completed;
    if (!this.savedOnboarding) {
      this.saveOnboardingStage();
    }
  }

  continueOnBoarding(): void {
    this.doneWithOnboarding = false;
    this.decision = false;
    this.firstLoad = false;
  }

  saveOnboardingStage(): void {
    this.shopifySettingsService
      .updateShopifySettings(this.shopifySettings)
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save onboarding stage.');
        },
        complete: () => {
          this.savedOnboarding = true;
        }
    });
  }
}
