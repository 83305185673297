import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private openSidenav = new BehaviorSubject(false);
  private rejectItemsModal = new BehaviorSubject<boolean>(false);

  open(): void {
    this.openSidenav.next(true);
  }

  close(): void {
    this.openSidenav.next(false);
  }

  getOpen() {
    return this.openSidenav.asObservable();
  }

  setRejectItemsModal(value: boolean): void {
    this.rejectItemsModal.next(value);
  }

  getRejectItemsModal(): Observable<boolean> {
    return this.rejectItemsModal.asObservable();
  }
}
