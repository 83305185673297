<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New integration"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="Deutsche Post"
      logo="deutsche-post"
      [shippingCompany]="shippingCarrier.DhlDeutschePost"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          Activate Deutsche Post today and start to autogenerate your return
          shipping slips. When your customers have registered their return they
          will automatically receive an email with a return shipping slip as a
          PDF. Your customers can then print the label at home. <br />
          All returns that are generated will go through Germany first and then
          will go out to the destination country. With Deutsche Post, we can
          only track that it has arrived at their warehouse in Germany, and no
          other points during the shipping process. <br />
          It takes about 2 to 4 weeks for the returns to reach their final
          destination.
        </p>
        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <p class="section section-subtitle">
              <b>These services will be available to you:</b>
            </p>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>

        <app-alert
          *ngIf="showAlert(alertType.Credentials)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>

        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Customer Key</p>
            <input
              type="text"
              name="customerKey"
              class="section-input"
              [(ngModel)]="serviceInformation.apiCustomerKey"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect
            </p>
          </div>
          <div class="w-half">
            <p class="section section-input-label">Customer secret</p>
            <div class="d-flex">
              <input
                [type]="showPassword ? 'text' : 'password'"
                type="password"
                name="secret"
                class="section-input"
                [(ngModel)]="serviceInformation.apiCustomerSecret"
                placeholder="*******"
                (change)="onValueChanged()"
                [disabled]="isLoading || isSaving"
                [ngClass]="{ error: isInformationInvalid() }"
              />
              <img
                (click)="showPassword = !showPassword"
                class="section-password-eye"
                src="../assets/images/svg/password-eye.svg"
                alt="EYE"
              />
            </div>
          </div>
          <p class="validation-message" *ngIf="isInformationInvalid()">
            The information is incorrect
          </p>
        </div>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Customer Number EKP</p>
            <input
              type="text"
              name="key"
              class="section-input"
              [(ngModel)]="serviceInformation.apiCustomerNumberEKP"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect
            </p>
          </div>
        </div>

        <app-credentials-guide provider="Deutsche Post"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which Deutsche Post services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
        (infoModalOpened)="openInfoModal()"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-available-carrier-services>

      <app-enable-countries
        [supportedCountries]="supportedCountries"
        [availableServices]="availableServices"
        [carrier]="shippingCarrier.DhlDeutschePost"
        description="Create optional country rules for your current carriers. For example, if you only want to use Deutsche Post in Germany, choose Germany as a selected market. You can also see which countries the carrier is available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.apiCustomerKey"
        description="The Deutsche Post’s activation will be instant and it will be ready to use straight away."
        [provider]="shippingCarrier.DhlDeutschePost"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="deutsche-post-services">
  <div>
    <h1 class="font-semiBold font-22 text-almost-black mt-0">
      Deutsche Post services
    </h1>
    <p class="font-normal font-14 text-almost-black"></p>
    <p class="font-normal font-14 text-almost-black">
      <b>Parcel Return</b> - For packages up to 2kg from 22 countries in EU.
      Transit time is between 2 to 4 weeks. The package is only scanned/tracked
      once they arrive to the International Return Center Niederaula. Returbo
      can only track that the return package has arrived to the return center in
      Germany
    </p>
    <p class="font-normal font-14 text-almost-black">
      For more information please visit
      <a
        class="text-primary"
        href="https://www.deutschepost.de/"
        target="_blank"
      >
        Deutsche Post’s official website</a
      >.
    </p>
  </div>
</app-modal>
