<div class="action">
  <ng-container *ngIf="!hasProccessStarted()">
    <p class="section mr-16">
      {{ description }}
    </p>
    <button
      class="btn btn-primary btn-md pr-15 check"
      [disabled]="disableActivation()"
      (click)="checkCarriersPricePlan()"
    >
      Activate carrier
    </button>
  </ng-container>

  <ng-container *ngIf="hasProccessStarted()">
    <p class="section">
      By deactivating
      {{
        provider === shippingCarrier.PostNord
          ? shippingCarrier.PostNord
          : "the carrier"
      }}
      you won’t be able to use its services unless activated again.
    </p>
    <button
      *ngIf="showSaveButton()"
      class="btn btn-primary btn-md ml-16 pr-15 save"
      (click)="enableIntegration(false)"
      [disabled]="isSaving || disableActivation()"
    >
      Save updated info
    </button>

    <button
      class="carrier-button ml-16 mr-16 float-left"
      (click)="openDeactivationModal()"
      [disabled]="isSaving"
    >
      <img
        *ngIf="getButtonDeactivateText() === 'Deactivate carrier'"
        class="mr-8"
        src="../assets/images/icons/X.png"
      />

      {{ getButtonDeactivateText() }}
    </button>
  </ng-container>
</div>

<app-modal class="carrier-deactivation" id="carrier-deactivation-{{provider}}" [dismissable]="false" size="x-small">
  <div class="text-almost-black font-normal font-14">
    <h1 class="title mt-0">Carrier deactivation</h1>

    <p>
      You are about to deactivate a carrier. Please bear in mind that the
      information entered will be saved and you will be able to reactivate the
      carrier with your current setup later on. Do you wish to proceed to
      deactivation?
    </p>
    <div class="activation-actions">
      <button
        class="button-cancel mr-16"
        (click)="closeModal()"
        [disabled]="isSaving"
      >
        <span class="text">Go back</span>
      </button>
      <button
        class="button-confirm"
        (click)="deactivateIntegration('deactivate')"
        [disabled]="isSaving"
      >
        <span *ngIf="!isSaving">Deactivate</span>
        <app-loader *ngIf="isSaving" size="medium"></app-loader>
      </button>
    </div>
  </div>
</app-modal>
