import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { GenerateSlipResponse } from 'src/app/models/generate-slip-response.model';
import { Item } from 'src/app/models/item.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { Shipping } from 'src/app/models/shipping.model';
import { SlipInfo } from 'src/app/models/slip-info.model';
import { SlipItem } from 'src/app/models/slip-item.model';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { UpdateItem } from 'src/app/models/update-item.model';
import { UpdateReturnRequest } from 'src/app/models/update-return-request.model';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingLocation } from 'src/app/settings/models/shipping-location.model';
import { ShippingSettings } from 'src/app/settings/models/shipping-settings.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { FilterStatusTypeEnum } from 'src/app/shared/enums/filter-status-type.enum';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { SelectedShippingOptionsEnum } from 'src/app/shared/enums/selected-shipping-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';
import { ExcludedItem } from '../../../settings/models/excluded-item-model';
import { filterOnStatus } from '../../filter-on-status';
import { ReturnsService } from '../../returns.service';
import { returnStatusesFilters } from '../../table-filters';
import {
  complaintRefund,
  complaintReturnItems,
  hasComplaintReason,
} from '../complaint-return-items';
import { shippings } from '../shippings';
import { orderAlertText } from './alert-types';
import { ItemsTableService } from './items-table/items-table.service';
import { RejectItemsModalComponent } from './reject-items-modal/reject-items-modal.component';
import { ReturnShippingService } from './return-shipping/return-shipping.service';

@Component({
  selector: 'app-left-content',
  templateUrl: './left-content.component.html',
  styleUrls: ['./left-content.component.scss'],
})
export class LeftContentComponent implements OnInit {
  return: ReturnRequest = new ReturnRequest();
  selectedReturnShipping: Shipping = new Shipping();
  ReturnStatusEnum = ReturnStatusEnum;
  ReturnItemStatusEnum = ReturnItemStatusEnum;
  amountSelected = 0;
  shippings = shippings;
  subtitle = '';

  shippingSettings: ShippingSettings = new ShippingSettings();
  returnSettings: ReturnSettings = new ReturnSettings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();
  complaintSettings: ComplaintSettings = new ComplaintSettings();
  hasReturnSettingsLoaded = false;

  automatic = false;
  autoSlip = false;
  selectedFile: any;
  returnStatusesFilters = returnStatusesFilters;
  firstTime = true;
  hasResentEmail = false;
  isLoading = false;
  isResendingEmail = false;
  dialogRef: any;
  selectedShippingOptionsEnum = SelectedShippingOptionsEnum;
  printedSlips = false;
  errorAutoGeneratingSlip = false;

  selectedItems: string[] = [];
  excludedItems: ExcludedItem[] = [];
  hasComplaintReason = hasComplaintReason;
  complaintRefund = complaintRefund;
  complaintReturnItems = complaintReturnItems;
  isComplaintRefund = false;
  isComplaintReturnItems = false;
  filterOnStatus = filterOnStatus;
  isReturnRequested = false;
  isIncomingPackageReceived = false;
  isSlipSkippedAttached = false;
  isPackageSentDropff = false;
  isPackageReceived = false;
  isResolvedPackageReceived = false;
  isRequestedPackageReceived = false;
  isWithShippingSlip = false;
  isShippingSlipSkipped = false;
  isResolved = false;
  isIncoming = false;
  rejectItemsModal!: MatDialogRef<RejectItemsModalComponent>;

  constructor(
    private returnsService: ReturnsService,
    private returnShippingService: ReturnShippingService,
    private itemsTableService: ItemsTableService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private popupService: PopupService,
    private complaintSettingsService: ComplaintSettingsService,
    private exchangeService: ExchangeSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private shippingSettingsService: ShippingSettingsService,
    private locationSettingsService: LocationSettingsService,
    private _dialog: MatDialog,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getComplaintsSettings();
    this.onGetSelectedReturn();
    this.getExchangeSettings();
    this.getReturnSettings();
    this.getShippingSettings();

    this.returnShippingService
      .getSelectedReturnShipping()
      .subscribe((res: Shipping) => {
        this.selectedReturnShipping = res;
      });
    this.returnShippingService
      .getSelectedFiles()
      .subscribe((res: any[]) => (this.selectedFile = res));
  }

  updateTitle(val: ReturnRequest): void {
    this.returnStatusesFilters.forEach((status: StatusFilter) => {
      if (status.status.includes(val.status))
        this.subtitle = status.headerStatus.subtitle;
    });
  }

  public dismiss(): void {
    this.returnsService.setisShowing(false);
  }

  isDisabled(): boolean {
    const itemsCheck = this.itemsDisableCheck();
    if (itemsCheck) {
      return true;
    }

    if (
      this.return.status !== ReturnStatusEnum.PackageReceived &&
      !this.allItemsDeclined() &&
      ((!this.selectedReturnShipping.name &&
        !this.autoSlip &&
        this.return.items.filter((r) => r.selected).length) ||
        (this.selectedReturnShipping?.name ===
          SelectedShippingOptionsEnum.AttachShippingSlip &&
          (!this.selectedFile || this.selectedFile.length === 0)))
    ) {
      if (
        this.hasComplaintReason(this.return.items) &&
        !this.complaintSettings.should_item_be_returned
      ) {
        return false;
      }
      return true;
    }

    return false;
  }

  itemsDisableCheck(): boolean {
    const validStatuses = [
      ReturnStatusEnum.PackageReceived,
      ReturnStatusEnum.AwaitingShippingSlip,
    ];

    if (
      this.isExchange() &&
      this.return.items.filter(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.Refund &&
          !item.reject_cause
      ).length > 0
    ) {
      return true;
    }
    for (const item of this.return.items) {
      if (!item.selected && !item.reject_cause) {
        return true;
      }

      if (validStatuses.includes(this.return.status)) {
        if (!item.decision && !item.reject_cause) return true;
        const returnAmount = Number(Number(item.return_amount).toFixed(2));
        const price = Number(Number(item.price).toFixed(2));
        const totalDiscount = Number(Number(item.total_discount).toFixed(2));
        const isReturnableAmount =
          returnAmount !== Number((price - totalDiscount).toFixed(2));

        if (item.decision && isReturnableAmount && !item.reject_cause)
          return true;
      }
    }
    return false;
  }

  mark(type: string): void {
    this.isLoading = true;
    this.updateTitle(this.return);
    this.confirm(type);
  }

  getRefundAmount(item: Item): number {
    return Number(item.price) * Number(item.quantity);
  }

  getReturnText(): string {
    const itemsText = this.formatItemsText();
    const isPreApprovedForShipping = this.isPreApprovedForReturnShipping();

    if (isPreApprovedForShipping) {
      return `${itemsText} pre-approved for return shipping`;
    }

    const refundStatus = this.allItemsDeclined() ? 'rejected' : 'accepted';

    return `${itemsText} ${refundStatus} for refund`;
  }

  private formatItemsText(): string {
    const selectedItemsCount = this.getSelectedItemsLength();
    const totalItemsCount = this.return?.items?.length ?? 0;
    return `${selectedItemsCount} out of ${totalItemsCount} item(s)`;
  }

  private isPreApprovedForReturnShipping(): boolean {
    const condition1 =
      !this.isExchange() &&
      this.return.status === ReturnStatusEnum.RequestReceived &&
      !this.isComplaintRequestedNoReturn();
    const condition2 =
      this.return.status === ReturnStatusEnum.AwaitingShippingSlip &&
      !this.isComplaintRefund &&
      !this.isComplaintReturnItems;

    return condition1 || condition2;
  }

  getAcceptedExchangeItemsText(): string {
    const acceptedItems = this.return.items.filter(
      (item: Item) =>
        (item.merchant_compensation_choice === CompensationOption.NewItem ||
          item.merchant_compensation_choice ===
            CompensationOption.StoreCredit) &&
        item.decision
    ).length;
    const outOfText = `${acceptedItems} out of ${this.return.items.length} item(s) accepted for`;
    if (
      !this.exchangeItemsForRefund() &&
      (!this.isReturnRequested || this.isComplaintRequestedNoReturn())
    ) {
      return `${outOfText} exchange`;
    }
    return `${outOfText} return shipment`;
  }

  displayAcceptedItemsText(type: string): boolean {
    if (!this.isRequestedPackageReceived) {
      return false;
    }
    if (
      type === 'exchange' &&
      !this.isExchange() &&
      !this.hasComplaintNewItem()
    ) {
      return false;
    }

    const isExchangeInvalid =
      this.isExchange() &&
      (this.isReturnRequested || this.exchangeRefundItemsLength() === 0);
    if (
      type === 'refund' &&
      (!this.isExchange() || isExchangeInvalid) &&
      !this.hasComplaintReason(this.return.items) &&
      this.return.type !== ReturnReasonType.Return
    ) {
      return false;
    }
    return true;
  }

  getSelectedItemsLength(): number {
    if (
      this.isExchange() &&
      this.exchangeRejectedItems() > 0 &&
      !this.isPackageReceived
    ) {
      return this.return.items.filter(
        (item: Item) =>
          item.decision &&
          item.merchant_compensation_choice === CompensationOption.NewItem
      ).length;
    }
    if (
      ((this.isExchange() ||
        this.hasComplaintReason(this.return.items) ||
        this.return.type === ReturnReasonType.Return) &&
        this.isPackageReceived) ||
      this.isComplaintRequestedNoReturn()
    ) {
      return this.return.items.filter(
        (item: Item) =>
          item.merchant_compensation_choice === CompensationOption.Refund &&
          item.decision &&
          item.status !== ReturnItemStatusEnum.ItemDenied
      ).length;
    }
    return this.return.items.filter((item: Item) => item.selected).length;
  }

  showLoadingOverlay(): void {
    this.dialogRef = this.dialog.open(LoaderComponent, {
      panelClass: 'transparent',
      disableClose: true,
    });
    this.dialogRef.componentInstance.size = 'large';
  }

  shouldAutoGenerate(): boolean {
    return (
      this.autoSlip &&
      !this.errorAutoGeneratingSlip &&
      this.return.status != ReturnStatusEnum.AwaitingShippingSlip
    );
  }

  confirmRequestReceived(): ReturnStatusEnum {
    let status = ReturnStatusEnum.Undefined;
    if (this.shouldAutoGenerate()) {
      this.generateSlip(null);
      return ReturnStatusEnum.AwaitingDropOff;
    }

    switch (this.selectedReturnShipping.type) {
      case SelectedShippingOptionsEnum.NoShippingSlip:
        status = ReturnStatusEnum.ShippingSlipSkipped;
        break;
      case SelectedShippingOptionsEnum.AttachShippingSlip:
        this.attachShippingSlip();
        status = ReturnStatusEnum.ManualShippingSlipAttached;
        break;
      default:
        status = ReturnStatusEnum.AwaitingDropOff;
        this.generateSlip(this.selectedReturnShipping.type);
        break;
    }
    return status;
  }

  confirmAwaiting(): ReturnStatusEnum {
    let status = ReturnStatusEnum.Undefined;
    const statusMappings = new Map<
      SelectedShippingOptionsEnum,
      ReturnStatusEnum
    >([
      [
        SelectedShippingOptionsEnum.PostNordVarubrev,
        ReturnStatusEnum.PackageSent,
      ],
      [
        SelectedShippingOptionsEnum.PostNordReturnDropOff,
        ReturnStatusEnum.PackageSent,
      ],
      [
        SelectedShippingOptionsEnum.NoShippingSlip,
        ReturnStatusEnum.ShippingSlipSkipped,
      ],
      [
        SelectedShippingOptionsEnum.AttachShippingSlip,
        ReturnStatusEnum.ManualShippingSlipAttached,
      ],
    ]);
    const isAwaitingShippingSlip =
      this.return.status === ReturnStatusEnum.AwaitingShippingSlip;

    if (
      !this.autoSlip &&
      isAwaitingShippingSlip &&
      this.selectedReturnShipping
    ) {
      status =
        statusMappings.get(this.selectedReturnShipping.type) ??
        ReturnStatusEnum.Undefined;
    } else if (
      this.autoSlip &&
      this.return.status === ReturnStatusEnum.AwaitingDropOff
    ) {
      status = ReturnStatusEnum.PackageReceived;
    }
    return status;
  }

  confirmPackageReceived(): ReturnStatusEnum {
    const selectedItemCount = this.return.items.filter(
      (item: Item) => item.selected
    ).length;
    const totalItemsCount = this.return.items.length;
    if (selectedItemCount === totalItemsCount) {
      return ReturnStatusEnum.RefundApproved;
    } else if (selectedItemCount !== 0) {
      return ReturnStatusEnum.RefundPartiallyApproved;
    } else {
      return ReturnStatusEnum.RefundDenied;
    }
  }

  checkRejectedItems(): void {
    const rejectedItems = this.return.items.filter((item) => !item.decision);

    if (!this.isPackageReceived || rejectedItems.length === 0) {
      if (
        this.selectedReturnShipping.name ===
        SelectedShippingOptionsEnum.NoShippingSlip
      ) {
        this.modalService.open('confirm-no-slip');
        return;
      }

      this.confirm('confirm');
      return;
    }

    this.rejectItemsModal = this._dialog.open(RejectItemsModalComponent, {
      data: { itemsCount: rejectedItems.length },
    });
    this.rejectItemsModal.afterClosed().subscribe((res) => {
      if (res === 'confirm') {
        this.confirm('confirm');
      }
    });
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  confirm(type: string): void {
    this.closeModal();
    this.showLoadingOverlay();
    let status: ReturnStatusEnum = ReturnStatusEnum.Undefined;
    if (type === 'received') {
      status = ReturnStatusEnum.PackageReceived;
    } else if (type === 'undo') {
      status = ReturnStatusEnum.PackageSent;
    } else if (type === 'confirm') {
      if (
        (this.isReturnRequested &&
          !this.isComplaintRefund &&
          !this.isComplaintReturnItems) ||
        (this.isReturnRequested &&
          this.isComplaintReturnItems &&
          !this.allItemsDeclined())
      ) {
        status = this.confirmRequestReceived();
      } else if (
        this.return.status === ReturnStatusEnum.AwaitingDropOff ||
        (this.isComplaintReturnItems &&
          !(
            this.return.status === ReturnStatusEnum.AwaitingShippingSlip &&
            this.allItemsDeclined()
          ) &&
          this.return.status !== ReturnStatusEnum.PackageReceived)
      ) {
        status = this.confirmAwaiting();
      } else if (
        (this.isComplaintRefund && !this.isComplaintReturnItems) ||
        this.return.status === ReturnStatusEnum.PackageReceived ||
        ((this.return.status === ReturnStatusEnum.RequestReceived ||
          this.return.status === ReturnStatusEnum.AwaitingShippingSlip) &&
          this.allItemsDeclined())
      ) {
        status = this.confirmPackageReceived();
      }
    }

    if (
      type === 'confirm' &&
      (this.return.status === ReturnStatusEnum.RequestReceived ||
        this.return.status === ReturnStatusEnum.AwaitingShippingSlip) &&
      status !== ReturnStatusEnum.PackageReceived &&
      this.selectedReturnShipping.name ===
        SelectedShippingOptionsEnum.AttachShippingSlip
    ) {
      const selectedItems = this.return.items.filter(
        (item: Item) => item.selected
      );

      if (selectedItems.length > 0) {
        // If there is items selected and there is a shipping slip attached upload it and update the return
        this.updateReturn(status, type, this.isComplaintReturnItems);
        this.attachShippingSlip();
      } else {
        // No items selected update the return as refund denied
        this.updateReturn(
          ReturnStatusEnum.RefundDenied,
          type,
          this.isComplaintReturnItems
        );
      }
    } else {
      this.updateReturn(
        status,
        type,
        complaintReturnItems(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        )
      );
    }
  }

  attachShippingSlip(): void {
    const items: SlipItem[] = [];
    this.return.items.forEach((item: Item) => {
      items.push({
        id: item.id,
        is_included: [
          ReturnItemStatusEnum.ItemDenied,
          ReturnItemStatusEnum.RefundDenied,
        ].includes(item.status as ReturnItemStatusEnum)
          ? false
          : true,
        cause_for_action: item.reject_cause,
        refund_amount: this.getRefundAmountBasedOnItemStatus(
          item.return_amount,
          item.status
        ),
        merchant_compensation_choice: item.merchant_compensation_choice
      });
    });
    this.returnShippingService
      .attachShippingSlip(
        this.return.id,
        this.return.type === ReturnReasonType.Complaint
          ? this.return.refund_original_shipping
          : null,
        this.return.type === ReturnReasonType.Complaint
          ? this.return.return_shipping_cost
          : null,
        this.selectedFile[0],
        items
      )
      .subscribe({
        next: (res: boolean) => {
          if (res) {
            this.returnShippingService
              .getManualSlip(this.return.id)
              .subscribe((resSlip: SlipInfo) => {
                this.returnShippingService.setReturnShippingSlip(resSlip);
              });
            this.isLoading = false;
            this.onReturnUpdate();
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to attach shipping slip!'
          );
          this.isLoading = false;
          this.dialogRef.close();
        },
      });
  }

  //Send the rejected items, accepted items and the rejection cause to the generate shipping slip method

  generateSlip(value: any): void {
    // Get the return address id from shipping locations
    const return_address_id = this.shippingSettings.shipping_locations.find(
      (location: ShippingLocation) => location.is_default_shipping_location
    )?.id;
    const items: SlipItem[] = [];
    this.return.items.forEach((item: Item) => {
      items.push({
        id: item.id,
        is_included:
          (this.isReturnRequested && !item.selected) ||
          [
            ReturnItemStatusEnum.ItemDenied,
            ReturnItemStatusEnum.RefundDenied,
          ].includes(item.status as ReturnItemStatusEnum)
            ? false
            : true,
        cause_for_action: item.reject_cause,
        refund_amount: this.getRefundAmountBasedOnItemStatus(
          item.return_amount,
          item.status
        ),
        merchant_compensation_choice: item.merchant_compensation_choice
      });
    });
    if (return_address_id) {
      this.returnShippingService
        .generateSlip(
          this.return.id,
          return_address_id,
          value,
          this.return.type === ReturnReasonType.Complaint
            ? this.return.refund_original_shipping
            : null,
          this.return.type === ReturnReasonType.Complaint
            ? (this.return.charge_return_shipping ? this.return.return_shipping_cost : '0')
            : null,
          items
        )
        .subscribe({
          next: (slip: GenerateSlipResponse) => {
            this.errorAutoGeneratingSlip = false;
            if (slip) {
              this.returnShippingService.getSlipInfo(this.return.id).subscribe({
                next: (res: SlipInfo) => {
                  this.returnShippingService.setReturnShippingSlip(res);
                },
                error: () => {
                  this.errorService.showErrorSnackBar(
                    'Failed to get slip informations!'
                  );
                },
              });
              this.onReturnUpdate();
            }
            this.dialogRef.close();
          },
          error: (error) => {
            if (
              this.selectedReturnShipping.type ===
                this.selectedShippingOptionsEnum.PostNordVarubrev &&
              this.return.items.find((item: Item) => item.grams > 2000)
            ) {
              this.errorService.showErrorSnackBar(
                "Failed to generate a shipping slip because the order's weight was over 2kg!"
              );
            } else {
              const errorMessage =
                error?.error?.title &&
                error?.error?.code !== 'InternalServerError'
                  ? error.error.title
                  : 'Failed to generate slip!';
              this.errorService.showErrorSnackBar(errorMessage);
            }
            this.isLoading = false;
            this.errorAutoGeneratingSlip = true;
            this.dialogRef.close();
          },
          complete: () => {
            this.dialogRef.close();
          },
        });
    }
    else{
      setTimeout(() => 
        {
          this.errorService.showErrorSnackBar("To be able to generate a slip, you first need to select a return address in settings");
          this.isLoading = false;
          this.dialogRef.close();
        },
        400);
    }
  }

  setSelectedItems(): void {
    const excludedItem: ExcludedItem = {
      id: '',
      rejection_cause: '',
    };

    this.return.items.forEach((item) => {
      const foundSelected = this.selectedItems.indexOf(item.id);
      const foundExcluded = this.excludedItems
        .map((item) => item.id)
        .indexOf(item.id);

      if (!item.selected && foundExcluded === -1) {
        excludedItem.id = item.id;
        excludedItem.rejection_cause = item.reject_cause;
        this.excludedItems.push(excludedItem);

        if (foundSelected !== -1) {
          this.selectedItems.splice(foundSelected, 1);
        }
      }

      if (item.selected && foundSelected === -1) {
        this.selectedItems.push(item.id);
        if (foundExcluded !== -1) {
          this.excludedItems.splice(foundExcluded, 1);
        }
      }
    });

    this.excludedItems.find((excludedItem) => {
      this.return.items.find((item) => {
        if (
          item.reject_cause !== excludedItem.rejection_cause &&
          item.reject_cause !== undefined &&
          item.id === excludedItem.id
        ) {
          excludedItem.rejection_cause = item.reject_cause;
        }
      });
    });
  }

  updateReturn(
    status: ReturnStatusEnum,
    type: string,
    isComplaint: boolean
  ): void {
    const items: UpdateItem[] = [];
    let itemSelected = false;
    // Assigning the status of items
    this.return.items.forEach((item: Item) => {
      let itemStatus = '';
      if (type === 'received') {
        item.status === ReturnItemStatusEnum.ItemDenied
          ? (itemStatus = ReturnItemStatusEnum.ItemDenied)
          : (itemStatus = ReturnItemStatusEnum.ItemReceived);
      } else if (type === 'undo') {
        item.status === ReturnItemStatusEnum.ItemDenied
          ? (itemStatus = ReturnItemStatusEnum.ItemDenied)
          : (itemStatus = ReturnItemStatusEnum.AwaitingDropOff);
      } else if (
        [
          ReturnStatusEnum.RefundApproved,
          ReturnStatusEnum.RefundDenied,
          ReturnStatusEnum.RefundPartiallyApproved,
        ].includes(status)
      ) {
        !item.decision || item.status === ReturnItemStatusEnum.ItemDenied
          ? (itemStatus = ReturnItemStatusEnum.RefundDenied)
          : (itemStatus = ReturnItemStatusEnum.RefundApproved);
        item.status === ReturnItemStatusEnum.ItemDenied
          ? (itemStatus = ReturnItemStatusEnum.ItemDenied)
          : itemStatus;
      } else {
        itemStatus = item.selected
          ? ReturnItemStatusEnum.Requested
          : ReturnItemStatusEnum.ItemDenied;
      }

      if (item.selected) itemSelected = true;
      const itemObj = {
        id: item.id,
        status: itemStatus,
        reject_cause: item.reject_cause ?? '',
        merchant_compensation_choice: item.merchant_compensation_choice,
        refund_amount: this.getRefundAmountBasedOnItemStatus(
          item.return_amount,
          itemStatus
        ),
        should_restock: this.returnSettings.use_manual_restock
          ? item.should_restock
          : null,
      };
      items.push(itemObj);
    });

    //Should not call this code if returnstatus is awaitingdropoff or manualshippingslipattached
    if (
      status !== ReturnStatusEnum.AwaitingDropOff &&
      status !== ReturnStatusEnum.ManualShippingSlipAttached
    ) {
      const requestObj: UpdateReturnRequest = {
        id: this.return.id,
        shopify_order_id: this.return.shopify_order_id,
        account_id: this.return.account_id,
        domain: this.return.domain,
        status: itemSelected ? status : ReturnStatusEnum.RefundDenied,
        return_shipping_cost: Number(this.return.return_shipping_cost),
        charge_return_shipping: this.return.charge_return_shipping,
        refund_original_shipping: this.return.refund_original_shipping,
        items: items,
      };

      this.returnsService
        .updateReturn(this.setReturnStatus(requestObj))
        .subscribe({
          next: (res: boolean) => {
            if (res) {
              this.returnsService.getReturnById(this.return.id).subscribe({
                next: (returnOrder: ReturnRequest) => {
                  this.return = returnOrder;
                  this.returnsService.setSelectedReturnSubject(
                    returnOrder,
                    true
                  );
                  this.itemsTableService.setRequestToUpdate(
                    this.return.id,
                    this.return.status
                  );
                  this.dialogRef.close();
                  if (isComplaint && this.isResolved) {
                    this.popupService.showMessageSnackBar('Complaint resolved');
                  }
                },
                error: () => {
                  this.dialogRef.close();
                  this.errorService.showErrorSnackBar('Failed to get return');
                },
              });
            }
            this.isLoading = false;
          },
          error: (err: HttpErrorResponse) => {
            const message =
              err.error.code === 'ItemOutOfStockError'
                ? `${this.return.type} not resolved, some item(s) are out of stock`
                : 'Failed to update return';
            this.errorService.showErrorSnackBar(message);
            this.isLoading = false;
            this.dialogRef.close();
          },
        });
    }
  }

  //If one item is rejected set it to partiallyRefunded, if all items are rejected set it to refundDenied. If all items are approved set it to refundApproved.
  setReturnStatus(returnRequest: UpdateReturnRequest): UpdateReturnRequest {
    const hasApproved = returnRequest.items.some(
      (item) => item.status === ReturnItemStatusEnum.RefundApproved
    );
    const hasRejected = returnRequest.items.some(
      (item) => item.status === ReturnItemStatusEnum.RefundDenied
    );

    if (hasApproved && hasRejected) {
      returnRequest.status = ReturnStatusEnum.RefundPartiallyApproved;
    }

    if (hasApproved && !hasRejected) {
      returnRequest.status = ReturnStatusEnum.RefundApproved;
    }

    if (!hasApproved && hasRejected) {
      returnRequest.status = ReturnStatusEnum.RefundDenied;
    }
    return returnRequest;
  }

  resendEmail(retunId: string): void {
    this.isResendingEmail = true;
    this.returnsService.resendEmail(retunId).subscribe({
      next: (success: boolean) => {
        this.hasResentEmail = success;
        if (!success) {
          this.errorService.showErrorSnackBar('Failed to resend e-mail');
        }
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to resend e-mail');
      },
      complete: () => {
        this.isResendingEmail = false;
      },
    });
  }

  getRefundAmountBasedOnItemStatus(
    originalRefundAmount: number,
    itemStatus: string
  ): number {
    const isItemDenied =
      itemStatus === ReturnItemStatusEnum.ItemDenied ||
      itemStatus === ReturnItemStatusEnum.RefundDenied;
    return isItemDenied ? 0 : originalRefundAmount;
  }

  onReturnUpdate(): void {
    this.returnsService.getReturnById(this.return.id).subscribe({
      next: (returnOrder: ReturnRequest) => {
        this.return = returnOrder;
        this.returnsService.setSelectedReturnSubject(returnOrder, true);
        this.itemsTableService.setRequestToUpdate(
          this.return.id,
          this.return.status
        );
        this.dialogRef.close();
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to get return');
      },
      complete: () => {
        this.dialogRef.close();
      },
    });
  }

  getTableTitle(): string {
    return `${this.return.type} items (${this.return.items.length})`;
  }

  allItemsDeclined(): boolean {
    return this.return.items.every(
      (item: Item) =>
        !item.decision || item.status === ReturnItemStatusEnum.RefundDenied
    );
  }

  getReturnSettings(): void {
    this.returnSettingsService.getSubjectReturnSettings().subscribe({
      next: (returnSettings: ReturnSettings) => {
        this.returnSettings = returnSettings;
        this.autoSlip = returnSettings.autogenerated_slips;
        this.printedSlips = returnSettings.printed_return_slips;
        this.hasReturnSettingsLoaded = true;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load return settings');
      },
    });
  }

  getShippingSettings(): void {
    this.shippingSettingsService.getSubjectShippingSettings().subscribe({
      next: (shippingSettings: ShippingSettings) => {
        this.shippingSettings = shippingSettings;
        this.getShippingLocations();
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load shipping settings');
      },
    });
  }

  getShippingLocations(): void {
    this.locationSettingsService.getSubjectLocationSettings().subscribe({
      next: (locations: ShippingLocation[]) => {
        this.shippingSettings.shipping_locations = locations;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load locations');
      },
    });
  }

  onGetSelectedReturn(): void {
    this.returnsService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
        this.return = res;
        if (this.return.items.length > 0) {
          this.amountSelected = this.return.items.length;
          this.return.items.forEach((item: Item) => {
            if (!item.selected && item.selected !== false) {
              item.selected = true;
            }
            if (this.firstTime) {
              item.decision = true;
            }
          });
          this.firstTime = false;
          this.hasResentEmail = false;
          if (this.complaintSettings) {
            this.isComplaintRefund = this.complaintRefund(
              this.return.items,
              this.return.status,
              this.complaintSettings.should_item_be_returned,
              this.complaintSettings.should_send_instant_refund
            );
            this.isComplaintReturnItems = this.complaintReturnItems(
              this.return.items,
              this.return.status,
              this.complaintSettings.should_item_be_returned
            );
          }
          this.assignStatus();
          if (this.hasReturnSettingsLoaded) {
            this.automatic = this.isAcceptedAutomatically();
          }
        }
        this.setSelectedItems();
        this.updateTitle(res);
      });
  }

  assignStatus(): void {
    this.isReturnRequested = this.filterOnStatus(
      FilterStatusTypeEnum.returnRequested,
      true,
      this.return.status
    );
    this.isIncomingPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.incomingPackageReceived,
      true,
      this.return.status
    );
    this.isSlipSkippedAttached = this.filterOnStatus(
      FilterStatusTypeEnum.slipSkippedAttached,
      true,
      this.return.status
    );
    this.isPackageSentDropff = this.filterOnStatus(
      FilterStatusTypeEnum.packageSentDropff,
      true,
      this.return.status
    );
    this.isPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.packageReceived,
      true,
      this.return.status
    );

    this.isResolvedPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.resolvedPackageReceived,
      true,
      this.return.status
    );

    this.isRequestedPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.requestedPackageReceived,
      true,
      this.return.status
    );

    this.isWithShippingSlip = this.filterOnStatus(
      FilterStatusTypeEnum.withShippingSlip,
      true,
      this.return.status
    );
    this.isShippingSlipSkipped = this.filterOnStatus(
      FilterStatusTypeEnum.shippingSlipSkipped,
      true,
      this.return.status
    );
    this.isResolved = this.filterOnStatus(
      FilterStatusTypeEnum.resolved,
      true,
      this.return.status
    );
    this.isIncoming = this.filterOnStatus(
      FilterStatusTypeEnum.incoming,
      true,
      this.return.status
    );
  }

  getComplaintsSettings(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((complaintOptions) => {
        this.complaintSettings = complaintOptions;
      });
  }

  getExchangeSettings(): void {
    this.exchangeService.getExchangeSubject().subscribe((exchangeOptions) => {
      this.exchangeSettings = exchangeOptions;
    });
  }

  isExchange(): boolean {
    return this.return.type === ReturnReasonType.Exchange;
  }

  shouldShowApproveAlert(): boolean {
    if (!this.isReturnRequested) {
      return false;
    }
    if (this.isExchange()) {
      return true;
    }
    return (
      !this.returnSettings.automatically_accept_requests &&
      !this.isComplaintRefund
    );
  }

  isAcceptedAutomatically(): boolean {
    return (
      this.isReturnRequested &&
      ((this.returnSettings.automatically_accept_requests &&
        !this.isExchange()) ||
        (this.isExchange() &&
          this.exchangeSettings.automatically_accept_requests))
    );
  }

  showEmailButton(): boolean {
    return (
      (!this.isExchange() && this.isWithShippingSlip) ||
      (this.isShippingSlipSkipped && !this.automatic && !this.printedSlips)
    );
  }

  exchangeItemsForRefund(): boolean {
    return (
      this.return.items.filter(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.Refund
      ).length === this.return.items.length
    );
  }

  goToOrder(): void {
    window.open(
      this.return.new_shopify_order_link
        ? this.return.new_shopify_order_link
        : this.return.draft_order_link,
      '_blank'
    );
  }

  getResolvedDraftOrderAlert(): string {
    if (this.hasAcceptedItemExchangeDraftOrder()) {
      return orderAlertText.resolvedAcceptedExchangeAlert;
    } else if (
      this.exchangeNoNewItems() ||
      this.allExchangeItemsRejected() ||
      !this.exchangeNewItems()
    ) {
      return orderAlertText.rejectedRefundedExchangeAlert;
    }
    return orderAlertText.draftDeleteOrderAlert;
  }

  getResolvedExchangeAlertInfo(): string {
    if (this.exchangeSettings.draft_order) {
      return this.getResolvedDraftOrderAlert();
    } else {
      if (this.showNewOrderAlertComplaint()) {
        return orderAlertText.complaintNewItemsAlert;
      }

      if (this.allExchangeNewItemsAccepted()) {
        return orderAlertText.sendNewItemsAlert;
      } else if (this.allExchangeItemsRejected()) {
        return orderAlertText.deleteOrderAlert;
      } else if (
        this.exchangeRefundItemsLength() > 0 &&
        this.exchangeItemsForRefund()
      ) {
        return orderAlertText.editOrderAlert;
      } else if (this.exchangeRejectedItems()) {
        return orderAlertText.rejectedItemExchangeAlert;
      }
    }
    return orderAlertText.draftDeleteOrderAlert;
  }

  showAutoAcceptAlert(): boolean {
    if (this.return.status !== ReturnStatusEnum.AwaitingShippingSlip)
      return false;
    if (this.return.type === ReturnReasonType.Complaint) {
      return false;
    }
    return true;
  }

  exchangeRejectedItems(): number {
    return this.return.items.filter(
      (item) =>
        !item.decision || item.status === ReturnItemStatusEnum.RefundDenied
    ).length;
  }

  allExchangeItemsRejected(): boolean {
    return (
      this.return.items.length ===
        this.return.items.filter(
          (item) => item.status === ReturnItemStatusEnum.RefundDenied
        ).length && this.isResolved
    );
  }
  allExchangeNewItemsAccepted(): boolean {
    return (
      this.return.items.length ===
      this.return.items.filter(
        (item) =>
          item.status === ReturnItemStatusEnum.RefundApproved &&
          item.merchant_compensation_choice === CompensationOption.NewItem
      ).length
    );
  }

  exchangeRefundItemsLength(): number {
    return this.return.items.filter(
      (item) => item.merchant_compensation_choice === CompensationOption.Refund
    ).length;
  }

  hasComplaintNewItem(): boolean {
    return (
      this.hasComplaintReason(this.return.items) &&
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.NewItem
      )
    );
  }

  getTableSubtitleText(): string {
    if (!this.complaintSettings.should_item_be_returned) {
      return this.isResolved
        ? 'Items not returned'
        : 'Items not to be returned';
    }
    return this.isResolved ? 'Items returned' : 'Items to be returned';
  }

  isComplaintRequestedNoReturn(): boolean {
    return (
      this.hasComplaintReason(this.return.items) &&
      this.isReturnRequested &&
      !this.complaintSettings.should_item_be_returned
    );
  }

  showNewOrderAlertExchange(): boolean {
    return (
      this.isExchange() &&
      !this.isAllItemsSetForStoreCredit() &&
      this.isIncoming &&
      ((this.return.draft_order_link?.length ?? 0) > 0 || 
      this.return.new_shopify_order_link.length > 0)
    );
  }

  //If all items are set as store credit, a draft order will not be created so the notification alert will not be shown
  isAllItemsSetForStoreCredit(): boolean {
    return this.return.items.every(
      (item) =>
        item.merchant_compensation_choice === CompensationOption.StoreCredit
    );
  }

  showNewOrderAlertComplaint(): boolean {
    return this.hasComplaintNewItem() && this.isResolved;
  }

  showRefundAmountComponent(): boolean {
    return (
      this.isResolvedPackageReceived ||
      (this.isReturnRequested &&
        this.hasComplaintReason(this.return.items) &&
        this.complaintSettings.should_send_instant_refund)
    );
  }

  hasAcceptedItemExchangeDraftOrder(): boolean {
    return (
      this.isExchange() &&
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.NewItem &&
          [
            ReturnItemStatusEnum.ItemReceived,
            ReturnItemStatusEnum.RefundApproved,
          ].includes(item.status as ReturnItemStatusEnum)
      ) &&
      this.exchangeSettings.draft_order
    );
  }

  exchangeTipAlert(): string {
    if (this.exchangeSettings.draft_order) {
      return 'Our system has created a draft order with the exchange item(s) in your Shopify. It will become a real order when you do the final approval on the items.';
    }
    return 'Our system has created a new order with the exchange item(s) in your Shopify. You can fulfill it now or wait until the customer’s item(s) arrive for quality check.';
  }

  exchangeNoNewItems(): boolean {
    return !this.return.items.some(
      (item) => item.merchant_compensation_choice === CompensationOption.NewItem
    );
  }

  exchangeNewItems(): boolean {
    return this.return.items.some(
      (item) =>
        item.merchant_compensation_choice === CompensationOption.NewItem &&
        [
          ReturnItemStatusEnum.ItemReceived,
          ReturnItemStatusEnum.RefundApproved,
        ].includes(item.status as ReturnItemStatusEnum)
    );
  }

  showGoToOrderAlertButton(): boolean {
    if (!this.exchangeSettings.draft_order) {
      return true;
    }

    return this.exchangeNewItems();
  }
}
