<app-settings-section
  id="complaints-item-flow"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [isToggleable]="true"
  (sectionEnabled)="enableComplaints()"
  [switchStatus]="complaintSettings.is_enabled"
  [bannerStats]="bannerStats"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
  <div
    class="mt-8"
    *ngIf="
      !complaintSettings.is_enabled && currentPricePlan?.name === 'Pay-as-You-Go'
    "
  >
    <app-alert
      icon="help_outline"
      type="Information"
      size="small"
      [showCloseButton]="true"
      text="If you have the Pay-as-you go plan, you will be charged $0.3/complaint, otherwise the limited amount of free complaints are included in Essentials and Pro plans. For more details, please visit the payment plan page."
    >
    </app-alert>
  </div>

  <div class="toggle-view" [ngClass]="{ 'adjust-margin': !showContent }">
    <p (click)="showContent = !showContent" class="expand-text">
      {{
        showContent
          ? "Collapse to hide configuration"
          : "Expand to show configuration"
      }}
    </p>
    <img
      [ngClass]="showContent ? 'arrow' : 'rotate-arrow'"
      src="../assets/images/icons/chevron-up.png"
    />
  </div>

  <ng-container *ngIf="showContent">
    <div>
      <h4 class="title">Upload image</h4>
      <p class="description">
        Choose whether it is optional or mandatory for a customer to upload an
        image and if so, how many of them for each complaint.
      </p>

      <div class="text">
        <app-radio
          [options]="imageOptions"
          name="imageSettings"
          [horizontal]="false"
          [selected]="getSelectedImage()"
          (selectedChange)="setSelectedImage($event)"
          [disabled]="isSaving || !complaintSettings.is_enabled"
          [boldWords]="boldWords"
        >
        </app-radio>
      </div>

      <div
        class="select-section"
        *ngIf="
          complaintSettings.image_upload_option !== PremiumOptions.NotAllowed
        "
      >
        <ng-container
          *ngIf="
            complaintSettings.image_upload_option === PremiumOptions.Optional
          "
        >
          Customer can upload
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !complaintSettings.is_enabled"
              [options]="maxNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMaxPhotos($event)"
              [selectedOption]="getPhotos('max')"
            ></app-animated-dropdown>
          </span>
        </ng-container>

        <ng-container
          *ngIf="
            complaintSettings.image_upload_option === PremiumOptions.Mandatory
          "
        >
          Customer must upload
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !complaintSettings.is_enabled"
              [options]="minNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMinPhotos($event)"
              [selectedOption]="getPhotos('min')"
            ></app-animated-dropdown>
          </span>

          per returned item and can upload up to
          <span class="mr-10 ml-10">
            <app-animated-dropdown
              [disabled]="isSaving || !complaintSettings.is_enabled"
              [options]="maxNumberOfPhotos"
              placeholder="Select"
              size="wide"
              (selectedChange)="setMaxPhotos($event)"
              [selectedOption]="getPhotos('max')"
            ></app-animated-dropdown>
          </span>
        </ng-container>
      </div>
    </div>
    <div>
      <h4 class="title">Image instructions</h4>
      <p class="description">
        Here you can include additional instructions for how the customers
        should take a picture of the item.
      </p>

      <app-checkbox
        size="medium"
        (checkedChange)="toggleInstructions($event)"
        [checked]="complaintSettings.has_image_instructions"
        [disabled]="isSaving || !complaintSettings.is_enabled"
        ><span class="text">
          Include instructions for the image that will appear to the customer
        </span>
      </app-checkbox>

      <h4 class="title mb-5">Instructions</h4>
      <input
        (focusout)="saveSettings()"
        class="text-input"
        type="text"
        [disabled]="
          !complaintSettings.has_image_instructions ||
          isSaving ||
          !complaintSettings.is_enabled
        "
        placeholder="e.g. We need the photo of the sides and the front."
        [(ngModel)]="complaintSettings.image_instructions_field"
      />
      <p class="information">
        Please keep it to 60 characters. Visible to the customer.
      </p>
    </div>
    <div>
      <h4 class="title">Comment on returned item</h4>
      <p class="description">
        Choose whether you want to make it optional or mandatory for a customer
        to write a comment about an item they are returning.
      </p>

      <div class="text">
        <app-radio
          [options]="summaryOptions"
          name="summarySetting"
          [horizontal]="false"
          [selected]="getSelectedSummary()"
          (selectedChange)="setSelectedSummary($event)"
          [disabled]="isSaving || !complaintSettings.is_enabled"
          [boldWords]="boldWords"
        >
        </app-radio>
      </div>
    </div>

    <div fragment="complaints-item-flow">
      <h4 class="title">
        Complaint items flow
      </h4>
      <span class="description">
        Decide whether the items are going to be returned back to you or if you
        want the customer to keep the item.
      </span>
      <div class="mt-15 mb-15">
        <app-alert
          type="Note"
          text="Complaint items cannot be restocked and merchants will have to do it manually on Shopify."
          imgIcon="../assets/images/icons/alert-triangle.png"
          size="large"
        ></app-alert>
      </div>

      <div>
        <app-checkbox
          size="small"
          (checkedChange)="setShouldBeReturned($event)"
          [checked]="complaintSettings.should_item_be_returned"
          [disabled]="isSaving || isLoading"
          >I want to receive the complaint items back to me
        </app-checkbox>
        <p class="information mt-12 mb-12">
          If you don't have this option selected then the complaint items that
          you approve to be refunded <b>won’t be sent back</b> to you, the
          <b>customer will keep the items</b> and
          <b>you will issue a refund</b> as soon as you approve the complaint.
        </p>
      </div>

      <div class="text ml-20">
        <app-radio
          [options]="flowOptions"
          name="flowSetting"
          [horizontal]="false"
          [selected]="getSelectedFlow()"
          (selectedChange)="setSelectedFlow($event)"
          [disabled]="
            isSaving ||
            !complaintSettings.is_enabled ||
            !complaintSettings.should_item_be_returned
          "
          [boldWords]="boldWords"
        >
        </app-radio>
      </div>
    </div>

    <!-- Hidden until we have more than 1 option -->
    <!-- <div>
      <h4 class="title">What can customers request</h4>
      <p class="description">Returbo only allows for a refund at this moment.</p>
  
      <div class="text">
        <app-radio
          [options]="complaintSettings.compensation_options"
          name="requestSetting"
          [horizontal]="false"
          [selected]="getSelectedRequest()"
          (selectedChange)="setSelectedRequest($event)"
          [disabled]="isSaving"
        >
        </app-radio>
      </div>
    </div> -->

    <div class="text mb-0">
      <p>
        Even if you have auto-approve “ON” for requests in
        <span class="link" (click)="goToPage('accepting-return-requests')"
          >Return settings</span
        >, complaints flow will always have manual approval.
      </p>
    </div>
  </ng-container>
</app-settings-section>
