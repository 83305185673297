<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [onBoardingStepCompleted]="onBoardingCompleted"
  id="locations-component"
>
  <div class="wrap" *ngIf="!isLoading">
    <div
      class="note mt-16 mb-16"
      *ngIf="this.onboardingStatus === 'Uncompleted'"
    >
      <img src="assets/images/icons/alert-triangle.png" class="mr-15" />
      <p class="font-bold mr-5">Note:</p>
      <p>{{ note }}</p>
    </div>

    <div class="locations">
      <!-- Code used to highlight default location -->
      <!-- [ngClass]="location.is_default_shipping_location ? 'selected':'unselected'" -->
      <div
        class="location"
        *ngFor="let location of getLocations()"
        (click)="toggleDetails(location.id)"
      >
        <div class="info">
          <div class="address-row">
            <span>
              <b>{{ location.receiver }}</b> {{ location.street }},
              {{ location.zip_code }} {{ location.city }},
              {{ location.country_code }}
            </span>
            <div
              class="toggle-view"
              *ngIf="location.door_number || location.additional_instructions"
            >
              <img
                [ngClass]="displayDetails(location) ? 'arrow' : 'rotate-arrow'"
                src="../assets/images/icons/chevron-up.png"
              />
            </div>
          </div>
          <div class="details-row" *ngIf="displayDetails(location)">
            <span> {{ location.door_number }}. </span>
            <span class="additional-instructions">
              {{ location.additional_instructions }}
            </span>
          </div>
        </div>
        <div class="icons-section">
          <div
            class="invalid-status mr-10"
            *ngIf="
              location.shopify_location_id &&
              (!location.city ||
                !location.zip_code ||
                !location.country_code ||
                !location.street)
            "
          >
            Invalid Address
          </div>

          <div class="status" *ngIf="location.is_default_shipping_location">
            <span> Return address </span>
          </div>
          <div
            class="status"
            *ngIf="location.is_restock_to_location_id"
          >
            <span> Restock address </span>
          </div>
          <div
            class="status"
            *ngIf="location.is_send_from_location_id"
          >
            <span> Send from address </span>
          </div>
          <div class="shopify-logo" *ngIf="location.shopify_location_id">
            <img src="assets/images/shopify-logo.png" />
          </div>
          <span class="overlay-menu">
            <app-overlay-menu (click)="toggleDetails(location.id)">
              <button
                mat-menu-item
                (click)="editLocation(location)"
                class="menu"
              >
                <img src="assets/images/icons/edit.png" class="menu-icon" />
                <span>Edit address</span>
              </button>
              <button
                mat-menu-item
                (click)="removeLocation(location)"
                class="menu"
                *ngIf="!location.shopify_location_id"
              >
                <img src="assets/images/icons/delete.png" class="menu-icon" />
                <span>Delete address</span>
              </button>
            </app-overlay-menu>
          </span>
        </div>
      </div>
    </div>

    <div class="actions">
      <app-button
        buttonStyle="primary"
        size="wide"
        [disabled]="isSaving"
        (click)="createLocation()"
      >
        Add location
      </app-button>
    </div>
  </div>
</app-settings-section>

<app-modal 
  id="location-modal" 
  [dismissable]="!isSaving"
  (dismissedEvent)="cancelEdit()" 
>
  <div id="title-section">
    <h2 *ngIf="activeLocation.id">
      Edit location
      <img
        src="assets/images/shopify-logo.png"
        *ngIf="!!activeLocation.shopify_location_id"
      />
    </h2>
    <h2 *ngIf="!activeLocation.id">Add location</h2>
    <p *ngIf="activeLocation.id && !!activeLocation.shopify_location_id">
      Locations fetched from Shopify can only be partially edited and cannot be
      deleted. Additionally, restocks can only be delivered there.
    </p>
  </div>

  <div class="warning" *ngIf="disabled">
    <img src="assets/images/icons/alert-octagon.png" />
    <p>
      <span class="font-bold">Warning: </span>
      It seems like this address does not exist or is missing some important
      parts. Please go to your Shopify store and provide the needed information,
      so that the address can be used for customer returns here.
    </p>
  </div>

  <form [formGroup]="locationForm" (ngSubmit)="saveLocation()">
  <div class="edit-location" *ngIf="activeLocation !== null">
    <div class="section">
      <div class="group">
        <label for="receiver">
          Name (firm or person)
          <span class="required-asterisk"> *</span>
        </label>

        <input
          id="receiver"
          formControlName="receiver"
          type="text"
          [ngClass]="{
            error: (locationForm.controls['receiver'].invalid && 
              locationForm.controls['receiver'].dirty)
          }"
          [placeholder]="'e.g. Company name'"
          [disabled]="isSaving"
          required
        />
        <p
          *ngIf="locationForm.controls['receiver'].invalid &&
            locationForm.controls['receiver'].dirty
          "
          class="error"
        >
          Name is required. Please provide a name shorter than 100 characters.
        </p>
      </div>

      <div class="group">
        <label for="doorNumber">
          Apartment no. or C/O
        </label>

        <input
          id="doorNumber"
          formControlName="door_number"
          type="text"
          [placeholder]="'e.g. C/O Sven Svensson'"
          [disabled]="isSaving"
          [ngClass]="{ 
            error: (locationForm.controls['door_number'].invalid &&
              locationForm.controls['door_number'].dirty)
          }"
        />
        <p
          *ngIf="locationForm.controls['door_number'].invalid &&
            locationForm.controls['door_number'].dirty
          "
          class="error"
        >
          Please provide a value shorter than 100 characters.
        </p>
      </div>

      <div class="group">
        <label for="city">
          City
          <span class="required-asterisk"> *</span>
        </label>

        <input
          id="city"
          formControlName="city"
          type="text"
          [ngClass]="{ 
            error: (locationForm.controls['city'].invalid && 
              locationForm.controls['city'].dirty),
            disabled: activeLocation.shopify_location_id
          }"
          [placeholder]="'e.g. Stockholm'"
          [disabled]="!!activeLocation.shopify_location_id || isSaving"
          required
        />
        <p
          *ngIf="locationForm.controls['city'].invalid &&
            (locationForm.controls['city'].dirty || 
            !!activeLocation.shopify_location_id)
          "
          class="error"
        >
          City is required. Please provide a city shorter than 80 characters.
        </p>
      </div>

      <div class="group">
        <label for="additionalInstructions">
          Additional instructions
        </label>

        <input
          id="additionalInstructions"
          formControlName="additional_instructions"
          type="text"
          [placeholder]="'e.g. Leave the parcel by the door'"
          [disabled]="isSaving"
          [ngClass]="{ 
            error: (locationForm.controls['additional_instructions'].invalid &&
              locationForm.controls['additional_instructions'].dirty)
          }"
        />
        <p
          *ngIf="locationForm.controls['additional_instructions'].invalid
          "
          class="error"
        >
          The instructions can only be up to 35 characters.
        </p>
      </div>

      <div class="group">
        <mat-checkbox
          id="restock"
          formControlName="is_restock_to_location_id"
          *ngIf="activeLocation.shopify_location_id"
          class="checkbox"
          [disabled]="isSaving || disabled"
          color="accent"
          >
          <p for="restock">
            Set as default <b>restock</b> location</p>
        </mat-checkbox>

        <mat-checkbox
          class="checkbox"
          [disabled]="isSaving || disabled"
          id="returnLocation"
          formControlName="is_default_shipping_location"
          color="accent"
        >
          <p for="returnLocation">
            Set as default <b>return</b> location, where
            <b>all returns will be sent to</b>
          </p>
        </mat-checkbox>

        <mat-checkbox
          id="sendFrom"
          formControlName="is_send_from_location_id"
          *ngIf="activeLocation.shopify_location_id"
          class="checkbox"
          [disabled]="isSaving || disabled"
          color="accent"
        >
          <p for="sendFrom">
            Set as default location from where the items will be
            <b>reserved/sent from (for draft orders)</b>
          </p>
        </mat-checkbox>
      </div>

      <div class="actions">
        <app-new-button
          [disabled]="isSaving || locationForm.invalid || disabled"
          size="wide"
          (click)="saveLocation()"
          type="submit"
        >
          {{ !activeLocation.id ? "Add location" : "Update location" }}
          <app-loader
            *ngIf="isSaving"
            class="ml-8"
            size="button-sized"
            type="primary-thin"
          ></app-loader>
        </app-new-button>
      </div>
    </div>

    <div class="section">
      <div class="group">
        <label for="street">
          Street
          <span class="required-asterisk"> *</span>
        </label>
        <input
          id="street"
          formControlName="street"
          type="text"
          [ngClass]="{ 
            error: (locationForm.controls['street'].invalid && 
              locationForm.controls['street'].dirty),
            disabled: activeLocation.shopify_location_id
          }"
          [placeholder]="'e.g. Ankeborgsvägen 12'"
          [disabled]="!!activeLocation.shopify_location_id || isSaving"
          required
        />
        <p
          *ngIf="locationForm.controls['street'].invalid &&
            (locationForm.controls['street'].dirty || 
            !!activeLocation.shopify_location_id)
          "
          class="error"
        >
          Street is required. Please provide a street shorter than 100 characters.
        </p>
      </div>

      <div class="group">
        <label for="zipCode">
          Zip code
          <span class="required-asterisk"> *</span>
        </label>
        <input
          id="zipCode"
          formControlName="zip_code"
          type="text"
          [ngClass]="{ 
            error: (locationForm.controls['zip_code'].invalid &&
              locationForm.controls['zip_code'].dirty), 
            disabled: activeLocation.shopify_location_id
          }"
          [placeholder]="'e.g. 112 63'"
          [disabled]="!!activeLocation.shopify_location_id || isSaving"
          required
        />
        <p
          *ngIf="locationForm.controls['zip_code'].invalid &&
            (locationForm.controls['zip_code'].dirty || 
            !!activeLocation.shopify_location_id)
          "
          class="error"
        >
          The zip code is required. Please provide a zip code between 2 and 20 characters.
        </p>
      </div>

      <div class="group">
        <label for="countryCode">
          Country code<span class="required-asterisk"> *</span>
        </label>

        <mat-select
          class="select"
          [ngClass]="{ 
            error: (locationForm.controls['country_code'].invalid && 
              (locationForm.controls['country_code'].dirty ||
              locationForm.controls['country_code'].touched)),
            disabled: activeLocation.shopify_location_id
          }"
          (change)="selectItem($event)"
          formControlName="country_code"
          [disabled]="activeLocation.shopify_location_id > 0"
        >
          <mat-select-trigger>
            {{ locationForm.controls['country_code'].value ? locationForm.controls['country_code'].value : "Select" }}
          </mat-select-trigger>
          <mat-option value="">Select</mat-option>
          <mat-option
            class="option"
            *ngFor="let country of countries" 
            [value]="country.iso_code"
          >
            <p>{{ country.iso_code }}</p>
            <p class="country-name">{{ country.name }}</p>
          </mat-option>
        </mat-select>
        <p
        *ngIf="locationForm.controls['country_code'].invalid &&
          (locationForm.controls['country_code'].touched || 
          !!activeLocation.shopify_location_id)
        "
        class="error"
      >
        The country is required. Please select a country from the list.
      </p>
      </div>
    </div>
  </div>
</form>
</app-modal>

<app-modal id="change-default" size="x-small-rectangle" [dismissable]="false">
  <h1>Update carrier default address</h1>
  <p>
    We can see that you are using your current default return address as your
    return location for some of your shipping carriers. Would you like to update
    these carriers to use this address?
  </p>

  <div class="actions">
    <app-new-button buttonStyle="cancel" (click)="handleDefaultPrompt('close')"
      >No</app-new-button
    >
    <app-new-button
      [disabled]="isSaving"
      (click)="handleDefaultPrompt('update')"
      >Yes
      <app-loader
        class="ml-8"
        *ngIf="isSaving"
        size="button-sized"
        type="primary-thin"
      ></app-loader
    ></app-new-button>
  </div>
</app-modal>
