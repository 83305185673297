<div class="wrap">
    <main>
      <nav>
        <div
          class="link ml-0"
          [routerLink]="['.', 'shipping']"
          routerLinkActive="active"
        >
          Shipping
        </div>
        <div
          class="link"
          [routerLink]="['.', 'other']"
          routerLinkActive="active"
        >
          Other
        </div>
      </nav>
      <router-outlet></router-outlet>
    </main>
  </div>
  