<div class="banner" 
    *ngIf="showBanner()">
    <div class="slide">
        <span class="span-content"><h3>{{ bannerText }}</h3> <app-countdown-timer></app-countdown-timer></span>
    </div>
    <div class="slide">
        <span class="span-content"><h3>{{ bannerText }}</h3> <app-countdown-timer></app-countdown-timer></span>
    </div>
    <div class="slide">
        <span class="span-content"><h3>{{ bannerText }}</h3> <app-countdown-timer></app-countdown-timer></span>
    </div>
</div>
