<div class="header" id="{{ logo }}">
  <div class="d-flex align-center">
    <img
      class="logo"
      src="assets/images/{{ logo }}-logo.png"
      alt="{{ carrier }} Logo"
    />
    <p class="section-title mb-15">{{ carrier }}</p>
  </div>
  <div class="d-flex" *ngIf="!isLoading">
    <button
      *ngIf="showStartIntegration()"
      class="btn btn-primary btn-cp mr-16 float-right"
      (click)="toggleActivation()"
    >
      Start
    </button>
    <span
      *ngIf="hasProccessStarted() || (!hasProccessStarted() && !isStandalone())"
      class="ripple {{ integrationStatus()?.class }}"
    >
      {{ integrationStatus()?.text }}
    </span>

    <img
      *ngIf="!showStartIntegration()"
      (click)="toggleActivation()"
      class="arrow pointer"
      [ngClass]="{ rotate: showActivationSettings }"
      src="../assets/images/icons/chevron-down.png"
    />
  </div>
  <app-loader size="medium" *ngIf="isLoading"></app-loader>
</div>
