import { SummaryEmail } from 'src/app/shared/enums/summary-email.enum';

export const summaryEmails = [
  {
    id: 1,
    interval: SummaryEmail.Daily,
    checked: false,
  },
  {
    id: 2,
    interval: SummaryEmail.Weekly,
    checked: false,
  },
  {
    id: 3,
    interval: SummaryEmail.Monthly,
    checked: false,
  },
];
