import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { PolicySettings } from './policy-settings.model';
import { ReturnReason } from './return-reason.model';

export class ReturnSettings {
  public deadline = 0;
  public deadline_time_period: DeadlineTimePeriod = DeadlineTimePeriod.Days;
  public automatically_accept_requests = false;
  public printed_return_slips = false;
  public autogenerated_slips = false;
  public return_reasons: ReturnReason[] = [];
  public policy_settings: PolicySettings[] = [];
  public is_using_3pl = false;
  public should_original_shipping_be_refunded = false;
  public use_manual_restock = false;
}
