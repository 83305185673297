import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { ShippingLocation } from 'src/app/settings/models/shipping-location.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationAlertService } from '../integration-alert.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';

@Component({
  selector: 'app-return-address',
  templateUrl: './return-address.component.html',
  styleUrls: ['./return-address.component.scss'],
})
export class ReturnAddressComponent implements OnInit {
  @Input() shippingIntegrationInfo: ShippingIntegration[] = [];
  @Input() updateMode = false;
  @Input() note = '';
  @Output() newReturnAddressIdEvent = new EventEmitter<string>();
  locations: ShippingLocation[] = [];
  returnAddress: ShippingLocation = new ShippingLocation();

  constructor(private locationSettingsService: LocationSettingsService,
    private integrationAlertService: IntegrationAlertService) {}

  ngOnInit(): void {
    this.locationSettingsService.getSubjectLocationSettings().subscribe((locations) => {
      this.locations = locations;

      this.returnAddress =
        this.shippingIntegrationInfo && this.shippingIntegrationInfo.length > 0
          ? this.getInitialReturnAddress()
          : this.getDefaultLocation();
      if (this.returnAddress.id) {
        this.newReturnAddressIdEvent.emit(this.returnAddress.id);
      }
    });
  }

  getInitialReturnAddress(): ShippingLocation {
    const storedLocationId = this.shippingIntegrationInfo[0].return_location_id;
    if (!storedLocationId) {
      return this.getDefaultLocation();
    }
    return (
      this.getLocations().find(
        (location) =>
          location.id === storedLocationId
      ) ?? new ShippingLocation()
    );
  }

  selectLocation(location: ShippingLocation): void {
    this.returnAddress = location;
    this.newReturnAddressIdEvent.emit(location.id);
    this.integrationAlertService.setAlertSectionForCarrier(
      this.shippingIntegrationInfo[0]?.shipping_carrier,
      AlertSectionType.ReturnLocation
    );
  }

  getLocations(): ShippingLocation[] {
    const locations = this.locations.filter(
      (location) => 
        location.id !== undefined &&
        location.id !== '' &&
        location.id !== '00000000-0000-0000-0000-000000000000'
      ) ?? [];
    return locations;
  }

  getDefaultLocation(): ShippingLocation {
    return (
      this.getLocations().find(
        (location) => location.is_default_shipping_location
      ) ?? new ShippingLocation()
    );
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      this.shippingIntegrationInfo[0]?.shipping_carrier
    );
    return alertType === AlertSectionType.ReturnLocation;
  }
}
