import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private currencySubject: BehaviorSubject<string[]> = new BehaviorSubject(
    <string[]>[]
  );
  private isLoadingCurrencies = false;
  constructor(private httpClient: HttpClient) {}

  public getCurrencies(): Observable<string[]> {
    if (
      this.currencySubject.value === null ||
      (!this.currencySubject.value.length &&
      !this.isLoadingCurrencies)
    ) {
      this.isLoadingCurrencies = true;
      this.httpClient.get<string[]>(
        `${environment.apiUrl}/settings/shopify/currencies`
      )
      .subscribe((currencies) => {
        this.isLoadingCurrencies = false;
        this.currencySubject.next(currencies);
      });
    }
    return this.currencySubject;
  }

  getCurrencySubject(): BehaviorSubject<string[]> {
    return this.currencySubject;
  }

  setCurrencySubject(currencies: string[]) {
    this.currencySubject.next(currencies);
  }
}
