import { NotificationEmailTypeEnum } from 'src/app/shared/enums/notification-email-type.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';

export class Notification {
  public id = '';
  public title = '';
  public body = '';
  public should_include_order_details = false;
  public is_enabled = true;
  public is_visible = false;
  public language = '';
  public notification_type: ReturnReasonType = ReturnReasonType.Return;
  public email_type: NotificationEmailTypeEnum =
    NotificationEmailTypeEnum.ReturnCreatedEmail;
}
