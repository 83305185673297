import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplaintSettingsService {
  private complaintSettingsSubject: BehaviorSubject<ComplaintSettings> =
    new BehaviorSubject(new ComplaintSettings());
  private isLoadingComplaintSettings = false;
  constructor(private httpClient: HttpClient) {}

  getComplaintSettings(): Observable<ComplaintSettings> {
    if (
      this.complaintSettingsSubject.value === null ||
      (!this.complaintSettingsSubject.value.deadline &&
      !this.isLoadingComplaintSettings)
    ) {
      this.isLoadingComplaintSettings = true;
      this.httpClient.get<ComplaintSettings>(
        `${environment.apiUrl}/settings/complaint`
      )
      .subscribe((complaintSettings: ComplaintSettings) => {
        this.isLoadingComplaintSettings = false;
        this.complaintSettingsSubject.next(complaintSettings);
      });
    }
    return this.complaintSettingsSubject;
  }

  getComplaintSettingsSubject(): Observable<ComplaintSettings> {
    return this.complaintSettingsSubject.asObservable();
  }

  setComplaintSettingsSubject(complaintSettings: ComplaintSettings): void {
    this.complaintSettingsSubject.next(complaintSettings);
  }

  updateComplaintSettings(
    complaintSettings: ComplaintSettings
  ): Observable<ComplaintSettings> {
    return this.httpClient.put<ComplaintSettings>(
      `${environment.apiUrl}/settings/complaint`,
      complaintSettings
    );
  }
}
