import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';

export class PackageTypes {
  text = '';
  type: ShippingPackageType = ShippingPackageType.PostNordVarubrev;
  packageType = '';
  logo = '';
  carrier = ShippingCarrier.PostNord;
}
