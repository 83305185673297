import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePickerComponent } from 'src/app/components/date-picker/date-picker.component';
import { ModalService } from 'src/app/components/modal/modal.service';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { SpecialDeadline } from 'src/app/settings/models/special-deadline.model';
import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { SpecialDeadlineEnum } from 'src/app/shared/enums/special-deadline.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { SpecialDeadlineService } from 'src/app/shared/services/special-deadline.service';
import { Utils } from 'src/app/shared/utils';

@Component({
  selector: 'app-special-deadlines',
  templateUrl: './special-deadlines.component.html',
  styleUrls: ['./special-deadlines.component.scss'],
})
export class SpecialDeadlinesComponent implements OnInit {
  SpecialDeadlineEnum = SpecialDeadlineEnum;
  isCreatingNew = false;
  showList = true;
  timeOptions = Object.values(DeadlineTimePeriod);
  deadlineOnEdit = new SpecialDeadline();

  @Input()
  type: ReturnReasonType = ReturnReasonType.Return;
  @Input()
  pricePlan: PricePlan = new PricePlan();
  @Input()
  isExchangesEnabled = false;
  @Input()
  isComplaintsEnabled = false;

  title = 'Special deadlines';

  filteredDeadlines: SpecialDeadline[] = [];
  isSaving = false;

  @ViewChild(DatePickerComponent) datePicker?: DatePickerComponent;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private specialDeadlineService: SpecialDeadlineService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.specialDeadlineService.getDeadlineSubject().subscribe((deadlines) => {
      this.filteredDeadlines = deadlines.filter(
        (deadline) => deadline.request_type === this.type
      );
    });
  }

  deadlineForm = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(30)]],
    deadline: [0, [Validators.required, Validators.min(1)]],
  });

  confirmPricePlan(): boolean {
    return (
      this.pricePlan?.name === 'Essentials' || this.pricePlan?.name === 'Pro'
    );
  }

  checkNameLength(): boolean {
    return this.deadlineForm.controls['name'].value?.length > 30;
  }

  checkDisabledDeadlines(type: string): boolean {
    if (type === 'Complaint') {
      return this.isComplaintsEnabled;
    }
    if (type === 'Exchange') {
      return this.isExchangesEnabled;
    }
    return true;
  }

  goToAccountSettings(): void {
    this.modalService.dismiss();
    this.router.navigate(['/settings/account']);
    setTimeout(() => {
      document.getElementById('select-plan')?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 100);
  }

  newDeadline(type: string): void {
    this.isCreatingNew = true;
    this.deadlineOnEdit = new SpecialDeadline();
    this.modalService.open(`deadline-${type}`);
  }

  close(): void {
    this.modalService.dismiss();
    this.deadlineForm.reset();
    this.datePicker?.reset();
    this.isCreatingNew = false;
    this.deadlineOnEdit = new SpecialDeadline();
  }

  closeModal(id: string): void {
    this.modalService.close(id);
  }

  getBadgeStatus(deadline: SpecialDeadline): string {
    return deadline.status.toLowerCase();
  }

  adjustDateFormat(date: Date): string {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  setTimeOption(selection: string): void {
    this.deadlineOnEdit.deadline_time_period =
      Utils.findDeadlineEnumByValue(selection);
  }

  getTimeOption(): string {
    return this.deadlineOnEdit.deadline_time_period ?? '';
  }

  removeDeadline(deadline: SpecialDeadline): void {
    this.isSaving = true;

    this.specialDeadlineService.deleteDeadline(deadline).subscribe({
      next: (success: boolean) => {
        if (success) {
          const index = this.filteredDeadlines.indexOf(deadline);
          this.filteredDeadlines.splice(index, 1);
        }
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to delete deadline');
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }

  editDeadline(deadline: SpecialDeadline, type: string): void {
    this.deadlineOnEdit = deadline;
    this.datePicker?.setStartDate(deadline.start_date);
    this.datePicker?.setEndDate(deadline.end_date);
    this.modalService.open(`deadline-${type}`);
  }

  checkDisabled(): boolean {
    return this.deadlineForm.invalid;
  }

  saveChanges(): void {
    this.isSaving = true;
    this.deadlineOnEdit.name = this.deadlineForm.value.name;
    this.deadlineOnEdit.deadline = this.deadlineForm.value.deadline;
    this.deadlineOnEdit.start_date =
      this.datePicker?.getStartDate() ?? new Date();
    this.deadlineOnEdit.end_date = this.datePicker?.getEndDate() ?? new Date();
    this.deadlineOnEdit.request_type = Utils.findRequestTypeEnumByValue(
      this.type
    );

    if (this.isCreatingNew) {
      this.specialDeadlineService
        .createNewDeadline(this.deadlineOnEdit)
        .subscribe({
          next: (res: SpecialDeadline) => {
            this.filteredDeadlines.push(res);
          },
          error: (res) => {
            if (res.error?.code === 'SpecialDeadlineLimitReached') {
              this.modalService.open('limit-reached-error');
            }
            if (res.error?.code === 'SpecialDeadlinesOverlap') {
              this.modalService.open('special-deadlines-overlap');
            }
            this.errorService.showErrorSnackBar(
              res.error?.errors?.Deadline[0] ?? 'Unable to add deadline'
            );
            this.isSaving = false;
          },
          complete: () => {
            this.isSaving = false;
          },
        });
    } else {
      this.specialDeadlineService
        .updateDeadline(this.deadlineOnEdit)
        .subscribe({
          next: (res: SpecialDeadline) => {
            this.filteredDeadlines.forEach((deadline) => {
              if (deadline.id === res.id) {
                deadline.status = res.status;
              }
            });
          },
          error: (res) => {
            if (res.error?.code === 'SpecialDeadlinesOverlap') {
              this.modalService.open('special-deadlines-overlap');
            }

            this.errorService.showErrorSnackBar(
              res.error?.errors?.Deadline[0] ?? 'Unable to update deadline'
            );
            this.isSaving = false;
          },

          complete: () => {
            this.isSaving = false;
          },
        });
    }
    this.deadlineForm.reset();
    this.datePicker?.reset();
    this.isCreatingNew = false;
    this.deadlineOnEdit = new SpecialDeadline();
    this.modalService.dismiss();
  }
}
