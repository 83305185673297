export enum FilterStatusTypeEnum {
  returnRequested = 'returnRequested',
  requestReceived = 'requestReceived',
  incoming = 'incoming',
  incomingPackageReceived = 'incomingPackageReceived',
  resolved = 'resolved',
  resolvedPackageReceived = 'resolvedPackageReceived',
  packageReceived = 'packageReceived',
  incomingResolved = 'incomingResolved',
  refundDenied = 'refundDenied',
  requestedPackageReceived = 'requestedPackageReceived',
  requestResolved = 'requestResolved',
  requestSlipSkipped = 'requestSlipSkipped',
  slipSkippedAttached = 'slipSkippedAttached',
  packageSentDropff = 'packageSentDropff',
  withShippingSlip = 'withShippingSlip',
  shippingSlipSkipped = 'shippingSlipSkipped',
}
