<div class="cards-container" [ngClass]="{ disabled: isLoading }">
  <div class="card">
    <div class="card-title">
      {{ getPricePlanName() }}
      <app-loader size="medium" *ngIf="isLoading"></app-loader>
    </div>
    <div class="card-description">Current plan</div>
  </div>
  <div class="card">
    <div class="card-title">
      {{ allowedCarrierText }}
      <span class="added" *ngIf="extraCarrierAdded() > 0"
        >+{{ extraCarrierAdded() }} carrier(s) added</span
      >
      <span class="view" (click)="openPricePlanHistoryPopup()">View</span>
    </div>
    <div class="card-description">Carrier(s) allowed active</div>
  </div>
  <div class="card">
    <div class="card-title">
      {{ activeCarriers }} of {{ allowedCarrierNumber }}
    </div>
    <div class="card-description">Carrier(s) currently activated</div>
  </div>
</div>
