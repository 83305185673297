<div class="wrap">
  <p class="status-title">
    {{ getStatusMessage(notification.email_type).text }}
    <span
      class="status-ripple"
      [ngClass]="
        getColorClass(getStatusMessage(notification.email_type).status)
      "
    >
      <img
        src="assets/images/icons/{{
          getStatusIcon(getStatusMessage(notification.email_type).status)
        }}"
        alt="{{ getStatusMessage(notification.email_type).status }}"
      />
      <span class="ml-5">
        {{ getStatusMessage(notification.email_type).status }}
      </span>
    </span>
  </p>

  <img
    *ngIf="getStatusMessage(notification.email_type).status === 'Resolved'"
    class="pointer info"
    (click)="toggleInfo()"
    src="../assets/images/svg/question-mark.svg"
    alt="?"
  />
</div>

<div class="mt-8 mb-8">
  <app-alert
    *ngIf="showInfo"
    imgIcon="../assets/images/icons/help-circle.png"
    [showCloseButton]="false"
    size="large"
    type="Information"
    text="This email will be sent to your customers when the items have been approved for exchange, switched to refund or store credit (if activated). So we advise to keep the text broad regarding the compensation methods. You can also ask the customers to look at the item table for more information, since it will display the compensation method for each item."
  >
  </app-alert>
</div>
