<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New feature"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="GLS"
      logo="gls"
      [shippingCompany]="shippingCarrier.Gls"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          Activate GLS today and start to autogenerate your return shipping
          slips. When your customers have registered their return they will
          automatically receive an email with a return shipping slip as a PDF.
          Your customers can then choose to print the label at their local post
          office. Your customers can also receive the return shipping slip as a
          QR code (currently only available for Austria, Belgium, Germany,
          Denmark and Luxembourg).
        </p>
        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <p class="section section-subtitle">
              <b>These services will be available to you:</b>
            </p>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <!-- Currently we don't have a contract with GLS for this, so this is hidden until later -->
          <!-- <app-request-quote [shippingCarrierName]="'GLS'"
          requestQuote="Placeholder"></app-request-quote> -->
        </div>
      </div>

      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>
        <app-alert
          *ngIf="showAlert()"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Portal key</p>
            <input
              type="text"
              name="portalKey"
              class="section-input"
              [(ngModel)]="serviceInformation.portalKey"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [ngClass]="{ error: isInformationInvalid() }"
              [disabled]="isLoading || isSaving"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect. Wrong country?
            </p>
          </div>
          <div class="w-half">
            <p class="section section-input-label">Secret</p>
            <div class="d-flex">
              <input
                [type]="showPassword ? 'text' : 'password'"
                type="password"
                name="secret"
                class="section-input"
                [(ngModel)]="serviceInformation.clientSecret"
                placeholder="*******"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
              <img
                (click)="showPassword = !showPassword"
                class="section-password-eye"
                src="../assets/images/svg/password-eye.svg"
                alt="EYE"
              />
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Key</p>
            <input
              type="text"
              name="key"
              class="section-input"
              [(ngModel)]="serviceInformation.clientId"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [ngClass]="{ error: isInformationInvalid() }"
              [disabled]="isLoading || isSaving"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect.
            </p>
          </div>
        </div>

        <app-credentials-guide provider="GLS"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which GLS services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
        (infoModalOpened)="openInfoModal()"
        [updateMode]="hasProccessStarted()"
        [note]="note"
        [disabled]="isLoading || isSaving"
      >
      </app-available-carrier-services>

      <app-enable-countries
        [availableServices]="availableServices"
        [supportedCountries]="supportedCountries"
        [carrier]="shippingCarrier.Gls"
        description="Create optional country rules for your current carriers. For example, if you only want to use GLS in Spain, choose Spain as a selected market. You can also see which countries the carrier is available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [updateMode]="hasProccessStarted()"
        [note]="note"
        [disabled]="isLoading || isSaving"
      >
      </app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.clientId"
        description="The GLS’s activation will be instant and it will be ready to use straight away."
        [provider]="shippingCarrier.Gls"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="gls-services">
  <div>
    <h1 class="font-semiBold font-22 text-almost-black mt-0">GLS services</h1>
    <p class="font-normal font-14 text-almost-black">
      <b>GLS Return</b> - the return is tracked and max 20 kg per parcel.
      Sending the parcel back is easy for the recipient. Please remember to
      inform them to get a receipt when returning the parcel, to have a proof of
      delivery.
    </p>
    <p class="font-normal font-14 text-almost-black">
      For more information please visit GLS's official website for the GLS
      Return service.
    </p>
  </div>
</app-modal>
