import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input()
  public id = '';
  @Input()
  public backgroundColor = '$secondary';
  @Input()
  public dismissable = true;
  @Input()
  public size = 'medium';
  @Input()
  public customWidth = '';

  @Output()
  public dismissedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('background')
  element: ElementRef | undefined;

  isShowing = false;

  private mouseDownClassName = 'N/A';

  constructor(private modalService: ModalService) {}
  ngAfterViewInit(): void {
    if (!this.id) {
      console.log('Invalid modal, requires id');
      return;
    }

    if (this.element == null) {
      console.log('Missing modal container.');
      return;
    }

    this.element!.nativeElement.addEventListener('mousedown', (el: any) => {
      this.mouseDownClassName = el.target.className;
    });

    this.element!.nativeElement.addEventListener('mouseup', (el: any) => {
      if (
        el.target.className === 'modal-background show' &&
        this.mouseDownClassName === el.target.className
      ) {
        if (this.dismissable === true) {
          this.dismiss();
        }
      }
    });

    this.modalService.register(this);
  }

  ngOnDestroy(): void {
    if (!this.id) {
      return;
    }
    this.modalService.remove(this.id);
    document.body.classList.remove('modal-open');
  }

  public show(): void {
    this.isShowing = true;
    document.body.classList.add('modal-open');
  }

  public dismiss(): void {
    this.isShowing = false;
    document.body.classList.remove('modal-open');
    this.dismissedEvent.emit(true);
  }
}
