import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  @Output()
  setSelectLanguage: EventEmitter<Language> = new EventEmitter<Language>();

  languages: Language[] = [];
  languageSettings: LanguageSettings = new LanguageSettings();

  @Input()
  title = '';
  @Input() defaultLanguage: Language = new Language();
  @Input()
  disabled = false;

  selectedLanguage: Language = new Language();

  constructor(
    private formBuilder: FormBuilder,
    private languageSettingsService: LanguageSettingsService
  ) {}

  languageForm = this.formBuilder.group({
    language: [''],
  });

  ngOnInit(): void {
    this.languageSettingsService
      .getSubjectLanguageSettings()
      .subscribe((languageSettings: LanguageSettings) => {
        this.languageSettings = languageSettings;
        this.languages = languageSettings.languages.filter(
          (language) => language.is_active
        );

        this.getDefaultLanguage();
        this.languageForm.patchValue({
          language: this.defaultLanguage.country_code,
        });
      });
  }

  getCountryCode(lang: Language): string {
    if (lang.country_code == 'EN') {
      return 'us';
    }
    return lang.country_code?.toLowerCase() ?? '';
  }

  selectLanguage(language: Language) {
    this.selectedLanguage = language;
    this.setSelectLanguage.emit(language);
  }

  getDefaultLanguage(): void {
    const defaultLanguageID = this.languageSettings.default_language;

    const defaultLanguage = this.languages.find(
      (Language) => Language.id === defaultLanguageID
    );
    if (defaultLanguage) {
      this.defaultLanguage = defaultLanguage;
    }
  }
}
