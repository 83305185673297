import { Component, Input, OnInit } from '@angular/core';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ShippingSettings } from 'src/app/settings/models/shipping-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';

@Component({
  selector: 'app-automatic-tracking',
  templateUrl: './automatic-tracking.component.html',
  styleUrls: ['./automatic-tracking.component.scss'],
})
export class AutomaticTrackingComponent implements OnInit {
  @Input()
  disabled = false;
  
  title = 'Automatic returns tracking';
  description =
    'Do you wish to enable automatic tracking of your returns? Returbo will automatically mark the return as received when it arrives to your warehouse. Let’s skip the manual steps today!';
  isLoading = true;
  isSaving = false;
  shippingSettings = new ShippingSettings();
  selectedPricePlan = new PricePlan();
  options: string[] = [
    'Enable automatic returns tracking',
    'I want to manually mark items as received',
  ];

  constructor(
    private shippingSettingsService: ShippingSettingsService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private errorService: ErrorService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.shippingSettingsService.getSubjectShippingSettings().subscribe((settings) => {
      this.shippingSettings = settings;
      this.isLoading = false;
    })

    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe((pricePlan) => {
      this.selectedPricePlan = pricePlan;
    })
  }

  getSelectedValue(): string {
    return this.shippingSettings
      .automatically_track_returns
      ? this.options[0]
      : this.options[1];
  }

  setSelectedValue(selection: string): void {
    this.shippingSettings.automatically_track_returns =
    selection === this.options[0];
    
    const trackingFeature = this.selectedPricePlan?.features.find(pricePlanfeature => 
      pricePlanfeature.feature === 'Automatic tracking of requests'
    );
    const includedIcon = 'resolved-icon';
    const automaticTrackingAllowed = trackingFeature?.icon === includedIcon;
    
    if (
      automaticTrackingAllowed &&
      selection === this.options[0]
    ) {
      this.popupService.setTrackingErrorPopup(true);
    } else {
      this.saveSettings();
    }
  }

  saveSettings(): void {
    this.isSaving = true;

    this.shippingSettingsService.saveShippingSettings(this.shippingSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save shipping settings.');
      },
      complete: () => {
        this.isSaving = false;
      }
    });
  }

  disableTracking(): void {
    this.shippingSettings.automatically_track_returns = false;
  }
}
