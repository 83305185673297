import { ReturnReason } from '../settings/models/return-reason.model';
import { CompensationOption } from '../shared/enums/compensation-option.enum';
import { UploadedImage } from './uploaded-image.model';

export class Item {
  id = '';
  order_id = '';
  name = '';
  variant_title = '';
  item_row_id = 0;
  product_id = 0;
  variant_id = 0;
  quantity = 0;
  requested_variant_id = '';
  requested_variant_title = '';
  grams = 0;
  price = '';
  total_discount = '';
  image_source = '';
  reject_cause = '';
  additional_information = '';
  status = '';
  return_reason_id = '';
  return_amount = 0;
  selected = false;
  rejection? = '';
  refund_total: string | number = '';
  decision = false;
  show_attached? = true;
  uploaded_images: UploadedImage[] = [];
  return_reason: ReturnReason = new ReturnReason();
  customer_compensation_wish: CompensationOption = CompensationOption.Refund;
  merchant_compensation_choice: CompensationOption = CompensationOption.Refund;
  sub_return_reason_id = 0;
  should_restock = true;
}
