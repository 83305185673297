import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { PremiumReturnSettings } from 'src/app/settings/models/premium-return-settings.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PremiumReturnSettingsService } from 'src/app/shared/services/premium-return-settings.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-return-options',
  templateUrl: './return-options.component.html',
  styleUrls: ['./return-options.component.scss'],
})
export class ReturnOptionsComponent implements OnInit {
  title = 'Returns: Attach image and comment options';
  description =
    'Ask your customers to upload an image of the return. This is a great option if you want to pre-check the item before they send it back.';
  isLoading = false;
  isSaving = false;
  showContent = false;
  includeInstructions = false;
  PremiumOptions = PremiumOptions;

  imageOptions = [
    'Make it optional for a customer to upload a picture of the item',
    'Make it mandatory for a customer to upload a picture of the item',
    'DON’T allow a customer to upload a picture of a returned item',
  ];

  summaryOptions = [
    'Make it optional for a customer to write a short summary about the item',
    'Make it mandatory for a customer to write a short summary about the item',
    'DON’T allow a customer to write a short summary',
  ];

  nrOfPhotosOptions = [
    '1 photo',
    '2 photos',
    '3 photos',
    '4 photos',
    '5 photos',
  ];
  minNumberOfPhotos = [...this.nrOfPhotosOptions];
  maxNumberOfPhotos = [...this.nrOfPhotosOptions];

  selectedNumberOfPhotos = '';

  boldWords = ['mandatory', 'optional', 'DON’T allow'];

  premiumReturnSettings: PremiumReturnSettings = new PremiumReturnSettings();
  currentPricePlan: PricePlan = new PricePlan();

  constructor(
    private premiumReturnSettingsServie: PremiumReturnSettingsService,
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private accountSettingsService: AccountSettingsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const pricePlanSettings =
      this.pricePlanSettingsService.getSubjectSelectedPricePlan();
    const premiumReturnSettings = 
      this.premiumReturnSettingsServie.getPremiumReturnSettingsSubject();

    this.accountSettingsService
      .getAccountSettings()
      .subscribe((accountSettings) => {
        if (accountSettings.company_name) {
          combineLatest([pricePlanSettings, premiumReturnSettings]).subscribe({
            next: ([pricePlanSettings, returnOptions]: [
              PricePlan,
              PremiumReturnSettings
            ]) => {
                this.currentPricePlan = pricePlanSettings;
                this.premiumReturnSettings = returnOptions;
                this.isLoading = false;
            },
            error: () => {
              this.isLoading = false;
              this.errorService.showErrorSnackBar('Failed to load settings.');
            },
          });
        }
      });
  }

  getSelectedImage(): string {
    switch (this.premiumReturnSettings.image_upload_option) {
      case PremiumOptions.Optional:
        return this.imageOptions[0];
      case PremiumOptions.Mandatory:
        return this.imageOptions[1];
      case PremiumOptions.NotAllowed:
        return this.imageOptions[2];
    }
  }

  getSelectedSummary(): string {
    switch (this.premiumReturnSettings.summary_option) {
      case PremiumOptions.Optional:
        return this.summaryOptions[0];
      case PremiumOptions.Mandatory:
        return this.summaryOptions[1];
      case PremiumOptions.NotAllowed:
        return this.summaryOptions[2];
    }
  }

  setSelectedImage(selection: string): void {
    switch (selection) {
      case this.imageOptions[0]:
        this.premiumReturnSettings.image_upload_option = PremiumOptions.Optional;
        break;
      case this.imageOptions[1]:
        this.premiumReturnSettings.image_upload_option = PremiumOptions.Mandatory;
        break;
      case this.imageOptions[2]:
        this.premiumReturnSettings.image_upload_option = PremiumOptions.NotAllowed;
        break;
    }

    this.saveSettings();
  }

  setSelectedSummary(selection: string): void {
    if (selection === this.summaryOptions[0]) {
      this.premiumReturnSettings.summary_option = PremiumOptions.Optional;
    }
    if (selection === this.summaryOptions[1]) {
      this.premiumReturnSettings.summary_option = PremiumOptions.Mandatory;
    }
    if (selection === this.summaryOptions[2]) {
      this.premiumReturnSettings.summary_option = PremiumOptions.NotAllowed;
    }

    this.saveSettings();
  }

  setMinPhotos(selection: string): void {
    this.premiumReturnSettings.min_number_of_images = parseInt(selection);
    this.saveSettings();
  }

  setMaxPhotos(selection: string): void {
    this.minNumberOfPhotos = this.nrOfPhotosOptions.filter(
      (nrOfPhotosChoice) => nrOfPhotosChoice.split(' ')[0].trim() < selection
    );
    this.premiumReturnSettings.max_number_of_images = parseInt(selection);
    if (
      this.premiumReturnSettings.min_number_of_images >
      this.premiumReturnSettings.max_number_of_images
    ) {
      this.premiumReturnSettings.min_number_of_images =
        this.premiumReturnSettings.max_number_of_images;
    }
    this.saveSettings();
  }

  getPhotos(type: string): string {
    return type === 'min'
      ? this.appendSuffix(this.premiumReturnSettings.min_number_of_images.toString())
      : this.appendSuffix(this.premiumReturnSettings.max_number_of_images.toString());
  }

  appendSuffix(text: string): string {
    return text.charAt(0) === '1'
      ? text.concat(' photo')
      : text.concat(' photos');
  }

  toggleInstructions(state: boolean): void {
    this.premiumReturnSettings.has_image_instructions = state;
    this.saveSettings();
  }

  enablePremiumReturns() {
    this.premiumReturnSettings.is_enabled = !this.premiumReturnSettings.is_enabled;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.premiumReturnSettingsServie.updatePremiumReturnSettings(this.premiumReturnSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save settings.');
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }
}
