<app-modal id="archive" [dismissable]="false" size="small">
  <h1 class="font-24 text-almost-black font-700">
    Do you want to archive this request?

    <div class="load-icon p-absolute" *ngIf="isLoading">
      <app-loader size="medium"></app-loader>
    </div>
  </h1>

  <div class="modal-wrap">
    <p class="font-normal font-14 text-almost-black">
      Sometimes you might want to archive a customer request (e.g. a duplicate).
      You can access your archived requests in the Return Listings menu and
      unarchive if you need them again.
    </p>

    <div class="d-flex">
      <button
        class="btn btn-secondary btn-md mr-16"
        (click)="closeModal()"
        [disabled]="isLoading"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary btn-md mr-16"
        (click)="archiveRequest()"
        [disabled]="isLoading"
      >
        Archive
      </button>
    </div>
  </div>
</app-modal>
