import { Component, Input, OnInit } from '@angular/core';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { Notification } from 'src/app/settings/models/notification.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { NotificationEmailTypeEnum } from 'src/app/shared/enums/notification-email-type.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { NotificationsService } from '../notifications.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';

@Component({
  selector: 'app-flow-email',
  templateUrl: './flow-email.component.html',
  styleUrls: ['./flow-email.component.scss'],
})
export class FlowEmailComponent implements OnInit {
  @Input() notificationType: ReturnReasonType = ReturnReasonType.Return;
  activeLanguage: Language = new Language();
  notifications: Notification[] = [];
  exchangeSettings: ExchangeSettings = new ExchangeSettings();
  returnSettings: ReturnSettings = new ReturnSettings();

  constructor(
    private notificationsService: NotificationsService,
    private exchangeSettingsService: ExchangeSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe((returnSettings) => {
        this.returnSettings = returnSettings;
      });

    this.notificationsService.getNotificationLanguage().subscribe((lang) => {
      this.activeLanguage = lang;
      this.getNotificationEmails();
    });
    this.exchangeSettingsService
      .getExchangeSubject()
      .subscribe((exchangeSettings) => {
        this.exchangeSettings = exchangeSettings;
      });
  }

  sortEmails(emails: Notification[]): Notification[] {
    const enumOrder = Object.values(NotificationEmailTypeEnum);
    const sortedEmails = emails.sort(
      (a, b) =>
        enumOrder.indexOf(a.email_type) - enumOrder.indexOf(b.email_type)
    );

    return sortedEmails;
  }

  showManualSlipAlert(notification: Notification): boolean {
    const autoSlipTypes = [ReturnReasonType.Return, ReturnReasonType.Exchange];
    return (
      notification.email_type ===
        NotificationEmailTypeEnum.UploadedShippingSlipEmail &&
      this.returnSettings.autogenerated_slips &&
      autoSlipTypes.includes(this.notificationType)
    );
  }

  showReturnCreatedAlert(notification: Notification): boolean {
    const autoSlipForReturn =
      this.returnSettings.autogenerated_slips &&
      this.returnSettings.automatically_accept_requests &&
      this.notificationType === ReturnReasonType.Return;
    const autoSlipForExchange =
      this.returnSettings.autogenerated_slips &&
      this.exchangeSettings.automatically_accept_requests &&
      this.notificationType === ReturnReasonType.Exchange;
    return (
      notification.email_type ===
        NotificationEmailTypeEnum.ReturnCreatedEmail &&
      (autoSlipForExchange || autoSlipForReturn)
    );
  }

  getAlertText(notification: Notification): string {
    if (
      notification.email_type === NotificationEmailTypeEnum.ReturnCreatedEmail
    ) {
      return 'If the autogeneration fails, this email will be sent instead of the email with the return slip. This means that you need to manually upload a shipping slip inside Returbo’s admin panel';
    }
    if (
      notification.email_type ===
      NotificationEmailTypeEnum.UploadedShippingSlipEmail
    ) {
      return 'If the autogeneration fails, this email will be sent instead of your normal return slip email, and this email will have the shipping slip you uploaded inside Returbo’s admin panel.';
    }
    return '';
  }

  getNotificationEmails(): void {
    this.notificationsService.getNotificationsEmails().subscribe((emails) => {
      this.notifications = this.sortEmails(
        emails.filter(
          (email: Notification) =>
            email.notification_type === this.notificationType &&
            email.language === this.activeLanguage.name
        )
      );
    });
  }
}
