import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';

export class ComplaintSettings {
  is_enabled = false;
  image_upload_option: PremiumOptions = PremiumOptions.NotAllowed;
  summary_option: PremiumOptions = PremiumOptions.NotAllowed;
  min_number_of_images = 0;
  max_number_of_images = 0;
  has_image_instructions = false;
  image_instructions_field = '';
  should_item_be_returned = false;
  should_send_instant_refund = false;
  compensation_options: CompensationOption[] = [];
  deadline = 0;
  deadline_time_period: DeadlineTimePeriod = DeadlineTimePeriod.Years;
}
