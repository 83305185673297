import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { DhlFreightIntegrationInformation } from 'src/app/settings/models/dhl-freight-integration-information.model';
import { DhlFreightIntegrationRequest } from 'src/app/settings/models/dhl-freight-integration-request.model';
import { FormValues } from 'src/app/settings/models/form-values.model';
import {
  IntegrationPackageSupportedCountries,
  SupportedCountry,
} from 'src/app/settings/models/integration-package-supported-countries.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { dhlFreightServices } from '../carrier-services';
import { IntegrationAlertService } from '../integration-alert.service';

@Component({
  selector: 'app-dhl-freight',
  templateUrl: './dhl-freight.component.html',
  styleUrls: ['./dhl-freight.component.scss'],
})
export class DhlFreightComponent implements OnInit {
  @Input()
  disabled = false;
  showPassword = false;
  isLoading = false;
  isSaving = false;
  title = 'DHL Freight Sweden';
  description =
    'Autogenerate you returns slips easily from Europe and Asia to Sweden.';
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';

  showActivationSettings = false;
  showPayAsYouGoInfo = false;
  availableServices = dhlFreightServices;
  shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  shippingCarrier = ShippingCarrier;
  formValueChanged = false;
  serviceInformation: DhlFreightIntegrationInformation =
    new DhlFreightIntegrationInformation();

  supportedCountries = new Map<ShippingPackageType, SupportedCountry[]>();
  alertType = AlertSectionType;
  returnAddressId = '';
  internationalPackageTypes = [
        ShippingPackageType.DhlFreightParcelReturnConnect,
        ShippingPackageType.DhlFreightEuroconnect,
        ShippingPackageType.DhlFreightEuroline
    ];

  constructor(
    private modalService: ModalService,
    private shippingIntegrationsService: ShippingIntegrationService,
    private integrationAlertService: IntegrationAlertService
  ) {}

  ngOnInit(): void {
    this.getShippingIntegrationInfo();

    this.shippingIntegrationsService
      .getValuesChanged()
      .subscribe((res: FormValues) => {
        this.formValueChanged = res.bring;
      });

    this.getSupportedCountries();
  }

  getShippingIntegrationInfo(): void {
    this.isSaving = true;
    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            shippingIntegration.shipping_carrier === ShippingCarrier.DhlFreight &&
            shippingIntegration.status === IntegrationStatusEnum.Active
        );
        if (
          this.shippingIntegrationInfo &&
          this.shippingIntegrationInfo.length > 0
        ) {
          this.shippingIntegrationInfo.forEach(
            (integration: ShippingIntegration) => {
              this.checkServices(integration);

              this.assignFormProperties(integration);
            }
          );
        }

        this.isSaving = false;
        this.isLoading = false;
      });
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  onSaveUpdate(isSaving: boolean): void {
    this.isSaving = isSaving;
  }

  toggleActivation(value: boolean): void {
    this.showActivationSettings = value;
  }

  proformaSelectedChange = (selectedValue: boolean) => {
    this.serviceInformation.apiProforma = selectedValue;
  };

  openInfoModal(): void {
    this.modalService.open('dhlfreight-services');
  }

  showCountryRules(services: AvailableService[]): boolean {
    const internationalServices = services.filter(
      (service) =>
        this.internationalPackageTypes.includes(
            service.packageType as ShippingPackageType
        )
    );
    return internationalServices && 
        internationalServices.some(service => service.checked) ? true : false;
  }

  isInformationInvalid(): boolean {
    const invalidIntegration = this.shippingIntegrationInfo?.filter(
      (integration: ShippingIntegration) =>
        integration.status === IntegrationStatusEnum.ActivationError &&
        integration.shipping_carrier === ShippingCarrier.DhlFreight
    );
    return invalidIntegration && invalidIntegration.length > 0 ? true : false;
  }

  checkServices(integration: ShippingIntegration): void {
    this.availableServices.forEach((service: AvailableService) => {
      if (
        integration.shipping_package_type === service.packageType &&
        integration.status !== IntegrationStatusEnum.Deactivated
      ) {
        service.checked = true;
      }
    });
  }

  assignFormProperties(integration: ShippingIntegration): void {
    const dhlFreightIntegation =
      integration.integration_info as DhlFreightIntegrationRequest;
    this.serviceInformation = {
      accountNumber: dhlFreightIntegation.account_number ?? '',
      phoneNumber: dhlFreightIntegation.phone_number ?? '',
      apiProforma: dhlFreightIntegation.api_proforma ?? false,
      apiOrgNumber: dhlFreightIntegation.api_org_number ?? '',
      enabledCountries: new Map([
        [
          dhlFreightIntegation.shipping_package_type as ShippingPackageType,
          dhlFreightIntegation.enabled_origin_countries ?? [],
        ],
      ]),
    };
  }

  onValueChanged(): void {
    if (!this.formValueChanged) {
      this.formValueChanged = true;
      let previousValues: FormValues = new FormValues();

      this.shippingIntegrationsService
        .getValuesChanged()
        .subscribe((res: FormValues) => {
          previousValues = res;
        });
      this.shippingIntegrationsService.setValuesChanged({
        ...previousValues,
        dhlfreight: true,
      });
    }
    this.integrationAlertService.setAlertSectionForCarrier(
      ShippingCarrier.DhlFreight,
      AlertSectionType.Credentials
    );
  }

  addMarketsToIntegration(
    enabledCountries: Map<ShippingPackageType, string[]>
  ): void {
    this.serviceInformation.enabledCountries = enabledCountries;
  }

  hasProccessStarted(): boolean {
    const startedIntegrations = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    return startedIntegrations && startedIntegrations.status ? true : false;
  }

  showAlert(alertType: AlertSectionType): boolean {
    const visibleAlertType =
      this.integrationAlertService.getAlertSectionForCarrier(
        ShippingCarrier.DhlFreight
      );
    return alertType === visibleAlertType && this.hasProccessStarted();
  }

  getSupportedCountries(): void {
    this.shippingIntegrationsService
      .getIntegationPackageSupportedCountriesSubject()
      .subscribe((res: IntegrationPackageSupportedCountries[]) => {
        if (res && res.length > 0) {
          const availableCountries = res.filter(
            (shippingIntegration: IntegrationPackageSupportedCountries) =>
              this.internationalPackageTypes.includes(shippingIntegration.id)
          );
          availableCountries.forEach((availableCountry) =>
            this.supportedCountries.set(
              availableCountry.id,
              availableCountry.countries
            )
          );
        }
      });
  }

  newLocation(id: string): void {
    this.returnAddressId = id;
  }

  newOrgNumber(orgNumber: string): void {
    this.serviceInformation.apiOrgNumber = orgNumber;
  }

  getCheckedServicesIntegrationInfo(): ShippingIntegration[] {
    const checkedServices = this.availableServices.filter(
      (service) => 
        service.checked && 
        service.shipping_carrier === ShippingCarrier.DhlFreight
    );
    const checkedPackageTypes = checkedServices.map(x => x.packageType);
    const checkedIntegrationInformations = this.shippingIntegrationInfo?.filter(
      (integrationInfo) => 
        checkedPackageTypes.includes(integrationInfo.shipping_package_type)
    );
    return checkedIntegrationInformations ?? []
  }
}
