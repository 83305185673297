<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [isToggleable]="true"
  (sectionEnabled)="toggleExchanges()"
  [switchStatus]="exchangeSettings.is_enabled"
  [bannerStats]="bannerStats"
  [hasNewFeatures]="true"
  feature="Updated feature"
>
  <div
    class="notification info d-flex mt-10"
    *ngIf="currentPricePlan?.name === 'Pay-as-You-Go' && showNote"
  >
    <mat-icon class="icon mr-16" fontSet="material-icons-round"
      >help_outline</mat-icon
    >

    <span class="font-14 font-normal mr-50"
      ><b class="mr-5">Information: </b> If you have the Pay-as-you go plan, you
      will be charged $0.3/return, otherwise the limited amount of free returns
      are included in Essentials and Pro plans. For more details, please
      <a class="link" routerLink="/settings/account"
        >visit the payment plan page</a
      >.
    </span>

    <mat-icon (click)="hideNote()" class="hide" fontSet="material-icons-round"
      >close</mat-icon
    >
  </div>

  <h4>Exchange flow</h4>
  <p class="subtitle">
    Select how you want your exchange flow to be. Create a new order with the
    exchange items straight away or make a draft order first.
  </p>

  <mat-radio-group
    class="draft-radio"
    aria-label="Exchange flow order"
    [(ngModel)]="exchangeSettings.draft_order"
    (change)="saveSettings()"
  >
    <mat-radio-button [value]="false">
      <span class="wrap-mat-radio-label">
        <p class="m-0">
          Create a <b>new exchange order</b> as soon as the
          <b>shipping slip has been generated</b>
        </p>
        <p class="radio-helper-text">
          Creating a new order straight away is a good option if you manage your
          items yourself. The order will not be fulfilled until you inspect the
          items in the final stage.
        </p>
      </span>
    </mat-radio-button>
    <div class="update-app-notification" *ngIf="!draftOrderPermission">
      <p class="update-app-text">
        If you installed the app <b>before 2 November 2023</b>, you have to
        update the app in order to enable the draft order. Please make sure you
        have the right permissions to do this for your Shopify store. Otherwise
        you will need to wait until the person with the required permissions can
        do so.
      </p>
      <button class="btn btn-primary" (click)="redirectToUpdate(AccessScopeType.DraftOrder)">
        Update app
      </button>
    </div>
    <mat-radio-button [value]="true" [disabled]="!draftOrderPermission">
      <span class="wrap-mat-radio-label">
        <p class="m-0">
          Create <b>a draft order</b> when the
          <b>shipping slip has been generated</b>, then once the
          <b>you do the final approval</b> – the <b>draft order</b> will become
          the
          <b>new exchange order</b>
        </p>
        <p class="radio-helper-text mb-0">
          Sometimes creating a draft order first is a better option if you have
          a 3PL that manages your inventory. When the 3PL does the quality check
          for you, after approving the request in the final stage – the draft
          order will become an actual order which you can later fulfill.
        </p>
      </span>
    </mat-radio-button>
  </mat-radio-group>

  <h4>
    Store credit via giftcard
    <span class="pro-badge ml-8">Essentials plan</span>
    <span class="pro-badge ml-8">Pro plan</span>
  </h4>
  <p class="subtitle mb-16">
    Enable your customers to choose the store credit option via a 
    giftcard instead of an exchange on the individual item.<br/>
    Gift cards are always issued in the store's currency, regardless 
    of the customer's purchase currency, due to Shopify's rules. For example, 
    if your store currency is USD and a customer purchases in SEK, the gift card 
    issued will be equivalent to the amount in USD. So, if a store credit item 
    is 100 SEK, the customer will receive a gift card for 10 USD (or the 
    equivalent amount based on that day's exchange rate).
  </p>

  <div class="update-app-notification" *ngIf="!storeCreditPermission">
    <p class="update-app-text">
      If you installed the app <b>before 1 August 2024</b>, you have to update the
      app in order to use this feature. Please make sure you have the right
      permissions to do this for your Shopify store. Otherwise you will need to
      wait until the person with the required permissions can do so.
    </p>
    <button class="btn btn-primary" (click)="redirectToUpdate(AccessScopeType.StoreCredit)">
      Update app
    </button>
  </div>

  <mat-checkbox
    color="primary"
    class="checkbox"
    [disabled]="!(storeCreditPermission && storeCreditAccess)"
    [ngClass]="!(storeCreditPermission && storeCreditAccess) ? 'disabled' : ''"
    [(ngModel)]="exchangeSettings.store_credit"
    (change)="saveSettings()"
  >
    <span class="checkbox-text">
      Allow customers to choose store credit option per individual items when
      submitting an exchange request
    </span>
  </mat-checkbox>
</app-settings-section>

<app-modal id="missing-send-from" [dismissable]="false">
  <div>
    <h1 class="title mt-0">Send from address is missing</h1>
    <p class="description">
      You need to set a <b>default send from address</b> to be able to use the
      draft order feature. This will be the address where the items will be sent
      from when a customer requests an exchange. Please go to the
      <i>Request settings > General > Return & restock locations</i>
      - then click on the
      <img class="icon" src="../assets/images/icons/options-vertical.png" />
      of the address you want to set as default for items to be sent from. Then
      tick the checkbox that says
      <b>default address where the items will be reserved/sent from.</b>
    </p>
  </div>

  <div>
    <img class="w-full" src="../assets/images/missing-send-from.png" />
  </div>

  <p class="description">Do you wish to do it now?</p>

  <div class="actions">
    <app-button buttonStyle="secondary" (click)="closeModal()">
      Back
    </app-button>
    <app-button (click)="goToLocations()" class="ml-16"> Proceed </app-button>
  </div>
</app-modal>
