<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New feature"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="DHL Express"
      logo="dhl"
      [shippingCompany]="shippingCarrier.DhlExpress"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          With DHL Express when your customers have registered their return they
          will automatically receive an email with a return shipping slip as a
          PDF. Your customers can then choose to print the label at their local
          post office.
          <b
            >DHL Express only ships internationally. Domestic shipments (e.g.
            from Sweden to Sweden) are not possible.</b
          >
        </p>
        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <div class="d-flex">
              <img src="assets/images/icons/globe.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Pro-forma customs document
                </p>
                <p class="section slip-description mt-0">
                  Autogenerated pro-forma for customs declaration.
                </p>
              </div>
            </div>
            <h4 class="section section-subtitle">
              <b>These services will be available to you:</b>
            </h4>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <app-request-quote
            [shippingCarrierName]="'DHL Express'"
            requestQuote="Don’t have a DHL Express contract or have an unfavorable agreement? Try Returbo’s agreement!"
          ></app-request-quote>
        </div>
      </div>

      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>

        <app-alert
          *ngIf="showAlert(alertType.Credentials)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>

        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Account Number</p>
            <input
              type="text"
              name="account_number"
              class="section-input"
              [(ngModel)]="serviceInformation.accountNumber"
              placeholder="e.g. 00000001"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
          </div>
          <div class="w-half">
            <p class="section section-input-label">Api Key</p>
            <input
              type="text"
              name="api_key"
              class="section-input"
              [(ngModel)]="serviceInformation.apiKey"
              placeholder="e.g. 00000002"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
          </div>
        </div>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Api Secret</p>
            <div class="d-flex">
              <input
                [type]="showPassword ? 'text' : 'password'"
                type="password"
                name="api_secret"
                class="section-input"
                [(ngModel)]="serviceInformation.apiSecret"
                placeholder="*******"
                (change)="onValueChanged()"
                [disabled]="isLoading || isSaving"
                [ngClass]="{ error: isInformationInvalid() }"
              />
              <img
                (click)="showPassword = !showPassword"
                class="section-password-eye"
                src="../assets/images/svg/password-eye.svg"
                alt="EYE"
              />
            </div>
          </div>
          <div class="w-half">
            <p class="section section-input-label">Phone Number</p>
            <input
              type="text"
              name="phone_nr"
              class="section-input"
              [(ngModel)]="serviceInformation.phoneNr"
              placeholder="e.g. +46 000 000 000"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
          </div>
        </div>

        <app-credentials-guide provider="DHL Express"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which DHL Express services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
        (infoModalOpened)="openInfoModal()"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-available-carrier-services>

      <app-paperless-trade
        (selectedValue)="paperlessTradeSelectedChange($event)"
        [paperlessTrade]="serviceInformation.paperlessTrade"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [carrier]="shippingCarrier.DhlExpress"
      ></app-paperless-trade>

      <app-enable-countries
        [availableServices]="availableServices"
        [supportedCountries]="supportedCountries"
        [carrier]="shippingCarrier.DhlExpress"
        description="Create optional country rules for your current carriers. For example, if you only want to use DHL Express in Canada, choose Canada as a selected market. You can also see which countries the carrier is available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.accountNumber"
        description="The DHL Express activation will be instant and it will be ready to use straight away."
        [provider]="shippingCarrier.DhlExpress"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="dhlexpress-services">
  <div>
    <h1 class="font-semiBold font-22 text-almost-black mt-0">
      DHL Express services
    </h1>
    <p class="font-normal font-14 text-almost-black">
      All your dutiable and non-dutiable shipments can be delivered to their
      destination in Europe with <b>DHL Express Worldwide</b> before the end of
      the working day. Outside Europe this will be before the end of the day, a
      few working days later.
    </p>
    <p class="font-normal font-14 text-almost-black">
      For more information please visit DHL’s Express Worldwide official page
      <a
        class="text-primary"
        href="https://www.dhlexpress.nl/en/business/services/products/dhl-express-worldwide"
        target="_blank"
        >here</a
      >.
    </p>
  </div>
</app-modal>
