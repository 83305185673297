import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PricePlanFeature } from 'src/app/settings/models/price-plan-feature.model';

@Component({
  selector: 'app-price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.scss'],
})
export class PricePlanComponent {
  toggle = false;
  status = 'Activate';

  @Input()
  public name = 'Title of Price Plan';
  @Input()
  public price = 1;
  @Input()
  public priceUnit = '/ return';
  @Input()
  public additionalInformation =
    '$0.5 per generated shipping slip only if PostNord is activated';
  @Input()
  public tagLine = '';
  @Input()
  public features: PricePlanFeature[] = [];
  @Input()
  public disclaimer = '';
  @Input()
  public isSelected = false;
  @Input()
  public numberOfPlans = 0;
  @Input()
  public plansSelected = false;
  @Input()
  public currentPricePlan = '';

  @Output()
  selected = new EventEmitter<boolean>();

  togglePlanStatus() {
    if (!this.isSelected) {
      this.selected.emit(true);
    }
  }

  setPlanButtonText(): string {
    if (this.name === 'Essentials') {
      if (this.currentPricePlan === 'Pro') {
        return 'Downgrade';
      }
      return 'Upgrade';
    }
    if (this.name === 'Pay-as-You-Go') {
      return 'Downgrade';
    }
    return 'Upgrade';
  }
}
