import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { AccessScopeType } from 'src/app/shared/enums/access-scope-type.enum';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { FeatureEnum } from 'src/app/shared/enums/feature.enum';
import { first } from 'rxjs';
import { BundleSettingsService } from 'src/app/shared/services/settings/bundle-settings.service';
import { BundleSettings } from 'src/app/settings/models/bundle-settings.model';

@Component({
  selector: 'app-bundles',
  templateUrl: './bundles.component.html',
  styleUrls: ['./bundles.component.scss'],
})
export class BundlesComponent implements OnInit {
  title = 'Bundles.app ‑ Inventory Sync';
  description =
    'Let your customers return separate products from the bundle products you have setup via the “Bundles.app ‑ Inventory Sync” app on Shopify';
  restockOptions = [
    "No, do not update the inventory of returned bundle items, let me handle it outside of Returbo",
    "Yes, update the inventory of the returned bundle items for me"
  ];
  boldWords = [
    "No",
    "not",
    "let me handle it",
    "Yes"
  ];
  planRestrictions = [
    "Essentials plan",
    "Pro plan"
  ];

  showContent = false;
  isLoadingSettings = true;
  isLoadingAccessScope = true;
  isLoadingFeatureAccess = true;
  isSaving = false;
  
  bundleSettings: BundleSettings = new BundleSettings();
  AccessScopeType = AccessScopeType;
  draftOrderPermission = false;
  bundleAccess = false;

  constructor(
    private errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private bundleSettingsService: BundleSettingsService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.getAccessScope();
    this.getBundleFeatureAccess();
    this.getBundleSettings();
  }

  isLoading() : boolean {
    return this.isLoadingSettings ||
          this.isLoadingFeatureAccess || 
          this.isLoadingAccessScope
  }

  getAccessScope(): void {
    this.isLoadingAccessScope = true;
    this.authenticationService.getAccessScope(AccessScopeType.DraftOrder).subscribe({
      next: (res) => {
        this.draftOrderPermission = res;
        this.isLoadingAccessScope = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to get access scope for inventory');
        this.isLoadingAccessScope = false;
      },
    });
  }
  
  getBundleFeatureAccess() {
    this.isLoadingFeatureAccess = true;
    this.featureService.getFeatureAccess(FeatureEnum.Bundles).subscribe({
      next: (res) => {
        this.bundleAccess = res;
        this.isLoadingFeatureAccess = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to check access for Bundles integration');
        this.isLoadingFeatureAccess = false;
      }
    })
  }

  getBundleSettings(): void {
    this.isLoadingSettings = true;
    this.bundleSettingsService.getBundleSettings().subscribe({
      next: (res) => {
        this.bundleSettings = res;
        this.isLoadingSettings = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to get bundle settings');
        this.isLoadingSettings = false;
      },
    });
  }
  
  toggleBundleSection(): void {
    this.bundleSettings.is_enabled = !this.bundleSettings.is_enabled;
    this.saveSettings();
  }

  goToBundleApp(): void {
    window.open(
        "https://apps.shopify.com/bundles",
        '_blank'
      );
  }

  saveSettings(): void {
    this.isSaving = true;
    this.bundleSettingsService.saveBundleSettings(this.bundleSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Unable to save exchange settings');
      },
      complete: () => {
        this.bundleSettingsService.setBundleSettingsSubject(this.bundleSettings);
        this.isSaving = false;
      },
    });
  }

  redirectToUpdate(accessScope: AccessScopeType): void {
    this.authenticationService
      .setAccessScope(accessScope)
      .pipe(first())
      .subscribe({
        next: (res) => {
          if (window.top) {
            window.top.location.href = res;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Unable to set access scope');
        },
      });
  }
}