import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AccountSettings } from "src/app/settings/models/account-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AccountSettingsService {
    
  private accountSettingsSubject: BehaviorSubject<AccountSettings> = new BehaviorSubject(
      new AccountSettings()
  );
  private isLoadingAccountSettings = false;
  constructor(private httpClient: HttpClient) {}

  getAccountSettings(): Observable<AccountSettings> {
    if (
      this.accountSettingsSubject.value === null ||
      (!this.accountSettingsSubject.value.company_name &&
      !this.isLoadingAccountSettings)
    ) {
      this.isLoadingAccountSettings = true;
      this.httpClient
        .get<AccountSettings>(`${environment.apiUrl}/settings/account`)
        .subscribe((settings) => {
          this.isLoadingAccountSettings = false;
          this.accountSettingsSubject.next(settings);
        });
    }
    return this.accountSettingsSubject;
  }

  hasAccountSettingsLoaded(): boolean {
    const accountSettingsPopulated = this.accountSettingsSubject.value !== null;
    const accountSettingsLoading = (!this.accountSettingsSubject.value.company_name &&
      this.isLoadingAccountSettings);
    return accountSettingsPopulated && !accountSettingsLoading;
  }
  
  setAccountSettingsSubject(settings: AccountSettings): void {
    this.accountSettingsSubject.next(settings);
  }
  
  getSubjectAccountSettings(): Observable<AccountSettings> {
    return this.accountSettingsSubject.asObservable();
  }

  saveAccountSettings(value: AccountSettings): Observable<boolean> {
    this.accountSettingsSubject.next(value);
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/account`,
      value
    );
  }
}