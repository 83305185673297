import { IntegrationInformation } from './integration-information.model';

export class UpsIntegrationInfo extends IntegrationInformation {
  clientID = '';
  clientSecret = '';
  accountNumber = '';
  phoneNumber = '';
  stateProvince = '';
  orgNumber = '';
  paperlessTrade = false;
}
