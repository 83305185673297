import { OnboardingStage } from 'src/app/shared/enums/onboarding-stage.enum';
import { BillingSettings } from './billing-settings.model';
import { WarehouseSettings } from './warehouse-settings.model';

export class ShopifySettings {
  public account_domain = '';
  public billing_settings: BillingSettings = new BillingSettings();
  public onboarding_stage: OnboardingStage = OnboardingStage.Uncompleted;
  public warehouse_settings: WarehouseSettings = new WarehouseSettings();
}
