export const environment = {
  production: false,
  name: 'Development',
  apiUrl: 'https://dev.returboapp.com/shopify',
  apiKey: 'df0b06f059daa186e4bf3d692addb564',
  shopifyDevelopmentAccessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczpcL1wvcmV0dXJiby1kZXZlbG9wbWVudC5teXNob3BpZnkuY29tXC9hZG1pbiIsImRlc3QiOiJodHRwczpcL1wvcmV0dXJiby1kZXZlbG9wbWVudC5teXNob3BpZnkuY29tIiwiYXVkIjoiZGYwYjA2ZjA1OWRhYTE4NmU0YmYzZDY5MmFkZGI1NjQiLCJzdWIiOiI3MTM5NDgyMDMyMiIsImV4cCI6MTY0OTE1MDI4NCwibmJmIjoxNjQ5MTUwMjI0LCJpYXQiOjE2NDkxNTAyMjQsImp0aSI6ImIyZThiZTlkLTFhY2QtNGIzNS05OWM3LWRkNDVhNTU5NjA4MiIsInNpZCI6ImFiZDcwMjA4MWM5MTAyYmM4MjUwNzdkNzY2MTQzODY4ZjZmN2VmNmU0Y2ZhOWFmYzZlZWZkN2FiZGM3NTFkOTQifQ.8SL5KIiAQrMUJXogiFbLbFCJ4ytG9RVds4qp1lr8CXg',
  appLink: 'form-shopify-dev-5e2besb5ka-lz.a.run.app',
  postNordLink: 'www.postnord.se',
  appName: 'returbo-development',
};
