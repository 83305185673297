import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BundleSettings } from "src/app/settings/models/bundle-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class BundleSettingsService {
    
  private bundleSettingsSubject: BehaviorSubject<BundleSettings> = new BehaviorSubject(
      new BundleSettings()
  );
  private isLoadingBundleSettings = false;
  constructor(private httpClient: HttpClient) {}

  getBundleSettings(): Observable<BundleSettings> {
    if (
      this.bundleSettingsSubject.value.is_enabled == undefined &&
      !this.isLoadingBundleSettings
    ) {
      this.isLoadingBundleSettings = true;
      this.httpClient
        .get<BundleSettings>(`${environment.apiUrl}/settings/bundle`)
        .subscribe((settings) => {
          this.isLoadingBundleSettings = false;
          this.bundleSettingsSubject.next(settings);
        });
    }
    return this.bundleSettingsSubject;
  }

  hasBundleSettingsLoaded(): boolean {
    const bundleSettingsPopulated = this.bundleSettingsSubject.value !== null;
    const bundleSettingsLoading = (!this.bundleSettingsSubject.value.is_enabled &&
      this.isLoadingBundleSettings);
    return bundleSettingsPopulated && !bundleSettingsLoading;
  }
  
  setBundleSettingsSubject(settings: BundleSettings): void {
    this.bundleSettingsSubject.next(settings);
  }
  
  getSubjectBundleSettings(): Observable<BundleSettings> {
    return this.bundleSettingsSubject.asObservable();
  }

  saveBundleSettings(value: BundleSettings): Observable<boolean> {
    this.bundleSettingsSubject.next(value);
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/bundle`,
      value
    );
  }
}