<!-- TODO: Will be enabled in the future -->
<!-- <p class="font-normal font-14 text-primary mt-0">Item missing or extra item</p> -->
<div class="refund-amount">
  <span class="deduct-info" *ngIf="showOptions && !isResolved">
    <mat-checkbox
      *ngIf="returnSettings.should_original_shipping_be_refunded"
      color="accent"
      class="mb-15 mt-20"
      (change)="updateReturn()"
      [(ngModel)]="return.refund_original_shipping"
      [disabled]="disableShipping()"
    >
      <span class="font-normal font-14">
        Refund original shipping to the customer
      </span>
    </mat-checkbox>
  </span>
  <br />
  <div class="d-flex refund-container" *ngIf="showRefundOptions()">
    <span
      class="deduct-info"
      [ngClass]="
        [
          returnStatusEnum.RefundPartiallyApproved,
          returnStatusEnum.RefundApproved,
          returnStatusEnum.RefundDenied
        ].includes(return.status)
          ? 'mt-10'
          : ''
      "
    >
      <span class="mr-5">
        <mat-checkbox
          color="accent"
          [(ngModel)]="return.charge_return_shipping"
          (change)="updateReturn()"
          [disabled]="disableShipping() || allItemsDeclined()"
        >
        </mat-checkbox>
      </span>
      <span>Deduct your standard return shipping charge of </span>
      <span>
        <span
          *ngIf="return.charge_return_shipping && disableShipping()"
          class="font-700"
          >{{ getShippingCost() | number }} {{ return.currency }}
        </span>
        <span class="font-700" *ngIf="!isEditingDeductCharge">
          {{ return.return_shipping_cost | number }}
          {{ return.currency }}
        </span>
        <div
          class="currency"
          *ngIf="
            return.charge_return_shipping &&
            !disableShipping() &&
            isEditingDeductCharge
          "
        >
          <input
            class="shipping-input"
            type="text"
            placeholder="0"
            [(ngModel)]="shippingCostInput"
          />
          <span>{{ return.currency }}</span>
        </div>
      </span>
      from the customer
      <button
        *ngIf="return.charge_return_shipping"
        class="ml-5 btn edit-charge"
        (click)="editDeductCharge()"
      >
        {{ isEditingDeductCharge ? "Save" : "Edit" }}
      </button>
      <mat-icon
        *ngIf="!edited"
        class="help-icon pointer"
        (click)="showDeductInfo = !showDeductInfo"
        >help_outline</mat-icon
      >
    </span>
  </div>

  <div *ngIf="showAmountDeductedAlert()" class="mt-20 mb-20">
    <app-alert
      imgIcon="/assets/images/icons/alert-triangle.png"
      type="Note"
      size="small"
      [showCloseButton]="true"
      text="The amount deducted is based on your return shipping charge in Settings"
    >
    </app-alert>
  </div>

  <div
    class="info-container font-14 text-almost-black font-normal d-flex"
    *ngIf="showDeductInfo"
  >
    <mat-icon class="mr-16">help_outline</mat-icon>
    <span>
      <span class="font-bold">Information:</span>
      Based on the standard return shipping charge you have set up for your
      store, a customer will have
      <span class="font-700"
        >{{ getShippingCost() | number }} {{ return.currency }}</span
      >
      deducted from their refund to pay for shipping.
    </span>
  </div>

  <div
    *ngIf="hasComplaintReason(this.return.items) && !isResolved"
    class="refund-settings"
    [ngClass]="{ 'refund-settings__open': refundSettingsOpened }"
  >
    <p
      class="refund-settings__title"
      (click)="refundSettingsOpened = !refundSettingsOpened"
    >
      Refund settings for complaints
      <mat-icon class="float-right">
        {{ refundSettingsOpened ? "expand_less" : "expand_more" }}</mat-icon
      >
    </p>
    <p class="refund-settings__content" *ngIf="refundSettingsOpened">
      Based on your preconfigured settings for the complaint flow the item will
      {{ isComplaintReturnItems ? "" : "not" }} be returned to you and you will
      issue a refund straight away once you click on the button below. To change
      that please visit the Settings
      <a
        style="text-decoration: none"
        [routerLink]="['/settings/return']"
        fragment="complaints-item-flow"
        ><span class="text-primary pointer">here</span></a
      >.
    </p>
  </div>
  <div class="font-14 font-normal text-almost-black text-right">
    <p *ngIf="isExchange() && exchangeRejectedItems() > 0">
      Rejected items
      <span class="pl-30">x{{ exchangeRejectedItems() }}</span>
    </p>
    <ng-container *ngIf="hasBothExchangeAndRefund()">
      <p class="font-bold">
        Refund added
        <span class="pl-30">
          + {{ getExchangeRefundAmount() }} {{ return.currency }}</span
        >
      </p>
    </ng-container>

    <p *ngIf="(isExchange() || hasComplaintNewItem()) && newItemsCount() > 0">
      <span
        [ngClass]="{
          'font-bold': !allItemsForRefund(),
          'text-line-through': allItemsDeclined()
        }"
        >{{ exchangeRejectedItems() > 0 ? "Total" : "New" }} items to be
        sent</span
      >
      <!-- To be enabled later -->
      <!-- <mat-icon class="help-icon pointer" *ngIf="exchangeRejectedItems() === 0"
        >help_outline</mat-icon
      > -->
      <span
        class="pl-30"
        [ngClass]="{
          'text-line-through': allItemsForRefund()
        }"
        >x{{ newItemsCount() }}</span
      >
    </p>

    <p class="no-new-items" *ngIf="allExchangeItemsRejected()">
      No new items will be sent
    </p>
    <ng-container
      *ngIf="
        !isRefundIssued() ||
        (isExchange() && (refundItemsLength() > 0 || hasItemWithStoreCredit()))
      "
    >
      <p *ngIf="!isExchange() || refundItemsLength() > 0">
        Refund amount
        <span class="pl-30"
          >{{ refundAmount | number }} {{ return.currency }}</span
        >
      </p>
      <p *ngIf="getReducedPrice() > 0">
        Reduced price
        <span class="pl-30"
          >-{{ getReducedPrice() | number }} {{ return.currency }}</span
        >
      </p>

      <p *ngIf="hasComplaintNewItem()">
        Refund deducted
        <span class="pl-30"
          >-{{ getComplaintDeductedPrice() | number }}
          {{ return.currency }}</span
        >
      </p>

      <p *ngIf="return.charge_return_shipping">
        Deducted shipping
        <span class="pl-30">-{{ shippingCost }} {{ return.currency }}</span>
      </p>
      <p *ngIf="return.refund_original_shipping">
        Refunded shipping
        <span class="pl-30"
          >+{{ return.shipping_cost }} {{ return.currency }}</span
        >
      </p>
    </ng-container>

    <p class="font-400 font-14" *ngIf="isRefundIssued() && !isExchange()">
      Refund status
      <span class="pl-30"
        ><mat-icon class="check-icon">{{
          refundStatusText(
            return.items,
            isResolved,
            hasComplaintNewItem(),
            allItemsDeclined(),
            exchangeRejectedItems()
          ).icon
        }}</mat-icon>
        {{
          refundStatusText(
            return.items,
            isResolved,
            hasComplaintNewItem(),
            allItemsDeclined(),
            exchangeRejectedItems()
          ).text
        }}</span
      >
    </p>

    <p class="font-700" *ngIf="!isExchange() && !isRefundReplacedByStoreCredit()">
      Total refund amount
      <span class="pl-30">
        {{ getRefundAmount() }}
        {{ return.currency }}</span
      >
    </p>

    <p *ngIf="hasItemWithStoreCredit()" class="font-bold">
      Store credit refund
      <span class="pl-30">
        {{ getStoreCreditRefundAmount() }} {{ return.currency }}</span
      >
    </p>
  </div>
</div>
