<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading()"
  [isLoading]="isLoading() || isSaving"
  [exludedInOnBoarding]="true"
  [isToggleable]="true"
  (sectionEnabled)="toggleBundleSection()"
  [switchStatus]="bundleSettings.is_enabled ?? false"
  [isSliderDisabled]="!bundleAccess"
  [hasNewFeatures]="true"
  feature="New feature"
  logoSource="assets/images/bundles-app-logo.png"
  [planRestrictions]="planRestrictions"
>

    <div class="toggle-view" [ngClass]="{ 'adjust-margin': !showContent }">
        <p (click)="showContent = !showContent" class="expand-text">
        {{
            showContent
            ? "Collapse to hide configuration"
            : "Expand to show configuration"
        }}
        </p>
        <img
        [ngClass]="showContent ? 'arrow' : 'rotate-arrow'"
        src="../assets/images/icons/chevron-up.png"
        />
    </div>

    <ng-container *ngIf="showContent">
        <div>
            <h4 class="title">Configure Bundles.app</h4>
            <p class="description">
              You need to give Returbo access in the Bundles.app for this integration to work.
            </p>
            <a href="assets/integration-guides/Bundles-app-guide.pdf" target="_blank">
              How do I give Returbo permission?
            </a>
            <br/>
            <button
                class="btn btn-primary btn-md app-store-btn"
                (click)="goToBundleApp()"
                >
                <img
                    class="btn-icon"
                    src="assets/images/svg/external.svg"
                    alt="External link"
                />
                To Shopify app store
            </button>
        </div>
        <div>
            <h4 class="title">Restock items inside bundle</h4>
            <p class="description">
                Since the customer is returning items that wasn’t originally
                in the Shopify order, we cannot automatically restock the item. 
                Would you like Returbo to update the inventory of the returned item?
            </p>
      
            <div class="text">
                <mat-radio-group
                class="radio-group"
                aria-label="Restock bundle options"
                [(ngModel)]="bundleSettings.should_restock"
                (change)="saveSettings()"
              >
                <mat-radio-button [value]="false" [disabled]="!bundleAccess || isSaving">
                  <span class="wrap-mat-radio-label">
                    <p class="m-0">
                        <b>No</b>, do not update the inventory of returned bundle items, 
                        <b>let me handle</b> it outside of Returbo
                    </p>
                    <p class="radio-helper-text">
                        If you have another system that regularly updates your inventory
                        (such as a WMS), this may be the best option for you.
                    </p>
                  </span>
                </mat-radio-button>
                <div class="update-app-notification" *ngIf="!draftOrderPermission">
                  <p class="update-app-text">
                    If you installed the app <b>before 2 November 2023</b>, you have to
                    update the app in order to let us update the inventory of items 
                    purchased via bundles. Please make sure you have the right permissions
                    to do this for your Shopify store. Otherwise you will need to wait 
                    until the person with the required permissions can do so. 
                  </p>
                  <button class="btn btn-primary" (click)="redirectToUpdate(AccessScopeType.DraftOrder)">
                    Update app
                  </button>
                </div>
                <mat-radio-button [value]="true" [disabled]="!bundleAccess || !draftOrderPermission || isSaving">
                  <span class="wrap-mat-radio-label">
                    <p class="m-0">
                      <b>Yes</b>, update the inventory of the returned bundle items for me
                    </p>
                    <p class="radio-helper-text mb-0">
                        If you have a 3PL that manages your inventory, 
                        they may handle this for you already and the updates from Returbo
                        may be overwritten.
                    </p>
                  </span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
        </div>
    </ng-container>

</app-settings-section>