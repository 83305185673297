import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationStatus } from 'src/app/settings/models/integration-status.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import {
  bringPackageTypes,
  deutschePostPackageTypes,
  dhlExpressPackageTypes,
  dhlFreightPackageTypes,
  glsPackageTypes,
  integrationStatuses,
  postNordPackageTypes,
  upsPackageTypes,
} from '../carrier-services';

@Component({
  selector: 'app-carrier-header',
  templateUrl: './carrier-header.component.html',
  styleUrls: ['./carrier-header.component.scss'],
})
export class CarrierHeaderComponent implements OnInit {
  @Input() carrier = '';
  @Input() logo = '';
  @Input() shippingCompany = '';
  @Input() disabled = false;
  @Output() activationToggled: EventEmitter<boolean> =
    new EventEmitter<boolean>(false);

  isLoading = true;
  shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  integrationStatuses = integrationStatuses;
  showActivationSettings = false;

  constructor(
    private shippingIntegrationsService: ShippingIntegrationService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.getShippingIntegrationInfo();
    this.getUpdatedIntegration();
    this.shippingIntegrationsService
      .getUpdatedIntegration()
      .subscribe((res) => {
        if (res.name === this.carrier && res.updated) {
          this.toggleActivation();
          this.shippingIntegrationsService.setUpdatedIntegration(
            res.name,
            false
          );
        }
      });
  }

  getShippingIntegrationInfo(): void {
    const packageTypes: ShippingPackageType[] | string[] =
      this.fillPackageTypes();

    this.shippingIntegrationsService.getIntegrationsSubject().subscribe({
      next: (res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            packageTypes.includes(shippingIntegration.shipping_package_type)
        );

        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to get integrations!');
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getUpdatedIntegration(): void {
    const packageTypes: ShippingPackageType[] = this.fillPackageTypes();

    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            packageTypes.includes(shippingIntegration.shipping_package_type)
        );
      });
  }

  fillPackageTypes(): ShippingPackageType[] {
    // Find the package types based on the company
    let packageTypes: ShippingPackageType[] = [];
    switch (this.shippingCompany) {
      case ShippingCarrier.PostNord:
        packageTypes = postNordPackageTypes;
        break;
      case ShippingCarrier.Gls:
        packageTypes = glsPackageTypes;
        break;
      case ShippingCarrier.DhlExpress:
        packageTypes = dhlExpressPackageTypes;
        break;
      case ShippingCarrier.Bring:
        packageTypes = bringPackageTypes;
        break;
      case ShippingCarrier.Ups:
        packageTypes = upsPackageTypes;
        break;
      case ShippingCarrier.DhlDeutschePost:
        packageTypes = deutschePostPackageTypes;
        break;
      case ShippingCarrier.DhlFreight:
        packageTypes = dhlFreightPackageTypes;
        break;
      default:
        break;
    }
    return packageTypes;
  }

  hasProccessStarted(): boolean {
    const startedStatus = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    if (!startedStatus) {
      return false;
    }
    return true;
  }

  integrationStatus(): IntegrationStatus | undefined {
    if (this.doesStatusExist([IntegrationStatusEnum.InfoCompleted])) {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.InfoCompleted)
      );
    } else if (this.doesStatusExist([IntegrationStatusEnum.Activating])) {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.Activating)
      );
    } else if (this.doesStatusExist([IntegrationStatusEnum.Active])) {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.Active)
      );
    } else if (this.doesStatusExist([IntegrationStatusEnum.ActivationError])) {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.ActivationError)
      );
    } else if (this.doesStatusExist([IntegrationStatusEnum.Deactivated])) {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.Deactivated)
      );
    } else {
      return this.integrationStatuses.find((integration: IntegrationStatus) =>
        integration.status.includes(IntegrationStatusEnum.Undefined)
      );
    }
  }

  doesStatusExist(statuses: IntegrationStatusEnum[]): boolean {
    return (
      this.shippingIntegrationInfo?.some((integration: ShippingIntegration) =>
        statuses.includes(integration.status)
      ) ?? false
    );
  }

  toggleActivation(): void {
    if (!this.disabled) {
      this.showActivationSettings = !this.showActivationSettings;
      this.activationToggled.emit(this.showActivationSettings);
    }
  }

  isStandalone(): boolean {
    return (
      this.shippingCompany === ShippingCarrier.PostNord ||
      this.shippingCompany === ShippingCarrier.Gls ||
      this.shippingCompany === ShippingCarrier.DhlExpress ||
      this.shippingCompany === ShippingCarrier.Bring ||
      this.shippingCompany === ShippingCarrier.Ups ||
      this.shippingCompany === ShippingCarrier.DhlDeutschePost ||
      this.shippingCompany === ShippingCarrier.DhlFreight
    );
  }

  showStartIntegration(): boolean {
    return (
      !this.showActivationSettings &&
      !this.hasProccessStarted() &&
      this.isStandalone()
    );
  }
}
