import { ShippingCostType } from 'src/app/shared/enums/shipping-cost-type.enum';
import { Currency } from './currency.model';
import { ShippingLocation } from './shipping-location.model';

export class ShippingSettings {
  public shipping_locations: ShippingLocation[] = [];
  public customer_pays_shipping_by_default = false;
  public standard_shipping_cost: number | undefined;
  public shipping_cost: Currency[] = [];
  public shipping_cost_type: ShippingCostType = ShippingCostType.Standard;
  public automatically_track_returns = false;
}
