<app-settings-section
  [title]="title"
  [description]="description"
  [isLoading]="isSaving || isLoading"
  [hasContent]="!isLoading"
  [(language)]="activeLanguage"
  [exludedInOnBoarding]="true"
>
  <div class="notification-container">
    <div *ngIf="!sendThroughReturbo && automaticRequestApproval" class="mb-16">
      <app-alert
        type="Note"
        text="{{ noteText }}"
        imgIcon="../assets/images/icons/alert-triangle.png"
        size="large"
      ></app-alert>
    </div>

    <div class="d-flex justify-space-between">
      <app-language-selector
        [defaultLanguage]="activeLanguage"
        (setSelectLanguage)="selectLanguage($event)"
      ></app-language-selector>

      <app-new-button buttonStyle="" (click)="showKeywordsModal()">
        <span class="btn-keywords">{{
          usefulKeywordsText
        }}</span></app-new-button
      >
    </div>

    <div class="d-flex">
      <p
        class="tab-text"
        *ngFor="let tab of tabs"
        (click)="switchTab(tab.name)"
        [ngClass]="{ 'tab-text__active': activeTab === tab.name }"
      >
        {{ tab.title }}
      </p>
    </div>

    <app-flow-email
      *ngIf="activeTab === 'return-flow-emails'"
      [notificationType]="returnReasonTypeEnum.Return"
    ></app-flow-email>
    <app-flow-email
      *ngIf="activeTab === 'complaint-flow-emails'"
      [notificationType]="returnReasonTypeEnum.Complaint"
    ></app-flow-email>
    <app-flow-email
      *ngIf="activeTab === 'exchange-flow-emails'"
      [notificationType]="returnReasonTypeEnum.Exchange"
    ></app-flow-email>
  </div>
</app-settings-section>

<app-useful-keywords-modal></app-useful-keywords-modal>

<app-hide-email-modal></app-hide-email-modal>
