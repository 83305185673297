import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PricePlanSubscription } from 'src/app/settings/models/price-plan-subscription.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { SubscriptionAddon } from 'src/app/settings/models/subscription-addon.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { BillingService } from 'src/app/shared/services/billing.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-price-plan-history-popup',
  templateUrl: './price-plan-history-popup.component.html',
  styleUrls: ['./price-plan-history-popup.component.scss'],
})
export class PricePlanHistoryPopupComponent implements OnInit {
  pricePlanSubscriptions: PricePlanSubscription = new PricePlanSubscription();
  isLoading = true;
  activeCarriers = 0;
  showCancelConfirmation = false;
  selectedAddonId = '';

  constructor(
    private billingService: BillingService,
    private errorService: ErrorService,
    private shippingIntegrationService: ShippingIntegrationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getPricePlanSubscriptions();
    this.getActiveCarriers();
  }

  getPricePlanSubscriptions(): void {
    this.billingService.getPricePlanSubscriptions().subscribe({
      next: (res) => {
        this.pricePlanSubscriptions = res;
        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load subscriptions');
        this.isLoading = false;
      },
    });
  }

  getActiveCarriers(): void {
    this.shippingIntegrationService
      .getShippingIntegrationsByStatus(IntegrationStatusEnum.Active)
      .subscribe((res: ShippingIntegration[]) => {
        const groupedItems = res.reduce(
          (acc: any, item: ShippingIntegration) => {
            const key = item.shipping_provider;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          },
          {}
        );
        this.activeCarriers = Object.keys(groupedItems).length;
      });
  }

  isCancelDisabled(): boolean {
    return (
      this.activeCarriers >=
      this.pricePlanSubscriptions.addons.filter((addon) => addon.is_active)
        .length +
        1
    );
  }

  toggleCancelConfirmation(addonId: string): void {
    this.showCancelConfirmation = !this.showCancelConfirmation;
    if (this.showCancelConfirmation) this.selectedAddonId = addonId;
  }

  cancelAddon(): void {
    this.isLoading = true;
    this.billingService.cancelSubscription(this.selectedAddonId).subscribe({
      next: () => {
        this.showCancelConfirmation = false;
        this.getPricePlanSubscriptions();
        this.billingService.setIsPricePlanUpdated(true);
      },
      error: () => {
        this.isLoading = false;
        this.errorService.showErrorSnackBar('Failed to cancel subscription');
      },
    });
  }

  getActiveAddons(): SubscriptionAddon[] {
    return this.pricePlanSubscriptions.addons.filter(
      (addon) => addon.is_active
    );
  }

  goToPlans(): void {
    this.router.navigate(['/settings/account'], { fragment: 'select-plan' });
  }
}
