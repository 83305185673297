<div class="wrap" [ngClass]="{ horizontal: horizontal }">
  <label
    *ngFor="let option of options; index as i"
    (click)="optionSelected(i)"
    [ngClass]="{ disabled: disabled }"
  >
    <input
      [ngClass]="{ disabled: disabled }"
      type="radio"
      [name]="name"
      [checked]="option === selected"
    />
    <span [ngClass]="{ disabled: disabled }" class="checkmark">
      <span [ngClass]="{ disabled: disabled }"></span>
    </span>
    <span class="text">
      <span [innerHTML]="bolden(option, boldWords)"> </span>
      <ng-content></ng-content>
    </span>
  </label>
</div>
