<div class="slip-info request-quote">
  <span class="section section-subtitle">
    <b>Interested in using our agreement?</b>
  </span>
  <div class="d-flex description">
    <img src="assets/images/icons/money-outlined.png" alt="Barcode" />
    <div>
      <p class="section section-subtitle m-0">Save up more with lower prices</p>
      <p class="section slip-description mt-0">
        {{ requestQuote }}
      </p>
    </div>
  </div>
  <span class="contact-us"> Contact us to request a special quote. </span>
  <div class="requested-success">
    <app-alert
      *ngIf="isRequested"
      type="Success"
      size="large"
      [text]="
        'Quote for ' +
        shippingCarrierName +
        ' has been requested. We will get back to you via email.'
      "
      imgIcon="/assets/images/icons/success-tick.png"
      [showCloseButton]="false"
    ></app-alert>
  </div>
  <div class="request-quote-button">
    <button
      class="btn btn-primary btn-cp mr-16 float-right"
      [ngStyle]="{ width: 'fit-content' }"
      (click)="setRequestQuote()"
      [disabled]="isRequesting || isRequested"
    >
      Request a quote
    </button>
  </div>
</div>
