<app-settings-section
  *ngIf="this.anyActiveIntegrations"
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
>
  <ng-container
    *ngIf="
      checkForDefaultShippingLocation() && !checkForAutomaticReturnRequests()
    "
  >
    <app-alert
      type="Information"
      [showCloseButton]="true"
      [text]="note"
      imgIcon="../assets/images/icons/help-circle.png"
    ></app-alert>
  </ng-container>

  <ng-container *ngIf="!checkForDefaultShippingLocation()">
    <app-alert
      type="Warning"
      [showCloseButton]="true"
      [text]="warning"
      imgIcon="./assets/images/icons/alert-octagon.png"
    ></app-alert>
  </ng-container>

  <div class="radio-btn">
    <app-radio
      name="auto-generate-slips"
      [options]="options"
      [horizontal]="false"
      [selected]="getSelectedValue()"
      (selectedChange)="setSelectedValue($event)"
      [disabled]="isLoading || isSaving || disabled"
    >
    </app-radio>
  </div>
</app-settings-section>
