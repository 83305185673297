<app-settings-section
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [title]="title"
  [description]="description"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
>
  <div class="wrap">
    <div class="radio-div">
      <mat-radio-group
        color="primary"
        class="radio disable-ripple"
        aria-label="Show refund cost option"
        [(ngModel)]="
          formSettings.show_estimated_refund
        "
        (change)="saveFormSettings()"
      >
        <mat-radio-button [value]="true">
          <span>
            Show estimated refund to the customers inside the Digital Return
            Form
          </span>
        </mat-radio-button>
        <mat-radio-button [value]="false">
          Hide estimated refund
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Preview  -->
    <div class="preview">
      <h4 class="preview-title">Estimated cost example</h4>
      <div class="preview-wrap">
        <p
          *ngIf="
            formSettings.show_estimated_refund
          "
          class="preview-info"
        >
          <b>1 of 2</b> items with a value of <b>2 199,00 SEK *</b>
        </p>
        <p class="preview-button">
          <img class="mr-8" src="../assets/images/svg/submit-request.svg" />
          Submit request
        </p>
        <p
          *ngIf="
            formSettings.show_estimated_refund
          "
          class="preview-small-text"
        >
          * The final refund amount is subject to applied deductions, shipping
          costs and store policy.
        </p>
      </div>
    </div>
  </div>
</app-settings-section>
