import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { SelectedImage } from 'src/app/models/selected-image.model';
import { ImagePreviewService } from './image-preview.service';

@Component({
  selector: 'app-images-preview',
  templateUrl: './images-preview.component.html',
  styleUrls: ['./images-preview.component.scss'],
})
export class ImagesPreviewComponent implements OnInit {
  @Input() selectedImage: SelectedImage = new SelectedImage();
  @Input() imgSrc = '';
  currentIndex = 0;

  constructor(
    private modalService: ModalService,
    private imagePreviewService: ImagePreviewService
  ) {}

  ngOnInit(): void {
    this.imagePreviewService.getImagePreview().subscribe((res) => {
      if (res) this.openModal();
    });
  }

  openModal(): void {
    this.currentIndex = this.selectedImage.item.uploaded_images.findIndex(
      (x) => this.imgSrc === x.url
    );

    this.modalService.open('preview-images');
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  selectImage(imgSrc: string): void {
    this.currentIndex = this.selectedImage.item.uploaded_images.findIndex(
      (x) => imgSrc === x.url
    );
    this.selectedImage.img = imgSrc;
  }

  next(): void {
    this.currentIndex =
      (this.currentIndex + 1) % this.selectedImage.item.uploaded_images.length;
    this.selectedImage.img =
      this.selectedImage.item.uploaded_images[this.currentIndex].url;
  }

  prev(): void {
    this.currentIndex =
      (this.currentIndex - 1 + this.selectedImage.item.uploaded_images.length) %
      this.selectedImage.item.uploaded_images.length;
    this.selectedImage.img =
      this.selectedImage.item.uploaded_images[this.currentIndex].url;
  }
}
