import { Component } from '@angular/core';

@Component({
  selector: 'app-complaints-dashboard',
  templateUrl: './complaints-dashboard.component.html',
  styleUrls: ['./complaints-dashboard.component.scss']
})
export class ComplaintsDashboardComponent {

}
