import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SpecialDeadline } from 'src/app/settings/models/special-deadline.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SpecialDeadlineService {
  private deadlineSubject: BehaviorSubject<SpecialDeadline[]> =
    new BehaviorSubject<SpecialDeadline[]>([]);
  private isLoading = false;

  constructor(private httpClient: HttpClient) {}

  getDeadlines(): Observable<SpecialDeadline[]> {
      if (
        this.deadlineSubject.value.length < 1 &&
        !this.isLoading
    ) {
        this.isLoading = true;
        this.httpClient
        .get<SpecialDeadline[]>(
          `${environment.apiUrl}/settings/deadlines/special`
        )
        .subscribe((deadlines) => {
            this.isLoading = false;
            this.deadlineSubject.next(deadlines);
        });
    }
    return this.deadlineSubject;
  }

  createNewDeadline(deadline: SpecialDeadline): Observable<SpecialDeadline> {
    return this.httpClient.post<SpecialDeadline>(
      `${environment.apiUrl}/settings/deadlines/special`,
      deadline
    );
  }

  updateDeadline(deadline: SpecialDeadline): Observable<SpecialDeadline> {
    return this.httpClient.put<SpecialDeadline>(
      `${environment.apiUrl}/settings/deadlines/special`,
      deadline
    );
  }

  deleteDeadline(deadline: SpecialDeadline): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${environment.apiUrl}/settings/deadlines/special/${deadline.id}`
    );
  }

  setDeadlineSubject(deadlines: SpecialDeadline[]): void {
    this.deadlineSubject.next(deadlines);
  }

  getDeadlineSubject(): Observable<SpecialDeadline[]> {
    return this.deadlineSubject.asObservable();
  }
}
