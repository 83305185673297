<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New feature"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="PostNord"
      logo="postnord"
      [shippingCompany]="shippingCarrier.PostNord"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          Activate PostNord today and start to autogenerate your return shipping
          slips. When your customer have registered their return they will
          automatically receive an email with a return shipping slip as a PDF
          and also a QR-code. Your customer can then choose to print the label
          or show the QR-code at their local post. Only available if you have
          your return address either in Sweden, Norway, Denmark or Finland.
        </p>

        <div *ngIf="showPayAsYouGoInfo" class="mb-16">
          <app-alert
            icon="help_outline"
            type="Information"
            size="large"
            [showCloseButton]="true"
            text="With Pay-as-you-go plan, if you activate PostNord we will charge an
          additional $0,5 per generated return shipping slip. This does not
          include the actual price of the return shipping cost. The price of
          return shipping cost is based on your agreement with PostNord."
          >
          </app-alert>
        </div>

        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <div class="d-flex">
              <img src="assets/images/icons/qrcode.png" alt="QR code" />
              <div>
                <p class="section section-subtitle m-0">QR code via SMS</p>
                <p class="section slip-description mt-0">
                  Send your return shipping slips as a QR code via SMS.
                </p>
              </div>
            </div>
            <p class="section section-subtitle">
              <b>These services will be available to you:</b>
            </p>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <app-request-quote
            [shippingCarrierName]="'PostNord'"
            requestQuote="Don’t have a PostNord contract or have an unfavorable agreement? Try Returbo’s agreement!"
          ></app-request-quote>
        </div>
      </div>
      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>
        <app-alert
          *ngIf="showAlert(alertType.Credentials)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Customer number</p>
            <input
              type="text"
              name="customerNumber"
              class="section-input"
              [(ngModel)]="serviceInformation.customerNumber"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [ngClass]="isInformationInvalid() ? 'error' : ''"
              [disabled]="isLoading || isSaving"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect. Wrong country?
            </p>
          </div>
          <div class="w-half">
            <p class="section section-input-label">
              Where is your PN number registered?
            </p>
            <app-dropdown
              [options]="getCountries()"
              (selectedChange)="setCountry($event)"
              [selected]="getSelectedCountryName()"
              [placeholder]="dropdownPlaceholder"
              [disabled]="isLoading || isSaving"
              [size]="'shipping-integration'"
            >
            </app-dropdown>
          </div>
        </div>

        <app-credentials-guide provider="PostNord"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which PostNord services you wish to use. These services
      will be activated instantly and can be later unselected at any time in
      this section."
        (infoModalOpened)="openInfoModal()"
        [updateMode]="hasProccessStarted()"
        [note]="note"
        [disabled]="isLoading || isSaving"
      >
      </app-available-carrier-services>

      <div class="section">
        <h4 class="section-title">QR code via SMS</h4>
        <p class="section section-description">
          Send your return shipping slips as an QR code via SMS. Your customer
          can go to nearest PostNord service point and scan the QR-code so they
          can print the shipping label for them. This service is only available
          in Sweden and Denmark. If you have the Pay-as-you go plan, you will be
          charged $0.3/SMS, otherwise the limited amount of free SMS are
          included in Essentials plan. For more details, please visit the
          payment plan page.
        </p>

        <app-alert
          *ngIf="showAlert(alertType.CarrierSpecificSettings)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>

        <mat-radio-group
          [ngClass]="{ disabled: isLoading || isSaving }"
          [disabled]="isLoading || isSaving"
          class="qr-code-sms"
          [ngModel]="serviceInformation.shouldSendSms"
          (change)="setSelectedValue($event)"
        >
          <mat-radio-button class="qr-sms-option" [value]="true">
            Enable QR via SMS for my customers
          </mat-radio-button>
          <mat-radio-button class="qr-sms-option" [value]="false">
            Disable this feature
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <app-enable-countries
        *ngIf="showCountryRules(availableServices)"
        [supportedCountries]="supportedCountries"
        [availableServices]="availableServices"
        [carrier]="shippingCarrier.PostNord"
        description="Create optional country rules for your current carriers. For example, if you only want to use PostNord in Sweden, choose Sweden as a selected market. You can also see which countries the carrier is available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [updateMode]="hasProccessStarted()"
        [note]="note"
        [disabled]="isLoading || isSaving"
      >
      </app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.customerNumber"
        description="The PostNord’s activation will be instant and it will be ready to use straight away."
        [provider]="shippingCarrier.PostNord"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="postnord-services">
  <div>
    <h1 class="font-semiBold font-22 text-almost-black mt-0">
      PostNord services
    </h1>
    <p class="font-normal font-14 text-almost-black">
      <b>Return Drop Off</b> is a simple and safe way for your customers to
      manage returns, which offers insurance on damaged items and tracking. You
      provide your customer with a return shipping document. The customer then
      hands in the shipment at the nearest partner outlet and soon the goods are
      returned to you. The maximum weight for parcels is 20 kilograms across
      Europe.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Varubrev Returns</b> allows customers to send consignments weighing up
      to 2 kilograms easily, quickly and cost-effectively – in Sweden and
      abroad. They are traceable within Sweden and have to be handed in at the
      latest 60 days after sending invoice documentation to PostNord.
    </p>
    <p class="font-normal font-14 text-almost-black">
      For more information please visit PostNord’s official website for these
      services:
      <a
        class="text-primary"
        href="https://www.postnord.se/en/sending/returns/postnord-return-drop-off"
        target="_blank"
        >Return Drop Off</a
      >
      and
      <a
        class="text-primary"
        href="https://www.postnord.se/en/sending/letters-and-parcels/varubrev"
        target="_blank"
        >Varubrev Returns</a
      >.
    </p>
  </div>
</app-modal>
