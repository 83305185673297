import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { CarrierRanking } from 'src/app/settings/models/carrier-ranking.model';
import { IntegrationPackageSupportedCountries } from 'src/app/settings/models/integration-package-supported-countries.model';
import { PackageTypes } from 'src/app/settings/models/package-types.model';
import { RankingSelect } from 'src/app/settings/models/ranking-select.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { CarrierRankingService } from 'src/app/shared/services/carrier-ranking.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { allPackageTypes } from '../carrier-services';

@Component({
  selector: 'app-shipping-carriers-ranking',
  templateUrl: './shipping-carriers-ranking.component.html',
  styleUrls: ['./shipping-carriers-ranking.component.scss'],
})
export class ShippingCarriesRankingComponent implements OnInit {
  @Input()
  disabled = false;

  isLoading = false;
  isSaving = false;
  expandCountryList = false;
  index = -1;

  rankings: RankingSelect[] = [];
  carriers: CarrierRanking[] = [];
  rankingsPlaceholder: RankingSelect[] = [];
  carriersPlaceholder: CarrierRanking[] = [];
  packageTypes = allPackageTypes;
  shippingIntegrations: ShippingIntegration[] = [];
  shippingIntegrationCountries: IntegrationPackageSupportedCountries[] = [];

  constructor(
    private carrierRankingService: CarrierRankingService,
    private shippingIntegrationService: ShippingIntegrationService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.getInitialCarrierRankings();
    this.carrierRankingService.getUpdateRanking().subscribe((res: boolean) => {
      if (res) {
        this.rankingsPlaceholder = [];
        this.carriersPlaceholder = [];
        this.getActiveCarriers();
        this.getInitialCarrierRankings();
        this.carrierRankingService.setUpdateRanking(false);
      }
    });

    this.shippingIntegrationService
      .getIntegationPackageSupportedCountriesSubject()
      .subscribe((res: IntegrationPackageSupportedCountries[]) => {
        if (res.length > 0) {
          this.shippingIntegrationCountries = res;
        }
      });

    this.getActiveIntegrations();
  }

  getShippingPackageType(
    packageType: ShippingPackageType
  ): PackageTypes | undefined {
    return this.packageTypes.find(
      (res: PackageTypes) => res.type === packageType
    );
  }

  getActiveIntegrations(): void {
    this.shippingIntegrationService
      .getActiveIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        if (res.length > 0) {
          this.shippingIntegrations = res;
        }
      });
  }

  getActiveCarriers(): void {
    this.shippingIntegrationService
      .getShippingIntegrationsByStatus(IntegrationStatusEnum.Active)
      .subscribe((res: ShippingIntegration[]) => {
        res.forEach((activeIntegration: ShippingIntegration) => {
          this.shippingIntegrations.push(activeIntegration);
          this.shippingIntegrationService.setActiveIntegrationsSubject(res);
        });
      });
  }

  populateRankings(
    carrier: CarrierRanking | ShippingIntegration,
    index: number,
    shouldUpdate: boolean
  ): void {
    this.rankingsPlaceholder.push({
      name:
        this.getShippingPackageType(carrier.shipping_package_type)?.text ?? '',
      packageType:
        this.getShippingPackageType(carrier.shipping_package_type)
          ?.packageType ?? '',
      value: carrier.shipping_package_type,
    });
    this.carriersPlaceholder.push({
      order_nr: 'order_nr' in carrier ? carrier?.order_nr : index,
      shipping_package_type: carrier.shipping_package_type,
      shipping_integration_id:
        'shipping_integration_id' in carrier
          ? carrier.shipping_integration_id
          : carrier.id,
      id:
        'order_nr' in carrier
          ? carrier.id
          : '00000000-0000-0000-0000-000000000000',
    });

    if (shouldUpdate) {
      this.rankings = this.removeDuplicates(this.rankingsPlaceholder, 'value');
      this.carriers = this.removeDuplicates(
        this.carriersPlaceholder,
        'shipping_package_type'
      );
    }
  }

  removeDuplicates<T>(array: T[], prop: string): T[] {
    return array.filter(
      (elem: any, index, arr) =>
        arr.findIndex((e: any) => e[prop] === elem[prop]) === index
    );
  }

  onUpdateRanking(event: MatSelectChange, i: number): void {
    this.isSaving = true;

    const newRankingNumber = i + 1;
    const prevRankingNumber = this.carriers.find(
      (carrierObj: CarrierRanking) =>
        carrierObj.shipping_package_type === event.value
    )?.order_nr ?? 0;

    const adjustHigher = prevRankingNumber > newRankingNumber 

    let packageRankingsToAdjust = this.carriers.filter(
      (packageRanking : CarrierRanking) => 
        packageRanking.order_nr > prevRankingNumber &&
        packageRanking.order_nr <=  newRankingNumber
    )
    if (adjustHigher) {
      packageRankingsToAdjust = this.carriers.filter(
        (packageRanking : CarrierRanking) =>
          packageRanking.order_nr < prevRankingNumber &&
          packageRanking.order_nr >= newRankingNumber
      )
    }
    
    packageRankingsToAdjust.forEach(packageRanking => {
      adjustHigher ? packageRanking.order_nr++ : packageRanking.order_nr--;
    });

    // Find the index of the carrier that got updated based on the package type
    const prevRankingIndex = this.carriers.findIndex(
      (carrierObj: CarrierRanking) =>
        carrierObj.shipping_package_type === event.value
    );
    this.carriers[prevRankingIndex].order_nr = newRankingNumber;

    const sortedCarrierRankings = this.carriers.sort(
      (a, b) => a.order_nr - b.order_nr
    );
    this.rankings = [];

    // Update the ranking property that is used in mat-select
    sortedCarrierRankings.forEach((carrier: CarrierRanking) => {
      this.rankings.push({
        name:
          this.getShippingPackageType(carrier.shipping_package_type)?.text ??
          '',
        packageType:
          this.getShippingPackageType(carrier.shipping_package_type)
            ?.packageType ?? '',
        value: carrier.shipping_package_type,
      });
    });

    this.updateCarrierRankings();
  }

  updateCarrierRankings(): void {
    this.isSaving = true;
    this.carrierRankingService.updateCarrierRankings(this.carriers).subscribe({
      next: () => {
        this.rankingsPlaceholder = [];
        this.carriersPlaceholder = [];
        this.getInitialCarrierRankings();
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to update the rankings!');
        this.isSaving = false;
      },
    });
  }

  getInitialCarrierRankings(): void {
    this.carrierRankingService.getCarrierRankings().subscribe({
      next: (res: CarrierRanking[]) => {
        if (res && res.length) {
          res.forEach((carrier: CarrierRanking, index: number) => {
            this.populateRankings(carrier, 0, index === res.length - 1);
          });
        } else {
          this.getActiveCarriers();
        }
        this.isSaving = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to get the rankings!');
      },
    });
  }

  getNumberOfCountries(carrier: CarrierRanking): string {
    const integration = this.shippingIntegrations.find(
      (integration) =>
        integration.shipping_package_type === carrier.shipping_package_type
    );

    const number =
      integration?.custom_rules?.enabled_origin_countries?.length ?? 0;
    return number === 1 ? `${number} country` : `${number} countries`;
  }

  expandCountries(index: number): void {
    this.index === index ? (this.index = -1) : (this.index = index);
  }

  getOriginCountriesList(carrier: CarrierRanking): string {
    const originCountryCodes = this.shippingIntegrations.find(
      (integration) =>
        integration.shipping_package_type === carrier.shipping_package_type
    );

    const integration = this.shippingIntegrationCountries.find(
      (integration) => integration.id === carrier.shipping_package_type
    );

    const joinedList = integration?.countries.filter((country) =>
      originCountryCodes?.custom_rules.enabled_origin_countries
        .map((countryCode) => countryCode)
        .includes(country.country_code)
    );
    return joinedList?.map((country) => country.country_name).join(', ') ?? '';
  }
}
