import { PricePlanFeature } from './price-plan-feature.model';

export class PricePlan {
  public id = '';
  public name = '';
  public cost_per_month = 0;
  public cost_per_extra_return = 0;
  public return_per_month = 0;
  public description = '';
  public features: PricePlanFeature[] = [];
  public domain_restriction = '';
}
