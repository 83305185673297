import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomDateFormat } from './pipes/custom-date-format-pipe';
import { RegExpValidatorDirective } from './directives/country-code-validator.directive';
@NgModule({
  declarations: [CustomDateFormat, RegExpValidatorDirective],
  imports: [CommonModule],
  exports: [CustomDateFormat, RegExpValidatorDirective],
})
export class SharedModule {}
