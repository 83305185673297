import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OneCarrierPopupComponent } from '../one-carrier-popup/one-carrier-popup.component';

@Component({
  selector: 'app-add-carrier-popup',
  templateUrl: './add-carrier-popup.component.html',
  styleUrls: ['./add-carrier-popup.component.scss'],
})
export class AddCarrierPopupComponent {
  agree = false;
  constructor(
    private oneCarrierDialog: MatDialogRef<OneCarrierPopupComponent>,
    private router: Router
  ) {}

  goToPlans(): void {
    this.router.navigate(['/settings/account'], { fragment: 'select-plan' });
  }
}
