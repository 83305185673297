import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SupportSettings } from "src/app/settings/models/support-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class SupportSettingsService {
    
  private supportSettingsSubject: BehaviorSubject<SupportSettings> = new BehaviorSubject(
      new SupportSettings()
  );
  private isLoadingSupportSettings = false;
  constructor(private httpClient: HttpClient) {}

  getSupportSettings(): Observable<SupportSettings> {
    if (
      this.supportSettingsSubject.value === null ||
      (!this.supportSettingsSubject.value.customer_support_email &&
      !this.isLoadingSupportSettings)
    ) {
      this.isLoadingSupportSettings = true;
      this.httpClient
        .get<SupportSettings>(`${environment.apiUrl}/settings/support`)
        .subscribe((settings) => {
          this.isLoadingSupportSettings = false;
          this.supportSettingsSubject.next(settings);
        });
    }
    return this.supportSettingsSubject;
  }

  hasSupportSettingsLoaded(): boolean {
    const supportSettingsPopulated = this.supportSettingsSubject.value !== null;
    const supportSettingsLoading = (!this.supportSettingsSubject.value.merchant_support_email &&
      this.isLoadingSupportSettings);
    return supportSettingsPopulated && !supportSettingsLoading;
  }

  setSupportSettingsSubject(settings: SupportSettings): void {
    this.supportSettingsSubject.next(settings);
  }
  
  getSubjectSupportSettings(): Observable<SupportSettings> {
    return this.supportSettingsSubject.asObservable();
  }

  saveSupportSettings(value: SupportSettings): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/support`,
      value
    );
  }
}