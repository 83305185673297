<h2 class="font-24 items-title mb-10" *ngIf="displayTitle()">
  Return shipping
</h2>
<ng-container *ngIf="!displayLoader()">
  <div class="mb-20" *ngIf="displaySlipInput()">
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      aria-label="Filter menu"
      class="select pl-10 font-14 font-normal mr-16"
      [disabled]="
        !printedSlips || areAllItemsUnselected() || allItemsDeclined()
      "
    >
      <span class="select-wrapper">
        {{
          selectedReturnShipping.name ? selectedReturnShipping.name : "Select"
        }}
        <img
          *ngIf="selectedReturnShipping"
          src="assets/images/{{ selectedReturnShipping.logo }}-logo.png"
          alt="{{ selectedReturnShipping.provider }}"
          class="selected-logo"
        />
      </span>

      <span class="ml-10">
        <mat-icon class="float-right text-dark-gray"> expand_more </mat-icon>
      </span>
    </button>
    <mat-menu 
      #menu="matMenu"
      class="mt-10 shipping-options"
    >
      <span
        class="mat-menu-item"
        *ngFor="let shipping of shippingOptions"
        (click)="selectReturnShipping(shipping)"
      >
        <span
          *ngIf="
            shipping.type !== SelectedShippingOptionsEnum.AttachShippingSlip &&
            shipping.type !== SelectedShippingOptionsEnum.NoShippingSlip
          "
        >
          <img
            src="assets/images/icons/{{ shipping.logo }}-icon.png"
            [alt]="shipping.type"
            class="shipping-logo"
          />
          <b>{{ shipping.provider }}</b>
        </span>

        {{ shipping.name }}
      </span>
    </mat-menu>

    <mat-icon
      *ngIf="selectedReturnShipping?.name || areAllItemsUnselected()"
      class="help-icon pointer"
      (click)="showInfo()"
      matTooltipPosition="above"
      matTooltip="Click for more info"
      matTooltipClass="tooltip"
      >help_outline</mat-icon
    >
  </div>

  <div
    class="info-container font-14 text-almost-black font-normal d-flex"
    *ngIf="displayReturnAlert()"
  >
    <app-alert
      icon="help_outline"
      [type]="
        selectedReturnShipping?.type !==
        SelectedShippingOptionsEnum.NoShippingSlip
          ? 'Information'
          : ''
      "
      [text]="getInformationAlertText()"
    ></app-alert>
  </div>

  <div class="mb-24" *ngIf="isReturnRequested || isIncoming || isPackageReceived">
    <p
      class="font-700 font-14 text-almost-black"
      *ngIf="
        selectedReturnShipping?.type ===
          SelectedShippingOptionsEnum.AttachShippingSlip ||
        return.status === ReturnStatusEnum.ManualShippingSlipAttached
      "
    >
      Return shipping slip attached
    </p>
    <p class="font-700 font-14 text-almost-black" *ngIf="displaySlipType()">
      {{
        selectedReturnShipping?.type ===
        SelectedShippingOptionsEnum.NoShippingSlip
          ? "No slip"
          : shipping_carrier + " " + selectedReturnShipping?.name
      }}
    </p>
    <p
      class="font-700 font-14 text-almost-black"
      *ngIf="isAutoSlipActivated() && isRequestReceived"
    >
      Autogenerated slips
    </p>
    <div class="w-full file-container__attached" *ngIf="displayAttachedFile()">
      <div
        *ngFor="let slip of returnSlips; let i = index"
        class="attached-file mt-10 p-relative preview-info"
      >
        <mat-icon
          fontSet="material-icons-outlined"
          class="float-left file-icon ml-8"
          >file_present</mat-icon
        >
        <span class="text-almost-black font-normal font-14 ml-5"
          ><span *ngIf="slip && slip.file_name">{{ slip.file_name }}</span>
          <span *ngIf="slip && !slip.file_name">{{
            getDocumentName(slip, i)
          }}</span></span
        >

        <span class="text-almost-black font-14 float-right pointer">
          <mat-icon
            (click)="previewSlip(slip.id)"
            fontSet="material-icons-outlined"
            class="mr-16"
            >visibility</mat-icon
          >
        </span>
      </div>
    </div>
    <div
      class="info-container font-14 text-almost-black font-normal d-flex"
      *ngIf="shouldDisplayInformationAlert()"
    >
      <app-alert
        icon="help_outline"
        [type]="'Information'"
        [innerText]="informationText"
        [showCloseButton]="false"
      ></app-alert>
    </div>
    <div
      class="info-container font-14 text-almost-black font-normal d-flex"
      *ngIf="isAutoSlipActivated() && isRequestReceived"
    >
      <app-alert
        icon="help_outline"
        [type]="
          selectedReturnShipping?.type !==
          SelectedShippingOptionsEnum.NoShippingSlip
            ? 'Information'
            : ''
        "
        [text]="
          errorAutoGeneratingSlip
            ? 'The autogeneration did not work, select a shipping option above or try again later'
            : 'Your return slip will be autogenerated. You do not need to do anything.'
        "
      ></app-alert>
    </div>
  </div>

  <div
    class="container file-container w-full"
    appDnd
    (fileDropped)="onFileDropped($event)"
    *ngIf="
      selectedReturnShipping?.type ===
        SelectedShippingOptionsEnum.AttachShippingSlip && isReturnRequested
    "
    [ngClass]="wrongSlipType ? 'error-file' : ''"
  >
    <div *ngIf="files.length === 0 || wrongSlipType">
      <div class="d-none">
        <input
          type="file"
          #fileDropRef
          id="fileDropRef"
          (change)="fileBrowseHandler($event)"
        />
      </div>

      <p class="font-14 font-normal file-container__text">
        <img
          src="assets/images/icons/drop.png"
          alt="Drop icon"
          class="drop-icon"
          *ngIf="!wrongSlipType"
        />
        <mat-icon class="drop-icon" *ngIf="wrongSlipType">
          sentiment_dissatisfied
        </mat-icon>
        {{
          wrongSlipType
            ? "Couldn’t upload the file. Please check the supported formats and"
            : "Drag and drop the return slip here or"
        }}
        <span class="pointer" (click)="fileDropRef.click()">{{
          wrongSlipType ? "try again" : "choose file"
        }}</span>
      </p>
    </div>

    <div class="w-half" *ngIf="files.length > 0 && files[0]?.progress !== 100">
      <p class="font-14 font-normal text-almost-black">Uploading file...</p>

      <app-progress [progress]="files[0]?.progress"></app-progress>
    </div>

    <div
      class="w-full file-container__attached"
      *ngIf="files[0]?.progress === 100"
    >
      <p class="attached-file m-0 p-relative">
        <mat-icon
          fontSet="material-icons-outlined"
          class="float-left file-icon ml-8"
          >file_present</mat-icon
        >
        <span class="text-almost-black font-normal font-14">{{
          files[0].name
        }}</span>

        <span class="text-almost-black font-14 float-right">
          <mat-icon
            fontSet="material-icons-outlined"
            class="mr-16 pointer"
            (click)="previewUploadedSlip()"
            >visibility</mat-icon
          >
          <mat-icon class="mr-8 pointer" (click)="removeFile()">close</mat-icon>
        </span>
      </p>
    </div>
  </div>

  <p
    *ngIf="
      selectedReturnShipping?.type ===
        SelectedShippingOptionsEnum.AttachShippingSlip && isReturnRequested
    "
    class="font-14 font-normal recommend-text"
  >
    Recommended minimum resolution 512x512 px. Accepted file types: .jpg, .png,
    .pdf.
  </p>
</ng-container>

<ng-container 
  *ngIf="!isResolved() && displayLoader()"
>
  <app-loader class="loading" size="large"></app-loader>
</ng-container>

<app-modal id="shipping-slip" size="preview-slip" [dismissable]="true">
  <div>
    <img class="preview-uploaded" id="slip" [src]="slipURL" />
  </div>
</app-modal>
