import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LanguageSettings } from "src/app/settings/models/language-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class LanguageSettingsService {
    
    private languageSettingsSubject: BehaviorSubject<LanguageSettings> = new BehaviorSubject(
        new LanguageSettings()
    );
    private isLoadingLanguageSettings = false;
    constructor(private httpClient: HttpClient) {}

    getLanguageSettings(): Observable<LanguageSettings> {
        if (
            this.languageSettingsSubject.value === null ||
            (!this.languageSettingsSubject.value.default_language &&
            !this.isLoadingLanguageSettings)
        ) {
            this.isLoadingLanguageSettings = true;
            this.httpClient
            .get<LanguageSettings>(`${environment.apiUrl}/settings/language`)
            .subscribe((settings) => {
                this.isLoadingLanguageSettings = false;
                this.languageSettingsSubject.next(settings);
            });
        }
        return this.languageSettingsSubject;
    }
    
    hasLanguageSettingsLoaded(): boolean {
        const languageSettingsPopulated = this.languageSettingsSubject.value !== null;
        const languageSettingsLoading = (!this.languageSettingsSubject.value.default_language &&
            this.isLoadingLanguageSettings);
        return languageSettingsPopulated && !languageSettingsLoading;
    }
      
    setLanguageSettingsSubject(settings: LanguageSettings): void {
        this.languageSettingsSubject.next(settings);
    }
      
    getSubjectLanguageSettings(): Observable<LanguageSettings> {
        return this.languageSettingsSubject.asObservable();
    }

    saveLanguageSettings(value: LanguageSettings): Observable<boolean> {
        return this.httpClient.put<boolean>(
          `${environment.apiUrl}/settings/language`,
          value
        );
    }
}