import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-one-carrier-popup',
  templateUrl: './one-carrier-popup.component.html',
  styleUrls: ['./one-carrier-popup.component.scss'],
})
export class OneCarrierPopupComponent {
  constructor(
    private oneCarrierDialog: MatDialogRef<OneCarrierPopupComponent>,
    private router: Router
  ) {}

  goToPlans(): void {
    this.oneCarrierDialog.close();
    this.router.navigate(['/settings/account']);
  }
}
