import { Component, OnInit } from '@angular/core';
import { NotificationSettings } from 'src/app/settings/models/notification-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { NotificationSettingsService } from 'src/app/shared/services/settings/notification-settings.service';

@Component({
  selector: 'app-return-notifications',
  templateUrl: './return-notifications.component.html',
  styleUrls: ['./return-notifications.component.scss'],
})
export class ReturnNotificationsComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  title = 'Instant notifications';
  description =
    'Choose whether you would like to instantly get notified about events that require actions, such as a new return request. If you get more than 10 returns a day, we recommend turning it OFF and use daily Summary Emails instead.';
  
  checked = false;
  notificationSettings = new NotificationSettings();

  constructor(
    private notificationSettingsService: NotificationSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.notificationSettingsService
      .getNotificationSettingsSubject()
      .subscribe((settings) => {
        if (this.notificationSettingsService.hasNotificationSettingsLoaded()) {
          this.notificationSettings = settings;
          this.checked = settings.should_send_instant_notifications;
          this.isLoading = false;
        }
    });
  }

  toggleNotifications(): void {
    this.notificationSettings.should_send_instant_notifications =
      !this.checked;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.notificationSettingsService
      .saveNotificationSettings(this.notificationSettings)
      .subscribe({
        next: () => {
          this.notificationSettingsService.setNotificationSettingsSubject(
            this.notificationSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to save notification settings'
          );
          this.isSaving = false;
        },

        complete: () => {
          this.isSaving = false;
        },
      });
  }
}
