import { SelectedShippingOptionsEnum } from '../shared/enums/selected-shipping-options.enum';

export class Shipping {
  name = '';
  type: SelectedShippingOptionsEnum =
    SelectedShippingOptionsEnum.NoShippingSlip;
  info = '';
  track = '';
  logo = '';
  provider = '';
}
