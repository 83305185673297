import { Item } from 'src/app/models/item.model';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';

export function hasComplaintReason(items: Item[]): boolean {
  return items.some(
    (item) => item.return_reason?.return_reason_type === 'Complaint'
  );
}

export function complaintRefund(
  items: Item[],
  status: ReturnStatusEnum,
  returnItem: boolean,
  refund: boolean
): boolean {
  return (
    hasComplaintReason(items) &&
    refund &&
    [
      ReturnStatusEnum.RequestReceived,
      ReturnStatusEnum.AwaitingShippingSlip,
    ].includes(status)
  );
}

export function complaintReturnItems(
  items: Item[],
  status: ReturnStatusEnum,
  returnItem: boolean
): boolean {
  return (
    hasComplaintReason(items) &&
    returnItem &&
    ![ReturnStatusEnum.Archived].includes(status)
  );
}

export function complaintReturnItemsStatus(
  status: string,
  items: Item[],
  returnItem: boolean
): boolean {
  return (
    hasComplaintReason(items) &&
    returnItem &&
    [
      ReturnItemStatusEnum.Requested,
      ReturnItemStatusEnum.RefundApproved,
      ReturnItemStatusEnum.RefundDenied,
      ReturnItemStatusEnum.ItemDenied,
      ReturnItemStatusEnum.ItemReceived,
    ].includes(status as ReturnItemStatusEnum)
  );
}

export function complaintRefundResolved(
  items: Item[],
  status: ReturnStatusEnum,
  returnItems: boolean
): boolean {
  return (
    hasComplaintReason(items) &&
    [
      ReturnStatusEnum.RefundApproved,
      ReturnStatusEnum.RefundDenied,
      ReturnStatusEnum.RequestDenied,
      ReturnStatusEnum.RefundPartiallyApproved,
      ReturnStatusEnum.RequestManaged,
    ].includes(status) &&
    !returnItems
  );
}
