<header>
  <div class="wrap">
    <div class="logo">
      <img src="assets/images/returbo-logo.png" alt="Returbo logo" />
    </div>
    <nav *ngIf="!hideNav()">
      <div class="link" [routerLink]="['returns']" routerLinkActive="active">
        <img src="../assets/images/icons/requests-icon.svg" />Requests
      </div>
      <!-- <div class="link">Analytics</div>
      <div class="link">Invoices</div> -->
      <div class="link" [routerLink]="['settings']" routerLinkActive="active">
        <img src="../assets/images/icons/settings-icon.svg" />Settings
        <span class="dot" *ngIf="!newFeaturesSeen()">New</span>
      </div>
      <!-- <div class="link">Support</div> -->
    </nav>
  </div>
</header>
