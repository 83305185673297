import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ColumnList } from 'src/app/models/column-list.model';
import { ListViewRequestUpdate } from 'src/app/models/list-view-request-update.model';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
@Injectable({
  providedIn: 'root',
})
export class ItemsTableService {
  private displayedColumns: BehaviorSubject<ColumnList[]> = new BehaviorSubject(
    [new ColumnList()]
  );

  private selectedStatus: BehaviorSubject<StatusFilter> =
    new BehaviorSubject<StatusFilter>(
      {} as StatusFilter
    );

  private requestToUpdate: BehaviorSubject<ListViewRequestUpdate> =
    new BehaviorSubject<ListViewRequestUpdate>(
      new ListViewRequestUpdate()
    );

  setDisplayedColumns(columns: ColumnList[]): void {
    this.displayedColumns.next(columns);
  }

  getDisplayedColumns(): Observable<ColumnList[]> {
    return this.displayedColumns.asObservable();
  }

  setSelectedStatus(value: StatusFilter): void {
    this.selectedStatus.next(value);
  }

  getSelectedStatus(): Observable<StatusFilter> {
    return this.selectedStatus.asObservable();
  }

  getRequestToUpdate(): Observable<ListViewRequestUpdate> {
    return this.requestToUpdate.asObservable();
  }

  setRequestToUpdate(id: string, newStatus: ReturnStatusEnum): void {
    const selectedStatusInfo = this.selectedStatus.getValue();
    const selectedStatuses = selectedStatusInfo?.status;
    const isExcludedStatus = 
      newStatus === ReturnStatusEnum.Archived || 
      (selectedStatuses?.length > 0 && !selectedStatuses.includes(newStatus));

    const requestToUpdate = new ListViewRequestUpdate(
      id,
      newStatus,
      isExcludedStatus
    );
    this.requestToUpdate.next(requestToUpdate);
  }
}
