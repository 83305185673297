import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ReturnReason } from "src/app/settings/models/return-reason.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class ReasonSettingsService {
    
  private reasonSettingsSubject: BehaviorSubject<ReturnReason[]> = new BehaviorSubject<ReturnReason[]>([]);
  private isLoadingReasonSettings = false;
  constructor(private httpClient: HttpClient) {}

  getReasonSettings(): Observable<ReturnReason[]> {
    if (
      this.reasonSettingsSubject.value === null ||
      (!this.reasonSettingsSubject.value.length &&
      !this.isLoadingReasonSettings)
    ) {
      this.isLoadingReasonSettings = true;
      this.httpClient
        .get<ReturnReason[]>(`${environment.apiUrl}/settings/reasons`)
        .subscribe((settings) => {
          this.isLoadingReasonSettings = false;
          this.reasonSettingsSubject.next(settings);
        });
    }
    return this.reasonSettingsSubject;
  }

  saveReasons(reasons: ReturnReason[]): Observable<ReturnReason[]> {
    return this.httpClient.put<ReturnReason[]>(
      `${environment.apiUrl}/settings/reasons`,
      reasons
    );
  }

  hasReasonSettingsLoaded(): boolean {
    const reasonSettingsPopulated = this.reasonSettingsSubject.value !== null;
    const reasonSettingsLoading = (!this.reasonSettingsSubject.value.length &&
      this.isLoadingReasonSettings);
    return reasonSettingsPopulated && !reasonSettingsLoading;
  }
  
  setReasonSettings(settings: ReturnReason[]): void {
    this.reasonSettingsSubject.next(settings);
  }
  
  getSubjectReasonSettings(): Observable<ReturnReason[]> {
    return this.reasonSettingsSubject.asObservable();
  }
}