export enum ReturnStatusEnum {
  Undefined = 'Undefined',
  RequestReceived = 'RequestReceived',
  AwaitingShippingSlip = 'AwaitingShippingSlip',
  PackageSent = 'PackageSent',
  AwaitingDropOff = 'AwaitingDropOff',
  ShippingSlipSkipped = 'ShippingSlipSkipped',
  ManualShippingSlipAttached = 'ManualShippingSlipAttached',
  RequestManaged = 'RequestManaged',
  PackageReceived = 'PackageReceived',
  RefundApproved = 'RefundApproved',
  RefundDenied = 'RefundDenied',
  RequestDenied = 'RequestDenied',
  RefundPartiallyApproved = 'RefundPartiallyApproved',
  Archived = 'Archived',
}
