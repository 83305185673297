import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpsActivationLoaderComponent } from './pages/ups-activation-loader/ups-activation-loader.component';

const routes: Routes = [
  {
    path: 'ups-activation',
    component: UpsActivationLoaderComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UpsActivationRoutingModule {}
