<div class="section">
  <h4 class="section-title mt-40">
    Available services

    <img
      class="pointer ml-8"
      (click)="openInfoModal()"
      src="../assets/images/svg/question-mark.svg"
      alt="?"
    />
  </h4>
  <p class="section section-description">
    {{ description }}
  </p>

  <div *ngIf="showAlert() && updateMode" class="mb-12">
    <app-alert
      type="Note"
      [showCloseButton]="true"
      [text]="note"
      imgIcon="../assets/images/icons/alert-triangle.png"
    ></app-alert>
  </div>
  
  <mat-checkbox
    *ngFor="let service of availableServices"
    class="section-checkbox"
    [disabled]="isDisabled(service)"
    [(ngModel)]="service.checked"
    (change)="showSavingAlert()"
    >{{ service.title }}</mat-checkbox
  >
</div>
