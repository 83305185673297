// Careful when changing the values since these are backend names and may break things
// For display only purposes use something else
export enum ShippingCarrier {
  PostNord = 'PostNord',
  Gls = 'Gls',
  DhlExpress = 'DhlExpress',
  Bring = 'Bring',
  Ups = 'Ups',
  DhlDeutschePost = 'DhlDeutschePost',
  DhlFreight = 'DhlFreight',
}
