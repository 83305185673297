export const keywords = [
  {
    text: '{first_name}',
    description:
      'A keyword that allows you to address the customer by their first name.',
  },
  {
    text: '{last_name}',
    description:
      'Similar to the one above, this keyword allows you to address the customer by their last name.',
  },
  {
    text: '{order_number}',
    description:
      'This keyword shows the specific order number of the customer.',
  },
  {
    text: '{support_email}',
    description:
      'Use this keyword to add your support email address to the email. The email address is the one you provided in Settings --> Contact information.',
  },
  {
    text: '{support_phone}',
    description:
      'Your support phone number is the one you provided in Settings --> Contact information.',
  },
  {
    text: '{company_name}',
    description: 'This keyword adds your company name in the email.',
  },
  {
    text: '{number_of_accepted_items}',
    description:
      'This keyword can be used together with the one below (e.g. if only “some” items were accepted out of “total items”).',
  },
  {
    text: '{total_items}',
    description:
      'Similar to the one above this keyword adds the number of all items that a return request contained.',
  },
  {
    text: '{shipping_provider}',
    description:
      'This keyword will be useful if you use more than one shipping carrier. Regardless of how many carriers you have, the one used to generate the shipping slip will be used.',
  },
  {
    text: '{service_point_link}',
    description:
      'This keyword is a link that redirects customers to a page where they can locate the closest service point to make a return. This will be a different link based on each shipping carrier.',
  },
];
