<div class="chat-wrap">
  <h1>
    {{ title }}
    <span class="comment-length-counter ml-8"
      ><span class="counter">{{
        isLoadingReturns ? 0 : commentCounter()
      }}</span></span
    >
    <span class="new-feature-badge ml-8">New feature</span>
    <span class="loader" *ngIf="isLoading || isLoadingReturns">
      <app-loader size="medium"></app-loader>
    </span>
  </h1>
  <p
    [ngClass]="{ blur: isLoading || isLoadingReturns }"
    *ngIf="comments.length === 0 || isLoadingReturns"
  >
    {{ description }}
  </p>

  <div *ngIf="!isLoadingReturns" class="scrollable">
    <div
      [ngClass]="{ blur: isLoading || isLoadingReturns }"
      class="comment"
      *ngFor="let comment of comments"
    >
      <ng-container *ngIf="comment.is_deleted; else displayComment">
        <p class="deleted-content">Comment was deleted</p>
      </ng-container>

      <ng-template #displayComment>
        <p class="title mb-4">
          {{ comment.name
          }}<span class="date">{{ getCurrentDate(comment.created_at) }}</span
          ><img
            (click)="deleteComment(comment)"
            src="../assets/images/svg/delete.svg"
          />
        </p>
        <p class="content">{{ comment.comment }}</p>
      </ng-template>
    </div>
  </div>

  <p
    class="show-comment mb-40"
    [ngClass]="{ disabled: isLoading || isLoadingReturns }"
    *ngIf="!showInput"
    (click)="showInput = !showInput"
  >
    <span class="font-24 mr-4">+</span>
    <span class="font-500">Add a comment</span>
  </p>

  <div *ngIf="showInput" class="form-wrap">
    <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
      <h4>{{ chatTitle }}</h4>
      <div class="input-wrap">
        <input type="text" placeholder="Add your name" formControlName="name" />
        <p
          *ngIf="
            commentForm.controls['name'].invalid &&
            commentForm.controls['name'].value.length > 0
          "
          class="error"
        >
          Name cannot be longer than 12 characters.
        </p>
        <textarea
          formControlName="comment"
          class="comment-input mt-8"
          placeholder="Type your comment"
          (keyup)="countChars($event)"
          maxlength="200"
        ></textarea>
      </div>

      <div>
        <span class="char-counter">{{ numberOfChars }}/200</span>
        <div class="actions">
          <app-new-button buttonStyle="cancel" (click)="close()"
            >Close</app-new-button
          >
          <app-new-button
            [disabled]="commentForm.invalid || isLoading || isLoadingReturns"
            type="submit"
            >Post</app-new-button
          >
        </div>
      </div>
    </form>
  </div>
</div>
