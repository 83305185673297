<app-settings-section
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [title]="title"
  [description]="description"
  [onBoardingStepCompleted]="onBoardingCompleted"
>
  <div class="linkBoxRed">
    <img src="assets/images/icons/alert-octagon.png" />
    <p>
      <span class="font-bold">Warning: </span>
      {{ warningText }}
    </p>
  </div>

  <h4>Return form link</h4>
  <div class="align-content">
    <div class="linkBox">
      <p class="link-formatting">
        <span class="mr-10">{{ returnURL }}</span>

        <img
          src="assets/images/icons/copy.png"
          [cdkCopyToClipboard]="returnURL"
          (cdkCopyToClipboardCopied)="showCopiedBox($event, returnURL)"
        />
      </p>
    </div>
    <div [ngClass]="copiedReturnLink ? 'popup' : 'popup-hidden'">Copied!</div>
  </div>

  <h4>Embedded form link</h4>
  <div class="align-content">
    <div class="linkBox-big">
      <p>{{ embeddedURL }}</p>
    </div>
    <img
      class="icon-alignment"
      src="assets/images/icons/copy.png"
      [cdkCopyToClipboard]="embeddedURL"
      (cdkCopyToClipboardCopied)="showCopiedBox($event, embeddedURL)"
    />
    <div
      class="popup-alignment"
      [ngClass]="copiedEmbedLink ? 'popup' : 'popup-hidden'"
    >
      Copied!
    </div>
  </div>

  <div class="howTo">
    <a [href]="activateReturboLink" target="_blank"
      >How to embed a form
      <img src="assets/images/icons/arrow-top-right-on-square.png" />
    </a>
  </div>

  <div
    class="terms mt-20"
    *ngIf="domainSettings.onboarding_stage === 'Uncompleted'"
  >
    <app-checkbox
      size="small"
      (checkedChange)="acceptTerms()"
      [checked]="
        formSettings.is_form_embedded
      "
      [disabled]="isSaving || isLoading"
    >
    </app-checkbox>
    <span class="text"
      >I understand that I have to embed the form or copy the return form link
      for Returbo to be activated.
    </span>
  </div>
</app-settings-section>
