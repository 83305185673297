<div
  class="box"
  [ngClass]="{ selected: isSelected, recommended: name === 'Essentials' }"
  id="{{ name }}"
  (click)="togglePlanStatus()"
>
  <div class="section">
    <h4 class="d-flex align-i-center" [ngClass]="{ check: isSelected }">
      {{ name }}
    </h4>
  </div>

  <h5>{{ tagLine }}</h5>

  <div class="bottom-section">
    <div class="section">
      <div class="price-section">
        <div class="price">
          <p>$</p>
          <h1>{{ price }}</h1>
        </div>
        <div class="price-info">
          <p>{{ priceUnit }}</p>
        </div>
      </div>
    </div>
    <ng-container>
      <app-button
        *ngIf="!isSelected"
        [size]="'full-width'"
        (byClick)="(selected)"
      >
        <ng-container *ngIf="plansSelected; else firstLoad">
          {{ setPlanButtonText() }}
        </ng-container>

        <ng-template #firstLoad> Select Plan </ng-template>
      </app-button>

      <button *ngIf="isSelected" class="btn mr-16 activated-btn">
        Activated
      </button>
    </ng-container>
  </div>
</div>
