import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { NotificationsService } from '../notifications.service';
import { keywords } from './keywords-list';

@Component({
  selector: 'app-useful-keywords-modal',
  templateUrl: './useful-keywords-modal.component.html',
  styleUrls: ['./useful-keywords-modal.component.scss'],
})
export class UsefulKeywordsModalComponent implements OnInit {
  keywordList = keywords;

  constructor(
    private modalService: ModalService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.notificationsService.getUsefulKeywordModal().subscribe((opened) => {
      if (opened) {
        this.openModal();
      }
    });
  }

  openModal(): void {
    this.modalService.open('useful-keywords');
  }

  closeModal(): void {
    this.modalService.dismiss();
    this.notificationsService.toggleUsefulKeywordModal(false);
  }
}
