<form [formGroup]="range">
  <div class="date-picker">
    <img class="calendar-icon" src="../assets/images/icons/calendar-icon.png" />

    <mat-date-range-input [rangePicker]="picker">
      <input
        formControlName="start"
        matStartDate
        placeholder="Start date"
        (dateChange)="emitStartDate($event)"
      />
      <input
        formControlName="end"
        matEndDate
        placeholder="End date"
        (dateChange)="emitEndDate($event)"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle class="ml-20" matIconSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</form>
