import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ShopifySettings } from "src/app/settings/models/shopify-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class ShopifySettingsService {
    
  private shopifySettingsSubject: BehaviorSubject<ShopifySettings> = new BehaviorSubject(
      new ShopifySettings()
  );
  private isLoadingShopifySettings = false;
  constructor(private httpClient: HttpClient) {}

  getShopifySettings(): Observable<ShopifySettings> {
    if (
      this.shopifySettingsSubject.value === null ||
      (!this.shopifySettingsSubject.value.account_domain &&
      !this.isLoadingShopifySettings)
    ) {
      this.isLoadingShopifySettings = true;
      this.httpClient
        .get<ShopifySettings>(`${environment.apiUrl}/settings/shopify`)
        .subscribe((settings) => {
          this.isLoadingShopifySettings = false;
          this.shopifySettingsSubject.next(settings);
        });
    }
    return this.shopifySettingsSubject;
  }

  hasShopifySettingsLoaded(): boolean {
    const shopifySettingsPopulated = this.shopifySettingsSubject.value !== null;
    const shopifySettingsLoading = (!this.shopifySettingsSubject.value.account_domain &&
      this.isLoadingShopifySettings);
    return shopifySettingsPopulated && !shopifySettingsLoading;
  }
  
  setShopifySettings(settings: ShopifySettings): void {
    this.shopifySettingsSubject.next(settings);
  }
  
  getSubjectShopifySettings(): Observable<ShopifySettings> {
    return this.shopifySettingsSubject.asObservable();
  }

  updateShopifySettings(settings: ShopifySettings): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/shopify`,
      settings
    );
  }
}