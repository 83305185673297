import { Component, OnInit } from '@angular/core';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  title = 'Customer language support';
  description =
    'Select supported languages and the default language for the Digital Return Form.';

  languageList: Language[] = [];
  languageSettings: LanguageSettings = new LanguageSettings();

  constructor(
    private languageSettingsService: LanguageSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.languageSettingsService
      .getSubjectLanguageSettings()
      .subscribe((languageSettings) => {
        if (this.languageSettingsService.hasLanguageSettingsLoaded()) {
          this.languageSettings = languageSettings;
          this.languageList = languageSettings.languages;
          this.isLoading = false;
        }
      });
  }

  public getCountryCode(lang: Language): string {
    if (lang.country_code == 'EN') return 'us';
    return lang.country_code?.toLowerCase() ?? '';
  }

  public toggleLanguage(value: boolean, lang: Language): void {
    lang.is_active = value;
    this.saveSettings();
  }

  setDRFLanguage(lang: Language): void {
    this.languageSettings.default_language = lang.id;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;
    this.languageSettingsService.saveLanguageSettings(this.languageSettings).subscribe({
      next: () => {
        this.languageSettingsService.setLanguageSettingsSubject(this.languageSettings);
        this.isSaving = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to save language settings.'
        );
        this.isSaving = false;
      },
    });
  }
}
