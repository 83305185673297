import { ColumnList } from '../models/column-list.model';
import { StatusFilter } from '../models/status-filter.model';
import { StatusStyling } from '../models/status-styling.model';
import { ReturnItemStatusEnum } from '../shared/enums/return-item-status.enum';
import { ReturnReasonType } from '../shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from '../shared/enums/return-status.enum';

export const returnStatusesFilters: StatusFilter[] = [
  {
    title: 'All',
    amount: 0,
    selected: true,
    status: [],
    name: 'all',
    nameTitle: '',
    class: '',
    updateTitle: '',
    noReturn: {
      title: 'No returns to show yet',
      description:
        'Make sure you have added the link to the return form to your store so customers can access it.',
    },
    headerStatus: {
      colorClass: '',
      icon: '',
      subtitle: '',
    },
  },
  {
    title: 'Requests',
    amount: 3,
    selected: false,
    status: [
      ReturnStatusEnum.RequestReceived,
      ReturnStatusEnum.AwaitingShippingSlip,
    ],
    name: 'requests',
    nameTitle: 'Request submitted',
    class: 'background-return-red',
    updateTitle: 'Customer requested',
    noReturn: {
      title: 'No new requests here',
      description:
        'You’re all set for now. There are no new return requests to handle at this moment.',
    },
    headerStatus: {
      colorClass: 'color-return-request',
      icon: 'return-request-icon.png',
      subtitle: 'Request submitted',
    },
  },
  {
    title: 'Incoming',
    amount: 11,
    selected: false,
    name: 'incoming',
    nameTitle: 'Incoming',
    status: [
      ReturnStatusEnum.PackageSent,
      ReturnStatusEnum.AwaitingDropOff,
      ReturnStatusEnum.ShippingSlipSkipped,
      ReturnStatusEnum.ManualShippingSlipAttached,
    ],
    class: 'background-return-incoming',
    updateTitle: 'Request approved',
    noReturn: {
      title: 'No incoming requests here',
      description:
        'Great job! You have no incoming requests that are pending at this moment.',
    },
    headerStatus: {
      colorClass: 'color-incoming',
      icon: 'incoming-icon.png',
      subtitle: 'Request incoming from',
    },
  },
  {
    title: 'Pending approval',
    amount: 4,
    selected: false,
    status: [ReturnStatusEnum.PackageReceived],
    name: 'pending',
    nameTitle: 'Pending Approval',
    class: 'background-return-pending',
    updateTitle: 'Request received',
    noReturn: {
      title: 'No approval needed here',
      description:
        'Take a deep breath. It seems like you have no requests that are pending your approval.',
    },
    headerStatus: {
      colorClass: 'color-pending-approval',
      icon: 'pending-approval-icon.png',
      subtitle: 'Request pending for approval from',
    },
  },
  {
    title: 'Resolved',
    amount: 0,
    selected: false,
    name: 'resolved',
    nameTitle: 'Resolved',
    status: [
      ReturnStatusEnum.RefundApproved,
      ReturnStatusEnum.RefundDenied,
      ReturnStatusEnum.RequestDenied,
      ReturnStatusEnum.RefundPartiallyApproved,
      ReturnStatusEnum.RequestManaged,
    ],
    class: 'background-return-resolved',
    updateTitle: 'Request resolved',
    noReturn: {
      title: 'No resolved requests to show yet',
      description:
        'You don’t have any completely resolved requests here. Your current requests might be in previous stages.',
    },
    headerStatus: {
      colorClass: 'color-resolved',
      icon: 'resolved-icon.png',
      subtitle: 'Request resolved',
    },
  },
  {
    title: 'Archived',
    amount: 0,
    status: [ReturnStatusEnum.Archived],
    selected: false,
    name: 'archived',
    nameTitle: 'Archived',
    class: 'background-return-archived',
    updateTitle: 'Archived',
    noReturn: {
      title: 'No archived returns to show here',
      description:
        'Make sure you have added the link to the return form to your store so customers can access it.',
    },
    headerStatus: {
      colorClass: 'color-archived',
      icon: 'return-archived.png',
      subtitle: 'Archived',
    },
  },
];

export const defaultTableColumns: ColumnList[] = [
  { name: 'Order number', checked: true, columnName: 'orderNo' },
  { name: 'Type', checked: true, columnName: 'type' },
  { name: 'Customer', checked: true, columnName: 'customer' },
  { name: 'Items', checked: true, columnName: 'items' },
  { name: 'Shipping carrier', checked: true, columnName: 'shippingCarrier' },
  { name: 'Tracking number', checked: true, columnName: 'trackingNo' },
  { name: 'Price', checked: false, columnName: 'price' },
  { name: 'Date initiated', checked: false, columnName: 'dateInitiated' },
  { name: 'Last update', checked: true, columnName: 'lastUpdate' },
  { name: 'Order status', checked: true, columnName: 'status' },
];

export const displayedColumnsList: ColumnList[] = [
  { name: 'Order number', checked: true, columnName: 'orderNo' },
  { name: 'Type', checked: true, columnName: 'type' },
  { name: 'Customer', checked: true, columnName: 'customer' },
  { name: 'Items', checked: true, columnName: 'items' },
  { name: 'Shipping carrier', checked: true, columnName: 'shippingCarrier' },
  { name: 'Tracking number', checked: true, columnName: 'trackingNo' },
  { name: 'Last update', checked: true, columnName: 'lastUpdate' },
  { name: 'Order status', checked: true, columnName: 'status' },
];

export const displayedColumnsNames: string[] = [
  'orderNo',
  'type',
  'customer',
  'items',
  'price',
  'dateInitiated',
  'lastUpdate',
  'status',
  'actions',
];

export const resolvedStatuses: StatusStyling[] = [
  {
    status: [
      ReturnStatusEnum.RequestDenied,
      ReturnStatusEnum.RefundDenied,
      ReturnItemStatusEnum.ItemDenied,
    ],
    title: 'Rejected',
    class: 'rejected',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
  {
    status: [ReturnStatusEnum.RefundApproved],
    title: 'Accepted',
    class: 'incoming',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
  {
    status: [ReturnStatusEnum.RefundPartiallyApproved],
    title: 'Partially accepted',
    class: 'partially',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
];

export const resolvedItemStatuses: StatusStyling[] = [
  {
    status: [
      ReturnItemStatusEnum.ItemDenied,
      ReturnItemStatusEnum.RefundDenied,
    ],
    title: 'Rejected',
    class: 'rejected',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
  {
    status: [
      ReturnItemStatusEnum.RefundApproved,
      ReturnItemStatusEnum.ItemReceived,
    ],
    title: 'Resolved',
    class: 'approved',
    returnType: [ReturnReasonType.Return],
  },
  {
    status: [
      ReturnItemStatusEnum.RefundApproved,
      ReturnItemStatusEnum.ItemReceived,
    ],
    title: 'Accepted',
    class: 'approved',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
  {
    status: [ReturnItemStatusEnum.AwaitingDropOff],
    title: 'Incoming',
    class: 'incoming',
    returnType: [
      ReturnReasonType.Complaint,
      ReturnReasonType.Return,
      ReturnReasonType.Exchange,
    ],
  },
];
