import { Item } from 'src/app/models/item.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';

export function refundStatusValuesText(
  items: Item[],
  isResolved: boolean,
  hasComplaintNewItem: boolean,
  allItemsDeclined: boolean,
  exchangeRejectedItems: number
) {
  const totalItemsLength = items.length;

  if (!isResolved) {
    return { text: 'Pending approval', icon: 'more_horiz' };
  }

  if (hasComplaintNewItem) {
    const newItemsCount = items.filter(
      (item) => item.merchant_compensation_choice === CompensationOption.NewItem
    ).length;

    return newItemsCount === totalItemsLength
      ? { text: 'Not issued', icon: 'close' }
      : { text: 'Issued', icon: 'done' };
  }

  const rejectedCount = exchangeRejectedItems;

  if (rejectedCount && rejectedCount !== totalItemsLength) {
    return { text: 'Partially Issued', icon: 'done' };
  }

  return allItemsDeclined
    ? { text: 'Rejected', icon: 'close' }
    : { text: 'Issued', icon: 'done' };
}
