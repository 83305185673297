<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="true"
  feature="New integration"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="DHL Freight Sweden"
      logo="dhl-freight"
      [shippingCompany]="shippingCarrier.DhlFreight"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          Activate DHL Freight Sweden today and start to autogenerate your return shipping 
          slips. When your customers have registered their return they will automatically 
          receive an email with a return shipping slip as a PDF. Your customers can then print 
          the label at home. For customers within EU they will also receive a QR-code. 
          Your customer can then choose to print the label or show the QR-code at their local post office.
        </p>
        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <div class="d-flex">
              <img src="assets/images/icons/qrcode.png" alt="QR code" />
              <div>
                <p class="section section-subtitle m-0">QR code within EU</p>
                <p class="section slip-description mt-0">
                  Let your customers within EU use QR codes to get their shipping slip - no printing required.
                </p>
              </div>
            </div>
            <h4 class="section section-subtitle">
              <b>These services will be available to you:</b>
            </h4>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <!-- Currently we don't have a contract with DHL Freight Sweden for this, so this is hidden until later -->
          <!-- <app-request-quote [shippingCarrierName]="'DhlFreight'"
          requestQuote="Placeholder"></app-request-quote> -->
        </div>
      </div>

      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>

        <app-alert
          *ngIf="showAlert(alertType.Credentials)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>

        <div class="d-flex">
            <div class="w-half">
              <p class="section section-input-label">Account Number</p>
              <input
                type="text"
                name="key"
                class="section-input"
                [(ngModel)]="serviceInformation.accountNumber"
                placeholder="e.g. 01010101"
                (change)="onValueChanged()"
                [disabled]="isLoading || isSaving"
                [ngClass]="{ error: isInformationInvalid() }"
              />
              <p class="validation-message" *ngIf="isInformationInvalid()">
                The information is incorrect
              </p>
            </div>
            <div class="w-half">
              <p class="section section-input-label">Phone Number</p>
              <input
                type="text"
                name="key"
                class="section-input"
                [(ngModel)]="serviceInformation.phoneNumber"
                placeholder="e.g. +4670 123 45 67"
                (change)="onValueChanged()"
                [disabled]="isLoading || isSaving"
                [ngClass]="{ error: isInformationInvalid() }"
              />
              <p class="validation-message" *ngIf="isInformationInvalid()">
                The information is incorrect.
              </p>
            </div>
          </div>

        <app-credentials-guide provider="DHL Freight"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which DHL Freight Sweden services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
        (infoModalOpened)="openInfoModal()"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-available-carrier-services>

      <app-paperless-trade
        (selectedValue)="proformaSelectedChange($event)"
        [paperlessTrade]="serviceInformation.apiProforma"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [carrier]="shippingCarrier.DhlFreight"
        [isReturboGenerated]="true"
        [apiOrgNumber]="serviceInformation.apiOrgNumber"
        (orgNumberValue)="newOrgNumber($event)"
      ></app-paperless-trade>

      <app-enable-countries
        *ngIf="showCountryRules(availableServices)"
        [availableServices]="availableServices"
        [supportedCountries]="supportedCountries"
        [carrier]="shippingCarrier.DhlFreight"
        description="Create optional country rules for your current carriers. For example, if you only want to use DHL Freight for shipments from Estonia, choose Estonia as a selected market. You can also see which countries the services are available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.accountNumber"
        description="The DHL Freight Sweden activation will be instant and it will be ready to use straight away."
        [provider]="shippingCarrier.DhlFreight"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="dhlfreight-services">
    <div>
      <h1 class="font-semiBold font-22 text-almost-black mt-0">DHL Freight Sweden services</h1>
      <p class="font-normal font-14 text-almost-black"></p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Service Point Return</b> - For packages up to 20kg within Sweden.
        Packages with max length of 150 cm and max length + circumference =  300 cm. 
        Delivers to most of Sweden within 1 to 2 days. C2B and traceable. 
      </p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Parcel Return Connect</b> - For packages up to 31.5 kg from
        24 countries to Sweden. Max length + circumference = 360 cm. C2B and traceable.
      </p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Home Delivery Return</b> - For packages up to 800kg within Sweden. 
        Package is picked up from customer’s home. Delivers to most of Sweden 
        within 1 to 2 days. C2B and traceable.
      </p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Paket</b> - For packages up to 35 kg within Sweden,
        door to door. 1 to 4 days delivery time. B2B and traceable.
      </p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Euroconnect</b> - For packages up to 1000kgs to and from 
        Sweden and 50 countries in Europa and Asia. B2B and traceable.
      </p>
      <p class="font-normal font-14 text-almost-black">
        <b>DHL Freight Euroline</b> - Weight allowance varies by destination country.
        To and from Sweden and 50 countries within Europa and Asia. B2B.
      </p>
      <p class="font-normal font-14 text-almost-black">
        DHL Freight Sweden only ships to and from Sweden. 
      </p>
      <p class="font-normal font-14 text-almost-black">
        For more information please visit
        <a
          class="text-primary"
          href="https://www.dhl.com/se-sv/home/frakt.html"
          target="_blank"
        >
          DHL Freight’s official website</a
        >.
      </p>
    </div>
  </app-modal>
