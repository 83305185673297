import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { SupportedCountry } from 'src/app/settings/models/integration-package-supported-countries.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { allPackageTypes } from '../carrier-services';
import { IntegrationAlertService } from '../integration-alert.service';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { PackageTypes } from 'src/app/settings/models/package-types.model';

@Component({
  selector: 'app-enable-countries',
  templateUrl: './enable-countries.component.html',
  styleUrls: ['./enable-countries.component.scss'],
})
export class EnableCountriesComponent implements OnInit {
  @Input() supportedCountries = new Map<
    ShippingPackageType,
    SupportedCountry[]
  >();
  @Input() availableServices: AvailableService[] = [];
  @Input() carrier = ShippingCarrier.Bring
  @Input() title = '';
  @Input() description = '';
  @Input() updateMode = false;
  @Input() note = '';
  @Input() disabled = false;
  @Output() selectedMarkets: EventEmitter<Map<ShippingPackageType, string[]>> =
    new EventEmitter<Map<ShippingPackageType, string[]>>();

  packageType = ShippingPackageType.DhlExpressWorldWide;
  uniqueMarkets = new Map<ShippingPackageType, Set<string>>();
  showMarket = true;
  searchValue = '';
  activateCountryRules = false;
  supportedCountriesCopy = new Map<ShippingPackageType, SupportedCountry[]>();

  constructor(
    private modalService: ModalService,
    private integrationAlertService: IntegrationAlertService
  ) {}

  ngOnInit(): void {
    this.supportedCountries.forEach((countryList, packageType) => {
      const uniqueMarkets = new Set<string>();
      countryList?.map(function (country) {
        uniqueMarkets.add(country.market);
      });
      this.uniqueMarkets.set(packageType, uniqueMarkets);
      this.packageType = packageType;
    });

    this.resetSupportedCountriesCopy();

    const supportedCountries = Array.from(this.supportedCountriesCopy.values());
    this.activateCountryRules = supportedCountries.some((supportedCountry) => {
      const selectedAmount =
        supportedCountry.filter((country) => country.is_selected)?.length || 0;
      const supportedAmount =
        supportedCountry.filter((country) => country.supported)?.length || 0;
      return selectedAmount > 0 && selectedAmount !== supportedAmount;
    });
  }

  checkEnabledMarkets(packageType: ShippingPackageType): boolean {
    const enabledMarkets = this.availableServices
      .filter((available) => available.checked)
      .map((x) => x.packageType);

    return enabledMarkets.includes(packageType);
  }

  setSelectedValue(event: MatRadioChange): void {
    this.activateCountryRules = event.value;
    this.showSavingAlert();

    if (!this.activateCountryRules) {
      this.supportedCountries.forEach((supportedCountry) =>
        supportedCountry.forEach((country) => (country.is_selected = false))
      );

      this.supportedCountriesCopy.clear();
      for (const [key, value] of this.supportedCountries) {
        const deepCopy = JSON.parse(JSON.stringify(value));
        this.supportedCountriesCopy.set(key, [...deepCopy]);
      }
    }
  }

  getCountriesInMarket(
    packageType: ShippingPackageType,
    market: string
  ): SupportedCountry[] {
    const packageCountries = this.supportedCountriesCopy.get(packageType);
    if (!packageCountries) {
      return [];
    }

    const lowerCaseSearchValue = this.searchValue.toLocaleLowerCase();
    return packageCountries.filter((country) => {
      const lowerCaseCountryCode = country.country_code.toLocaleLowerCase();
      const lowerCaseCountryName = country.country_name.toLocaleLowerCase();

      return (
        country.market === market &&
        (lowerCaseCountryCode.includes(lowerCaseSearchValue) ||
          lowerCaseCountryName.includes(lowerCaseSearchValue))
      );
    });
  }

  isMarketDisabled(packageType: ShippingPackageType, market: string): boolean {
    return !this.supportedCountriesCopy
      .get(packageType)
      ?.some((country) => country.market === market && country.supported);
  }

  isMarketChecked(packageType: ShippingPackageType, market: string): boolean {
    return !this.supportedCountriesCopy
      .get(packageType)
      ?.some(
        (country) =>
          country.market === market && !country.is_selected && country.supported
      );
  }

  getCountryCode(country: SupportedCountry): string {
    return country.country_code.toLowerCase();
  }

  openMarketModal(packageType?: ShippingPackageType): void {
    this.packageType = packageType ?? this.packageType;
    this.modalService.open(`enable-countries-${this.carrier}`);
  }

  toggleMarket(
    packageType: ShippingPackageType,
    toggle: boolean,
    market: string
  ): void {
    this.supportedCountriesCopy
      .get(packageType)
      ?.filter((country) => country.market === market && country.supported)
      .forEach((country) => (country.is_selected = toggle));
  }

  toggleCountry(
    packageType: ShippingPackageType,
    toggle: boolean,
    countryCode: string
  ): void {
    this.supportedCountriesCopy
      .get(packageType)
      ?.filter(
        (country) => country.country_code === countryCode && country.supported
      )
      .forEach((country) => (country.is_selected = toggle));
  }

  getSelectedRegions(packageType: ShippingPackageType): number {
    return (
      this.supportedCountriesCopy
        .get(packageType)
        ?.filter((country) => country.is_selected).length ?? 0
    );
  }

  cancel(): void {
    this.resetSupportedCountriesCopy();
    this.modalService.dismiss();
  }

  saveMarkets(packageType: ShippingPackageType): void {
    const savedMarkets =
      this.supportedCountriesCopy
        .get(packageType)
        ?.filter((country) => country.is_selected && country.supported)
        .map((country) => country.country_code) ?? [];

    this.resetSupportedCountries(
      this.supportedCountriesCopy,
      this.supportedCountries
    );

    this.showSavingAlert();
    this.modalService.dismiss();

    const output = new Map<ShippingPackageType, string[]>();
    output.set(packageType, savedMarkets);
    this.selectedMarkets.emit(output);
  }

  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.showMarket = !this.searchValue;
  }

  getSelectedMarketsText(packageType: ShippingPackageType): string {
    const selectedCountries =
      this.supportedCountriesCopy
        .get(packageType)
        ?.filter((country) => country.is_selected && country.supported) ?? [];

    if (selectedCountries.length === 1) {
      return selectedCountries[0].country_name;
    }
    if (selectedCountries.length === 2) {
      return `${selectedCountries[0].country_name} & ${selectedCountries[1].country_name}`;
    }

    let text = `${selectedCountries[0].country_name}, ${selectedCountries[1].country_name}, ${selectedCountries[2].country_name}`;
    if (selectedCountries.length > 3) {
      text += ` and ${selectedCountries.length - 3} more`;
    }
    return text;
  }

  showSavingAlert(): void {
    this.integrationAlertService.setAlertSectionForCarrier(
      this.carrier,
      AlertSectionType.CountryRules
    );
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      this.carrier,
    );
    return alertType === AlertSectionType.CountryRules;
  }

  isUsed(country: SupportedCountry): boolean {
    return country.used_by?.length > 0;
  }

  getIcon(packageType: ShippingPackageType): string {
    const packageTypeInfo = allPackageTypes.find(
      (info) => info.type === packageType
    );
    return packageTypeInfo?.logo ?? '';
  }

  getDisplayNameForCarrier(shippingCarrier: ShippingCarrier): string {
    const carrierDisplayName = allPackageTypes.find((info) => info.carrier === shippingCarrier)?.text;
    return carrierDisplayName ?? shippingCarrier;
  }

  getDisplayNameForPackageType(packageType: ShippingPackageType): string {
    const packageTypeInfo = allPackageTypes.find(
      (info) => info.type === packageType
    );
    const displayName = `<b>${packageTypeInfo?.text}</b> ${packageTypeInfo?.packageType}`;
    return displayName;
  }

  getShippingPackageTypesDisplayNames(): PackageTypes[] {
    return [...this.supportedCountries.keys()].map((packageType) => {
      const packageInfo = allPackageTypes.find(
        (type) => type.type === packageType
      );
      return packageInfo ?? new PackageTypes();
    });
  }

  getShippingPackageTypes(): ShippingPackageType[] {
    return [...this.supportedCountries.keys()];
  }

  getPackageType(packageType: ShippingPackageType): string {
    const packageInfo = allPackageTypes.find(
      (packageTypeInfo) => packageTypeInfo.type === packageType
    );
    return packageInfo?.packageType ?? this.packageType;
  }

  setPackageType(packageType: ShippingPackageType): void {
    const packageInfo = allPackageTypes.find(
      (type) => type.type === packageType
    );
    this.packageType = packageInfo?.type ?? this.packageType;
  }

  resetSupportedCountriesCopy(): void {
    this.resetSupportedCountries(
      this.supportedCountries,
      this.supportedCountriesCopy
    );
  }

  resetSupportedCountries(
    intactSupportedCountries: Map<ShippingPackageType, SupportedCountry[]>,
    overwrittenSupportedCountries: Map<ShippingPackageType, SupportedCountry[]>
  ): void {
    overwrittenSupportedCountries.clear();
    for (const [key, value] of intactSupportedCountries) {
      const deepCopy = JSON.parse(JSON.stringify(value));
      overwrittenSupportedCountries.set(key, [...deepCopy]);
    }
  }

  getNameForPackageType(packageType: ShippingPackageType): string {
    return (
      allPackageTypes.find((packageInfo) => packageInfo.type === packageType)
        ?.packageType ?? ''
    );
  }

  displayAddMarketButton(): boolean {
    const hasCountryWithNoSelections = [
      ...this.supportedCountriesCopy.keys(),
    ].some((key) => this.getSelectedRegions(key) === 0);
    return hasCountryWithNoSelections || !this.activateCountryRules;
  }
}
