import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerCountry } from 'src/app/settings/models/customer-country';
import { FormValues } from 'src/app/settings/models/form-values.model';
import { IntegrationPackageSupportedCountries } from 'src/app/settings/models/integration-package-supported-countries.model';
import { RequestQuote } from 'src/app/settings/models/request-quote.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { UpsLogin } from 'src/app/settings/models/ups-login.model';
import { environment } from 'src/environments/environment';
import { IntegrationStatusEnum } from '../enums/integration-status.enum';
import { ShippingPackageType } from '../enums/package-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ShippingIntegrationService {
  private integrationsInformationSubject: BehaviorSubject<
    ShippingIntegration[]
  > = new BehaviorSubject<ShippingIntegration[]>([]);
  private activatedIntegrations: BehaviorSubject<ShippingIntegration[]> =
    new BehaviorSubject<ShippingIntegration[]>([]);

  private valuesChanged: BehaviorSubject<FormValues> =
    new BehaviorSubject<FormValues>(new FormValues());

  private leaveUrl: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private postNordCustomerCountries: BehaviorSubject<CustomerCountry[]> =
    new BehaviorSubject<CustomerCountry[]>([]);

  private integationPackageSupportedCountries: BehaviorSubject<
    IntegrationPackageSupportedCountries[]
  > = new BehaviorSubject<IntegrationPackageSupportedCountries[]>([]);

  private updatedIntegration: BehaviorSubject<{
    name: string;
    updated: boolean;
  }> = new BehaviorSubject<{ name: string; updated: boolean }>({
    name: '',
    updated: false,
  });

  private activeCarriers: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );

  constructor(private httpClient: HttpClient) {}

  getShippingIntegrations(): Observable<ShippingIntegration[]> {
    return this.httpClient.get<ShippingIntegration[]>(
      `${environment.apiUrl}/api/shipping/integration`
    );
  }

  getUpsLoginUrl(): Observable<UpsLogin> {
    return this.httpClient.get<UpsLogin>(
      `${environment.apiUrl}/api/shipping/integration/ups/login-url`
    );
  }

  getShippingIntegrationCountries(
    packageTypes: string
  ): Observable<IntegrationPackageSupportedCountries[]> {
    return this.httpClient.get<IntegrationPackageSupportedCountries[]>(
      `${environment.apiUrl}/api/shipping/integration/supported-countries?packageTypes=${packageTypes}`
    );
  }

  createUpdateShippingIntegration(
    payload: any[],
    addExtraCarrier: boolean
  ): Observable<ShippingIntegration[]> {
    return this.httpClient.put<ShippingIntegration[]>(
      `${environment.apiUrl}/api/shipping/integration?addExtraCarrier=${addExtraCarrier}`,
      payload
    );
  }

  deactiveShippingIntegration(shippingIntegrationIds: string): Observable<number> {
    return this.httpClient.delete<number>(
      `${environment.apiUrl}/api/shipping/integration?shippingIntegrationIds=${shippingIntegrationIds}`
    );
  }

  activateIntegrations(shippingPackageType: ShippingPackageType[]) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/shipping/integration/activation-email`,
      shippingPackageType
    );
  }

  setIntegrationsInformation(integrations: ShippingIntegration[]): void {
    this.integrationsInformationSubject.next(integrations);
  }

  getIntegrationsSubject(): Observable<ShippingIntegration[]> {
    return this.integrationsInformationSubject.asObservable();
  }

  getShippingIntegrationsByStatus(
    status: IntegrationStatusEnum | null
  ): Observable<ShippingIntegration[]> {
    return this.httpClient.get<ShippingIntegration[]>(
      `${environment.apiUrl}/api/shipping/integration?status=${status}`
    );
  }

  getValidShippingIntegrationsForReturnOrder(
    returnId: string
  ): Observable<ShippingIntegration[]> {
    return this.httpClient.get<ShippingIntegration[]>(
      `${environment.apiUrl}/api/shipping/integration/available-for/${returnId}`
    );
  }

  getPostNordCustomerCountries(): Observable<CustomerCountry[]> {
    return this.httpClient.get<CustomerCountry[]>(
      `${environment.apiUrl}/api/shipping/integration/postnord/customer-countries`
    );
  }

  requestIntegrationQuote(shippingCarrierName: string): Observable<any> {
    const request = {
      shipping_carrier_name: shippingCarrierName,
    } as RequestQuote;
    return this.httpClient.post(
      `${environment.apiUrl}/api/shipping/integration/request-quote`,
      request
    );
  }

  getPostNordCustomerCountriesSubject(): Observable<CustomerCountry[]> {
    return this.postNordCustomerCountries.asObservable();
  }

  setPostNordCustomerCountriesSubject(countries: CustomerCountry[]): void {
    this.postNordCustomerCountries.next(countries);
  }

  getIntegationPackageSupportedCountriesSubject(): Observable<
    IntegrationPackageSupportedCountries[]
  > {
    return this.integationPackageSupportedCountries.asObservable();
  }

  setIntegationPackageSupportedCountriesSubject(
    countries: IntegrationPackageSupportedCountries[]
  ): void {
    let populatedCountries = this.populateSelected(countries);
    populatedCountries = this.populateUsedBy(populatedCountries);
    this.integationPackageSupportedCountries.next(populatedCountries);
  }

  private populateSelected(
    countries: IntegrationPackageSupportedCountries[]
  ): IntegrationPackageSupportedCountries[] {
    this.getIntegrationsSubject().subscribe((res: ShippingIntegration[]) => {
      if (res && res.length > 0) {
        countries = countries.map((supportedCountry) => {
          const filteredIntegration = res.find(
            (shippingIntegration) =>
              shippingIntegration.shipping_package_type === supportedCountry.id
          );

          supportedCountry.countries.forEach((country) => {
            country.is_selected =
              filteredIntegration?.custom_rules.enabled_origin_countries.includes(
                country.country_code
              ) ?? false;
          });

          return supportedCountry;
        });
      }
    });
    return countries;
  }

  private populateUsedBy(
    countries: IntegrationPackageSupportedCountries[]
  ): IntegrationPackageSupportedCountries[] {
    return countries.map((integrationSupportedCountry) => {
      integrationSupportedCountry.countries
        .filter((country) => country.is_selected)
        .forEach((supportedCountry) => {
          const countryCode = supportedCountry.country_code;
          countries
            .filter(
              (otherCountry) =>
                otherCountry.id !== integrationSupportedCountry.id
            )
            .forEach((otherCountry) => {
              const matchingCountry = otherCountry.countries.find(
                (country) => country.country_code === countryCode
              );
              if (
                matchingCountry &&
                !matchingCountry.used_by?.includes(
                  integrationSupportedCountry.id
                )
              ) {
                matchingCountry.used_by = [
                  ...(matchingCountry.used_by || []),
                  integrationSupportedCountry.id,
                ];
              }
            });
        });
      return integrationSupportedCountry;
    });
  }

  setActiveIntegrationsSubject(integrations: ShippingIntegration[]) {
    this.activatedIntegrations.next(integrations);
  }

  getActiveIntegrationsSubject(): Observable<ShippingIntegration[]> {
    return this.activatedIntegrations.asObservable();
  }

  setValuesChanged(value: FormValues): void {
    this.valuesChanged.next(value);
  }

  getValuesChanged(): Observable<FormValues> {
    return this.valuesChanged.asObservable();
  }

  setLeaveUrl(url: string): void {
    this.leaveUrl.next(url);
  }

  getLeaveUrl(): Observable<string> {
    return this.leaveUrl.asObservable();
  }

  setUpdatedIntegration(name: string, updated: boolean): void {
    this.updatedIntegration.next({ name, updated });
  }

  getUpdatedIntegration(): Observable<{ name: string; updated: boolean }> {
    return this.updatedIntegration.asObservable();
  }

  setActiveCarriers(activeCarriers: number): void {
    this.activeCarriers.next(activeCarriers);
  }

  getActiveCarriers(): Observable<number> {
    return this.activeCarriers.asObservable();
  }
}
