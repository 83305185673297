import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CarrierRanking } from 'src/app/settings/models/carrier-ranking.model';
import { UpdateRankingResponse } from 'src/app/settings/models/update-ranking-response.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CarrierRankingService {
  private shouldUpdateRanking: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  getCarrierRankings(): Observable<CarrierRanking[]> {
    return this.httpClient.get<CarrierRanking[]>(
      `${environment.apiUrl}/settings/package-type/ranking`
    );
  }

  updateCarrierRankings(
    rankings: CarrierRanking[]
  ): Observable<UpdateRankingResponse[]> {
    return this.httpClient.put<UpdateRankingResponse[]>(
      `${environment.apiUrl}/settings/package-type/ranking`,
      rankings
    );
  }

  setUpdateRanking(shouldUpdate: boolean): void {
    this.shouldUpdateRanking.next(shouldUpdate);
  }

  getUpdateRanking(): Observable<boolean> {
    return this.shouldUpdateRanking.asObservable();
  }
}
