export enum ReturnItemStatusEnum {
  Undefined = 'Undefined',
  Requested = 'Requested',
  ItemDenied = 'ItemDenied',
  AwaitingDropOff = 'AwaitingDropOff',
  ItemShipped = 'ItemShipped',
  ItemReceived = 'ItemReceived',
  RefundApproved = 'RefundApproved',
  RefundDenied = 'RefundDenied',
}
