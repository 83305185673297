import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-new-button',
  templateUrl: './new-button.component.html',
  styleUrls: ['./new-button.component.scss'],
})
export class NewButtonComponent {
  @Input()
  buttonStyle = 'confirm';
  @Input()
  textStyle = 'text';
  @Input()
  disabled = false;
  @Input()
  type = 'button';
  @Input()
  customWidth = 'auto';

  @Output()
  byClick: EventEmitter<Event> = new EventEmitter<Event>();

  buttonPressed(event: Event) {
    if (!this.disabled) {
      this.byClick.emit(event);
    }
  }
}
