import { Component, Input } from '@angular/core';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss'],
})
export class RequestQuoteComponent {
  @Input() shippingCarrierName = '';
  @Input() requestQuote = '';

  isRequested = false;
  isRequesting = false;

  constructor(
    private shippingIntegrationService: ShippingIntegrationService,
    private errorService: ErrorService
  ) {}

  setRequestQuote(): void {
    this.isRequesting = true;
    this.shippingIntegrationService
      .requestIntegrationQuote(this.shippingCarrierName)
      .subscribe({
        next: () => {
          this.isRequested = true;
          this.isRequesting = false;
        },
        error: (err) => {
          this.errorService.showErrorSnackBar(err);
          this.isRequested = false;
          this.isRequesting = false;
        },
      });
  }
}
