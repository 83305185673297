import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/components/modal/modal.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-not-activated-popup',
  templateUrl: './not-activated-popup.component.html',
  styleUrls: ['./not-activated-popup.component.scss'],
})
export class NotActivatedPopupComponent implements OnInit {
  url = '';
  constructor(
    private modalService: ModalService,
    private popupService: PopupService,
    private router: Router,
    private shippingIntegrationService: ShippingIntegrationService
  ) {}

  ngOnInit(): void {
    this.popupService.getNotActivatedPopup().subscribe((res: boolean) => {
      if (res) this.openModal();
    });

    this.shippingIntegrationService
      .getLeaveUrl()
      .subscribe((res: string) => (this.url = res));
  }

  openModal(): void {
    this.modalService.open('not-activated');
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  leavePage(): void {
    this.shippingIntegrationService.setValuesChanged({
      postnord: false,
      gls: false,
      dhlexpress: false,
      bring: false,
      ups: false,
      deutschePost: false,
      dhlfreight: false,
    });
    this.router.navigate([this.url]);
  }
}
