import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private notActivatedPopup = new BehaviorSubject<boolean>(false);
  private trackingErrorPopup = new BehaviorSubject<boolean>(false);

  constructor(private snackBar: MatSnackBar) {}

  public showMessageSnackBar(message: string): void {
    this.snackBar.open(message || 'Success!', 'OK', {
      duration: 4000,
    });
  }

  setNotActivatedPopup(value: boolean): void {
    this.notActivatedPopup.next(value);
  }

  getNotActivatedPopup(): Observable<boolean> {
    return this.notActivatedPopup.asObservable();
  }

  setTrackingErrorPopup(value: boolean): void {
    this.trackingErrorPopup.next(value);
  }

  getTrackingErrorPopup(): Observable<boolean> {
    return this.trackingErrorPopup.asObservable();
  }
}
