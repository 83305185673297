<div class="modal-background" [ngClass]="{ show: isShowing }" #background>
  <div class="modal-body large">
    <div class="modal-body__content">
      <div class="modal-body__content-left">
        <app-left-content></app-left-content>
      </div>
      <div class="modal-body__content-right">
        <app-right-content></app-right-content>
      </div>
    </div>
  </div>
</div>
