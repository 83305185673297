<div class="section">
  <h4 class="section-title mt-40">
    {{ title ? title : "Country Rules for " + getDisplayNameForCarrier(carrier) }} (optional)
  </h4>
  <p class="section section-description">
    {{ description }}
  </p>

  <div *ngIf="showAlert() && updateMode" class="mb-12">
    <app-alert
      type="Note"
      [showCloseButton]="true"
      [text]="note"
      imgIcon="../assets/images/icons/alert-triangle.png"
    ></app-alert>
  </div>

  <mat-radio-group
    [ngClass]="{ disabled: disabled }"
    [disabled]="disabled"
    class="enable-country-rules"
    [ngModel]="activateCountryRules"
    (change)="setSelectedValue($event)"
  >
    <mat-radio-button class="country-rules-option" [value]="true">
      Enable this feature
    </mat-radio-button>
    <mat-radio-button class="country-rules-option" [value]="false">
      No thanks, disable it
    </mat-radio-button>
  </mat-radio-group>

  <app-button
    *ngIf="displayAddMarketButton()"
    [disabled]="!activateCountryRules || disabled"
    (byClick)="openMarketModal()"
    buttonStyle="secondary"
    prefix="add"
  >
    Add market
  </app-button>
  <div class="mt-10" *ngFor="let packageType of getShippingPackageTypes()">
    <div
      *ngIf="
        getSelectedRegions(packageType) > 0 &&
        checkEnabledMarkets(packageType) &&
        activateCountryRules
      "
      class="current-markets"
    >
      <div>
        <h5 class="font-semiBold font-14 text-almost-black mt-0 mb-8">
          Current markets for {{ getDisplayNameForCarrier(carrier) }}
          {{ getNameForPackageType(packageType) }}
        </h5>
        <p class="m-0">
          {{ getSelectedMarketsText(packageType) }}
        </p>
      </div>
      <div>
        <p
          class="manage"
          [ngClass]="{ disabled: disabled }"
          (click)="!disabled && openMarketModal(packageType)"
        >
          Manage
        </p>
      </div>
    </div>
  </div>
</div>

<app-modal class="modal" id="enable-countries-{{ carrier }}">
  <div class="header">
    <h1 class="font-semiBold font-22 text-almost-black mt-0">
      Add a market to {{ getDisplayNameForCarrier(carrier) }}
    </h1>
    <div *ngIf="getShippingPackageTypes().length > 1">
      <p class="font-bold font-14 m-0 mb-1">Select service</p>
      <mat-select [value]="packageType">
        <mat-select-trigger class="mat-form-field-infix">
          {{ getPackageType(packageType) }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let shippingPackagetype of getShippingPackageTypesDisplayNames()"
          (click)="setPackageType(shippingPackagetype.type)"
          [value]="shippingPackagetype.type"
          >{{ shippingPackagetype.packageType }}
        </mat-option>
      </mat-select>
    </div>
    <div>
      <input
        class="font-14 font-normal pl-35"
        type="text"
        placeholder="Search countries/regions"
        [disabled]="!packageType"
        (keyup)="applyFilter($event)"
      />
    </div>
  </div>
  <div class="regions">
    <ul *ngFor="let market of uniqueMarkets.get(packageType)">
      <li *ngIf="showMarket" class="market">
        <app-checkbox
          size="small"
          [checked]="isMarketChecked(packageType, market)"
          (checkedChange)="toggleMarket(packageType, $event, market)"
          [disabled]="isMarketDisabled(packageType, market)"
          [ngClass]="isMarketDisabled(packageType, market) ? 'disabled' : ''"
          type="Minus"
          >{{ market }}
        </app-checkbox>
      </li>
      <li
        *ngFor="let country of getCountriesInMarket(packageType, market)"
        class="country"
      >
        <div class="d-flex justify-space-between">
          <app-checkbox
            [ngClass]="country.supported ? '' : 'disabled'"
            size="small"
            [disabled]="!country.supported"
            [checked]="country.is_selected"
            (checkedChange)="
              toggleCountry(packageType, $event, country.country_code)
            "
          >
            <span class="mr-5 fi fi-{{ getCountryCode(country) }}"></span>
            {{ country.country_name }}
          </app-checkbox>
          <div class="used-by-box" *ngIf="isUsed(country)">
            <p class="m-0">also used by</p>
            <div
              class="tool-tip-box"
              *ngFor="let packageType of country.used_by"
            >
              <img
                src="../assets/images/icons/{{ getIcon(packageType) }}-icon.png"
                class="carrier-icon"
              />
              <span
                class="tool-tip-text"
                innerHTML="{{ getDisplayNameForPackageType(packageType) }}"
              >
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="footer">
    {{ getSelectedRegions(packageType) }} countries/regions selected

    <div>
      <app-button class="mr-10" (byClick)="cancel()" buttonStyle="secondary">
        Cancel
      </app-button>
      <app-button (byClick)="saveMarkets(packageType)"> Done </app-button>
    </div>
  </div>
</app-modal>
