import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { PolicySettings } from 'src/app/settings/models/policy-settings.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-return-policy',
  templateUrl: './return-policy.component.html',
  styleUrls: ['./return-policy.component.scss'],
})
export class ReturnPolicyComponent implements OnInit {
  title = 'Return policy form';
  description =
    'A return policy helps the customer to know the timeframe for returns, complaints, exchanges, and what products are allowed to be returned.';

  returnURL = `https://${environment.appLink}`;
  embedURL =
    '<div style="position: relative; width: 100%; height: 100%;"><iframe src="{returnFormUrl}" style="width: 100%; min-height: 800px; border: none;" allowtransparency="true"></iframe></div>';

  tipText =
    'Please make sure you mention complaint and exchange policy inside this document, if you are using these functions.';

  isLoading = true;
  isSaving = false;
  policyID = {
    language: '',
    summary: '',
    type: '',
  };

  test = '';
  editMode: string[] = [];
  fullText = false;
  copiedReturnLink = false;
  copiedEmbedLink = false;

  languageSettings: LanguageSettings = new LanguageSettings();
  returnSettings: ReturnSettings = new ReturnSettings();

  constructor(
    private clipBoard: Clipboard,
    private errorService: ErrorService,
    private languageSettingsService: LanguageSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private shopifySettingsService: ShopifySettingsService
  ) {}

  ngOnInit(): void {

    const shopifySettings =
      this.shopifySettingsService.getSubjectShopifySettings();
    const languageSettings =
      this.languageSettingsService.getSubjectLanguageSettings();
    const returnSettings =
      this.returnSettingsService.getSubjectReturnSettings();

    combineLatest([
      shopifySettings,
      languageSettings,
      returnSettings,
    ]).subscribe(
      ([shopifySettings, languageSettings, returnSettings]) => {
        if (this.shopifySettingsService.hasShopifySettingsLoaded() &&
            this.languageSettingsService.hasLanguageSettingsLoaded() &&
            this.returnSettingsService.hasReturnSettingsLoaded()
            ) {
              this.setReturnFormUrls(shopifySettings.account_domain);
              this.returnSettings = returnSettings;
              this.languageSettings = languageSettings;
              this.isLoading = false;
        }
      }
    );
  }

  setReturnFormUrls(account_domain: string): void {
    const accountDomainParts = account_domain.split('.');
    if (account_domain && accountDomainParts.length > 0) {
      this.returnURL += `/${accountDomainParts[0]}/my-returns/search-order`;
      this.embedURL = this.embedURL.replace('{returnFormUrl}', this.returnURL);
    }
  }

  getLanguageCode(language: string): string {
    let code = '';

    this.languageSettings.languages.forEach((lang: Language) => {
      if (lang.name?.toLowerCase() === language.toLowerCase()) {
        if (lang.country_code === 'EN') {
          code = 'us';
          return;
        }
        code = lang.country_code ?? '';
        return;
      }
    });

    return code.toLowerCase();
  }

  saveSettings(policyLanguage: string): void {
    this.isSaving = true;

    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: () => {
          this.returnSettingsService.setReturnSettings(this.returnSettings);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to get settings');
        },
        complete: () => {
          this.isSaving = false;
          this.removeEdit(policyLanguage);
        },
      });
  }

  isLanguageActive(language: string): boolean {
    let active = false;

    this.languageSettings.languages.forEach((lang: Language) => {
      if (lang.name?.toLowerCase() === language.toLowerCase() && lang.is_active) {
        active = true;
        return;
      }
    });

    return active;
  }

  getActivePolicies(): PolicySettings[] {
    return this.returnSettings.policy_settings.filter((x: PolicySettings) =>
      this.isLanguageActive(x.language ?? '')
    );
  }

  setEdit(policyLanguage: string): void {
    this.editMode.push(policyLanguage);
  }

  isOnEdit(policyLanguage: string): boolean {
    return this.editMode.includes(policyLanguage);
  }

  removeEdit(policyLanguage: string): void {
    this.editMode = this.editMode.filter(
      (policy: string) => policy !== policyLanguage
    );
  }

  toggleText(): void {
    this.fullText = !this.fullText;
  }

  copyURL(activeLanguage: string, policySummary: string, type: string): void {
    this.policyID = {
      language: activeLanguage,
      summary: policySummary,
      type: type + activeLanguage,
    };

    const value = type === 'language' ? this.returnURL : this.embedURL;

    this.clipBoard.copy(value);
    this.copiedReturnLink = true;
    setTimeout(() => {
      this.policyID = {
        language: '',
        summary: '',
        type: '',
      };
      this.copiedReturnLink = false;
    }, 2000);
  }

  isDefaultLanguage(policyLanguage: string): boolean {
    return (
      policyLanguage.toLowerCase() ===
      this.languageSettings.languages
        .find((lang) => lang.id === this.languageSettings.default_language)
        ?.name.toLowerCase()
    );
  }
}
