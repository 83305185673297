<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [onBoardingStepCompleted]="onBoardingCompleted"
>
  <div *ngIf="returnSettings.is_using_3pl" class="warning">
    <img src="assets/images/icons/alert-triangle.png" class="mr-15" />
    <p>
      <b>Caution: </b>Please make sure you add your 3PL's address in your
      <b>Return locations</b> down below.
    </p>
  </div>

  <div class="radio_buttons">
    <app-radio
      name="outsourcing"
      [options]="options"
      [horizontal]="false"
      [selected]="getSelectedOption()"
      (selectedChange)="setSelectedOption($event)"
      [disabled]="isSaving"
    >
    </app-radio>
  </div>
</app-settings-section>
