<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
>
  <div>
    <ul>
      <li *ngFor="let type of availableSummaryEmails">
        <app-checkbox
          size="small"
          (checkedChange)="setSummaryEmails(type)"
          [checked]="type.checked"
          [disabled]="isSaving"
        >
          {{ type.interval }}
        </app-checkbox>
      </li>
    </ul>
  </div>
</app-settings-section>
