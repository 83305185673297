import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from 'src/app/settings/models/language.model';
import { Notification } from 'src/app/settings/models/notification.model';
import { SelectedEmail } from 'src/app/settings/models/selected-email.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private showUsefulKeywordModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private showHideEmailModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private selectedEmailSubject: BehaviorSubject<SelectedEmail> =
    new BehaviorSubject<SelectedEmail>(new SelectedEmail());

  private toggleEmailAction: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isSavingNotification: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private notificationsEmailSubject: BehaviorSubject<Notification[]> =
    new BehaviorSubject<Notification[]>([]);
  private savedNotification: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private notificationLanguageSubject: BehaviorSubject<Language> =
    new BehaviorSubject<Language>(new Language());

  constructor(private httpClient: HttpClient) {}

  getNotificationEmails(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(
      `${environment.apiUrl}/settings/emails`
    );
  }

  toggleUsefulKeywordModal(value: boolean): void {
    this.showUsefulKeywordModal.next(value);
  }

  getUsefulKeywordModal(): Observable<boolean> {
    return this.showUsefulKeywordModal.asObservable();
  }

  toggleHideEmailModal(value: boolean): void {
    this.showHideEmailModal.next(value);
  }

  getHideEmailModal(): Observable<boolean> {
    return this.showHideEmailModal.asObservable();
  }

  setSelectedEmail(email: SelectedEmail): void {
    this.selectedEmailSubject.next(email);
  }

  getSelectedEmail(): Observable<SelectedEmail> {
    return this.selectedEmailSubject.asObservable();
  }

  setToggleEmailAction(value: boolean): void {
    this.toggleEmailAction.next(value);
  }

  getToggleEmailAction(): Observable<boolean> {
    return this.toggleEmailAction.asObservable();
  }

  setIsSavingNotification(value: boolean): void {
    this.isSavingNotification.next(value);
  }

  getIsSavingNotification(): Observable<boolean> {
    return this.isSavingNotification.asObservable();
  }

  setNotificationsEmails(value: Notification[]): void {
    this.notificationsEmailSubject.next(value);
  }

  getNotificationsEmails(): Observable<Notification[]> {
    return this.notificationsEmailSubject.asObservable();
  }

  saveNotificationEmails(
    notification: Notification
  ): Observable<Notification[]> {
    return this.httpClient.put<Notification[]>(
      `${environment.apiUrl}/settings/emails`,
      notification
    );
  }

  setSavedNotification(value: boolean): void {
    this.savedNotification.next(value);
  }

  getSavedNotification(): Observable<boolean> {
    return this.savedNotification.asObservable();
  }

  getNotificationLanguage(): Observable<Language> {
    return this.notificationLanguageSubject.asObservable();
  }

  setNotificationLanguage(value: Language): void {
    this.notificationLanguageSubject.next(value);
  }
}
