import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { SettingsModule } from '../settings/settings.module';

import { UpsActivationLoaderComponent } from './pages/ups-activation-loader/ups-activation-loader.component';
import { UpsActivationRoutingModule } from './ups-activation-routing.module';

@NgModule({
  declarations: [UpsActivationLoaderComponent],
  imports: [
    FormsModule,
    CommonModule,
    UpsActivationRoutingModule,
    ComponentsModule,
    SettingsModule,
  ],
})
export class UpsActivationModule {}
