<app-modal id="tracking-error" [dismissable]="false" size="small">
  <h1 class="font-24 text-almost-black font-700">
    Tracking is not available with the current payment plan
  </h1>

  <div class="modal-wrap">
    <p class="font-normal font-14 text-almost-black">
      With your current Pay-as-you-go plan, you can’t have the automatic
      tracking activated. It is only available with the Essentials payment plan.
      If you want to activate it – please upgrade your plan in Settings
      <span class="pointer text-primary" (click)="goToPlans()">here</span>.
    </p>

    <div class="d-flex">
      <button class="btn btn-secondary btn-md mr-16" (click)="closeModal()">
        Cancel
      </button>
      <button class="btn btn-primary btn-md mr-16" (click)="goToPlans()">
        Go to plans
      </button>
    </div>
  </div>
</app-modal>
