import { Component, OnInit } from '@angular/core';
import { UpsUrl } from 'src/app/settings/models/ups-url';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { environment } from 'src/environments/environment';
import { UpsTokenService } from './ups-token.service';

@Component({
  selector: 'app-loading',
  templateUrl: './ups-activation-loader.component.html',
  styleUrls: ['./ups-activation-loader.component.scss'],
})
export class UpsActivationLoaderComponent implements OnInit {
  storeName = '';
  sentInURL = false;

  constructor(
    private upsTokenService: UpsTokenService,
    private errorService: ErrorService,
    private popupService: PopupService
  ) {}

  upsUrl: UpsUrl = new UpsUrl();

  ngOnInit(): void {
    const url = window.location.href;
    //Gets the code part of the URL
    const index = url.indexOf('=');
    const code = url.slice(index + 1);

    this.upsUrl.code = code;
  }

  activateUPS(): void {
    const redirectURL = `https://admin.shopify.com/store/${this.storeName}/apps/${environment.appName}`;
    const apiDomain = `${this.storeName}.myshopify.com`;

    this.upsUrl.domain = apiDomain;
    this.sentInURL = true;

    //Call API to verify code & domain
    this.upsTokenService.setUpsToken(this.upsUrl).subscribe({
      next: () => {
        //Redirect back to shopify store
        this.popupService.showMessageSnackBar('Integration successful!');
        window.location.href = redirectURL;
      },

      //Not sure what to do here except throw an error
      error: (error: any) => {
        this.errorService.showErrorSnackBar(`Failed to enable integration`);
        console.log(error);
        setTimeout(() => {
          window.location.href = redirectURL;
        }, 1000);
      },
    });
  }
}
