import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WarehouseSettings } from "src/app/settings/models/warehouse-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class WarehouseSettingsService {

  private warehouseSettingsSubject: BehaviorSubject<WarehouseSettings> = new BehaviorSubject(
      new WarehouseSettings()
  );
  private isLoadingWarehouseSettings = false;
  constructor(private httpClient: HttpClient) {}

  getWarehouseSettings(): Observable<WarehouseSettings> {
    if (
      this.warehouseSettingsSubject.value === null ||
      (!this.warehouseSettingsSubject.value.restock_to_warehouse_id &&
        !this.isLoadingWarehouseSettings)
    ) {
      this.isLoadingWarehouseSettings = true;
      this.httpClient
        .get<WarehouseSettings>(`${environment.apiUrl}/settings/warehouse`)
        .subscribe((settings) => {
          this.isLoadingWarehouseSettings = false;
          this.warehouseSettingsSubject.next(settings);
        });
    }
    return this.warehouseSettingsSubject;
  }

  hasWarehouseSettingsLoaded(): boolean {
    const warehouseSettingsPopulated = this.warehouseSettingsSubject.value !== null;
    const warehouseSettingsLoading = (!this.warehouseSettingsSubject.value.restock_to_warehouse_id !== undefined &&
      this.isLoadingWarehouseSettings);
    return warehouseSettingsPopulated && !warehouseSettingsLoading;
  }
  
  setWarehouseSettingsSubject(settings: WarehouseSettings): void {
    this.warehouseSettingsSubject.next(settings);
  }
  
  getSubjectWarehouseSettings(): Observable<WarehouseSettings> {
    return this.warehouseSettingsSubject.asObservable();
  }
}