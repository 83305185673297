<select
  class="{{ size }}"
  [required]="selected === ''"
  (change)="!blur ? setSelected($event) : null"
  (blur)="blur ? setSelected($event) : null"
  [disabled]="disabled"
>
  <option
    class="option-placeholder"
    *ngIf="selected === ''"
    disabled
    selected
    hidden
  >
    {{ placeholder }}
  </option>
  <option
    *ngFor="let option of options"
    value="{{ option }}"
    [selected]="selected === option"
  >
    {{ option }}
  </option>
</select>
