<div class="section">
  <h4 class="section-title">Return address</h4>
  <p class="section section-description">
    Select a preferred return address for your returns when using different
    carriers. By default, all returns are directed to the default location
    specified in the Return & Restock Locations setting, but you can manually
    customize this address for each carrier here.
  </p>

  <div
    *ngIf="showAlert() && updateMode"
    class="mb-12"
  >
    <app-alert
    type="Note"
    [showCloseButton]="true"
    [text]="note"
    imgIcon="../assets/images/icons/alert-triangle.png"
    ></app-alert>
  </div>
  
  <p class="section section-input-label">Return address for this carrier</p>
  <mat-select [value]="returnAddress.id">
    <mat-select-trigger class="mat-form-field-infix">
      <b>{{ returnAddress.receiver }}</b> {{ returnAddress.street }},
      {{ returnAddress.zip_code }} {{ returnAddress.city }},
      {{ returnAddress.country_code }}
    </mat-select-trigger>
    <mat-option
      (click)="selectLocation(location)"
      *ngFor="let location of getLocations()"
      [value]="location.id"
      ><b>{{ location.receiver }}</b> {{ location.street }},
      {{ location.zip_code }} {{ location.city }},
      {{ location.country_code }}
    </mat-option>
  </mat-select>
</div>
