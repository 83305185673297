import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ShippingLocation } from "src/app/settings/models/shipping-location.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class LocationSettingsService {
    
  private locationSettingsSubject: BehaviorSubject<ShippingLocation[]> = new BehaviorSubject<ShippingLocation[]>([]);
  private isLoadingLocationSettings = false;
  constructor(private httpClient: HttpClient) {}

  getLocationSettings(): Observable<ShippingLocation[]> {
    if (
      this.locationSettingsSubject.value === null ||
      (!this.locationSettingsSubject.value.length &&
      !this.isLoadingLocationSettings)
    ) {
      this.isLoadingLocationSettings = true;
      this.httpClient
        .get<ShippingLocation[]>(`${environment.apiUrl}/settings/locations`)
        .subscribe((settings) => {
          this.isLoadingLocationSettings = false;
          this.locationSettingsSubject.next(settings);
        });
    }
    return this.locationSettingsSubject;
  }

  hasLocationSettingsLoaded(): boolean {
    const locationSettingsPopulated = this.locationSettingsSubject.value !== null;
    const locationSettingsLoading = (!this.locationSettingsSubject.value.length &&
      this.isLoadingLocationSettings);
    return locationSettingsPopulated && !locationSettingsLoading;
  }
  
  setLocationSettingsSubject(settings: ShippingLocation[]): void {
    this.locationSettingsSubject.next(settings);
  }
  
  getSubjectLocationSettings(): Observable<ShippingLocation[]> {
    return this.locationSettingsSubject.asObservable();
  }

  public saveLocationSettings(
    locations: ShippingLocation[],
    value: boolean
  ): Observable<ShippingLocation[]> {
    return this.httpClient.put<ShippingLocation[]>(
      `${environment.apiUrl}/settings/locations?updateReturnLocationForIntegrations=${value}`,
      locations
    );
  }

  getIsUsedDefaultLocation(locationId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${environment.apiUrl}/settings/default-location/${locationId}`
    );
  }

  removeLocation(locationId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${environment.apiUrl}/settings/locations/${locationId}`
    );
  }
}