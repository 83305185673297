import { Component, OnInit } from '@angular/core';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';

@Component({
  selector: 'app-outsourcing',
  templateUrl: './outsourcing.component.html',
  styleUrls: ['./outsourcing.component.scss'],
})
export class OutsourcingComponent implements OnInit {
  title = 'Outsourcing to third party';
  description =
    'Do you outsource stocking and/or quality checks of returned items? Select “Yes” if a third party manages your returned items, for example, if you use 3PL (third party logistics).';
  isLoading = true;
  isSaving = false;
  onBoardingCompleted = false;

  options: string[] = [
    'Yes, I outsource quality checks to a 3PL',
    'No, I do the quality check myself',
  ];

  returnSettings: ReturnSettings = new ReturnSettings();

  constructor(
    private errorService: ErrorService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe({
        next: (returnSettings: ReturnSettings) => {
          if (this.returnSettingsService.hasReturnSettingsLoaded()) {
            this.returnSettings = returnSettings;
            this.isLoading = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Unable to load return settings (3pl)'
          );
        }
      });
  }

  checkOnBoarding() {
    this.returnSettings.is_using_3pl !== undefined
      ? (this.onBoardingCompleted = true)
      : (this.onBoardingCompleted = false);
  }

  getSelectedOption(): string {
    const option: boolean = this.returnSettings.is_using_3pl;
    this.checkOnBoarding();

    if (option === undefined) {
      return '';
    }

    return option ? this.options[0] : this.options[1];
  }

  setSelectedOption(value: string): void {
    this.returnSettings.is_using_3pl = value === this.options[0];
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;
    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: (saved: boolean) => {
          if (saved) {
            this.isSaving = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
      });
  }
}
