import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { NotificationSettings } from "src/app/settings/models/notification-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class NotificationSettingsService {
    
    private notificationSettingsSubject: BehaviorSubject<NotificationSettings> = new BehaviorSubject(
        new NotificationSettings()
    );
    private isLoadingNotificationSettings = false;
    constructor(private httpClient: HttpClient) {}

    getNotificationSettings(): Observable<NotificationSettings> {
        if (
            this.notificationSettingsSubject.value === null ||
            (!this.notificationSettingsSubject.value.merchant_notification_interval.length &&
            !this.isLoadingNotificationSettings)
        ) {
            this.isLoadingNotificationSettings = true;
            this.httpClient
            .get<NotificationSettings>(`${environment.apiUrl}/settings/notifications`)
            .subscribe((settings) => {
                this.isLoadingNotificationSettings = false;
                this.notificationSettingsSubject.next(settings);
            });
        }
        return this.notificationSettingsSubject;
    }
    
    hasNotificationSettingsLoaded(): boolean {
        const notificationSettingsPopulated = this.notificationSettingsSubject.value !== null;
        const notificationSettingsLoading = (!this.notificationSettingsSubject.value.merchant_notification_interval.length &&
            this.isLoadingNotificationSettings);
        return notificationSettingsPopulated && !notificationSettingsLoading;
    }
      
    setNotificationSettingsSubject(settings: NotificationSettings): void {
        this.notificationSettingsSubject.next(settings);
    }
      
    getNotificationSettingsSubject(): Observable<NotificationSettings> {
        return this.notificationSettingsSubject.asObservable();
    }

    saveNotificationSettings(
        notificationsSettings: NotificationSettings
      ): Observable<boolean> {
        return this.httpClient.put<boolean>(
          `${environment.apiUrl}/settings/notifications`,
          notificationsSettings
        );
      }
}