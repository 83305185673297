import { Component, OnInit } from '@angular/core';
import { FeatureTracker } from '../settings/models/features-tracker.model';
import { FeatureService } from '../shared/services/feature.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  featureTracker: FeatureTracker[] = [];

  constructor(private featureService: FeatureService) {}

  ngOnInit() {
    this.featureService
      .getNewFeatures()
      .subscribe((features: FeatureTracker[]) => {
        this.featureTracker = features;
      });
  }

  newFeaturesSeen(): boolean {
    return this.featureTracker.every((feature) => feature.is_seen);
  }

  hideNav(): boolean {
    return window.location.href.toLowerCase().includes('ups-activation');
  }
}
