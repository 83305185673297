import { Label } from './label.model';

export class SlipInfo {
  id = '';
  account_id = '';
  return_id = '';
  booking_id = '';
  tracking_id = '';
  tracking_url = '';
  shipment_message_id = 0;
  labels: Label[] = [];
  shipping_carrier = '';
  shipping_package_type = '';
}
