import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProformaNotAvailableComponent } from '../proforma-not-available/proforma-not-available.component';
import { IntegrationAlertService } from '../integration-alert.service';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-paperless-trade',
  templateUrl: './paperless-trade.component.html',
  styleUrls: ['./paperless-trade.component.scss'],
})
export class PaperlessTradeComponent {
  @Input() paperlessTrade = false;
  @Input() disabled = false;
  @Input() updateMode = false;
  @Input() carrier = ShippingCarrier.DhlExpress;
  @Input() isReturboGenerated = false;
  @Input() apiOrgNumber = '';
  @Output() selectedValue = new EventEmitter<boolean>(false);
  @Output() orgNumberValue = new EventEmitter<string>();
  
  canActivateProforma = false;
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';

  constructor(
    private dialog: MatDialog,
    private pricePlanSettingsService: PricePlanSettingsService,
    private integrationAlertService: IntegrationAlertService
  ) {
    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe((pricePlan) => {
      const proformaFeature = pricePlan?.features.find(pricePlanfeature => 
        pricePlanfeature.feature === 'Pro-forma (customs  declaration)'
      );
      const includedIcon = 'resolved-icon';
      this.canActivateProforma = proformaFeature?.icon === includedIcon;
    });
  }

  onChange = (event: any) => {
    this.selectedValue.emit(event.value);

    this.integrationAlertService.setAlertSectionForCarrier(
      this.carrier,
      AlertSectionType.PaperlessTrade
    );
  };

  onValueChanged(): void {
    this.orgNumberValue.emit(this.apiOrgNumber);
    this.integrationAlertService.setAlertSectionForCarrier(
      this.carrier,
      AlertSectionType.PaperlessTrade
    );
  }

  onPaperlessTradeClick(event: any) {
    if (!this.canActivateProforma) {
      this.dialog.open(ProformaNotAvailableComponent, {
        width: '477px',
      });
      event.preventDefault();
      return;
    }
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      this.carrier
    );
    return alertType === AlertSectionType.PaperlessTrade;
  }
}
