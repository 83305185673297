import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Country } from 'src/app/settings/models/country-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countriesSubject: BehaviorSubject<Country[]> = new BehaviorSubject(
    <Country[]>[]
  );
  private isLoadingCountries = false;
  constructor(private httpClient: HttpClient) {}

  getCountries(): Observable<Country[]> {
    if (
      this.countriesSubject.value === null ||
      (!this.countriesSubject.value.length &&
      !this.isLoadingCountries)
    ) {
      this.isLoadingCountries = true;
      this.httpClient.get<Country[]>(
        `${environment.apiUrl}/settings/countries`
      )
      .subscribe((countries) => {
        this.isLoadingCountries = false;
        this.countriesSubject.next(countries);
      });
    }
    return this.countriesSubject;
  }

  setCountriesSubject(country: Country[]): void {
    this.countriesSubject.next(country);
  }

  getCountriesSubject(): Observable<Country[]> {
    return this.countriesSubject.asObservable();
  }
}
