import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationAlertService } from '../integration-alert.service';

@Component({
  selector: 'app-available-carrier-services',
  templateUrl: './available-carrier-services.component.html',
  styleUrls: ['./available-carrier-services.component.scss'],
})
export class AvailableCarrierServicesComponent {
  @Input() availableServices: AvailableService[] = [];
  @Input() description = '';
  @Input() disabled = false;
  @Input() updateMode = false;
  @Input() note = '';
  @Output() infoModalOpened: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );

  @Output() checkedServices: EventEmitter<AvailableService[]> =
    new EventEmitter<AvailableService[]>();

  constructor(private integrationAlertService: IntegrationAlertService) {}

  openInfoModal(): void {
    this.infoModalOpened.emit(true);
  }

  isDisabled(service: AvailableService): boolean {
    return service.disabled || this.disabled;
  }

  showSavingAlert() {
    this.integrationAlertService.setAlertSectionForCarrier(
      this.availableServices[0].shipping_carrier,
      AlertSectionType.AvailableServices
    );
    this.checkedServices.emit(this.availableServices);
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      this.availableServices[0].shipping_carrier
    );
    return alertType === AlertSectionType.AvailableServices;
  }
}
