<button
    type="button"
    class="{{buttonStyle}}" 
    (click)="buttonPressed($event)" 
    [disabled]="disabled">

    <i *ngIf="direction === 'left'" class="fa fa-arrow-left" aria-hidden="true"></i>
    {{buttonText}}
    <i *ngIf="direction === 'right'" class="fa fa-arrow-right" aria-hidden="true"></i>

</button>