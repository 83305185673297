export const orderAlertText = {
  sendNewItemsAlert:
    'Please make sure you go to the order and send new items via Shopify.',
  deleteOrderAlert:
    'Since you have rejected all items in the request, please delete the order that was created for you in the Shopify store.',
  editOrderAlert:
    'Since you have refunded the item(s) instead, you can then delete the new order our system made, as you might not need it anymore.',
  draftDeleteOrderAlert:
    'Since the item has been refunded instead, you can delete the draft order, as you might not need it anymore.',
  complaintNewItemsAlert:
    'Our system has created an order with the new item(s) in your Shopify. Please make sure you go there and send new item(s) to the customer.',
  resolvedAcceptedExchangeAlert:
    'The draft order with the exchange item(s) has now become an actual order in your Shopify. Please fulfill it.',
  rejectedRefundedExchangeAlert:
    'The draft order with the exchange item(s) did not become an actual order in your Shopify and has been deleted, since you have refunded/rejected all item(s) instead.',
  rejectedItemExchangeAlert:
    'Since you have rejected some items in the request, please edit the order that was created for you in the Shopify store to reflect the changes made.',
};
