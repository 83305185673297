import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { PricePlan } from "src/app/settings/models/price-plan.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class PricePlanSettingsService {

    private selectedPricePlanSubject: BehaviorSubject<PricePlan> = new BehaviorSubject(
        new PricePlan()
    );
    private isLoadingSelectedPricePlan = false;
    private firstLoad = true;
    constructor(private httpClient: HttpClient) {}

  getSelectedPricePlan(): Observable<PricePlan> {
    if (
      this.selectedPricePlanSubject.value === null ||
      (!this.selectedPricePlanSubject.value.name &&
        !this.isLoadingSelectedPricePlan)
    ) {
      this.isLoadingSelectedPricePlan = true;
      this.httpClient
        .get<PricePlan>(`${environment.apiUrl}/settings/price-plan`)
        .subscribe((pricePlan) => {
          this.firstLoad = false;
          this.isLoadingSelectedPricePlan = false;
          this.selectedPricePlanSubject.next(pricePlan);
        });
    }
    return this.selectedPricePlanSubject;
  }
  
  hasSelectedPricePlanLoaded(): boolean {
    return !this.firstLoad && !this.isLoadingSelectedPricePlan;
  }
  
  setSelectedPricePlan(settings: PricePlan): void {
    this.selectedPricePlanSubject.next(settings);
  }
  
  getSubjectSelectedPricePlan(): Observable<PricePlan> {
    return this.selectedPricePlanSubject.asObservable();
  }
}