<div class="dialog-header">
  <h1 class="font-24 text-almost-black font-700 mt-0">
    Pro-forma is not available with the current payment plan
  </h1>
</div>

<mat-dialog-content>
  <p class="font-normal font-14 text-almost-black">
    With your current <b>{{ currentPricePlaneName }}</b> plan, you can’t have
    the Pro-forma customs document option activated. It is only available with
    the <b>Pro</b> payment plan. If you would like to activate it – please
    upgrade your plan in Settings
    <span class="pointer text-primary" (click)="goToPlans()">here</span>.
  </p>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <button class="btn btn-secondary btn-md mr-16" mat-dialog-close>Close</button>
  <button
    class="btn btn-primary btn-md mr-0"
    (click)="goToPlans()"
    mat-dialog-close="proceed"
  >
    Go to plans
  </button>
</mat-dialog-actions>
