import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';

export class PremiumReturnSettings {
  is_enabled = false;
  has_image_instructions = false;
  image_upload_option: PremiumOptions = PremiumOptions.NotAllowed;
  summary_option: PremiumOptions = PremiumOptions.NotAllowed;
  image_instructions_field = '';
  max_number_of_images = 0;
  min_number_of_images = 0;
}
