import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { DeutschePostIntegrationInformation } from 'src/app/settings/models/deutsche-post-integration-information.model';
import { DeutschePostIntegrationRequest } from 'src/app/settings/models/deutsche-post-integration-request.model';
import { FormValues } from 'src/app/settings/models/form-values.model';
import {
  IntegrationPackageSupportedCountries,
  SupportedCountry,
} from 'src/app/settings/models/integration-package-supported-countries.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { deutschePostServices } from '../carrier-services';
import { IntegrationAlertService } from '../integration-alert.service';

@Component({
  selector: 'app-deutsche-post',
  templateUrl: './deutsche-post.component.html',
  styleUrls: ['./deutsche-post.component.scss'],
})
export class DeutschePostComponent implements OnInit {
  @Input() disabled = false;

  isLoading = false;
  isSaving = false;
  title = 'Deutsche Post';
  description =
    'Autogenerate you returns slips easily within EU and the rest of the world.';
  shippingCarrier = ShippingCarrier;
  showActivationSettings = false;
  availableServices = deutschePostServices;
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';
  shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  serviceInformation: DeutschePostIntegrationInformation =
    new DeutschePostIntegrationInformation();
  alertType = AlertSectionType;
  formValueChanged = false;
  supportedCountries = new Map<ShippingPackageType, SupportedCountry[]>();
  returnAddressId = '';
  showPassword = false;

  constructor(
    private shippingIntegrationsService: ShippingIntegrationService,
    private integrationAlertService: IntegrationAlertService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getShippingIntegrationInfo();

    this.shippingIntegrationsService
      .getValuesChanged()
      .subscribe((res: FormValues) => {
        this.formValueChanged = res.deutschePost;
      });

    this.getSupportedCountries();
  }

  getShippingIntegrationInfo(): void {
    this.isSaving = true;
    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            shippingIntegration.shipping_carrier ===
              ShippingCarrier.DhlDeutschePost &&
            shippingIntegration.status === IntegrationStatusEnum.Active
        );
        if (
          this.shippingIntegrationInfo &&
          this.shippingIntegrationInfo.length > 0
        ) {
          this.shippingIntegrationInfo.forEach(
            (integration: ShippingIntegration) => {
              this.checkServices(integration);

              this.assignFormProperties(integration);
            }
          );
        }

        this.isSaving = false;
        this.isLoading = false;
      });
  }

  toggleActivation(value: boolean): void {
    this.showActivationSettings = value;
  }

  hasProccessStarted(): boolean {
    const startedIntegrations = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    return startedIntegrations && startedIntegrations.status ? true : false;
  }

  showAlert(alertType: AlertSectionType): boolean {
    const visibleAlertType =
      this.integrationAlertService.getAlertSectionForCarrier(
        ShippingCarrier.DhlDeutschePost
      );
    return alertType === visibleAlertType && this.hasProccessStarted();
  }

  checkServices(integration: ShippingIntegration): void {
    this.availableServices.forEach((service: AvailableService) => {
      if (
        integration.shipping_package_type === service.packageType &&
        integration.status !== IntegrationStatusEnum.Deactivated
      ) {
        service.checked = true;
      }
    });
  }

  assignFormProperties(integration: ShippingIntegration): void {
    const deutschePostIntegration =
      integration.integration_info as DeutschePostIntegrationRequest;
    this.serviceInformation = {
      apiCustomerSecret: deutschePostIntegration.customer_secret ?? '',
      apiCustomerKey: deutschePostIntegration.customer_key ?? '',
      apiCustomerNumberEKP: deutschePostIntegration.account_number ?? '',
      enabledCountries: new Map([
        [
          deutschePostIntegration.shipping_package_type as ShippingPackageType,
          deutschePostIntegration.enabled_origin_countries ?? [],
        ],
      ]),
    };
  }

  onValueChanged(): void {
    if (!this.formValueChanged) {
      this.formValueChanged = true;
      let previousValues: FormValues = new FormValues();

      this.shippingIntegrationsService
        .getValuesChanged()
        .subscribe((res: FormValues) => {
          previousValues = res;
        });
      this.shippingIntegrationsService.setValuesChanged({
        ...previousValues,
        deutschePost: true,
      });
    }
    this.integrationAlertService.setAlertSectionForCarrier(
      ShippingCarrier.DhlDeutschePost,
      AlertSectionType.Credentials
    );
  }

  isInformationInvalid(): boolean {
    const invalidIntegration = this.shippingIntegrationInfo?.filter(
      (integration: ShippingIntegration) =>
        integration.status === IntegrationStatusEnum.ActivationError &&
        integration.shipping_carrier === ShippingCarrier.DhlDeutschePost
    );

    return invalidIntegration && invalidIntegration.length > 0 ? true : false;
  }

  openInfoModal(): void {
    this.modalService.open('deutsche-post-services');
  }

  onSaveUpdate(isSaving: boolean): void {
    this.isSaving = isSaving;
  }

  getSupportedCountries(): void {
    this.shippingIntegrationsService
      .getIntegationPackageSupportedCountriesSubject()
      .subscribe((res: IntegrationPackageSupportedCountries[]) => {
        if (res && res.length > 0) {
          const availableCountries = res.filter(
            (shippingIntegration: IntegrationPackageSupportedCountries) =>
              shippingIntegration.shipping_carrier ===
              ShippingCarrier.DhlDeutschePost
          );
          availableCountries.forEach((availableCountry) =>
            this.supportedCountries.set(
              availableCountry.id,
              availableCountry.countries
            )
          );
        }
      });
  }

  addMarketsToIntegration(
    enabledCountries: Map<ShippingPackageType, string[]>
  ): void {
    this.serviceInformation.enabledCountries = enabledCountries;
  }

  newLocation(id: string): void {
    this.returnAddressId = id;
  }

  getCheckedServicesIntegrationInfo(): ShippingIntegration[] {
    const checkedServices = this.availableServices.filter(
      (service) => 
        service.checked && 
        service.shipping_carrier === ShippingCarrier.DhlDeutschePost
    );
    const checkedPackageTypes = checkedServices.map(x => x.packageType);
    const checkedIntegrationInformations = this.shippingIntegrationInfo?.filter(
      (integrationInfo) => 
        checkedPackageTypes.includes(integrationInfo.shipping_package_type)
    );
    return checkedIntegrationInformations ?? []
  }
}
