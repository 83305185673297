<div class="table-container">
  <table
    mat-table
    [dataSource]="dataSource"
    class="return-table w-full transparent"
    *ngIf="displayedColumns && displayedColumns.length > 0"
  >
    <ng-container matColumnDef="orderNo">
      <th mat-header-cell *matHeaderCellDef>Order No.</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="default-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        <span
          class="status-dot"
          [ngClass]="[
            [
              ReturnStatusEnum.RequestReceived,
              ReturnStatusEnum.AwaitingShippingSlip
            ].includes(row.status)
              ? 'request-dot'
              : '',
            row.status === ReturnStatusEnum.PackageReceived ? 'pending-dot' : ''
          ]"
        ></span>

        <span
          [ngClass]="{
            'font-bold':
              getStatusName(row.status) === 'Return requested' ||
              getStatusName(row.status) === 'Pending Approval'
          }"
          >{{ row.order_name }}</span
        >
      </td>
    </ng-container>

    <!-- Working here -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="default-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        <span>{{ row.type }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Customer</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="customer-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        <span>
          {{ row?.customer?.first_name }} {{ row?.customer?.last_name }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="items">
      <th mat-header-cell *matHeaderCellDef>Items</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="default-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        {{ row.items.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Total Price</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="price-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        {{ row.total_price.toFixed(2) }} {{ row.currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateInitiated">
      <th mat-header-cell *matHeaderCellDef>Date initiated</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="date-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        {{ row.created_at | customDateFormat : "shortDate" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdate">
      <th mat-header-cell *matHeaderCellDef>Last update</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="date-cell"
        [ngClass]="{
          'resolved-row':
            getStatusName(row.status) === 'Archived' ||
            getStatusName(row.status) === 'Resolved'
        }"
      >
        {{ row.updated_at | customDateFormat : "shortDate" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td
        mat-cell
        *matCellDef="let row"
        (click)="openSidenav(row)"
        class="status-cell"
      >
        <span class="status-box" [ngClass]="getColorClass(row)">
          <img
            [ngClass]="setIconClass(getColorClass(row))"
            [src]="setReturnIcon(getColorClass(row))"
          />
          {{ getStatusName(row.status) }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="action-cell">
        <button
          mat-icon-button
          [matMenuTriggerFor]="actions"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actions="matMenu">
          <button
            mat-menu-item
            *ngIf="
              [
                ReturnStatusEnum.PackageSent,
                ReturnStatusEnum.AwaitingDropOff,
                ReturnStatusEnum.ShippingSlipSkipped,
                ReturnStatusEnum.ManualShippingSlipAttached
              ].includes(row.status)
            "
            (click)="updateRequest(row, ReturnStatusEnum.PackageReceived)"
          >
            <mat-icon>check</mat-icon>
            <span>Mark as received</span>
          </button>
          <button
            mat-menu-item
            *ngIf="row.status === ReturnStatusEnum.PackageReceived"
            (click)="updateRequest(row, ReturnStatusEnum.AwaitingDropOff)"
          >
            <mat-icon>close</mat-icon>
            <span>Mark as not received</span>
          </button>
          <button
            mat-menu-item
            *ngIf="row.status !== ReturnStatusEnum.Archived"
            (click)="updateRequest(row, ReturnStatusEnum.Archived)"
          >
            <mat-icon fontSet="material-icons-outlined">inventory_2</mat-icon>
            <span>Archive request</span>
          </button>
          <button
            mat-menu-item
            *ngIf="row.status === ReturnStatusEnum.Archived"
            (click)="updateRequest(row, ReturnStatusEnum.RequestManaged)"
          >
            <img
              class="menu-item-logo"
              src="assets/images/icons/unarchive.png"
              alt="Unarchive"
            />
            <span>Unarchive request</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="pointer"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>

  <div class="text-center">
    <button
      mat-button
      class="table__load"
      (click)="loadMoreItems()"
      *ngIf="dataSource.data.length < total_count"
      [disabled]="isLoadingMoreData"
    >
      Load more
    </button>
    <p class="table__load-text">
      Showing {{ dataSource.data.length }} of {{ total_count }} returns
    </p>
  </div>
</div>

<app-sidenav *ngIf="returnSelected"></app-sidenav>
