import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { UpsUrl } from 'src/app/settings/models/ups-url';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UpsTokenService {
  constructor(private httpClient: HttpClient) {}

  setUpsToken(params: UpsUrl): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/api/shipping/integration/ups/token`,
      params
    );
  }
}
