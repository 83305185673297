import { Component, OnInit } from '@angular/core';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import { AccountSettings } from 'src/app/settings/models/account-settings.model';
import { SupportSettings } from 'src/app/settings/models/support-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { SupportSettingsService } from 'src/app/shared/services/settings/support-settings.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  valid = false;
  onBoardingCompleted = false;

  title = 'Contact information';
  description =
    'Please let us know how to contact you and where your customers should turn if they have any problems.';

  supportSettings: SupportSettings = new SupportSettings();
  accountSettings: AccountSettings = new AccountSettings();

  constructor(
    private errorService: ErrorService,
    private accountSettingsService: AccountSettingsService,
    private supportSettingsService: SupportSettingsService
  ) {}

  ngOnInit(): void {
    this.accountSettingsService
      .getSubjectAccountSettings()
      .subscribe((accountSettings) => {
        this.accountSettings = accountSettings;
        if (accountSettings.company_name) {
          this.supportSettingsService
            .getSubjectSupportSettings()
            .subscribe((supportSettings: SupportSettings) => {
              this.supportSettings = supportSettings;
              this.turnUndefinedValuesIntoEmptyStrings();
              this.checkValues();
              this.isLoading = false;
            });
        }
      });
  }

  saveIfValidInput() {
    this.checkValues();

    if (this.valid) {
      this.saveSettings();
    }
  }

  checkValues() {
    const companyName = this.accountSettings.company_name;
    const companyEmail = this.supportSettings.merchant_support_email;
    const supportEmail = this.supportSettings.customer_support_email;
    const supportPhone = this.supportSettings.customer_support_phone;

    if (
      companyName &&
      companyEmail &&
      supportEmail &&
      supportPhone &&
      this.isEmailValid(companyEmail) &&
      this.isEmailValid(supportEmail)
    ) {
      this.onBoardingCompleted = true;
      this.valid = true;
    } else {
      this.onBoardingCompleted = false;
      this.valid = false;
    }
  }

  // emailToCheck is valid if there is:
  // 1: at least one character
  // 2: followed by an @
  // 3: followed by at least one character
  // 4: followed by a .
  // 5: followed by at least one character
  // Valid example: x@g.o
  isEmailValid(emailToCheck: string): boolean {
    const emailRegExp = new RegExp(
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    );
    return emailRegExp.test(emailToCheck);
  }

  turnUndefinedValuesIntoEmptyStrings(): void {
    if (!this.supportSettings.customer_support_phone) {
      this.supportSettings.customer_support_phone = '';
    }
  }

  getErrorMessage(field: string): string {
    const supportEmail = this.supportSettings.customer_support_email;
    const companyEmail = this.supportSettings.merchant_support_email;
    const companyName = this.accountSettings.company_name;
    const supportPhone = this.supportSettings.customer_support_phone;

    switch (field) {
      case 'Company name':
        if (!companyName) {
          return "Company name can't be left empty";
        }
        break;
      case 'Company email':
        if (!companyEmail) {
          return "Company email can't be left empty";
        } else if (!this.isEmailValid(companyEmail)) {
          return 'Not a valid email address, check the spelling';
        }
        break;
      case 'Support email':
        if (!supportEmail) {
          return "Customer Service email can't be left empty";
        } else if (!this.isEmailValid(supportEmail)) {
          return 'Not a valid email address, check the spelling';
        }
        break;
      case 'Support phone':
        if (!supportPhone) {
          return "Can't be left empty. Don't have one? Enter 0";
        }
        break;
      default:
        return '';
    }

    return '';
  }

  saveSettings(): void {
    this.isSaving = true;
    this.checkValues();

    const accountSettings = this.accountSettingsService.saveAccountSettings(
      this.accountSettings
    );
    const supportSettings = this.supportSettingsService.saveSupportSettings(
      this.supportSettings
    );

    combineLatest([accountSettings, supportSettings])
      .pipe(debounceTime(2000), distinctUntilChanged())
      .subscribe({
        next: () => {
          this.accountSettingsService.setAccountSettingsSubject(this.accountSettings);
          this.supportSettingsService.setSupportSettingsSubject(this.supportSettings);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}
