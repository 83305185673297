import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MerchantComment } from 'src/app/models/merchant-comment.model';
import { ReturnsService } from 'src/app/returns/returns.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  @Input()
  title = '';
  @Input()
  description = '';
  @Input()
  chatTitle = '';
  @Input()
  returnID = '';
  @Input()
  comments: MerchantComment[] = [];

  isLoading = true;
  isLoadingReturns = false;
  showInput = false;
  numberOfChars = 0;
  disabledInput = false;

  constructor(
    private formBuilder: FormBuilder,
    private chatService: ChatService,
    private errorService: ErrorService,
    private returnsService: ReturnsService
  ) {
    
    this.chatService.getLoading().subscribe((res: boolean) => {
      this.isLoading = res;
    });
    this.returnsService.getLoading().subscribe((res: boolean) => {
      this.isLoadingReturns = res;
    });
  }

  commentForm = this.formBuilder.group({
    name: ['', [Validators.required, Validators.maxLength(12)]],
    comment: ['', [Validators.required, Validators.maxLength(200)]],
  });

  close(): void {
    this.commentForm.reset();
    this.showInput = false;
    this.numberOfChars = 0;
  }

  onSubmit(): void {
    const newComment: MerchantComment = {
      name: this.commentForm.value.name,
      comment: this.commentForm.value.comment,
    };
    this.updateComments(newComment);
    this.close();
    this.commentForm.reset();
  }

  getCurrentDate(date?: string): string {
    const currentDate = date ? new Date(date) : new Date();
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} at ${formattedTime}`;
  }

  commentCounter(): number {
    return this.comments.filter((comment) => !comment.is_deleted).length;
  }

  deleteComment(comment: MerchantComment): void {
    this.isLoading = true;
    if (comment.id) {
      this.chatService.deleteComment(this.returnID, comment.id).subscribe({
        next: () => {
          this.isLoading = false;
          comment.is_deleted = true;
        },
        error: () => {
          this.isLoading = false;
          this.errorService.showErrorSnackBar('Could not delete comment');
        },
      });
      return;
    }
    comment.is_deleted = true;
  }

  countChars(chars: any): void {
    this.numberOfChars = chars.target.value.length;
  }

  updateComments(comment: MerchantComment): void {
    this.isLoading = true;
    this.chatService.addComment(this.returnID, comment).subscribe({
      next: (comment: MerchantComment) => {
        this.comments.push(comment);
        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to add comment');
        this.isLoading = false;
      },
    });
  }
}
