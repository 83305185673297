<div class="slider">
  <span *ngIf="!hideText" class="mr-8" [ngClass]="{ black: !checked }">No</span>
  <mat-slide-toggle
    (change)="toggle()"
    [checked]="checked"
    [disableRipple]="disableRipple"
    [disabled]="disabled"
  ></mat-slide-toggle
  ><span *ngIf="!hideText" [ngClass]="{ black: checked }" class="ml-8"
    >Yes</span
  >
</div>
