import { Injectable } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: ModalComponent[] = [];

  register(modal: ModalComponent): void {
    this.modals.push(modal);
  }

  remove(id: string) {
    this.modals = this.modals.filter((m) => m.id !== id);
  }

  open(id: string): void {
    const modal: ModalComponent | null =
      this.modals.find((m) => m.id === id) ?? null;
    if (modal == null) {
      return;
    }
    modal!.show();
  }

  close(id: string): void {
    const modal: ModalComponent | null =
      this.modals.find((m) => m.id === id) ?? null;
    if (modal == null) {
      return;
    }
    modal.dismiss();
    
  }

  dismiss(): void {
    this.modals.forEach((m) => {
      m.dismiss();
    });
  }
}
