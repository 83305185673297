import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalService } from 'src/app/components/modal/modal.service';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { ReturnReasonDescription } from 'src/app/settings/models/return-reason-description.model';
import { ReturnReason } from 'src/app/settings/models/return-reason.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { SubReason } from 'src/app/settings/models/sub-reason.model';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';
import { ReasonSettingsService } from 'src/app/shared/services/settings/reason-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ReasonService } from './reasons.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-return-reasons',
  templateUrl: './return-reasons.component.html',
  styleUrls: ['./return-reasons.component.scss'],
})
export class ReturnReasonsComponent {
  subMenuAddIcon = 'subdirectory_arrow_right';
  status = 'Restocked';
  title = 'Return reasons';
  description =
    'Choose which return reasons you would like your customers to select from. This is important as this will allow us to collect return data for you. Please also select if you would like to restock items that are being returned';
  infoText =
    'You can adjust whether you want the returned items restocked based on a specific reason. For instance, if an item is being returned with a reason “Wrong size – too big”, then you would probably want to restock it and sell it again. On the other hand, if an item is returned with a reason “Item came damaged”, then chances are, you would not want to restock it.';

  isEditing = false;
  isSaving = false;
  isLoading = true;
  showContent = false;
  firstLoad = true;
  activeLanguage: Language = new Language();
  showSubreasons = false;
  onBoardingCompleted = false;
  showHelp = false;
  showArchive = false;
  exchangesEnabled = false;
  complaintsEnabled = false;
  index = 0;
  inputValue = '';
  editID: number | string = '';
  editType = '';
  tempReasonID = '';
  tempSubReasonID = 0;
  tempSubReasonParentID = '';

  isTranslationValid = false;
  showReturns = true;
  showComplaints = true;
  showExchanges = true;

  reasons: ReturnReason[] = [];

  complaints: string[] = [];
  returns: string[] = [];
  subReasons: SubReason[] = [];

  archivedReturns: ReturnReason[] = [];
  archivedComplaints: ReturnReason[] = [];
  archivedExchanges: ReturnReason[] = [];

  activeReturnReason: ReturnReason = new ReturnReason();
  ReturnReasonType = ReturnReasonType;

  languageForm = this.formBuilder.group({
    language: [''],
  });

  enabledLanguages: Language[] = [];
  languageSettings: LanguageSettings = new LanguageSettings();

  descriptions: ReturnReasonDescription[] = [];

  returnSettings: ReturnSettings = new ReturnSettings();

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private errorService: ErrorService,
    private exchangeSettingsService: ExchangeSettingsService,
    private complaintSettingsService: ComplaintSettingsService,
    private reasonsService: ReasonService,
    private returnSettingsService: ReturnSettingsService,
    private languageSettingsService: LanguageSettingsService,
    private reasonSettingsService: ReasonSettingsService
  ) {

    const returnSettings = this.returnSettingsService.getSubjectReturnSettings();
    const languageSettings = this.languageSettingsService.getSubjectLanguageSettings();
    const reasons = this.reasonSettingsService.getSubjectReasonSettings();
    const exchangeSettings = this.exchangeSettingsService.getExchangeSubject();
    const complaintSettings = this.complaintSettingsService.getComplaintSettingsSubject();

    combineLatest([languageSettings, reasons, returnSettings, exchangeSettings, complaintSettings]).subscribe(
      ([languageSettings, reasons, returnSettings, exchangeSettings, complaintSettings]) => {
        this.reasons = reasons;
        this.returnSettings = returnSettings;
        this.exchangesEnabled = exchangeSettings.is_enabled;
        this.complaintsEnabled = complaintSettings.is_enabled;
        this.languageSettings = languageSettings;

        this.enabledLanguages = languageSettings.languages.filter(
          (language) => language.is_active
        );
        this.activeLanguage = this.getLanguage(
          this.languageSettings.default_language
        );

        if (reasons.length && languageSettings.languages.length) {
          this.isLoading = false;
          this.checkOnBoarding();
          if (this.firstLoad) {
            this.populateList();
            this.firstLoad = false;
          }
        }

      }
    );
  }

  checkIfActiveSubReasons(subReasons: SubReason[]): boolean {
    return subReasons.some((reason) => reason.is_enabled);
  }

  toggleHelp(): void {
    this.showHelp = !this.showHelp;
  }

  toggleSectionView(section: string): void {
    switch (section) {
      case 'Complaints': {
        this.showComplaints = !this.showComplaints;
        break;
      }
      case 'Exchanges': {
        this.showExchanges = !this.showExchanges;
        break;
      }

      default: {
        this.showReturns = !this.showReturns;
        break;
      }
    }
  }

  checkOnBoarding(): void {
    this.onBoardingCompleted = this.reasons.some((x) => x.is_enabled);
  }

  public getDescription(reason: ReturnReason | SubReason): string {
    let desc = 'N/A';

    reason.descriptions.forEach((description: ReturnReasonDescription) => {
      if (
        description.language?.toLowerCase() ==
        this.activeLanguage.name?.toLowerCase()
      ) {
        if (description.description?.length > 0) {
          desc = description.description;
        }
        return;
      }
    });
    return desc;
  }

  public setShouldRestock(value: boolean, id?: string): void {
    const reason = this.getReturnReason(id);

    if (reason.should_restock == value || this.isSaving) {
      return;
    }
    reason.should_restock = value;
  }

  public setShouldRestockSubReason(subReason: SubReason, value: boolean): void {
    subReason.should_restock = value;
  }

  public getRestockOrNot(id?: string): string {
    const reason = this.getReturnReason(id);
    let shouldReturn;

    if (reason.should_restock == false) {
      shouldReturn = 'Not restocked';
      return shouldReturn;
    } else {
      shouldReturn = 'Restocked';
      return shouldReturn;
    }
  }

  public getRestockOrNotSubReason(subReason: SubReason): string {
    let shouldReturn;

    if (subReason.should_restock == false) {
      shouldReturn = 'Not restocked';
      return shouldReturn;
    } else {
      shouldReturn = 'Restocked';
      return shouldReturn;
    }
  }

  public getReturnReason(id?: string): ReturnReason {
    return (
      this.reasons.find((x: ReturnReason) => x.id == id) ?? new ReturnReason()
    );
  }

  toggleArchive(): void {
    this.showArchive = !this.showArchive;
  }

  populateList(): void {
    this.reasons.forEach((reason) => {
      if (
        reason.return_reason_type === ReturnReasonType.Return &&
        reason.is_archived
      ) {
        this.archivedReturns.push(reason);
      }
      if (
        reason.return_reason_type === ReturnReasonType.Complaint &&
        reason.is_archived
      ) {
        this.archivedComplaints.push(reason);
      }
      if (
        reason.return_reason_type === ReturnReasonType.Exchange &&
        reason.is_archived
      ) {
        this.archivedExchanges.push(reason);
      }
    });
  }

  handleArchive(selectedReason: ReturnReason, type: string): void {
    const archiveList: ReturnReason[] =
      selectedReason.return_reason_type === ReturnReasonType.Return
        ? this.archivedReturns
        : selectedReason.return_reason_type === ReturnReasonType.Complaint
        ? this.archivedComplaints
        : this.archivedExchanges;

    const index = archiveList.indexOf(selectedReason);
    const foundReason = this.reasons.find(
      (reason) => reason.id === selectedReason.id
    );

    if (index === -1 && type === 'archive') {
      selectedReason.is_archived = true;
      archiveList.push(selectedReason);
    }

    if (index !== -1 && type === 'unarchive') {
      selectedReason.is_archived = false;
      if (foundReason) {
        foundReason.is_archived = false;
      }
      archiveList.splice(index, 1);
    }
  }

  public editReturnReason(reason: ReturnReason | undefined, event: any): void {
    if (reason != null) {
      if (event.target.tagName != 'TD') {
        return;
      }
      this.activeReturnReason = reason;
      this.modalService.open('return-reason-edit');
      return;
    }

    if (this.activeReturnReason == null || this.activeReturnReason.id != null) {
      this.activeReturnReason = new ReturnReason();

      this.languageSettings.languages.forEach((lang: Language) => {
        if (lang.is_active) {
          const description = new ReturnReasonDescription();
          description.language = lang.name;
          description.description = '';

          this.activeReturnReason?.descriptions.push(description);
        }
      });
    }

    this.modalService.open('return-reason-edit');
  }

  public closeAndReset(window: string): void {
    this.reasons = this.reasons.filter((x) => x.id !== this.tempReasonID);

    this.reasons.forEach((reason) => {
      if (reason.id === this.tempSubReasonParentID) {
        reason.sub_reasons = reason.sub_reasons.filter(
          (subReason) => subReason.id !== this.tempSubReasonID
        );
      }
    });
    this.tempReasonID = '';
    this.tempSubReasonID = 0;
    this.tempSubReasonParentID = '';

    this.modalService.close(window);
  }

  closeWindow(window: string): void {
    this.modalService.close(window);
  }

  public saveSettings(): void {
    this.removeTempIDs();
    this.isSaving = true;

    this.reasonSettingsService.saveReasons(this.reasons).subscribe({
      next: (reasons) => {
        this.reasonSettingsService.setReasonSettings(reasons);
        this.reasons = reasons;

        this.returnSettingsService
          .saveReturnSettings(this.returnSettings)
          .subscribe({
            error: () => {
              this.errorService.showErrorSnackBar(
                'Failed to save return settings.'
              );
            },
          });
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save return reason');
      },
      complete: () => {
        this.isSaving = false;
        this.activeReturnReason = new ReturnReason();
        this.modalService.dismiss();
      },
    });
  }

  public selectLanguage(language: Language) {
    this.activeLanguage = language;
  }

  private getLanguage(id: string): Language {
    let language: Language =
      this.languageSettings?.languages[0] ?? new Language();

    this.languageSettings?.languages.forEach((lang: Language) => {
      if (lang.id == id) {
        language = lang;
        return;
      }
    });
    return language;
  }

  toggleVisibility(reason: ReturnReason, event: any): void {
    const subReasons: SubReason[] = reason.sub_reasons;
    subReasons.forEach((x) => (x.is_enabled = false));
    reason.sub_reasons = subReasons;

    reason.is_enabled = event;
    this.checkOnBoarding();
  }

  toggleVisibilitySubReason(
    reason: ReturnReason,
    sub: SubReason,
    event: any
  ): void {
    reason.is_enabled = true;
    sub.is_enabled = event;
  }

  randomizeID(): number {
    return Math.floor(Math.random() * 9999);
  }

  deleteReason(type: string, id?: string): void {
    this.reasons.forEach((reason: ReturnReason, index: number) => {
      if (reason.id === id && !reason.is_used) {
        this.reasons.splice(index, 1);
      } else {
        this.errorService.showErrorSnackBar(
          `Unable to delete ${type} reason. Reason has been used in a previous ${type}. However, you can archive it instead. `,
          8000
        );
      }
    });
  }

  deleteSubReason(returnReason: ReturnReason, id?: number) {
    returnReason.sub_reasons.forEach(
      (foundReason: SubReason, index: number) => {
        if (foundReason.id === id) {
          returnReason.sub_reasons.splice(index, 1);
        }
      }
    );
  }

  getNumberOfMissingTranslationsInActiveLanguage(): number {
    const reasons = this.reasons.filter((reason) => reason.is_enabled);
    const subReasons = reasons
      .flatMap((reason) => reason.sub_reasons)
      .filter((subReason) => subReason.is_enabled);

    const reasonsWithActiveLanguageDescriptions = reasons.filter((reason) =>
      reason.descriptions.find((description) => description.language === this.activeLanguage.name && description.description?.length > 0)
    );
    const subReasonsWithActiveLanguageDescriptions = subReasons.filter((subReason) =>
      subReason.descriptions.find((description) => description.language === this.activeLanguage.name && description.description?.length > 0)
    );

    const totalActiveReasons = reasons.length + subReasons.length;
    const totalTranslatedReasons =
      reasonsWithActiveLanguageDescriptions.length +
      subReasonsWithActiveLanguageDescriptions.length;

    const numberOfMissingTranslations =
      totalActiveReasons - totalTranslatedReasons;
    return numberOfMissingTranslations;
  }

  checkIfEnabledSubReasons(reason: ReturnReason): boolean {
    return reason.sub_reasons.some(
      (subReason) => subReason.is_enabled === true
    );
  }

  editReason(reason: ReturnReason): void {
    this.reasonsService.setReasonOnEditSubject(reason);
    this.editType = 'reason';
    this.isEditing = true;
    this.modalService.open('custom-reason');
  }

  editSubReason(subReason: SubReason): void {
    this.reasonsService.setSubReasonOnEditSubject(subReason);
    this.editType = 'subReason';
    this.isEditing = true;
    this.modalService.open('custom-reason');
  }

  newReason(type: ReturnReasonType): void {
    this.isEditing = false;
    this.tempReasonID = `temp${this.randomizeID()}`;

    const descriptions: ReturnReasonDescription[] = [];
    this.enabledLanguages.forEach(lang => {
      const description : ReturnReasonDescription = {
        description: '',
        language: lang.name
      } 
      descriptions.push(description);
    })

    const newReason: ReturnReason = {
      id: this.tempReasonID,
      return_reason_type: type,
      descriptions,
      is_enabled: true,
      is_archived: false,
      is_used: false,
      is_custom: true,
      display_priority: 0,
      should_restock: false,
      sub_reasons: [],
    };

    this.reasons.push(newReason);
    this.editType = 'reason';
    this.reasonsService.setReasonOnEditSubject(newReason);
    this.modalService.open('custom-reason');
  }

  newSubReason(reason: ReturnReason): void {
    this.isEditing = false;
    this.tempSubReasonParentID = reason.id ?? '';
    this.tempSubReasonID = this.randomizeID();

    const newSubReason: SubReason = {
      id: this.tempSubReasonID,
      descriptions: [
        {
          description: '',
          language: 'English',
        },
        {
          description: '',
          language: 'Swedish',
        },
        {
          description: '',
          language: 'Danish',
        },
        {
          description: '',
          language: 'Norwegian',
        },
      ],
      is_enabled: true,
      should_restock: false,
      is_custom: true,
    };
    reason.sub_reasons.push(newSubReason);
    this.editType = 'subReason';
    this.reasonsService.setSubReasonOnEditSubject(newSubReason);
    this.modalService.open('custom-reason');
  }

  getReasonDescriptions(type: string): ReturnReasonDescription[] {
    if (type === 'reason') {
      this.reasonsService.getReasonOnEditSubject().subscribe((x) => {
        this.descriptions = x.descriptions;
      });
      return this.filterActiveLanguages(this.descriptions);
    }
    this.reasonsService.getSubReasonOnEditSubject().subscribe((x) => {
      this.descriptions = x.descriptions;
    });

    return this.filterActiveLanguages(this.descriptions);
  }

  filterActiveLanguages(
    descriptions: ReturnReasonDescription[]
  ): ReturnReasonDescription[] {
    return descriptions.filter((description) =>
      this.enabledLanguages.some(
        (enabledLanguage) => description.language === enabledLanguage.name
      )
    );
  }

  checkDisabled(): boolean {
    let invalid = true;

    const enabledLanguages = this.descriptions.filter((x) =>
      this.enabledLanguages.some((y) => y.name === x.language)
    );

    invalid = enabledLanguages.some((description) => !description.description);

    return invalid;
  }

  getCountryFlag(lang: string): string {
    const language = this.enabledLanguages.find(enabledLanguage => enabledLanguage.name === lang);

    if(language?.name === 'English'){
      return 'us';
    }
    return language?.country_code.toLowerCase() ?? '';
  }

  getPlaceholder(country: string): string {
    const placeholders: { [key: string]: string } = {
      English: 'e.g. Not satisfied with the item',
      Swedish: 'e.g. Inte nöjd med varan',
      Norwegian: 'e.g. Ikke fornøyd med varen',
      Danish: 'e.g. Ikke tilfreds med varen'
    };
    return placeholders[country] ?? placeholders['English'];
  }

  removeTempIDs(): void {
    this.reasons.forEach((reason) => {
      if (reason.id?.includes('temp')) {
        reason.id = undefined;
      }
    });
  }
}
