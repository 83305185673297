import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
})
export class ArrowButtonComponent {
  @Input()
  direction = 'right';
  @Input()
  buttonText = '';
  @Input()
  buttonStyle = 'primary';
  @Input()
  disabled = false;

  @Output()
  byClick: EventEmitter<Event> = new EventEmitter<Event>();

  buttonPressed(event: Event) {
    if (!this.disabled) {
      this.byClick.emit(event);
    }
  }
}
