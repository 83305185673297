import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { AvailableService } from '../../../models/available-service.model';
import { IntegrationStatus } from '../../../models/integration-status.model';
import { PackageTypes } from '../../../models/package-types.model';

export const postNordServices: AvailableService[] = [
  {
    title: 'PostNord Return Drop Off',
    description: 'Return goods in a simple way at one of the service points.',
    packageType: ShippingPackageType.PostNordReturnDropOff,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.PostNord,
  },
  {
    title: 'PostNord Varubrev Returns',
    description:
      'Using a partner outlet or service center, with up to 60 days.',
    packageType: ShippingPackageType.PostNordVarubrev,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.PostNord,
  },
];

export const glsServices: AvailableService[] = [
  {
    title: 'GLS Return',
    description: 'Transnational returns to many European countries available',
    packageType: ShippingPackageType.GlsReturn,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Gls,
  },
];

export const upsServices: AvailableService[] = [
  {
    title: 'UPS Standard',
    description: '1 to 5 days delivery within Europe',
    packageType: ShippingPackageType.UpsStandard,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Ups,
  },
  {
    title: 'UPS Express',
    description: 'Next day delivery by mid-afternoon',
    packageType: ShippingPackageType.UpsExpress,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Ups,
  },
  {
    title: 'UPS Worldwide Express Plus',
    description: 'Next day delivery by early morning',
    packageType: ShippingPackageType.UpsWorldwideExpressPlus,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Ups,
  },
  {
    title: 'UPS Worldwide Economy',
    description: 'Delivery in 5 to 12 working days',
    packageType: ShippingPackageType.UpsWorldwideEconomy,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Ups,
  },
];

export const bringServices: AvailableService[] = [
  {
    title: 'Parcel Norway Domestic (Return from pick-up point)',
    description: 'Returns within Norway at one of the 1,500 Post locations.',
    packageType: ShippingPackageType.BringParcelDomesticReturnFromPickUpPoint,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'Parcel Norway Domestic (Return to business)',
    description: 'Returns within Norway at one of the 1,500 Post locations.',
    packageType: ShippingPackageType.BringParcelDomesticReturnToBusiness,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'Parcel Norway Domestic (Return package in mailbox)',
    description:
      'Return via mailbox or through a Post Office within Norway, up to 5kg.',
    packageType: ShippingPackageType.BringParcelDomesticReturnPackageInMailbox,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'PickUp Parcel',
    description:
      'Available in Nordics to all countries, parcel delivered to PickUp point. Not recommended.',
    packageType: ShippingPackageType.BringPickUpParcel,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'PickUp Parcel Bulk',
    description:
      'Bulk up to 20 parcels within the same country.  Available in the Nordics and some countries in Europe.  Not recommended.',
    packageType: ShippingPackageType.BringPickUpParcelBulk,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'Home Delivery Parcel',
    description:
      'Home delivery in Sweden, Denmark, Norway, and Finland. Not recommended.',
    packageType: ShippingPackageType.BringHomeDeliveryParcel,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'PickUp Parcel Return',
    description:
      'Let your customer return via parcel boxes or PickUp point in Sweden, Denmark, Norway and Finland.',
    packageType: ShippingPackageType.BringPickUpParcelReturn,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'PickUp Parcel Return Bulk',
    description:
      'Let your customer return via parcel boxes or PickUp point in Sweden, Denmark, Norway and Finland.',
    packageType: ShippingPackageType.BringPickUpParcelReturnBulk,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'Express International',
    description: 'From Sweden, Denmark, and Norway to the rest of the world.',
    packageType: ShippingPackageType.BringExpressInternational,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
  {
    title: 'Express Economy',
    description: 'From Sweden and Denmark to the rest of the world.',
    packageType: ShippingPackageType.BringExpressEconomy,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.Bring,
  },
];

export const dhlExpressServices: AvailableService[] = [
  {
    title: 'DHL Express WorldWide',
    description:
      'Shipments delivered to Europe before the end of the working day. Outside Europe – a few working days later.',
    packageType: ShippingPackageType.DhlExpressWorldWide,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlExpress,
  },
];

export const deutschePostServices: AvailableService[] = [
  {
    title: 'Packet Return',
    description: 'Packets up to 2kg from 22 EU countries.',
    packageType: ShippingPackageType.DhlDeutschePostPacketReturn,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlDeutschePost,
  },
];

export const dhlFreightServices: AvailableService[] = [
  {
    title: 'DHL Freight Service Point Return',
    description: 'For domestic C2B shipments in Sweden up to 20 kg',
    packageType: ShippingPackageType.DhlFreightServicePointReturn,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  },
  {
    title: 'DHL Freight Parcel Return Connect',
    description: 'A return service from 24 European countries C2B to Sweden',
    packageType: ShippingPackageType.DhlFreightParcelReturnConnect,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  },
  {
    title: 'DHL Freight Home Delivery Return',
    description:
      'For domestic C2B shipments in Sweden up to 800 kg',
    packageType: ShippingPackageType.DhlFreightHomeDeliveryReturn,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  },
  {
    title: 'DHL Freight Paket',
    description:
      'For domestic B2B shipments in Sweden up to 35 kg',
    packageType: ShippingPackageType.DhlFreightPaket,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  },
  {
    title: 'DHL Freight Euroconnect',
    description:
      'Up to 1000 kg to or from Sweden and 50 countries in Europe and Asia B2B',
    packageType: ShippingPackageType.DhlFreightEuroconnect,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  },
  {
    title: 'DHL Freight Euroline',
    description:
      'Shipments to or from Sweden and 50 countries in Europe and Asia B2B',
    packageType: ShippingPackageType.DhlFreightEuroline,
    checked: false,
    disabled: false,
    shipping_carrier: ShippingCarrier.DhlFreight,
  }
];

export const integrationStatuses: IntegrationStatus[] = [
  {
    status: [IntegrationStatusEnum.Activating],
    text: 'In progress',
    class: 'progress',
  },
  {
    status: [IntegrationStatusEnum.InfoCompleted],
    text: 'Filled in',
    class: 'filled',
  },
  {
    status: [IntegrationStatusEnum.Active],
    text: 'Active',
    class: 'active',
  },
  {
    status: [IntegrationStatusEnum.ActivationError],
    text: 'Incorrect information',
    class: 'error',
  },
  {
    status: [
      IntegrationStatusEnum.Deactivated,
      IntegrationStatusEnum.Undefined,
    ],
    text: 'Requires completing',
    class: 'pending',
  },
];

export const postNordPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.PostNordVarubrev,
  ShippingPackageType.PostNordReturnDropOff,
];

export const glsPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.GlsReturn,
];

export const dhlExpressPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.DhlExpressWorldWide,
];

export const bringPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.BringParcelDomesticReturnFromPickUpPoint,
  ShippingPackageType.BringParcelDomesticReturnToBusiness,
  ShippingPackageType.BringParcelDomesticReturnPackageInMailbox,
  ShippingPackageType.BringPickUpParcel,
  ShippingPackageType.BringPickUpParcelBulk,
  ShippingPackageType.BringHomeDeliveryParcel,
  ShippingPackageType.BringPickUpParcelReturn,
  ShippingPackageType.BringPickUpParcelReturnBulk,
  ShippingPackageType.BringExpressInternational,
  ShippingPackageType.BringExpressEconomy,
];

export const upsPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.UpsStandard,
  ShippingPackageType.UpsExpress,
  ShippingPackageType.UpsWorldwideExpressPlus,
  ShippingPackageType.UpsWorldwideEconomy,
];

export const deutschePostPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.DhlDeutschePostPacketReturn,
];

export const dhlFreightPackageTypes: ShippingPackageType[] = [
  ShippingPackageType.DhlFreightServicePointReturn,
  ShippingPackageType.DhlFreightParcelReturnConnect,
  ShippingPackageType.DhlFreightHomeDeliveryReturn,
  ShippingPackageType.DhlFreightPaket,
  ShippingPackageType.DhlFreightEuroconnect,
  ShippingPackageType.DhlFreightEuroline
];

export const allPackageTypes: PackageTypes[] = [
  {
    text: 'PostNord',
    type: ShippingPackageType.PostNordVarubrev,
    packageType: 'Varubrev',
    logo: 'postnord',
    carrier: ShippingCarrier.PostNord
  },
  {
    text: 'PostNord',
    type: ShippingPackageType.PostNordReturnDropOff,
    packageType: 'Return Drop Off',
    logo: 'postnord',
    carrier: ShippingCarrier.PostNord
  },
  {
    text: 'GLS',
    type: ShippingPackageType.GlsReturn,
    packageType: 'Return',
    logo: 'gls',
    carrier: ShippingCarrier.Gls
  },
  {
    text: 'DHL Express',
    type: ShippingPackageType.DhlExpressWorldWide,
    packageType: 'WorldWide',
    logo: 'dhl',
    carrier: ShippingCarrier.DhlExpress
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringParcelDomesticReturnFromPickUpPoint,
    packageType: 'Parcel Norway domestic Return From PickUp Point',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringParcelDomesticReturnToBusiness,
    packageType: 'Parcel Norway domestic(Return to business)',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringParcelDomesticReturnPackageInMailbox,
    packageType: 'Parcel Norway domestic(Return package in mailbox)',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringPickUpParcel,
    packageType: 'PickUp Parcel',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringPickUpParcelBulk,
    packageType: 'PickUp Parcel Bulk',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringHomeDeliveryParcel,
    packageType: 'Home Delivery Parcel',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringPickUpParcelReturn,
    packageType: 'PickUp Parcel Return',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringPickUpParcelReturnBulk,
    packageType: 'PickUp Parcel Return Bulk',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringExpressInternational,
    packageType: 'Express International',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'Bring',
    type: ShippingPackageType.BringExpressEconomy,
    packageType: 'Express Economy',
    logo: 'bring',
    carrier: ShippingCarrier.Bring
  },
  {
    text: 'UPS',
    type: ShippingPackageType.UpsStandard,
    packageType: 'UPS Standard',
    logo: 'ups',
    carrier: ShippingCarrier.Ups
  },
  {
    text: 'UPS',
    type: ShippingPackageType.UpsExpress,
    packageType: 'UPS Express',
    logo: 'ups',
    carrier: ShippingCarrier.Ups
  },
  {
    text: 'UPS',
    type: ShippingPackageType.UpsWorldwideExpressPlus,
    packageType: 'UPS Worldwide Express Plus',
    logo: 'ups',
    carrier: ShippingCarrier.Ups
  },
  {
    text: 'UPS',
    type: ShippingPackageType.UpsWorldwideEconomy,
    packageType: 'UPS Worldwide Economy',
    logo: 'ups',
    carrier: ShippingCarrier.Ups
  },
  {
    text: 'Deutsche Post',
    type: ShippingPackageType.DhlDeutschePostPacketReturn,
    packageType: 'Packet Return',
    logo: 'deutsche-post',
    carrier: ShippingCarrier.DhlDeutschePost
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightServicePointReturn,
    packageType: 'DHL Freight Service Point Return',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightParcelReturnConnect,
    packageType: 'DHL Freight Parcel Return Connect',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightHomeDeliveryReturn,
    packageType: 'DHL Freight Home Delivery Return',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightPaket,
    packageType: 'DHL Freight Paket',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightEuroconnect,
    packageType: 'DHL Freight Euroconnect',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  },
  {
    text: 'DHL Freight',
    type: ShippingPackageType.DhlFreightEuroline,
    packageType: 'DHL Freight Euroline',
    logo: 'dhl-freight',
    carrier: ShippingCarrier.DhlFreight
  }
];
