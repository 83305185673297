import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { ReturnsService } from '../returns.service';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements AfterViewInit {
  dismissable = true;

  @ViewChild('background')
  element: ElementRef | undefined;

  isShowing = false;

  private mouseDownClassName = 'N/A';
  return: ReturnRequest = new ReturnRequest();
  selectedReturnShipping = '';
  amountSelected = 0;
  files: any[] = [];

  constructor(
    private sidenavService: SidenavService,
    private returnsService: ReturnsService,
    private modalService: ModalService
  ) {
    this.returnsService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
        this.return = res;
      });

    this.returnsService.getIsShowing().subscribe((val: boolean) => {
      this.isShowing = val;
      if (val) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.element == null) {
      return;
    }

    this.element!.nativeElement.addEventListener('mousedown', (el: any) => {
      this.mouseDownClassName = el.target.className;
    });

    this.element!.nativeElement.addEventListener('mouseup', (el: any) => {
      if (
        el.target.className === 'modal-background show' &&
        this.mouseDownClassName === el.target.className
      ) {
        if (this.dismissable === true) {
          this.dismiss();
        }
      }
    });

    this.sidenavService.getOpen().subscribe((res: boolean) => {
      if (res) this.show();
      else this.dismiss();
    });
  }

  public show(): void {
    this.isShowing = true;
    document.body.classList.add('modal-open');
  }

  public dismiss(): void {
    this.isShowing = false;
    document.body.classList.remove('modal-open');
    this.modalService.dismiss();
  }
}
