import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-printed-slips',
  templateUrl: './printed-slips.component.html',
  styleUrls: ['./printed-slips.component.scss'],
})
export class PrintedSlipsComponent implements OnInit {
  title = 'Return shipping slips';
  description =
    'Do you want to send return shipping slips with Returbo to your customers? By answering “Yes” you will be able to activate and use various shipping carriers that we are integrated with or upload your own slips. With “No” we assume that you send the slips either with the original order or in any other way.';
  isLoading = true;
  isSaving = false;
  onBoardingCompleted = false;

  options: string[] = ['Yes, I want to send them with Returbo', 'No'];

  returnSettings: ReturnSettings = new ReturnSettings();
  shippingIntegrations: ShippingIntegration[] = [];

  constructor(
    private errorService: ErrorService,
    private modalService: ModalService,
    private shippingIntegrationsService: ShippingIntegrationService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe({
        next: (returnSettings: ReturnSettings) => {
          if (this.returnSettingsService.hasReturnSettingsLoaded()) {
            this.returnSettings = returnSettings;
            this.isLoading = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Unable to load return settings'
          );
          this.isLoading = false;
        }
      });

    this.shippingIntegrationsService
      .getShippingIntegrations()
      .subscribe((shippingIntegrations) => {
        this.shippingIntegrations = shippingIntegrations;
      });
  }

  checkOnBoarding() {
    this.returnSettings.printed_return_slips !== undefined
      ? (this.onBoardingCompleted = true)
      : (this.onBoardingCompleted = false);
  }

  getSelectedOption(): string {
    const option: boolean = this.returnSettings.printed_return_slips;
    this.checkOnBoarding();

    if (option === undefined) {
      return '';
    }

    return option ? this.options[0] : this.options[1];
  }

  setSelectedOption(value: string): void {
    this.returnSettings.printed_return_slips = value === this.options[0];
    this.onBoardingCompleted = true;

    if (
      !this.returnSettings.printed_return_slips &&
      this.shippingIntegrations.length > 0
    ) {
      this.modalService.open('integrations-page');
      return;
    }

    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: (saved: boolean) => {
          if (saved) {
            this.isSaving = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
      });
  }

  cancel(): void {
    this.returnSettings.printed_return_slips =
      !this.returnSettings.printed_return_slips;
    this.modalService.dismiss();
  }

  confirm(): void {
    this.saveSettings();
    this.modalService.dismiss();
  }
}
