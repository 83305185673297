<div>
  <h4 class="mb-8">{{ title }}</h4>
  <div class="wrap">
    <div class="w-half left">
      <img class="mr-16" src="assets/images/icons/store-credit.png" />
      <span class="text">••••• ••••• ••••• {{ code }}</span>
    </div>
    <div class="w-half">
      <a class="link" href="{{ link }}" target="_blank">See in Shopify</a>
    </div>
  </div>
</div>
