<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
  <app-alert
    *ngIf="minimumDays || exchangeErrorMessage"
    type="Warning"
    size="large"
    [text]="warning"
    [showCloseButton]="true"
    imgIcon="/assets/images/icons/alert-octagon.png"
  ></app-alert>

  <app-alert
    *ngIf="showInformation || showComplaintMessage"
    type="Information"
    size="large"
    [text]="information"
    icon="help_outline"
    [showCloseButton]="true"
    [link]="minimumComplaintLink"
    linkText="Read more here"
  ></app-alert>

  <div class="section">
    <div class="deadline">
      <h3 class="deadline-title mt-0">Returns</h3>
      <div class="control">
        <div class="input">
          <app-input
            size="small"
            placeholder="30"
            [value]="getDeadline(requestType.Return)"
            (change)="setDeadlineAndPeriod($event, false, requestType.Return)"
            [disabled]="isSaving"
            [error]="minimumDays"
          ></app-input>
        </div>
        <div class="input ml-8">
          <app-dropdown
            size="compact"
            [selected]="getDeadlineTimePeriod(requestType.Return)"
            (selectedChange)="
              setDeadlineAndPeriod($event, true, requestType.Return)
            "
            [blur]="true"
            [options]="options"
            [disabled]="isSaving"
          ></app-dropdown>
        </div>
        <p class="info ml-8">
          is the general allowed period to submit a <b>return request</b> after
          the item has been shipped.
        </p>
        <img
          (click)="toggleInformation(requestType.Return)"
          class="help"
          src="assets/images/icons/help-circle.png"
        />
      </div>
      <app-special-deadlines
        [pricePlan]="pricePlan"
        [type]="requestType.Return"
      >
      </app-special-deadlines>
    </div>

    <div class="deadline" [ngClass]="{ disabled: !isExchangesEnabled() }">
      <h3 class="deadline-title">Exchanges</h3>
      <div class="control">
        <div class="input">
          <app-input
            size="small"
            placeholder="30"
            [value]="getDeadline(requestType.Exchange)"
            (change)="setDeadlineAndPeriod($event, false, requestType.Exchange)"
            [disabled]="!isExchangesEnabled() || isSaving"
            [error]="exchangeErrorMessage"
          ></app-input>
        </div>
        <div class="input ml-8">
          <app-dropdown
            size="compact"
            [selected]="getDeadlineTimePeriod(requestType.Exchange)"
            (selectedChange)="
              setDeadlineAndPeriod($event, true, requestType.Exchange)
            "
            [options]="options"
            [blur]="true"
            [disabled]="!isExchangesEnabled() || isSaving"
          ></app-dropdown>
        </div>
        <p class="info ml-8">
          is the general allowed period to submit an
          <b>exchange request</b> after the item has been shipped.
        </p>
      </div>
      <app-special-deadlines
        [pricePlan]="pricePlan"
        [type]="requestType.Exchange"
        [isExchangesEnabled]="isExchangesEnabled()"
      >
      </app-special-deadlines>
    </div>

    <div class="deadline" [ngClass]="{ disabled: !isComplaintEnabled() }">
      <h3 class="deadline-title">Complaints</h3>
      <div class="control">
        <div class="input">
          <app-input
            size="small"
            placeholder="30"
            [value]="getDeadline(requestType.Complaint)"
            (change)="
              setDeadlineAndPeriod($event, false, requestType.Complaint)
            "
            [disabled]="!isComplaintEnabled() || isSaving"
            [error]="showComplaintMessage"
          ></app-input>
        </div>
        <div class="input ml-8">
          <app-dropdown
            size="compact"
            [selected]="getDeadlineTimePeriod(requestType.Complaint)"
            (selectedChange)="
              setDeadlineAndPeriod($event, true, requestType.Complaint)
            "
            [options]="options"
            [blur]="true"
            [disabled]="!isComplaintEnabled() || isSaving"
          ></app-dropdown>
        </div>
        <p class="info ml-8">
          is the general allowed period to submit a
          <b>complaint request</b> after the item has been shipped.
        </p>
        <img
          (click)="toggleInformation(requestType.Complaint)"
          class="help"
          src="assets/images/icons/help-circle.png"
        />
      </div>
    </div>
    <app-special-deadlines
      [pricePlan]="pricePlan"
      [type]="requestType.Complaint"
      [isComplaintsEnabled]="isComplaintEnabled()"
    >
    </app-special-deadlines>
  </div>
</app-settings-section>
