<div class="notification-email">
  <ng-container *ngIf="notification.is_enabled; else hiddenMail">
    <p class="title">
      Email title
      <span class="edit-text pointer" *ngIf="!isOnEdit" (click)="toggleEdit()"
        ><mat-icon *ngIf="!isOnEdit">edit</mat-icon>
        {{ isOnEdit ? "Cancel" : "Edit" }}</span
      >
      <span
        *ngIf="
          !isOnEdit &&
          getStatusMessage(notification.email_type).status ===
            'Pending Approval'
        "
        (click)="
          openEmailModal(
            notification.id,
            getStatusMessage(notification.email_type).text,
            'deactivate',
            notification.email_type
          )
        "
        class="hide-email"
        matRipple
        [matRippleCentered]="true"
        >Hide Email</span
      >
    </p>
    <ng-container *ngIf="!isOnEdit">
      <p class="email-title">{{ notification.title }}</p>
    </ng-container>
    <ng-container *ngIf="isOnEdit">
      <app-input
        placeholder="Notifaction title"
        [(value)]="notification.title"
      ></app-input>
    </ng-container>

    <p class="title">Content</p>

    <ng-container *ngIf="!isOnEdit">
      <p class="content" [innerHTML]="notification.body"></p>
    </ng-container>

    <ng-container *ngIf="isOnEdit">
      <div class="text">
        <editor
          [init]="{
            height: 400,
            menubar: false,
            placeholder: 'Write notification',
            statusbar: false,
            plugins: ['lists'],
            toolbar:
              'undo redo | bold italic underline | bullist numlist | outdent indent'
          }"
          initialValue=""
          [(ngModel)]="notification.body"
          apiKey="gv3ebtsrd0si3bbzzv2hholu6or7r9my70gce8ruuhw1lb1h"
          [disabled]="isSaving"
        ></editor>
      </div>

      <div class="actions">
        <app-button
          size="wide"
          (click)="saveNotification()"
          [disabled]="isSaving"
          >Save</app-button
        >
        <p class="cancel" (click)="toggleEdit()">Cancel</p>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #hiddenMail>
    <div class="items">
      <p class="text-dark-gray">
        This email is deactivated. It will not be sent to your customers.
      </p>

      <span
        (click)="
          openEmailModal(
            notification.id,
            getStatusMessage(notification.email_type).text,
            'activate',
            notification.email_type
          )
        "
        class="show-email"
        matRipple
        [matRippleCentered]="true"
      >
        Activate email
      </span>
    </div>
  </ng-template>
</div>
