import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { Item } from 'src/app/models/item.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { UpdateItem } from 'src/app/models/update-item.model';
import { UpdateReturnRequest } from 'src/app/models/update-return-request.model';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { ReturnsService } from '../../returns.service';
import { ItemsTableService } from '../left-content/items-table/items-table.service';
import { SidenavService } from '../sidenav.service';
import { ArchiveService } from './archive.service';

@Component({
  selector: 'app-archive-modal',
  templateUrl: './archive-modal.component.html',
  styleUrls: ['./archive-modal.component.scss'],
})
export class ArchiveModalComponent implements OnInit {
  return: ReturnRequest = new ReturnRequest();
  isLoading = false;
  ReturnStatusEnum = ReturnStatusEnum;

  constructor(
    private modalService: ModalService,
    private returnService: ReturnsService,
    private sidenavService: SidenavService,
    private itemsTableService: ItemsTableService,
    private archiveService: ArchiveService
  ) {}

  ngOnInit(): void {
    this.archiveService.getArchiveModal().subscribe((res: boolean) => {
      if (res) this.openModal();
    });

    this.returnService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
        this.return = res;
      });
  }

  openModal(): void {
    this.modalService.open('archive');
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  archiveRequest(): void {
    this.isLoading = true;
    const items: UpdateItem[] = [];
    this.return.items.forEach((item: Item) => {
      items.push({
        id: item.id,
        status: ReturnItemStatusEnum.ItemReceived,
        reject_cause: item.reject_cause ?? '',
        refund_amount: item.return_amount,
        merchant_compensation_choice: item.merchant_compensation_choice,
      });
    });

    const requestObj: UpdateReturnRequest = {
      id: this.return.id,
      shopify_order_id: this.return.shopify_order_id,
      account_id: this.return.account_id,
      domain: this.return.domain,
      status: ReturnStatusEnum.Archived,
      return_shipping_cost: Number(this.return.return_shipping_cost),
      charge_return_shipping: this.return.charge_return_shipping,
      refund_original_shipping: this.return.refund_original_shipping,
      items: items,
    };

    this.returnService.updateReturn(requestObj).subscribe((res: boolean) => {
      this.isLoading = false;
      if (res) {
        this.closeModal();
        this.itemsTableService.setRequestToUpdate(this.return.id, ReturnStatusEnum.Archived);
        this.sidenavService.close();
      }
    });
  }
}
