import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenerateSlipResponse } from 'src/app/models/generate-slip-response.model';
import { Shipping } from 'src/app/models/shipping.model';
import { SlipInfo } from 'src/app/models/slip-info.model';
import { SlipItem } from 'src/app/models/slip-item.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ReturnShippingService {
  private selectedReturnShipping: BehaviorSubject<Shipping> =
    new BehaviorSubject(new Shipping());
  private selectedFiles: BehaviorSubject<any> = new BehaviorSubject([]);
  private selectedReturnSlip = new BehaviorSubject(new SlipInfo());

  constructor(private httpClient: HttpClient) {}

  setSelectedReturnShipping(value: Shipping): void {
    this.selectedReturnShipping.next(value);
  }

  getSelectedReturnShipping(): Observable<Shipping> {
    return this.selectedReturnShipping.asObservable();
  }

  setSelectedFiles(value: File[]): void {
    this.selectedFiles.next(value);
  }

  getSelectedFiles(): Observable<File[]> {
    return this.selectedFiles.asObservable();
  }

  attachShippingSlip(
    returnId: string,
    refundShipping: boolean | null,
    returnShippingCost: string | null,
    slips: any,
    items: SlipItem[]
  ): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append('return_id', returnId);
    formData.append('slips', slips);

    for (let i = 0; i < items.length; i++) {
      formData.append(`items[${i}].id`, items[i].id);
      formData.append(
        `items[${i}].is_included`,
        items[i].is_included.toString()
      );
      formData.append(
        `items[${i}].cause_for_action`,
        items[i].cause_for_action ?? ''
      );
      formData.append(
        `items[${i}].refund_amount`,
        items[i].refund_amount?.toString() ?? ''
      );
      formData.append(
        `items[${i}].merchant_compensation_choice`,
        items[i].merchant_compensation_choice
      );
    }

    if (refundShipping) {
      formData.append('refund_original_shipping', refundShipping.toString());
    }
    if (returnShippingCost) {
      formData.append('return_shipping_cost', returnShippingCost);
    }

    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/api/booking/slip/send`,
      formData
    );
  }

  getSlipInfo(return_id: string): Observable<SlipInfo> {
    return this.httpClient.get<SlipInfo>(
      `${environment.apiUrl}/api/booking/return/${return_id}/slips`
    );
  }

  setReturnShippingSlip(returnSlip: SlipInfo) {
    this.selectedReturnSlip.next(returnSlip);
  }

  getReturnShippingSlip(): Observable<SlipInfo> {
    return this.selectedReturnSlip.asObservable();
  }

  getManualSlip(return_id: string): Observable<SlipInfo> {
    return this.httpClient.get<SlipInfo>(
      `${environment.apiUrl}/api/booking/return/${return_id}/slips/manual`
    );
  }

  generateSlip(
    return_id: string,
    return_address_id: string,
    shipping_package_type: string | null,
    refund_original_shipping: boolean | null,
    return_shipping_cost: string | null,
    items: SlipItem[]
  ): Observable<GenerateSlipResponse> {
    return this.httpClient.post<GenerateSlipResponse>(
      `${environment.apiUrl}/api/booking/slip/generate`,
      {
        return_id,
        return_address_id,
        shipping_package_type,
        refund_original_shipping,
        return_shipping_cost,
        items,
      }
    );
  }
}
