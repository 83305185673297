import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input()
  buttonStyle = 'primary';
  @Input()
  size = 'regular';
  @Input()
  disabled = false;
  @Input()
  prefix = '';

  @Output()
  byClick: EventEmitter<Event> = new EventEmitter<Event>();

  buttonPressed(event: Event) {
    if (!this.disabled) {
      this.byClick.emit(event);
    }
  }
}
