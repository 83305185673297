import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() icon = '';
  @Input() type = '';
  @Input() text = '';
  @Input() imgIcon = '';
  @Input() size = '';
  @Input() link = '';
  @Input() linkText = '';
  @Input() showCloseButton = true;
  @Input() innerText = '';
  @Input() showButton = false;
  @Input() buttonText = '';
  @Input() buttonType = '';

  showNote = true;

  @Output()
  clickButton: EventEmitter<Event> = new EventEmitter<Event>();

  hideNote(): void {
    this.showNote = false;
  }

  buttonPressed(event: Event) {
    this.clickButton.emit(event);
  }
}
