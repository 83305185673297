<div
  *ngIf="showNote"
  class="notification d-flex"
  [ngClass]="{
    caution: type === 'Caution' || type === 'Note',
    info: type === 'Information',
    warning: type === 'Warning',
    success: type === 'Success',
    tip: type === 'Tip'
  }"
>
  <img
    *ngIf="imgIcon.length > 0"
    class="mr-16 {{ size }}"
    src="{{ imgIcon }}"
  />
  <mat-icon
    *ngIf="icon.length > 0"
    class="icon mr-16"
    fontSet="material-icons-round"
    >{{ icon }}</mat-icon
  >

  <span class="font-14 font-normal mr-50"
    ><b class="mr-5" *ngIf="type">{{ type }}: </b>
    <span *ngIf="!innerText">{{ text }}</span>
    <span *ngIf="innerText" [innerHTML]="innerText"></span>
    <br />
    <a *ngIf="link" href="{{ link }}">{{ linkText }}</a>
  </span>
  
  <span>
    <app-new-button
      (click)="buttonPressed($event)"
      customWidth="135px"
      *ngIf="showButton"
      buttonStyle="{{ buttonType }}"
      >{{ buttonText }}</app-new-button
    >
  </span>

  <mat-icon
    *ngIf="showCloseButton"
    (click)="hideNote()"
    class="hide"
    fontSet="material-icons-round"
    >close
  </mat-icon>
</div>
