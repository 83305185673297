import { Injectable } from '@angular/core';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';

@Injectable({
  providedIn: 'root'
})
export class IntegrationAlertService {
  private alertSections: Map<ShippingCarrier, AlertSectionType> =
    new Map<ShippingCarrier, AlertSectionType>();

  setAlertSectionForCarrier(shippingCarrier: ShippingCarrier, alertSection: AlertSectionType): void {
    const currentAlertSectionType = this.alertSections.get(shippingCarrier);
    if (!currentAlertSectionType || currentAlertSectionType == AlertSectionType.None || alertSection == AlertSectionType.None) {
      this.alertSections.set(shippingCarrier, alertSection);
    }
  }

  getAlertSectionForCarrier(shippingCarrier: ShippingCarrier): AlertSectionType {
    return this.alertSections.get(shippingCarrier) ?? AlertSectionType.None;
  }
}
