import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ReturnReasonDescription } from './return-reason-description.model';
import { SubReason } from './sub-reason.model';

export class ReturnReason {
  public id? = '';
  public return_reason_type: ReturnReasonType = ReturnReasonType.Complaint;
  public descriptions: ReturnReasonDescription[] = [];
  public is_enabled = false;
  public is_custom = false;
  public is_used = false;
  public is_archived = false;
  public display_priority = 0;
  public should_restock = false;
  public sub_reasons: SubReason[] = [];
}
