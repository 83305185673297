<app-settings-section
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [title]="title"
  [description]="description"
  [exludedInOnBoarding]="true"
>
  <div class="section">
    <div class="mb-10">
      <mat-slide-toggle
        (click)="toggleNotifications()"
        color="primary"
        [checked]="checked"
        [disabled]="isSaving || isLoading"
      >
        Instant notifications
      </mat-slide-toggle>
    </div>
  </div>
</app-settings-section>
