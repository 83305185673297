import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input()
  size = 'small';
  @Input()
  checked = false;
  @Input()
  disabled = false;
  @Input()
  type = 'Check';
  @Output()
  checkedChange = new EventEmitter<boolean>();

  toggle(event: Event): void {
    if (event != undefined) {
      event.stopPropagation();
    }

    if (this.disabled) {
      return;
    }

    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
