import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingLocation } from 'src/app/settings/models/shipping-location.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-shipping-slips',
  templateUrl: './shipping-slips.component.html',
  styleUrls: ['./shipping-slips.component.scss'],
})
export class ShippingSlipsComponent implements OnInit {
  title = 'Autogenerated return shipping slips';
  description =
    'With this feature activated, you have the option to autogenerate your return shipping slips directly to your customer when they have registered their return.';
  note =
    'To fully automate your flow make sure you have “Automatically accept return requests” as an option for Accepting return requests. This can be found in Return settings.';
  warning =
    'Please make sure you have set one of your addresses as a default return location in Return settings. The autogeneration will not work otherwise and when changing this setting it will toggle back to manual.';
  isLoading = true;
  isSaving = false;

  defaultShippingLocationEnabled = false;
  automaticRequests = false;

  locations: ShippingLocation[] = [];
  returnSettings: ReturnSettings = new ReturnSettings();

  options: string[] = [
    'Autogenerate return slips',
    'I prefer manual generation',
  ];

  @Input()
  disabled = false;

  showAutomateInfo = true;
  anyActiveIntegrations = false;

  constructor(
    private returnSettingsService: ReturnSettingsService,
    private locationSettingsService: LocationSettingsService,
    private errorService: ErrorService,
    private shippingIntegrationsService: ShippingIntegrationService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    combineLatest([
      this.returnSettingsService.getSubjectReturnSettings(),
      this.locationSettingsService.getSubjectLocationSettings()])
      .subscribe({
        next: ([returnSettings, locations]: [ReturnSettings, ShippingLocation[]]) => {
          this.returnSettings = returnSettings;
          this.locations = locations;

          if (returnSettings.deadline && locations.length) {
            this.isLoading = false;
          }

          this.checkForAutomaticReturnRequests();
          this.checkForDefaultShippingLocation();
        }
    });

    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((integrations) => {
        this.anyActiveIntegrations = integrations.some(
          (x) => x.status === IntegrationStatusEnum.Active
        );
      });
  }

  checkForDefaultShippingLocation(): boolean {
    const foundLocation =
      this.locations.find(
        (location) => location.is_default_shipping_location
      );

    return foundLocation ? true : false;
  }

  checkForAutomaticReturnRequests(): boolean {
    return this.returnSettings.automatically_accept_requests;
  }

  getSelectedValue(): string {
    return this.returnSettings.autogenerated_slips
      ? this.options[0]
      : this.options[1];
  }

  setSelectedValue(selection: string): void {
    this.returnSettings.autogenerated_slips =
      selection === this.options[0];
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.returnSettingsService.saveReturnSettings(this.returnSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save return settings');
      },
      complete: () => {
        this.isSaving = false;
      }
    });
  }

  toggleInfoBox(): void {
    this.showAutomateInfo = !this.showAutomateInfo;
  }
}
