import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appRegExpValidator]'
})
export class RegExpValidatorDirective {
  @Input() appRegExpValidator = '';

  constructor(private elRef: ElementRef) {}

  @HostListener('keyup',['$event']) onKeyDown(event: any) {
    const regExp = new RegExp(this.appRegExpValidator); 
    const newInputValue = event.target.value;
    if(!regExp.test(newInputValue)) { 
      const revertLastCharValue = newInputValue.substring(0, newInputValue.length-1)
      this.elRef.nativeElement.value = revertLastCharValue;
    }
  }
}
