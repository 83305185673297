import { Component, OnInit } from '@angular/core';
import { FeatureTrackerTypeEnum } from 'src/app/shared/enums/feature-tracker-type.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { PremiumReturnSettingsService } from 'src/app/shared/services/premium-return-settings.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ReasonSettingsService } from 'src/app/shared/services/settings/reason-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { SupportSettingsService } from 'src/app/shared/services/settings/support-settings.service';
import { WarehouseSettingsService } from 'src/app/shared/services/settings/warehouse-settings.service';
import { SpecialDeadlineService } from 'src/app/shared/services/special-deadline.service';
import { FeatureTracker } from '../../models/features-tracker.model';
import { NotificationSettingsService } from 'src/app/shared/services/settings/notification-settings.service';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';

@Component({
  selector: 'app-settings-dashboard',
  templateUrl: './settings-dashboard.component.html',
  styleUrls: ['./settings-dashboard.component.scss'],
})
export class SettingsDashboardComponent implements OnInit {
  featureTracker: FeatureTracker[] = [];
  featureTrackerEnum = FeatureTrackerTypeEnum;

  constructor(
    private featureService: FeatureService,
    private errorService: ErrorService,
    private exchangeSettingsService: ExchangeSettingsService,
    private shopifySettingsService: ShopifySettingsService,
    private specialDeadlineService: SpecialDeadlineService,
    private shippingSettingsService: ShippingSettingsService,
    private currencyService: CurrencyService,
    private countriesService: CountriesService,
    private complaintSettingsService: ComplaintSettingsService,
    private premiumReturnSettingsService: PremiumReturnSettingsService,
    private notificationSettingsService: NotificationSettingsService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private locationSettingsService: LocationSettingsService,
    private warehouseSettingsService: WarehouseSettingsService,
    private accountSettingsService: AccountSettingsService,
    private languageSettingsService: LanguageSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private reasonSettingsService: ReasonSettingsService,
    private formSettingsService: FormSettingsService,
    private supportSettingsService: SupportSettingsService
  ) {}

  ngOnInit(): void {

    this.accountSettingsService
    .getAccountSettings()
    .subscribe({
      error:() => {
        this.errorService.showErrorSnackBar(
          'Failed to load account settings.'
        );
      }
    });

    this.featureService
    .getNewFeatures()
    .subscribe({
      next: (features: FeatureTracker[]) => {
        this.featureTracker = features;
      },
      error:() => {
        this.errorService.showErrorSnackBar(
          'Failed to load which new features has been seen by the merchant.'
        );
      }
    });

    this.pricePlanSettingsService.getSelectedPricePlan().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load price plan.'
        );
      }
    });

    this.specialDeadlineService.getDeadlines().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load special deadlines.'
        );
      }
    });

    this.languageSettingsService.getLanguageSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load language settings.'
        );
      }
    });

    this.returnSettingsService
    .getReturnSettings()
    .subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Return settings'
        );
      }
    });

    this.reasonSettingsService.getReasonSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Return reasons'
        );
      }
    });

    this.exchangeSettingsService.getExchangeSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Exchange settings'
        );
      }
    });

    this.premiumReturnSettingsService.getPremiumReturnSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Premium Return options'
        );
      }
    });

    this.complaintSettingsService.getComplaintSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Complaint options'
        );
      }
    });

    this.formSettingsService.getFormSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Form settings'
        );
      }
    });

    this.shippingSettingsService.getShippingSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Shipping settings'
        );
      }
    });

    this.shippingSettingsService.getShippingCostSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Shipping cost settings'
        );
      }
    });

    this.locationSettingsService.getLocationSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load shipping locations'
        );
      }
    });

    this.currencyService.getCurrencies().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load currencies from Shopify');
      }
    });

    this.warehouseSettingsService.getWarehouseSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load warehouse settings');
      }
    });

    this.countriesService.getCountries().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load countries');
      }
    });

    this.shopifySettingsService.getShopifySettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Shopify settings'
        );
      }
    });

    this.notificationSettingsService.getNotificationSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load Notifications settings'
        );
      }
    });

    this.supportSettingsService.getSupportSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to load support settings'
        );
      }
    });
  }

  newFeaturesSeen(type: FeatureTrackerTypeEnum): boolean {
    const foundFeature = this.featureTracker.find(
      (feature) => feature.type === type
    );

    return foundFeature?.is_seen ?? true;
  }

  setAsSeen(type: FeatureTrackerTypeEnum): void {
    const feature = this.featureTracker.find(
      (feature) => feature.type === type && !feature.is_seen
    );
    if (feature) {
      feature.is_seen = true;
      this.saveSeenFeatures();
    }
  }

  saveSeenFeatures(): void {
    this.featureService.updateNewFeatures(this.featureTracker).subscribe({
      next: () => {
        this.featureService.setSeen(this.featureTracker);
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save seen features');
      },
    });
  }
}
