<app-settings-section
  [title]="title"
  [description]="description"
  [isLoading]="isSaving || isLoading"
  [hasContent]="!isLoading"
  [exludedInOnBoarding]="true"
>
  <ng-container>
    <app-alert
      type="Tip"
      size="large"
      [text]="tipText"
      [showCloseButton]="true"
      imgIcon="/assets/images/icons/lightbulb.png"
    ></app-alert>
    <div
      class="policy mb-24 mt-32"
      *ngFor="let policy of getActivePolicies(); let index = index"
    >
      <h6>
        <span class="fi fi-{{ getLanguageCode(policy.language) }}"></span
        >{{ policy.language }}
        <span
          class="default-language"
          *ngIf="isDefaultLanguage(policy.language)"
          >Default</span
        >
        <span
          class="edit-text pointer"
          *ngIf="!isOnEdit(policy.language)"
          (click)="setEdit(policy.language)"
          ><mat-icon>{{ policy.policy_summary ? "edit" : "add" }}</mat-icon>
          {{ policy.policy_summary ? "Edit" : "Add" }}</span
        >
      </h6>

      <ng-container *ngIf="isOnEdit(policy.language)">
        <editor
          [init]="{
            height: 400,
            menubar: false,
            placeholder: 'Write your return policy here...',
            statusbar: false,
            plugins: ['lists'],
            toolbar:
              'undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent',
            fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 32pt'
          }"
          initialValue=""
          [(ngModel)]="policy.policy_summary"
          [disabled]="isSaving"
          apiKey="gv3ebtsrd0si3bbzzv2hholu6or7r9my70gce8ruuhw1lb1h"
        ></editor>

        <div class="actions">
          <button
            (click)="removeEdit(policy.language)"
            class="btn btn-tertiary mr-16"
            *ngIf="!policy.policy_summary"
          >
            Cancel
          </button>
          <app-button
            size="regular"
            [disabled]="isSaving"
            (click)="saveSettings(policy.language)"
            >Save {{ policy.policy_summary ? "changes" : "" }}</app-button
          >
        </div>
      </ng-container>

      <ng-container *ngIf="!isOnEdit(policy.language)">
        <p
          class="policyText mb-7"
          [ngClass]="[fullText ? 'full-text' : 'shorten-text']"
          [innerHtml]="policy.policy_summary"
        ></p>
        <p
          class="text-primary mt-0 pointer d-flex"
          *ngIf="policy.policy_summary && policy.policy_summary.length >= 340"
          (click)="toggleText()"
        >
          {{ fullText ? "Collapse text" : "Expand text" }}
          <mat-icon>{{ fullText ? "expand_less" : "expand_more" }}</mat-icon>
        </p>
      </ng-container>

      <!-- Keeping this for later -->

      <!-- <ng-container
        *ngIf="
          policy.policy_summary ||
          (!policy.policy_summary && isOnEdit(policy.language))
        "
      >
        <div class="link">
          <span>Link to the return form:</span>
          <div class="box">
            <div class="value">
              {{ returnURL }}
              <img
                src="../assets/images/icons/copy.png"
                (click)="
                  copyURL(
                    getLanguageCode(policy.language),
                    policy.policy_summary,
                    'language'
                  )
                "
              />
            </div>
            <div
              [ngClass]="copiedReturnLink ? 'popup' : 'popup-hidden'"
              *ngIf="
                this.policyID.language === getLanguageCode(policy.language) &&
                this.policyID.summary === policy.policy_summary &&
                this.policyID.type ===
                  'language' + getLanguageCode(policy.language)
              "
            >
              Copied!
            </div>
          </div>
        </div>
        <div class="link">
          <span>Add the link directly to your website:</span>
          <div class="box">
            <div class="value">
              {{ embedURL }}
              <img
                src="../assets/images/icons/copy.png"
                (click)="
                  copyURL(
                    getLanguageCode(policy.language),
                    policy.policy_summary,
                    'summary'
                  )
                "
              />
            </div>
            
            <div
              [ngClass]="copiedReturnLink ? 'popup' : 'popup-hidden'"
              *ngIf="
                this.policyID.language === getLanguageCode(policy.language) &&
                this.policyID.summary === policy.policy_summary &&
                this.policyID.type ===
                  'summary' + getLanguageCode(policy.language)
              "
            >
              Copied!
            </div>
          </div>
        </div>
      </ng-container> -->
    </div>
  </ng-container>
</app-settings-section>
