import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { PremiumOptions } from 'src/app/shared/enums/premium-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-complaint-options',
  templateUrl: './complaint-options.component.html',
  styleUrls: ['./complaint-options.component.scss'],
})
export class ComplaintOptionsComponent implements OnInit {
  title = 'Advanced complaints: Attach image and comment options';
  description =
    'Level up with an advanced complaint feature. Craft a personalized complaint flow. Customers upload images and comments on damaged items. You can tailor your complaint flow to immediately refund your customer for the damaged item or have them send it back.';
  isLoading = false;
  isSaving = false;
  includeInstructions = false;
  showContent = false;
  boldWords = [
    'mandatory',
    'optional',
    'Receive',
    'issue a refund straight away',
    'The customer can keep the damaged item',
    'I want to receive',
    'they receive a refund straight away',
    'DON’T allow',
  ];

  compensationOption = CompensationOption;

  imageOptions = [
    'Make it optional for a customer to upload a picture of the item',
    'Make it mandatory for a customer to upload a picture of the item',
    'DON’T allow a customer to upload a picture of a returned item',
  ];

  summaryOptions = [
    'Make it optional for a customer to write a short summary about the item',
    'Make it mandatory for a customer to write a short summary about the item',
    'DON’T allow a customer to write a short summary',
  ];

  flowOptions = [
    'Issue a refund/new item for a complaint straight away without getting the complaint item back first',
    'Issue a refund/new item for a complaint only after receiving the complaint item back',
  ];

  bannerStats = {
    text: 'Please make sure you add complaint reasons in the Return Reasons block.',
    icon: '',
    imgIcon: '',
    type: 'Note',
    size: 'small',
    showClose: true,
    boldWords: ['Return Reasons'],
  };

  selectedValue = '';

  nrOfPhotosOptions = [
    '1 photo',
    '2 photos',
    '3 photos',
    '4 photos',
    '5 photos',
  ];
  minNumberOfPhotos = [...this.nrOfPhotosOptions];
  maxNumberOfPhotos = [...this.nrOfPhotosOptions];

  complaintSettings: ComplaintSettings = new ComplaintSettings();
  PremiumOptions = PremiumOptions;
  currentPricePlan: PricePlan = new PricePlan();

  constructor(
    private complaintSettingsService: ComplaintSettingsService,
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const pricePlanSettings =
      this.pricePlanSettingsService.getSubjectSelectedPricePlan();
    const complaintOptions = this.complaintSettingsService.getComplaintSettingsSubject();

    combineLatest([pricePlanSettings, complaintOptions]).subscribe({
      next: ([pricePlanSettings, complaintOptions]: [
        PricePlan,
        ComplaintSettings
      ]) => {
        if (complaintOptions.deadline) {
          this.currentPricePlan = pricePlanSettings;
          this.complaintSettings = complaintOptions;
          this.isLoading = false;
        }
      },
      error: () => {
        this.isLoading = false;
        this.errorService.showErrorSnackBar('Failed to load settings.');
      },
    });
  }

  setShouldBeReturned(selection: boolean): void {
    if (selection) {
      this.complaintSettings.should_item_be_returned = true;
    } else {
      this.complaintSettings.should_item_be_returned = false;
      this.complaintSettings.should_send_instant_refund = true;
    }

    this.saveSettings();
  }

  getSelectedFlow(): string {
    return this.complaintSettings.should_send_instant_refund
      ? this.flowOptions[0]
      : this.flowOptions[1];
  }

  setSelectedRequest(selection: string): void {
    const enumArray: string[] = Object.values(
      this.complaintSettings.compensation_options
    );
    if (
      selection === this.complaintSettings.compensation_options[0] &&
      !enumArray.includes(selection)
    ) {
      this.complaintSettings.compensation_options.push(
        CompensationOption.Refund
      );
    }
  }

  getSelectedRequest(): string | undefined {
    const selectedItem = this.complaintSettings.compensation_options.find(
      (x) => x === this.compensationOption.Refund
    );

    return selectedItem;
  }

  setSelectedFlow(selection: string): void {
    selection === this.flowOptions[0]
      ? (this.complaintSettings.should_send_instant_refund = true)
      : (this.complaintSettings.should_send_instant_refund = false);

    this.saveSettings();
  }

  getSelectedImage(): string {
    switch (this.complaintSettings.image_upload_option) {
      case PremiumOptions.Optional:
        return this.imageOptions[0];
      case PremiumOptions.Mandatory:
        return this.imageOptions[1];
      case PremiumOptions.NotAllowed:
        return this.imageOptions[2];
    }
  }

  getSelectedSummary(): string {
    switch (this.complaintSettings.summary_option) {
      case PremiumOptions.Optional:
        return this.summaryOptions[0];
      case PremiumOptions.Mandatory:
        return this.summaryOptions[1];
      case PremiumOptions.NotAllowed:
        return this.summaryOptions[2];
    }
  }

  setSelectedImage(selection: string): void {
    switch (selection) {
      case this.imageOptions[0]:
        this.complaintSettings.image_upload_option = PremiumOptions.Optional;
        break;
      case this.imageOptions[1]:
        this.complaintSettings.image_upload_option = PremiumOptions.Mandatory;
        break;
      case this.imageOptions[2]:
        this.complaintSettings.image_upload_option = PremiumOptions.NotAllowed;
        break;
    }

    this.saveSettings();
  }

  setSelectedSummary(selection: string): void {
    if (selection === this.summaryOptions[0]) {
      this.complaintSettings.summary_option = PremiumOptions.Optional;
    }
    if (selection === this.summaryOptions[1]) {
      this.complaintSettings.summary_option = PremiumOptions.Mandatory;
    }
    if (selection === this.summaryOptions[2]) {
      this.complaintSettings.summary_option = PremiumOptions.NotAllowed;
    }

    this.saveSettings();
  }

  setMinPhotos(selection: string): void {
    this.complaintSettings.min_number_of_images = parseInt(selection);
    this.saveSettings();
  }

  setMaxPhotos(selection: string): void {
    this.minNumberOfPhotos = this.nrOfPhotosOptions.filter(
      (x) => x.split(' ')[0].trim() < selection
    );
    this.complaintSettings.max_number_of_images = parseInt(selection);
    if (
      this.complaintSettings.min_number_of_images >
      this.complaintSettings.max_number_of_images
    ) {
      this.complaintSettings.min_number_of_images =
        this.complaintSettings.max_number_of_images;
    }
    this.saveSettings();
  }
  getPhotos(type: string): string {
    return type === 'min'
      ? this.appendSuffix(this.complaintSettings.min_number_of_images.toString())
      : this.appendSuffix(
          this.complaintSettings.max_number_of_images.toString()
        );
  }

  appendSuffix(text: string): string {
    return text.charAt(0) === '1'
      ? text.concat(' photo')
      : text.concat(' photos');
  }

  toggleInstructions(state: boolean): void {
    this.complaintSettings.has_image_instructions = state;
    this.saveSettings();
  }

  enableComplaints() {
    this.complaintSettings.is_enabled = !this.complaintSettings.is_enabled;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }

  goToPage(elementID: string) {
    setTimeout(() => {
      document.getElementById(elementID)?.scrollIntoView({
        behavior: 'smooth',
      });
    });
  }
}
