import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './authentication/auth-interceptor';
import { ComponentsModule } from './components/components.module';
import { HeaderComponent } from './header/header.component';
import { ReturnsModule } from './returns/returns.module';
import { SettingsModule } from './settings/settings.module';
import { CustomDateFormat } from './shared/pipes/custom-date-format-pipe';
import { SharedModule } from './shared/shared.module';
import { StartComponent } from './start/start.component';
import { UpsActivationModule } from './ups-activation/ups-activation.module';
import { BannerComponent } from './components/banner/banner.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';

@NgModule({
  declarations: [AppComponent, HeaderComponent, StartComponent, BannerComponent, CountdownTimerComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SettingsModule,
    ReturnsModule,
    UpsActivationModule,
    ComponentsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MatSnackBar,
    DatePipe,
    CustomDateFormat,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
