import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from 'src/app/components/modal/modal.service';
import { Country } from 'src/app/settings/models/country-model';
import { ShippingLocation } from 'src/app/settings/models/shipping-location.model';
import { ShopifySettings } from 'src/app/settings/models/shopify-settings.model';
import { WarehouseSettings } from 'src/app/settings/models/warehouse-settings.model';
import { OnboardingStage } from 'src/app/shared/enums/onboarding-stage.enum';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { WarehouseSettingsService } from 'src/app/shared/services/settings/warehouse-settings.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  isValid = false;
  disabled = false;
  onBoardingCompleted = false;
  menuOpened = false;
  showDetails = false;
  selectedId = '';

  title = 'Return & restock locations';
  description =
    'Select one return and one restock address by editing your location. You can also add additional locations if you have more than one.';
  note =
    'Restock address can only be set on the address you use in your Shopify account.';

  companyName = '';
  warehouseSettings: WarehouseSettings = new WarehouseSettings();
  onboardingStatus = OnboardingStage.Uncompleted;

  activeLocation: ShippingLocation = new ShippingLocation();
  uneditedRestockId: number | undefined;
  uneditedSendFromId: number | undefined;
  oldLocationId: string | undefined = '';
  locations: ShippingLocation[] = [];
  countries: Country[] = [];

  locationForm = this.formBuilder.group({
    receiver: ['', [Validators.required, Validators.maxLength(100)]],
    door_number: ['', [Validators.maxLength(100)]],
    city: ['', [Validators.required, Validators.maxLength(80)]],
    additional_instructions: ['', [Validators.maxLength(35)]],
    is_restock_to_location_id: [false],
    is_default_shipping_location: [false],
    is_send_from_location_id: [false],
    street: ['', [Validators.required, Validators.maxLength(100)]],
    zip_code: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
    country_code: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]]
  });

  constructor(
    private modalService: ModalService,
    private errorService: ErrorService,
    private countryService: CountriesService,
    private shopifySettingsService: ShopifySettingsService,
    private locationSettingsService: LocationSettingsService,
    private accountSettingsService: AccountSettingsService,
    private warehouseSettingsService: WarehouseSettingsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.shopifySettingsService
      .getSubjectShopifySettings()
      .subscribe((res: ShopifySettings) => {
        this.onboardingStatus = res.onboarding_stage;
    });
    
    this.locationSettingsService
      .getSubjectLocationSettings()
      .subscribe((locations: ShippingLocation[]) => {
        this.locations = locations;
        if (locations.length) {
          this.isLoading = false;
          const restockLocation = locations.find(location => location.is_restock_to_location_id);
          const returnLocation = locations.find(location => location.is_default_shipping_location);
          this.onBoardingCompleted = !!(restockLocation && returnLocation);
        }
    });

    this.countryService
      .getCountriesSubject()
      .subscribe((countries) => {
      this.countries = countries;
    });

    this.accountSettingsService
      .getSubjectAccountSettings()
      .subscribe((accountSettings) => {
        this.companyName = accountSettings.company_name;
    });

    this.warehouseSettingsService
      .getSubjectWarehouseSettings()
      .subscribe((warehouseSettings) => {
        this.warehouseSettings = warehouseSettings;
      })
  }

  selectItem(event: any): void {
    this.locationForm.controls['country_code']?.setValue(event.target.value, {
      onlySelf: true,
    });
  }

  toggleMenu(event: MouseEvent): void {
    event.preventDefault();
    document.addEventListener('click', (event) => {
      const menuArea = document.getElementById('menu');
      const clickedArea = event.target;

      clickedArea === menuArea
        ? (this.menuOpened = true)
        : (this.menuOpened = false);
    });
  }

  public checkShopifyLocation(
    activeLocation: ShippingLocation
  ): void | boolean {
    if (activeLocation.shopify_location_id) {
      !activeLocation.zip_code ||
      !activeLocation.city ||
      !activeLocation.street ||
      !activeLocation.country_code
        ? (this.disabled = true)
        : (this.disabled = false);
      return this.disabled;
    }
  }

  getLocations(): ShippingLocation[] {
    return this.locations ?? [];
  }

  updateLocationBoolean(locationType: string): void {
    let locationProperty: keyof ShippingLocation;
    let warehouseProperty: keyof WarehouseSettings | undefined;

    switch (locationType) {
      case 'restock':
        locationProperty = 'is_restock_to_location_id';
        warehouseProperty = 'restock_to_warehouse_id';
        break;
      case 'sendFrom':
        locationProperty = 'is_send_from_location_id';
        warehouseProperty = 'send_from_warehouse_id';
        break;
    
      default:
        locationProperty = 'is_default_shipping_location';
        warehouseProperty = undefined;
        break;
    }

    if (locationType === 'restock') {
      locationProperty = 'is_restock_to_location_id'
    }
    else if (locationType === 'sendFrom') {
      locationProperty = 'is_send_from_location_id';
    }

    if (this.activeLocation[locationProperty]) {
      this.locations.forEach((location: ShippingLocation) => {
        (location[locationProperty] as boolean) = false
      });
      (this.activeLocation[locationProperty] as boolean) = true;
      if (warehouseProperty != undefined) {
        (this.warehouseSettings[warehouseProperty] as number) = this.activeLocation.shopify_location_id;
      }
    }
  }

  toggleDetails(locationId: string | undefined): void {
    this.selectedId = locationId ?? '';
    this.showDetails = !this.showDetails;
  }

  displayDetails(location: ShippingLocation): boolean {
    if (location.additional_instructions || location.door_number) {
      return this.showDetails && this.selectedId === location.id;
    }
    return false;
  }

  editLocation(location: ShippingLocation): void {
    this.activeLocation = location;

    this.storeUnchangedValues();
    this.checkShopifyLocation(location);
    this.locationForm.patchValue(this.activeLocation);

    this.modalService.open('location-modal');
  }

  storeUnchangedValues(): void {
    this.uneditedRestockId = 
      this.locations.find(
        location => location.is_restock_to_location_id
      )?.shopify_location_id;

    this.uneditedSendFromId = 
      this.locations.find(
        location => location.is_send_from_location_id
      )?.shopify_location_id;

    this.oldLocationId =
      this.locations.find(
        location => location.is_default_shipping_location
      )?.id;
  }

  createLocation(): void {
    this.activeLocation = new ShippingLocation();

    this.activeLocation.receiver = this.companyName;
    this.disabled = false;
    this.storeUnchangedValues();
    this.locationForm.patchValue(this.activeLocation);

    this.modalService.open('location-modal');
  }

  cancelEdit(): void {
    this.locationForm.reset();
  }

  saveLocation(): void {
    const locationToStore: ShippingLocation = {
      receiver: this.locationForm.value.receiver,
      street: this.locationForm.value.street,
      door_number: this.locationForm.value.door_number,
      city: this.locationForm.value.city,
      zip_code: this.locationForm.value.zip_code,
      country_code: this.locationForm.value.country_code,
      additional_instructions: this.locationForm.value.additional_instructions,
      is_restock_to_location_id: this.locationForm.value.is_restock_to_location_id,
      is_default_shipping_location: this.locationForm.value.is_default_shipping_location,
      is_send_from_location_id: this.locationForm.value.is_send_from_location_id,
      shopify_location_id: this.activeLocation.shopify_location_id,
      id: this.activeLocation.id
    };

    if (this.activeLocation.id) {
      const currentIndex = this.locations.findIndex(location => location.id === this.activeLocation.id);
      this.locations[currentIndex] = locationToStore;
    }

    this.activeLocation = locationToStore;
    const editedDefaultLocation =
      !!this.oldLocationId && (this.oldLocationId !== this.activeLocation.id);
    
    this.isSaving = true;

    if (this.activeLocation.is_restock_to_location_id) {
      this.updateLocationBoolean('restock');
    }
    if (this.activeLocation.is_send_from_location_id) {
      this.updateLocationBoolean('sendFrom');
    }
    this.warehouseSettingsService.setWarehouseSettingsSubject(this.warehouseSettings);

    if (this.activeLocation.is_default_shipping_location) {
      this.updateLocationBoolean('default');

      if (editedDefaultLocation) {
        this.locationSettingsService
          .getIsUsedDefaultLocation(this.oldLocationId ?? '')
          .subscribe({
            next: (isInUse: boolean) => {
              if (isInUse) {
                this.modalService.open('change-default');
                this.isSaving = false;
                return;
              }
              this.saveSettings(false);
            },
            error: () => {
              this.errorService.showErrorSnackBar(
                'Unable to set default location'
              );
              this.isSaving = false;
            },
          });
      } else {
        this.saveSettings(false);
      }
      return;
    }

    if (!this.activeLocation.id) {
      if (
        !this.activeLocation.is_default_shipping_location ||
        !editedDefaultLocation
      ) {
        this.saveSettings(false);
        return;
      }
    }

    this.saveSettings(false);
  }

  removeLocation(location: ShippingLocation): void {
    this.isSaving = true;
    const index: number = this.locations.indexOf(location) ?? -1;

    if (index === -1) {
      this.isSaving = false;
      this.errorService.showErrorSnackBar("Something went wrong, please try again!");
      return;
    }

    this.locationSettingsService
      .removeLocation(location.id ?? '')
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.locations.splice(index, 1);
        },
        error: (error) => {
          this.isSaving = false;
          if (error.error.code === 'LocationInUseError') {
            this.errorService.showErrorSnackBar("This location is used by one or more of your integrations. Please switch address before deleting this one.");
            return;
          }
          this.errorService.showErrorSnackBar("Failed to delete return location");
        }
      });
  }

  saveSettings(updateIntegrations: boolean): void {
    this.isSaving = true;
    
    if (!this.activeLocation.id) {
      this.locations.push(this.activeLocation);
    }

    this.locationSettingsService
      .saveLocationSettings(this.locations, updateIntegrations)
      .subscribe({
        next: (locations) => {
          this.isSaving = false;
          this.modalService.dismiss();
          this.locations = locations;
          this.locationSettingsService.setLocationSettingsSubject(locations);
        },
        error: () => {
          this.isSaving = false;
          this.errorService.showErrorSnackBar('Failed to save locations.');
        }
    });
  }

  handleDefaultPrompt(type: string): void {
    if (type === 'close') {
      this.saveSettings(false);
      this.modalService.close("change-default");
      return;
    }

    this.saveSettings(true);
    this.modalService.close("change-default");
  }

  formValid(): boolean {
    return (this.locationForm?.valid ?? false) && 
            this.activeLocation.country_code.length === 2;
  }
}
