import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { Item } from 'src/app/models/item.model';
import { Label } from 'src/app/models/label.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { Shipping } from 'src/app/models/shipping.model';
import { SlipInfo } from 'src/app/models/slip-info.model';
import { filterOnStatus } from 'src/app/returns/filter-on-status';
import { ReturnsService } from 'src/app/returns/returns.service';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { FilterStatusTypeEnum } from 'src/app/shared/enums/filter-status-type.enum';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { SelectedShippingOptionsEnum } from 'src/app/shared/enums/selected-shipping-options.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import {
  complaintReturnItems,
  hasComplaintReason
} from '../../complaint-return-items';
import { shippings } from '../../shippings';
import { ReturnShippingService } from './return-shipping.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';

@Component({
  selector: 'app-return-shipping',
  templateUrl: './return-shipping.component.html',
  styleUrls: ['./return-shipping.component.scss'],
})
export class ReturnShippingComponent implements OnInit {
  @Input()
  errorAutoGeneratingSlip = false;

  autoSlip = false;
  automatic = false;
  printedSlips = false;
  return: ReturnRequest = new ReturnRequest();
  selectedReturnShipping: Shipping = new Shipping();
  ReturnStatusEnum = ReturnStatusEnum;
  isShowingInfo = false;
  shippingOptions: Shipping[] = [
    {
      name: SelectedShippingOptionsEnum.AttachShippingSlip,
      type: SelectedShippingOptionsEnum.AttachShippingSlip,
      info: '',
      track: '',
      logo: '',
      provider: '',
    },
    {
      name: SelectedShippingOptionsEnum.NoShippingSlip,
      type: SelectedShippingOptionsEnum.NoShippingSlip,
      info: 'Use this option if you include a return slip with the original order. As customers may lose the slip, the safer option is to send a return slip again.',
      track: 'You have not attached any slip with this return.',
      logo: '',
      provider: '',
    },
  ];
  wrongSlipType = false;
  selectedFile: any;
  files: any[] = [];
  returnSlips: Label[] = [];
  tracking_id = '';
  SelectedShippingOptionsEnum = SelectedShippingOptionsEnum;
  shippingIntegrationInfo: ShippingIntegration[] = [];
  slipURL = '';
  loadedReturnId = '';
  isShippingIntegrationLoading = false;

  informationText = '';
  shipping_carrier = '';

  filterOnStatus = filterOnStatus;
  hasComplaintReason = hasComplaintReason;
  complaintReturnItems = complaintReturnItems;

  isLoading = false;
  isIncoming = false;
  isPackageReceived = false;
  isShippingSlipSkipped = false;
  isReturnRequested = false;
  isPackageSentDropOff = false;
  isRequestReceived = false;
  complaintSettings: ComplaintSettings = new ComplaintSettings();
  returnSettings: ReturnSettings = new ReturnSettings();
  isNotReturnRequested = false;
  isWithShippingSlip = false;
  trackingUrl = '';

  constructor(
    private returnShippingService: ReturnShippingService,
    private returnService: ReturnsService,
    private shippingIntegrationService: ShippingIntegrationService,
    private modalService: ModalService,
    private complaintSettingsService: ComplaintSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.getComplaintSettings();
    this.getReturnSettings();
    this.errorAutoGeneratingSlip = false;
  }

  getShippingIntegrations(): void {
    this.clearShippingSelection();
    if (this.return.id && this.return.id !== this.loadedReturnId) {
      this.isShippingIntegrationLoading = true;
      this.shippingIntegrationInfo = [];
      this.shippingIntegrationService
        .getValidShippingIntegrationsForReturnOrder(this.return.id)
        .subscribe({
          next: (res: ShippingIntegration[]) => {
            this.shippingIntegrationInfo = res;
            const shippingOptionsToPush = shippings.filter(
              (shipping) =>
                this.shippingIntegrationInfo.some(
                  (integrationInfo) =>
                    integrationInfo.shipping_package_type.toString() ===
                    shipping.type.toString()
                ) ||
                [
                  SelectedShippingOptionsEnum.AttachShippingSlip,
                  SelectedShippingOptionsEnum.NoShippingSlip,
                ].includes(shipping.type)
            );
            this.shippingOptions = shippingOptionsToPush;
            this.setDefaultShippingOption();
            this.shippingIntegrationService.setActiveIntegrationsSubject(res);
            this.isShippingIntegrationLoading = false;
          },
          error: () => {
            this.errorService.showErrorSnackBar(
              'Failed to load shipping integrations'
            );
            this.isShippingIntegrationLoading = false;
          },
        });
      this.loadedReturnId = this.return.id;
    }
  }

  clearShippingSelection(): void {
    this.returnShippingService.setSelectedReturnShipping(new Shipping());
    this.selectedReturnShipping = new Shipping();
  }

  getSelectedReturnInfo(): void {
    this.returnService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
          if (this.return.id !== res.id) {
            this.removeFile();
          }
  
          this.return = res;
          this.assignStatus();
          this.getShippingIntegrations();
  
          if (res.status === ReturnStatusEnum.ManualShippingSlipAttached) {
            this.returnShippingService
              .getManualSlip(this.return.id)
              .subscribe((resSlip: any) => {
                if (resSlip) {
                  this.tracking_id = resSlip.id;
                  this.shipping_carrier = resSlip.shipping_carrier;
                  this.selectedReturnShipping =
                    shippings.find(
                      (shi) => shi.name === resSlip.shipping_package_type
                    ) ?? shippings[0];
                  this.setInfo();
                  this.returnSlips = resSlip.labels;
                }
              });
          } else if (
            [
              ReturnStatusEnum.AwaitingDropOff,
              ReturnStatusEnum.PackageSent,
              ReturnStatusEnum.PackageReceived,
            ].includes(res.status)
          ) {
            this.returnShippingService
              .getSlipInfo(this.return.id)
              .subscribe((resSlip: SlipInfo) => {
                if (resSlip) {
                  this.tracking_id = resSlip.tracking_id;
                  this.trackingUrl = resSlip.tracking_url;
                  this.shipping_carrier = resSlip.shipping_carrier;
                  this.selectedReturnShipping =
                    shippings.find(
                      (shi) => shi.type === resSlip.shipping_package_type
                    ) ?? shippings[0];
                  this.setInfo();
                  this.returnSlips = resSlip.labels;
                }
              });
          }
  
          this.returnShippingService
            .getReturnShippingSlip()
            .subscribe((resSlip: SlipInfo) => {
              this.tracking_id = resSlip.id;
              this.returnSlips = resSlip.labels;
            });
      });
  }

  setDefaultShippingOption() {
    if (
      !this.printedSlips ||
      this.return.status === ReturnStatusEnum.ShippingSlipSkipped
    ) {
      this.shippingOptions.find((option) => {
        if (option.name === 'No shipping slip') {
          this.selectedReturnShipping = option;
          this.returnShippingService.setSelectedReturnShipping(option);
        }
      });
    }
  }

  selectReturnShipping(value: Shipping): void {
    this.selectedReturnShipping = value;
    this.returnShippingService.setSelectedReturnShipping(value);
  }

  showInfo(): void {
    this.isShowingInfo = !this.isShowingInfo;
  }

  onFileDropped($event: any): void {
    this.prepareFilesList($event);
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(e: Event): void {
    const files = (e.target as any).files;
    if (
      files[0].type !== 'image/png' &&
      files[0].type !== 'image/jpeg' &&
      files[0].type !== 'application/pdf'
    ) {
      this.wrongSlipType = true;
    } else {
      this.wrongSlipType = false;
      this.selectedFile = files;
      this.returnShippingService.setSelectedFiles(files);
      this.prepareFilesList(files);

      this.readURL(e, files[0].type);
    }
  }

  readURL(event: any, type: string): void {
    if (type !== 'application/pdf') {
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.slipURL = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      this.slipURL = URL.createObjectURL(event.target.files[0]);
    }
  }

  //Converts encoded PDFs
  convertBase64PDF(base64 = ''): Blob {
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: 'application/pdf' });
  }

  previewSlip(labelId: string): void {
    const slip = this.returnSlips.find((slip) => slip.id === labelId);
    if (!slip) return;
    if (slip.type === 'PDF' || slip.file_extension === '.pdf') {
      window.open(
        URL.createObjectURL(this.convertBase64PDF(slip.encoded_value))
      );
    } else {
      this.slipURL = 'data:image/png;base64,' + slip.encoded_value;
      this.modalService.open('shipping-slip');
    }
  }

  previewUploadedSlip(): void {
    if (this.selectedFile[0].type === 'application/pdf') {
      window.open(this.slipURL);
    } else {
      this.modalService.open('shipping-slip');
    }
  }

  setInfo(): void {
    this.informationText = 'You have not attached any slip with this return.';
    if (
      this.selectedReturnShipping.type !==
      SelectedShippingOptionsEnum.NoShippingSlip
    ) {
      this.informationText = `Here is your ${this.selectedReturnShipping.provider} tracking number <a target="_blank" href=${this.trackingUrl}>${this.tracking_id}</a>`;
    }
  }

  getDocumentName(label: Label, index: number): string {
    const documentNumber = index + 1;
    const labelType = label.label_type.toLowerCase();
    const trackingId = this.tracking_id;
    const fileType = label.type.toLowerCase();

    return `${documentNumber}_${labelType}_${trackingId}.${fileType}`;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>): void {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  removeFile(): void {
    this.files = [];
  }

  isAutoSlipActivated(): boolean {
    if (
      this.returnSettings.autogenerated_slips &&
      this.return.status === ReturnStatusEnum.AwaitingShippingSlip
    ) {
      return false;
    }
    return this.returnSettings.autogenerated_slips;
  }

  areAllItemsUnselected(): boolean {
    const selectedItems = this.return.items.filter((r) => r.selected === true);
    return selectedItems.length === 0;
  }

  getInformationAlertText() {
    if (this.areAllItemsUnselected()) {
      return 'Since no items are approved in this order – it will be rejected straight away, so there is no need to select a shipping carrier.';
    }
    return this.selectedReturnShipping.info;
  }

  shouldDisplayInformationAlert(): boolean {
    if (!this.informationText) {
      this.informationText = 'You have not attached any slip with this return.';
    }
    return (
      ((this.isPackageSentDropOff || this.isPackageReceived) &&
        (this.trackingUrl && this.tracking_id ? true : false)) ||
      this.isShippingSlipSkipped
    );
  }

  displayTitle(): boolean {
    return (
      this.isIncoming ||
      (this.isPackageReceived && this.shouldDisplayInformationAlert()) ||
      (this.isShippingSlipSkipped && (!this.automatic || this.printedSlips)) ||
      (this.isReturnRequested &&
        this.complaintReturnItems(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        )) ||
      (this.isReturnRequested && !this.hasComplaintReason(this.return.items))
    );
  }

  displaySlipInput(): boolean {
    return (
      this.isReturnRequested &&
      (!this.isAutoSlipActivated() || this.errorAutoGeneratingSlip)
    );
  }

  displayReturnAlert(): boolean {
    if (!this.selectedReturnShipping?.info && !this.areAllItemsUnselected()) {
      return false;
    }
    return this.isShowingInfo && this.isReturnRequested;
  }

  displaySlipType(): boolean {
    return (
      (this.selectedReturnShipping?.type !==
        SelectedShippingOptionsEnum.AttachShippingSlip &&
        this.automatic &&
        this.isPackageSentDropOff) ||
      (this.isShippingSlipSkipped && (!this.automatic || this.printedSlips))
    );
  }

  displayLoader(): boolean {
    if(this.displaySlipInput()) {
      return this.isShippingIntegrationLoading;
    }
    return this.isLoading 
  }

  allItemsDeclined(): boolean {
    return this.return.items.every(
      (item: Item) =>
        !item.decision || item.status === ReturnItemStatusEnum.RefundDenied
    );
  }

  assignStatus(): void {
    this.isIncoming = this.filterOnStatus(
      FilterStatusTypeEnum.incoming,
      true,
      this.return.status
    );
    this.isPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.packageReceived,
      true,
      this.return.status
    );
    this.isShippingSlipSkipped = this.filterOnStatus(
      FilterStatusTypeEnum.shippingSlipSkipped,
      true,
      this.return.status
    );
    this.isReturnRequested = this.filterOnStatus(
      FilterStatusTypeEnum.returnRequested,
      true,
      this.return.status
    );
    this.isPackageSentDropOff = this.filterOnStatus(
      FilterStatusTypeEnum.packageSentDropff,
      true,
      this.return.status
    );
    this.isRequestReceived = this.filterOnStatus(
      FilterStatusTypeEnum.requestReceived,
      true,
      this.return.status
    );
    this.isNotReturnRequested = this.filterOnStatus(
      FilterStatusTypeEnum.returnRequested,
      false,
      this.return.status
    );
    this.isWithShippingSlip = this.filterOnStatus(
      FilterStatusTypeEnum.withShippingSlip,
      true,
      this.return.status
    );
  }

  getComplaintSettings(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((complaintOptions) => {
        this.complaintSettings = complaintOptions;
      });
  }

  getReturnSettings(): void {
    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe((returnSettings: ReturnSettings) => {
        this.returnSettings = returnSettings;
          this.autoSlip = returnSettings.autogenerated_slips;
          this.automatic = returnSettings.automatically_accept_requests;
          this.printedSlips = returnSettings.printed_return_slips;

          this.getSelectedReturnInfo();
          this.returnService.getLoading().subscribe((res) => {
            this.isLoading = res;
          });
      });
  }

  displayAttachedFile(): boolean {
    return ((this.selectedReturnShipping?.type ===
      SelectedShippingOptionsEnum.AttachShippingSlip &&
      this.isNotReturnRequested) ||
      this.isWithShippingSlip) &&
      this.returnSlips.length > 0
      ? true
      : false;
  }

  isResolved(): boolean {
    return (
      this.return.status === ReturnStatusEnum.RefundPartiallyApproved ||
      this.return.status === ReturnStatusEnum.RequestDenied ||
      this.return.status === ReturnStatusEnum.RefundDenied ||
      this.return.status === ReturnStatusEnum.RefundApproved
    );
  }
}
