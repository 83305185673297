<app-modal id="not-activated" [dismissable]="false" size="small">
  <h1 class="font-24 text-almost-black font-700">
    Leaving the page already? The information won’t be saved.
  </h1>

  <div class="modal-wrap">
    <p class="font-normal font-14 text-almost-black">
      It looks like you are leaving the page without finishing the carrier
      activation. All the information will be lost and you will need to start
      again. Do you wish to stay on this page?
    </p>

    <div class="d-flex">
      <button class="btn btn-secondary btn-md mr-16" (click)="leavePage()">
        Leave
      </button>
      <button class="btn btn-primary btn-md mr-16" (click)="closeModal()">
        Stay
      </button>
    </div>
  </div>
</app-modal>
