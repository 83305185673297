import { Component, OnInit } from '@angular/core';
import { combineLatest, first } from 'rxjs';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { SpecialDeadline } from 'src/app/settings/models/special-deadline.model';
import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-return-deadline',
  templateUrl: './return-deadline.component.html',
  styleUrls: ['./return-deadline.component.scss'],
})
export class ReturnDeadlineComponent implements OnInit {
  isLoading = true;
  isSaving = false;
  minimumDays = false;
  exchangeErrorMessage = false;
  showInformation = false;
  showComplaintMessage = false;
  title = 'Request deadline';
  description =
    'You can limit the time that a customer has to send in any request from the time the order was fulfilled.';
  linkToPolicyForm = `https://${environment.appLink}/returnform/SE`;
  returnSettings: ReturnSettings = new ReturnSettings();
  pricePlan: PricePlan = new PricePlan();

  minimumComplaintLink =
    'https://europa.eu/youreurope/citizens/consumers/shopping/guarantees-returns/index_en.htm#:~:text=Guarantees%20for%20faulty%20goods,-Free%20of%20charge&text=You%20always%20have%20the%20right,may%20give%20you%20extra%20protection';

  warning =
    'According to Swedish law, the minimum return window has to be at least 14 days';

  information =
    "We allow an additional five (5) days beyond the initial deadline to account for unforeseen circumstances, like shipment delays. For instance, if you input '15', orders shipped up to 20 days ago remain eligible for returns.";

  options = Object.values(DeadlineTimePeriod);
  requestType = ReturnReasonType;
  complaintSettings: ComplaintSettings = new ComplaintSettings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();

  deadlineDisabledText =
    'Request deadline for complaints and exchanges is not yet available but it is coming out soon in the future release.';

  specialDeadlines: SpecialDeadline[] = [];

  constructor(
    private errorService: ErrorService,
    private complaintSettingsService: ComplaintSettingsService,
    private exchangeSettingsService: ExchangeSettingsService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.getComplaintsSettings();
    this.getExchangeSettings();

    this.isLoading = true;

    const returnSettings = this.returnSettingsService.getSubjectReturnSettings();
    const pricePlanSettings =
      this.pricePlanSettingsService.getSubjectSelectedPricePlan();

    combineLatest([returnSettings, pricePlanSettings]).subscribe(
      ([returnSettings, pricePlanSettings]) => {
        if (this.returnSettingsService.hasReturnSettingsLoaded()) {
          this.isLoading = false;
          this.returnSettings = returnSettings;
          this.pricePlan = pricePlanSettings;
          this.isLoading = false;
        }
      }
    );
  }

  toggleInformation(type: ReturnReasonType) {
    this.information =
      type === ReturnReasonType.Return
        ? 'We automatically add 5 days on top of the number of days you have selected to account for any unforeseeable circumstances such as shipment delays (i.e. if entered “15”, then orders shipped 20 days ago would still be returnable).'
        : 'For complaints inside the EU, a customer has the right to a minimum 2-year guarantee at no cost, regardless of whether the goods were bought online, in a shop or by mail order. This 2-year guarantee is their minimum right, however national rules in each country may give them extra protection. We urge you to be open to complaints for at least 1 year.';

    this.showInformation = !this.showInformation;
  }

  getDeadline(requestType: ReturnReasonType): string {
    if (requestType === ReturnReasonType.Exchange) {
      return (this.exchangeSettings?.deadline ?? 0).toString();
    }
    if (requestType === ReturnReasonType.Complaint) {
      return (this.complaintSettings?.deadline ?? 0).toString();
    }
    return (this.returnSettings.deadline ?? 0).toString();
  }

  getDeadlineTimePeriod(requestType: ReturnReasonType): DeadlineTimePeriod {
    if (requestType === ReturnReasonType.Complaint) {
      return (
        this.complaintSettings?.deadline_time_period ?? DeadlineTimePeriod.Years
      );
    }

    if (requestType === ReturnReasonType.Exchange) {
      return (
        this.exchangeSettings?.deadline_time_period ?? DeadlineTimePeriod.Days
      );
    }

    return this.returnSettings.deadline_time_period ?? DeadlineTimePeriod.Days;
  }

  setDeadlineAndPeriod(
    value: any,
    isPeriod: boolean,
    requestType: ReturnReasonType
  ): void {
    if (
      (isPeriod && !Object.keys(DeadlineTimePeriod).includes(value)) ||
      (!isPeriod && (value.target.value.isNaN || value.target.value === ''))
    ) {
      return;
    }

    const period = isPeriod
      ? (value as DeadlineTimePeriod)
      : this.getDeadlineTimePeriod(requestType);
    const deadline = !isPeriod
      ? Number.parseInt(value.target.value)
      : Number.parseInt(this.getDeadline(requestType));

    if (
      requestType === ReturnReasonType.Complaint &&
      ((period === DeadlineTimePeriod.Days && deadline < 365) ||
        (period === DeadlineTimePeriod.Months && deadline < 12) ||
        (period === DeadlineTimePeriod.Years && deadline < 1))
    ) {
      this.toggleInformation(requestType);
      this.showComplaintMessage = true;
      return;
    }

    if (requestType === ReturnReasonType.Complaint) {
      this.showComplaintMessage = false;
      this.complaintSettings.deadline = deadline;
      this.complaintSettings.deadline_time_period = period;
      this.saveComplaintSettings();
      return;
    }

    if (requestType === ReturnReasonType.Exchange && deadline <= 0) {
      this.warning =
        'Please set a value larger than 0, otherwise exchanges will always be overdue';
      this.exchangeErrorMessage = true;
      return;
    }

    if (requestType === ReturnReasonType.Exchange) {
      this.exchangeErrorMessage = false;
      this.warning =
        'According to Swedish law, the minimum return window has to be at least 14 days';
      this.exchangeSettings.deadline = deadline;
      this.exchangeSettings.deadline_time_period = period;
      this.saveExchangeSettings();
      return;
    }

    if (deadline < 14 && period === DeadlineTimePeriod.Days) {
      this.minimumDays = true;
      return;
    }

    this.minimumDays = false;
    this.returnSettings.deadline = deadline;
    this.returnSettings.deadline_time_period = period;
    this.saveSettings();
  }

  saveSettings(): void {
    this.isSaving = true;

    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: (saved: boolean) => {
          if (saved) {
            this.isSaving = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
      });
  }

  saveComplaintSettings(): void {
    this.isSaving = true;

    this.complaintSettingsService
      .updateComplaintSettings(this.complaintSettings)
      .pipe(first((x) => x.deadline != 0))
      .subscribe({
        next: () => {
          this.complaintSettingsService.setComplaintSettingsSubject(
            this.complaintSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to save complaint settings'
          );
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }

  saveExchangeSettings(): void {
    this.isSaving = true;

    this.exchangeSettingsService
      .saveSettings(this.exchangeSettings)
      .pipe(first((x) => x.deadline != 0))
      .subscribe({
        next: () => {
          this.exchangeSettingsService.setSettings(this.exchangeSettings);
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to save exchange settings'
          );
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }

  getComplaintsSettings(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((complaintOptions) => {
        this.complaintSettings = complaintOptions;
      });
  }

  getExchangeSettings(): void {
    this.exchangeSettingsService
      .getExchangeSubject()
      .subscribe((exchangeSettings) => {
        this.exchangeSettings = exchangeSettings;
      });
  }

  isComplaintEnabled(): boolean {
    return this.complaintSettings.is_enabled;
  }

  isExchangesEnabled(): boolean {
    return this.exchangeSettings.is_enabled;
  }
}
