import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent {
  @Input()
  title = '';
  @Input()
  code = '';
  @Input()
  link = '';
}
