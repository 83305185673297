import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { SpecialDeadlineEnum } from 'src/app/shared/enums/special-deadline.enum';

export class SpecialDeadline {
  id? = '';
  start_date = new Date();
  end_date = new Date();
  name = '';
  deadline = 0;
  deadline_time_period: DeadlineTimePeriod = DeadlineTimePeriod.Days;
  status: SpecialDeadlineEnum = SpecialDeadlineEnum.Upcoming;
  request_type: ReturnReasonType = ReturnReasonType.Return;
}
