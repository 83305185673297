import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { PremiumReturnSettings } from 'src/app/settings/models/premium-return-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PremiumReturnSettingsService {
  private premiumReturnSettingsSubject: BehaviorSubject<PremiumReturnSettings> =
    new BehaviorSubject(new PremiumReturnSettings());
  private isLoading = false;
  constructor(private httpClient: HttpClient) {}

  public getPremiumReturnSettings(): Observable<PremiumReturnSettings> {
    if (
      this.premiumReturnSettingsSubject.value === null ||
      (!this.premiumReturnSettingsSubject.value.max_number_of_images &&
      !this.isLoading)
    ) {
      this.isLoading = true;
      this.httpClient.get<PremiumReturnSettings>(
        `${environment.apiUrl}/settings/premium-return`
      )
      .subscribe((settings) => {
        this.isLoading = false;
        this.premiumReturnSettingsSubject.next(settings);
      });
    }
    return this.premiumReturnSettingsSubject;
  }

  getPremiumReturnSettingsSubject(): Observable<PremiumReturnSettings> {
    return this.premiumReturnSettingsSubject.asObservable();
  }

  setPremiumReturnSettingsSubject(premiumReturnSettings: PremiumReturnSettings): void {
    this.premiumReturnSettingsSubject.next(premiumReturnSettings);
  }

  updatePremiumReturnSettings(
    premiumReturnSettings: PremiumReturnSettings
  ): Observable<PremiumReturnSettings> {
    return this.httpClient.put<PremiumReturnSettings>(
      `${environment.apiUrl}/settings/premium-return`,
      premiumReturnSettings
    );
  }
}
