import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { FormValues } from 'src/app/settings/models/form-values.model';
import {
  IntegrationPackageSupportedCountries,
  SupportedCountry,
} from 'src/app/settings/models/integration-package-supported-countries.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { UpsIntegrationInfo } from 'src/app/settings/models/ups-integration-info.model';
import { UpsIntegrationRequest } from 'src/app/settings/models/ups-integration-request.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { upsServices } from '../carrier-services';
import { IntegrationAlertService } from '../integration-alert.service';
import { UpsLogin } from 'src/app/settings/models/ups-login.model';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-ups',
  templateUrl: './ups.component.html',
  styleUrls: ['./ups.component.scss'],
})
export class UpsComponent implements OnInit {
  @Input()
  disabled = false;
  showPassword = false;
  isLoading = false;
  isSaving = false;
  title = 'UPS';
  description =
    'Autogenerate you returns slips easily within EU and the rest of the world.';
  showActivationSettings = false;
  showPayAsYouGoInfo = false;
  copiedLink = false;

  availableServices = upsServices;
  shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  shippingCarrier = ShippingCarrier;
  formValueChanged = false;

  serviceInformation: UpsIntegrationInfo = new UpsIntegrationInfo();

  supportedCountries = new Map<ShippingPackageType, SupportedCountry[]>();
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';
  returnAddressId = '';

  constructor(
    private modalService: ModalService,
    private shippingIntegrationsService: ShippingIntegrationService,
    private integrationAlertService: IntegrationAlertService,
    private authService: AuthenticationService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.getShippingIntegrationInfo();
    this.shippingIntegrationsService
      .getValuesChanged()
      .subscribe((res: FormValues) => {
        this.formValueChanged = res.ups;
      });

    this.getSupportedCountries();
  }

  getShippingIntegrationInfo(): void {
    this.isSaving = true;
    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            shippingIntegration.shipping_carrier === ShippingCarrier.Ups
        );
        if (
          this.shippingIntegrationInfo &&
          this.shippingIntegrationInfo.length > 0
        ) {
          this.shippingIntegrationInfo.forEach(
            (integration: ShippingIntegration) => {
              this.checkServices(integration);

              this.assignFormProperties(integration);
            }
          );
        }

        this.isSaving = false;
        this.isLoading = false;
        this.getRedirectParams();
      });
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  onSaveUpdate(isSaving: boolean): void {
    if (!isSaving) {
      const upsAlert = this.integrationAlertService.getAlertSectionForCarrier(ShippingCarrier.Ups);
      const activatingCarrier = !this.hasProccessStarted();

      if (activatingCarrier || upsAlert === AlertSectionType.Credentials) {
        this.shippingIntegrationsService
          .getUpsLoginUrl()
          .subscribe((upsLogin: UpsLogin) => {
            if (window.top) {
              window.top.location.href = upsLogin.login_url;
            }
          });
      }
      this.integrationAlertService.setAlertSectionForCarrier(
        ShippingCarrier.Ups,
        AlertSectionType.None
      );
      this.popupService.showMessageSnackBar('Integration info saved!');
    }
    this.isSaving = isSaving;
  }

  toggleActivation(value: boolean): void {
    this.showActivationSettings = value;
  }

  openInfoModal(): void {
    this.modalService.open('ups-services');
  }

  isInformationInvalid(): boolean {
    const invalidIntegration = this.shippingIntegrationInfo?.filter(
      (integration: ShippingIntegration) =>
        integration.status === IntegrationStatusEnum.ActivationError &&
        integration.shipping_carrier === ShippingCarrier.Ups
    );

    return invalidIntegration && invalidIntegration.length > 0 ? true : false;
  }

  checkServices(integration: ShippingIntegration): void {
    this.availableServices.forEach((service: AvailableService) => {
      if (
        integration.shipping_package_type === service.packageType &&
        integration.status !== IntegrationStatusEnum.Deactivated
      ) {
        service.checked = true;
      }
    });
  }

  assignFormProperties(integration: ShippingIntegration): void {
    const upsIntegration =
      integration.integration_info as UpsIntegrationRequest;
    this.serviceInformation = {
      clientID: upsIntegration.client_id ?? '',
      clientSecret: upsIntegration.client_secret ?? '',
      accountNumber: upsIntegration.account_number ?? '',
      phoneNumber: upsIntegration.phone_number ?? '',
      stateProvince: upsIntegration.state_province_code ?? '',
      orgNumber: upsIntegration.org_number ?? '',
      paperlessTrade: upsIntegration.paperless_trade ?? false,
      enabledCountries: new Map([
        [
          upsIntegration.shipping_package_type as ShippingPackageType,
          upsIntegration.enabled_origin_countries ?? [],
        ],
      ]),
    };
  }

  onValueChanged(): void {
    if (!this.formValueChanged) {
      this.formValueChanged = true;
      let previousValues: FormValues = new FormValues();

      this.shippingIntegrationsService
        .getValuesChanged()
        .subscribe((res: FormValues) => {
          previousValues = res;
        });
      this.shippingIntegrationsService.setValuesChanged({
        ...previousValues,
        ups: true,
      });
    }
    this.integrationAlertService.setAlertSectionForCarrier(
      ShippingCarrier.Ups,
      AlertSectionType.Credentials
    );
  }

  hasProccessStarted(): boolean {
    const startedIntegrations = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    return startedIntegrations && startedIntegrations.status ? true : false;
  }

  addMarketsToIntegration(
    enabledCountries: Map<ShippingPackageType, string[]>
  ): void {
    this.serviceInformation.enabledCountries = enabledCountries;
  }

  showAlert(): boolean {
    const alertType = this.integrationAlertService.getAlertSectionForCarrier(
      ShippingCarrier.Ups
    );
    return (
      alertType === AlertSectionType.Credentials && this.hasProccessStarted()
    );
  }

  getSupportedCountries(): void {
    this.shippingIntegrationsService
      .getIntegationPackageSupportedCountriesSubject()
      .subscribe((res: IntegrationPackageSupportedCountries[]) => {
        if (res && res.length > 0) {
          const availableCountries = res.filter(
            (shippingIntegration: IntegrationPackageSupportedCountries) =>
              shippingIntegration.shipping_carrier === ShippingCarrier.Ups
          );

          availableCountries.forEach((availableCountry) =>
            this.supportedCountries.set(
              availableCountry.id,
              availableCountry.countries
            )
          );
        }
      });
  }

  getRedirectParams(): string {
    let domain = this.authService.getDomainName();
    domain = domain.replace('.myshopify.com', '');
    return domain;
  }

  getStoreLink(): string {
    return this.getRedirectParams();
  }

  showCopiedBox(): void {
    this.copiedLink = true;
    setTimeout(() => {
      this.copiedLink = false;
    }, 2000);
  }

  paperlessTradeSelectedChange = (selectedValue: boolean) => {
    this.serviceInformation.paperlessTrade = selectedValue;
  };

  newLocation(id: string): void {
    this.returnAddressId = id;
  }

  getCheckedServicesIntegrationInfo(): ShippingIntegration[] {
    const checkedServices = this.availableServices.filter(
      (service) => 
        service.checked && 
        service.shipping_carrier === ShippingCarrier.Ups
    );
    const checkedPackageTypes = checkedServices.map(x => x.packageType);
    const checkedIntegrationInformations = this.shippingIntegrationInfo?.filter(
      (integrationInfo) => 
        checkedPackageTypes.includes(integrationInfo.shipping_package_type)
    );
    return checkedIntegrationInformations ?? []
  }
}
