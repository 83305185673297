<ng-container>
  <ng-container *ngFor="let notification of notifications">
    <ng-container *ngIf="notification.is_visible">
      <app-status-title [notification]="notification"></app-status-title>

      <app-alert
        *ngIf="
          showManualSlipAlert(notification) ||
          showReturnCreatedAlert(notification)
        "
        icon="help_outline"
        type="Information"
        size="large"
        text="{{ getAlertText(notification) }}"
      ></app-alert>

      <app-email-content [notification]="notification"></app-email-content>
    </ng-container>
  </ng-container>
</ng-container>
