import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createApp } from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccessScopeType } from '../shared/enums/access-scope-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private isAuthenticated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private shopifyAccessToken = '';
  private host = '';

  constructor(private http: HttpClient) {}

  public setAuthenticated(value: boolean): void {
    this.isAuthenticated.next(value);
  }

  public isClientAuthenticated(): Observable<boolean> {
    return this.isAuthenticated;
  }
  public async refreshToken(): Promise<string> {
    if (
      !environment.production ||
      window.location.href.includes('/ups-activation')
    ) {
      const fakeToken: string = await new Promise<string>((resolve) => {
        setTimeout(() => {
          resolve(environment.shopifyDevelopmentAccessToken);
        }, 1000);
      }).then((val: string) => {
        return val;
      });

      this.shopifyAccessToken = fakeToken;
      return fakeToken;
    } else {
      const app = createApp({
        apiKey: environment.apiKey,
        host: this.host,
      });

      const token = await getSessionToken(app).then((res: string) => {
        this.shopifyAccessToken = res;
        return res;
      });

      return token;
    }
  }
  public initAuthentication(host: string): void {
    this.host = host;

    //Development
    if (!environment.production) {
      this.host = 'cmV0dXJiby1kZXZlbG9wbWVudC5teXNob3BpZnkuY29tL2FkbWlu';
      this.shopifyAccessToken = environment.shopifyDevelopmentAccessToken;
      this.isAuthenticated.next(true);
      return;
    }

    const app = createApp({
      apiKey: environment.apiKey,
      host: host,
    });

    console.log('Authenticating...');

    getSessionToken(app).then((res: string) => {
      console.log('Authenticated');
      this.shopifyAccessToken = res;
      this.isAuthenticated.next(true);
    });
  }
  public getHost(): string {
    try {
      const decodedToken: any = jwtDecode(this.shopifyAccessToken);

      if (decodedToken == undefined) {
        return this.host;
      }

      return decodedToken.dest.replace('https://', '');
    } catch (error) {
      return this.host;
    }
  }

  public getDomainName(): string {
    try {
      const decodedToken: any = jwtDecode(this.shopifyAccessToken);

      if (decodedToken == undefined) {
        return '';
      }

      return decodedToken.dest.replace('https://', '');
    } catch (error) {
      return '';
    }
  }

  public getAccessToken(): string {
    return this.shopifyAccessToken;
  }

  getAccessScope(scope: AccessScopeType): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.apiUrl}/authentication/access?scope=${scope}`
    );
  }

  setAccessScope(scope: AccessScopeType): Observable<string> {
    return this.http.post<string>(
      `${environment.apiUrl}/authentication/access?scope=${scope}`,
      null
    );
  }
}
