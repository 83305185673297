import { Component, OnInit } from '@angular/core';
import { NotificationSettings } from 'src/app/settings/models/notification-settings.model';
import { SummaryEmail } from 'src/app/shared/enums/summary-email.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { summaryEmails } from './summary-emails';
import { NotificationSettingsService } from 'src/app/shared/services/settings/notification-settings.service';

@Component({
  selector: 'app-summary-emails',
  templateUrl: './summary-emails.component.html',
  styleUrls: ['./summary-emails.component.scss'],
})
export class SummaryEmailsComponent implements OnInit {
  title = 'Summary emails';
  description =
    'Choose what summary emails you want us to send. Our summary emails shows you how many returns have been handled as well as what is pending actions from you.';
  isLoading = true;
  isSaving = false;
  availableSummaryEmails: any[] = summaryEmails;
  selectedSummaryEmails: SummaryEmail[] = [];
  notificationSettings: NotificationSettings = new NotificationSettings();

  constructor(
    private notificationSettingsService: NotificationSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.notificationSettingsService
      .getNotificationSettingsSubject()
      .subscribe((notificationSettings) => {
        if (this.notificationSettingsService.hasNotificationSettingsLoaded()) {
          this.notificationSettings = notificationSettings;
          this.getChecked();
          this.isLoading = false;
        }
    });
  }

  setSummaryEmails(selectedSummary: any): void {
    const index = this.selectedSummaryEmails.indexOf(selectedSummary.interval);

    if (index != -1) {
      this.selectedSummaryEmails.splice(index, 1);

      const summary = this.availableSummaryEmails.find(
        (x) => x === selectedSummary
      );
      if (summary) {
        summary.checked = false;
      }

      if (this.selectedSummaryEmails.length === 0) {
        this.selectedSummaryEmails.push(SummaryEmail.Never);
      }
    }

    if (index === -1) {
      this.selectedSummaryEmails.push(selectedSummary.interval);

      const neverIndex = this.selectedSummaryEmails.indexOf(SummaryEmail.Never);
      if (neverIndex != -1) {
        this.selectedSummaryEmails.splice(neverIndex, 1);
      }

      const summary = this.availableSummaryEmails.find(
        (x) => x === selectedSummary
      );
      if (summary) {
        summary.checked = true;
      }
    }
    this.notificationSettings.merchant_notification_interval =
      this.selectedSummaryEmails;

    this.saveSettings();
  }

  getChecked(): void {
    this.selectedSummaryEmails =
      this.notificationSettings.merchant_notification_interval;

    this.availableSummaryEmails.forEach((availableSummary) => {
      const foundSummary = this.selectedSummaryEmails.find(
        (selectedSummary) => availableSummary.interval === selectedSummary
      );
      if (foundSummary) {
        availableSummary.checked = true;
      }
    });
  }

  saveSettings(): void {
    this.isSaving = true;

    this.notificationSettingsService
      .saveNotificationSettings(this.notificationSettings)
      .subscribe({
        next: () => {
          this.notificationSettingsService.setNotificationSettingsSubject(
            this.notificationSettings
          );
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings');
          this.isSaving = false;
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }
}
