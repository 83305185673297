import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-essentials-carrier-limit-popup',
  templateUrl: './essentials-carrier-limit-popup.component.html',
  styleUrls: ['./essentials-carrier-limit-popup.component.scss'],
})
export class EssentialsCarrierLimitPopupComponent {
  constructor(
    private router: Router,
    private essentialsCarrierDialog: MatDialogRef<EssentialsCarrierLimitPopupComponent>
  ) {}

  goToPlans(): void {
    this.essentialsCarrierDialog.close();
    this.router.navigate(['/settings/account']);
  }
}
