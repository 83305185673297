export class ReturnFormSettings {
  public is_form_embedded = false;
  public form_logo_path = '';
  public primary_color = '#DE4066';
  public text_color = '#FFFFFF';
  public secondary_color = '';
  public show_returbo_emblem = false;
  public show_logo = false;
  public show_name = false;
  public show_estimated_refund = false;
}
