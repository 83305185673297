<div class="wrap">
  <main>
    <nav>
      <div
        class="link ml-0"
        [routerLink]="['.', 'general']"
        routerLinkActive="active"
      >
        General
      </div>
      <div
        class="link"
        [routerLink]="['.', 'returns']"
        routerLinkActive="active"
      >
        Returns
      </div>
      <div
        class="link"
        [routerLink]="['.', 'exchanges']"
        routerLinkActive="active"
      >
        Exchanges
      </div>
      <div
        class="link"
        [routerLink]="['.', 'complaints']"
        routerLinkActive="active"
      >
        Complaints
      </div>
    </nav>
    <router-outlet></router-outlet>
  </main>
</div>
