import { Component, OnInit } from '@angular/core';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';

@Component({
  selector: 'app-estimated-cost',
  templateUrl: './estimated-cost.component.html',
  styleUrls: ['./estimated-cost.component.scss'],
})
export class EstimatedCostComponent implements OnInit {
  isLoading = true;
  isSaving = false;

  title = 'Estimated cost customization';
  description =
    'Choose whether you want the estimated refund to be displayed to your customers inside the Digital Return Form when they submit a complaint request.';

  formSettings: ReturnFormSettings = new ReturnFormSettings();

  constructor(
    private formSettingsService: FormSettingsService,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
    this.formSettingsService
      .getSubjectFormSettings()
      .subscribe((formSettings) => {
        if (this.formSettingsService.hasFormSettingsLoaded()) {
          this.formSettings = formSettings;
          this.isLoading = false;
        }
      });
  }

  public saveFormSettings(): void {
    this.isSaving = true;
    this.formSettingsService.saveFormSettings(this.formSettings).subscribe({
      next: () => {
        this.isSaving = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save form settings');
        this.isSaving = false;
      },
    });
  }
}
