import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { FeatureTracker } from 'src/app/settings/models/features-tracker.model';
import { environment } from 'src/environments/environment';
import { FeatureEnum } from '../enums/feature.enum';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private featureSubject: BehaviorSubject<FeatureTracker[]> =
    new BehaviorSubject(<FeatureTracker[]>[]);
  private isLoading = false;
  constructor(private httpClient: HttpClient) {}

  public getNewFeatures(): Observable<FeatureTracker[]> {
    if (!this.featureSubject.value.length &&
      !this.isLoading
    ) {
      this.isLoading = true;
      this.httpClient
        .get<FeatureTracker[]>(`${environment.apiUrl}/api/feature/tracker`)
        .subscribe({
          next: (features: FeatureTracker[]) => {
            this.featureSubject.next(features);
            this.isLoading = false;
          },
          error:() => {
            this.isLoading = false;
          }
        });
    }
    return this.featureSubject;
  }

  public setSeen(feature: FeatureTracker[]): void {
    this.featureSubject.next(feature);
  }

  updateNewFeatures(feature: FeatureTracker[]): Observable<FeatureTracker[]> {
    return this.httpClient.put<FeatureTracker[]>(
      `${environment.apiUrl}/api/feature/tracker`,
      feature
    );
  }

  getFeatureAccess(feature: FeatureEnum) {
    return this.httpClient.get<boolean>(
      `${environment.apiUrl}/api/feature/access/${feature}`
    );
  }
}
