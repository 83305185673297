import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Currency } from "src/app/settings/models/currency.model";
import { ShippingSettings } from "src/app/settings/models/shipping-settings.model";
import { environment } from 'src/environments/environment';
import { ShippingCostType } from "../../enums/shipping-cost-type.enum";
  
@Injectable({
    providedIn: 'root',
})

export class ShippingSettingsService {
    
  private shippingSettingsSubject: BehaviorSubject<ShippingSettings> = new BehaviorSubject(
    new ShippingSettings()
  );
  private shippingCostSettingsSubject: BehaviorSubject<Currency[]> = new BehaviorSubject<Currency[]>([]);

  private isLoadingShippingSettings = false;
  private isLoadingShippingCostSettings = false;

  constructor(private httpClient: HttpClient) {}

  getShippingSettings(): Observable<ShippingSettings> {
    if (
      this.shippingSettingsSubject.value === null ||
      (this.shippingSettingsSubject.value.shipping_cost_type === ShippingCostType.Standard &&
      !this.isLoadingShippingSettings)
    ) {
      this.isLoadingShippingSettings = true;
      this.httpClient
        .get<ShippingSettings>(`${environment.apiUrl}/settings/shipping`)
        .subscribe((settings) => {
          this.isLoadingShippingSettings = false;
          this.shippingSettingsSubject.next(settings);
        });
    }
    return this.shippingSettingsSubject;
  }

  hasShippingSettingsLoaded(): boolean {
    const shippingSettingsPopulated = this.shippingSettingsSubject.value !== null;
    const shippingSettingsLoading = (!this.shippingSettingsSubject.value.shipping_cost_type &&
      this.isLoadingShippingSettings);
    return shippingSettingsPopulated && !shippingSettingsLoading;
  }
  
  setShippingSettings(settings: ShippingSettings): void {
    this.shippingSettingsSubject.next(settings);
  }
  
  getSubjectShippingSettings(): Observable<ShippingSettings> {
    return this.shippingSettingsSubject.asObservable();
  }

  saveShippingSettings(
    shippingSettings: ShippingSettings
  ): Observable<boolean> {
    this.shippingSettingsSubject.next(shippingSettings);
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/shipping`,
      shippingSettings
    );
  }

  getShippingCostSettings(): Observable<Currency[]> {
    if (
      this.shippingCostSettingsSubject.value === null ||
      (!this.shippingCostSettingsSubject.value.length &&
        !this.isLoadingShippingCostSettings)
    ) {
      this.isLoadingShippingCostSettings = true;
      this.httpClient
        .get<Currency[]>(`${environment.apiUrl}/settings/shipping-cost`)
        .subscribe((settings) => {
          this.isLoadingShippingCostSettings = false;
          this.shippingCostSettingsSubject.next(settings);
        });
    }
    return this.shippingCostSettingsSubject;
  }
  
  hasShippingCostSettingsLoaded(): boolean {
    const shippingCostSettingsPopulated = this.shippingCostSettingsSubject.value !== null;
    return shippingCostSettingsPopulated && !this.isLoadingShippingCostSettings;
  }
  
  setShippingCostSettings(settings: Currency[]): void {
    this.shippingCostSettingsSubject.next(settings);
  }
  
  getSubjectShippingCostSettings(): Observable<Currency[]> {
    return this.shippingCostSettingsSubject.asObservable();
  }

  saveShippingCost(shippingCost: Currency[]): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/shipping-cost`,
      shippingCost
    );
  }

  hasShippingAndCostSettingsLoaded(): boolean {
    return this.hasShippingCostSettingsLoaded() && this.hasShippingSettingsLoaded();
  }
}