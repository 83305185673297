import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormSettingsService {
  private formSettingsSubject: BehaviorSubject<ReturnFormSettings> =
    new BehaviorSubject(new ReturnFormSettings());
  private isLoadingFormSettings = false;

  constructor(private httpClient: HttpClient) {}

  getFormSettings(): Observable<ReturnFormSettings> {
    if (
      this.formSettingsSubject.value === null ||
      (!this.formSettingsSubject.value.secondary_color &&
        !this.isLoadingFormSettings)
    ) {
      this.isLoadingFormSettings = true;
      this.httpClient
        .get<ReturnFormSettings>(`${environment.apiUrl}/settings/form`)
        .subscribe((formSettings) => {
          this.isLoadingFormSettings = false;
          this.formSettingsSubject.next(formSettings);
        });
    }
    return this.formSettingsSubject;
  }

  saveFormSettings(formSettings: ReturnFormSettings): Observable<boolean> {
    this.formSettingsSubject.next(formSettings);
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/form`,
      formSettings
    );
  }

  hasFormSettingsLoaded(): boolean {
    const formSettingsPopulated = this.formSettingsSubject.value !== null;
    const formSettingsLoading =
      !this.formSettingsSubject.value.secondary_color &&
      this.isLoadingFormSettings;
    return formSettingsPopulated && !formSettingsLoading;
  }

  setFormSettingsSubject(settings: ReturnFormSettings): void {
    this.formSettingsSubject.next(settings);
  }

  getSubjectFormSettings(): Observable<ReturnFormSettings> {
    return this.formSettingsSubject.asObservable();
  }

  public uploadLogo(file: File): Observable<ReturnFormSettings> {
    const data = new FormData();
    data.append('file', file, file.name);
    return this.httpClient.post<ReturnFormSettings>(
      `${environment.apiUrl}/settings/logo`,
      data
    );
  }
}
