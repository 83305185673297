import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input()
  options: string[] = [];
  @Input()
  boldWords: string[] = [];
  @Input()
  horizontal = true;
  @Input()
  name = '';
  @Input()
  disabled = false;
  @Input()
  selected: string | undefined;
  @Output()
  selectedChange: EventEmitter<string> = new EventEmitter<string>();

  private selectedIndex = -1;

  ngOnInit(): void {
    if (
      this.selected != null &&
      this.options != null &&
      this.selected.length > 0
    ) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i] == this.selected) {
          this.selectedIndex = i;
          break;
        }
      }
    }
  }

  optionSelected(index: number): void {
    if (this.disabled) {
      return;
    }
    this.selectedIndex = index;
    this.selectedChange.emit(this.options[index]);
  }

  bolden(text: string, chosenWords: string[]): string {
    chosenWords.forEach((word) => {
      text = text.replace(word, `<b>${word}</b>`);
    });

    return text;
  }
}
