import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { FeatureEnum } from 'src/app/shared/enums/feature.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FeatureService } from 'src/app/shared/services/feature.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  bannerText = 'The Pay-As-You-Go (PAYG) plan will be replaced by our new price plan Starter, from the 1st of October |';
  trackingAccess = true;

  constructor(
    private errorService: ErrorService,
    private featureService: FeatureService,
    private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.authService.isClientAuthenticated().subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.checkFeatureAccess();
      }
    });
  }

  checkFeatureAccess(): void {
    this.featureService.getFeatureAccess(FeatureEnum.AutomaticTracking).subscribe({
      next: (res) => {
        this.trackingAccess = res;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Please reload the page, we had difficulties checking your access.');
      }
    });
  }

  showBanner(): boolean {
      return !this.trackingAccess;
  }
}
