import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PendingChangesGuard } from '../core/guards/pending-changes.guard';
import { AccountDashboardComponent } from './pages/account-settings/account-dashboard/account-dashboard.component';
import { CustomizationDashboardComponent } from './pages/customization/customization-dashboard/customization-dashboard.component';
import { IntegrationShippingDashboardComponent } from './pages/integration/shipping/shipping-dashboard/integration-shipping-dashboard.component';
import { ComplaintsDashboardComponent } from './pages/request-settings/complaints/complaints-dashboard/complaints-dashboard.component';
import { ExchangesDashboardComponent } from './pages/request-settings/exchanges/exchanges-dashboard/exchanges-dashboard.component';
import { GeneralDashboardComponent } from './pages/request-settings/general/general-dashboard/general-dashboard.component';
import { RequestSettingsDashboardComponent } from './pages/request-settings/request-settings-dashboard/request-settings-dashboard.component';
import { IntegrationSettingsDashboardComponent } from './pages/integration/integration-settings-dashboard/integration-settings-dashboard.component';
import { ReturnsDashboardComponent } from './pages/request-settings/returns/returns-dashboard/returns-dashboard.component';
import { SettingsDashboardComponent } from './pages/settings-dashboard/settings-dashboard.component';
import { IntegrationOtherDashboardComponent } from './pages/integration/other/other-dashboard/integration-other-dashboard.component';

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsDashboardComponent,
    children: [
      {
        path: '',
        redirectTo: 'request-settings',
        pathMatch: 'full',
      },
      {
        path: 'request-settings',
        component: RequestSettingsDashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'general',
            pathMatch: 'full',
          },
          {
            path: 'general',
            component: GeneralDashboardComponent,
          },
          {
            path: 'returns',
            component: ReturnsDashboardComponent,
          },
          {
            path: 'exchanges',
            component: ExchangesDashboardComponent,
          },
          {
            path: 'complaints',
            component: ComplaintsDashboardComponent,
          },
        ],
      },
      {
        path: 'customization',
        component: CustomizationDashboardComponent,
      },
      {
        path: 'account',
        component: AccountDashboardComponent,
      },
      {
        path: 'integrations',
        component: IntegrationSettingsDashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'shipping',
            pathMatch: 'full',
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'shipping',
            component: IntegrationShippingDashboardComponent,
            canDeactivate: [PendingChangesGuard],
          },
          {
            path: 'other',
            component: IntegrationOtherDashboardComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
