<div class="language-selector">
  <mat-form-field
    appearance="outline"
    [formGroup]="languageForm"
  >
    <mat-label>{{ title }}</mat-label>
    <mat-select formControlName="language" [disabled]="disabled">
      <mat-select-trigger class="mat-form-field-infix">
        <span class="fi fi-{{ getCountryCode(defaultLanguage) }} mr-7"></span
        >{{ defaultLanguage.name
        }}<span class="ml-5">{{
          defaultLanguage.name === "English" ? "(US)" : ""
        }}</span>
      </mat-select-trigger>
      <mat-option
        (click)="selectLanguage(lang)"
        *ngFor="let lang of languages"
        [value]="lang.country_code"
        ><span class="fi fi-{{ getCountryCode(lang) }} mr-7"></span>
        {{ lang.name }} <span>{{ lang.name === "English" ? "(US)" : "" }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
