import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  constructor(private formBuilder: FormBuilder) {}

  @Output()
  startDate: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  endDate: EventEmitter<Date> = new EventEmitter<Date>();

  range = this.formBuilder.group({
    start: new Date(),
    end: new Date(),
  });

  emitStartDate(event: any): void {
    const date = new Date(event.target.value);
    this.startDate.emit(date);
  }
  emitEndDate(event: any): void {
    const date = new Date(event.target.value);
    this.endDate.emit(date);
  }

  reset(): void {
    this.range.reset();
  }

  setStartDate(date: Date): void {
    this.range.patchValue({
      start: date,
    });
  }

  getStartDate(): Date {
    return this.range.value.start;
  }

  setEndDate(date: Date): void {
    this.range.patchValue({
      end: date,
    });
  }

  getEndDate(): Date {
    return this.range.value.end;
  }
}
