<app-settings-section
  [hasContent]="!isLoadingSettings && !isLoadingPricePlans"
  [isLoading]="isLoadingSettings || isLoadingPricePlans || isSaving"
  [title]="title"
  [description]="description"
  [onBoardingStepCompleted]="onboardingCompleted"
  [hasNewFeatures]="false"
  [feature]="feature"
  id="select-plan"
>
  <div id="price-plans">
    <app-price-plan
      class="price-plan ml-15"
      *ngFor="let pricePlan of pricePlans"
      [name]="pricePlan.name"
      [price]="getDisplayPrice(pricePlan)"
      [priceUnit]="getPriceUnit(pricePlan)"
      [tagLine]="pricePlan.description"
      [numberOfPlans]="numberOfAvailablePlans"
      [features]="pricePlan.features"
      [disclaimer]="getDisclaimer(pricePlan)"
      [isSelected]="currentPricePlanId === pricePlan.id"
      (selected)="setPricePlan(pricePlan)"
      [plansSelected]="checkForActivePlans()"
      [currentPricePlan]="currentPricePlan"
    >
    </app-price-plan>
  </div>

  <!-- Expandable section -->
  <div>
    <div class="benefits">
      <p>List of benefits</p>
      <p class="text-primary" (click)="showFullContent = !showFullContent">
        Expand benefits list
        <img
          class="arrow"
          [ngClass]="{ rotate: showFullContent }"
          src="../assets/images/icons/chevron-down.png"
        />
      </p>
    </div>
    <table class="full-benefits" [ngClass]="{ expanded: showFullContent }">
      <tbody>
        <tr *ngFor="let featureName of featureTitles; let i = index">
          <th>{{ featureName }}</th>
          <td>
            <p class="description">{{ getDescription(proFeatures[i]) }}</p>

            <ng-container *ngIf="proFeatures[i]?.icon">
              <img
                class="mr-8"
                [ngClass]="{ disabled: proFeatures[i].icon === 'X' }"
                [src]="getImgSource(proFeatures[i].icon)"
              />
            </ng-container>
            <span *ngFor="let note of proFeatures[i]?.notes" class="notes">{{
              note
            }}</span>
          </td>
          <td>
            <p class="description">
              {{ getDescription(essentialsFeatures[i]) }}
            </p>

            <ng-container *ngIf="essentialsFeatures[i]?.icon">
              <img
                class="mr-8"
                [ngClass]="{ disabled: essentialsFeatures[i].icon === 'X' }"
                [src]="getImgSource(essentialsFeatures[i].icon)"
              />
            </ng-container>

            <span
              *ngFor="let note of essentialsFeatures[i]?.notes"
              class="notes"
              >{{ note }}</span
            >
          </td>
          <td>
            <p class="description">
              {{ getDescription(payAsYouGoFeatures[i]) }}
            </p>
            <span
              *ngFor="let note of payAsYouGoFeatures[i]?.notes"
              class="notes"
              >{{ note }}</span
            >
            <ng-container *ngIf="payAsYouGoFeatures[i]?.icon">
              <img
                class="mr-8"
                [ngClass]="{ disabled: payAsYouGoFeatures[i].icon === 'X' }"
                [src]="getImgSource(payAsYouGoFeatures[i].icon)"
              />
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-settings-section>
