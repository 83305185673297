import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-animated-dropdown',
  templateUrl: './animated-dropdown.component.html',
  styleUrls: ['./animated-dropdown.component.scss'],
})
export class AnimatedDropdownComponent {
  menuOpened = false;

  @Input()
  disabled = false;
  @Input()
  selectedOption = '';
  @Input()
  options: string[] = [];
  @Input()
  placeholder = 'Select';
  @Input()
  size = '';

  @Output()
  selectedChange: EventEmitter<string> = new EventEmitter<string>();

  toggleMenu(): void {
    document.addEventListener('click', (event) => {
      const menuArea = document.getElementById('menu');
      const clickedArea = event.target;

      this.menuOpened = clickedArea === menuArea ? true : false;
    });
  }

  select(option: string): void {
    this.selectedOption = option;
    this.selectedChange.emit(this.selectedOption);
  }
}
