import { Component, HostListener, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/core/guards/pending-changes.guard';
import { FormValues } from 'src/app/settings/models/form-values.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';

@Component({
  selector: 'app-integration-dashboard',
  templateUrl: './integration-shipping-dashboard.component.html',
  styleUrls: ['./integration-shipping-dashboard.component.scss'],
})
export class IntegrationShippingDashboardComponent
  implements ComponentCanDeactivate, OnInit
{
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.formValueChanged ? false : true;
  }
  noteText =
    'Integrations page is disabled because you are not sending the slips with Returbo. To enable the page please select an option to send shipping slips with Returbo in Settings ';
  link = '/settings/return#printed-slips-component';
  linkText = 'here';

  formValueChanged = false;
  integrationEnabled = false;
  pageDisabled = false;
  autoGeneration = false;
  firstLoad = true;

  constructor(
    private shippingIntegrationService: ShippingIntegrationService,
    private router: Router,
    private returnSettingsService: ReturnSettingsService,
    private errorService: ErrorService
  ) {
    this.router.events.subscribe((res: Event) => {
      if (res instanceof NavigationEnd || res instanceof NavigationStart) {
        this.shippingIntegrationService.setLeaveUrl(res['url']);
      }
    });
  }

  ngOnInit(): void {
    this.getShippingIntegrations();

    this.returnSettingsService.getSubjectReturnSettings().subscribe((settings) => {
      this.pageDisabled = !settings.printed_return_slips;
      this.autoGeneration = settings.autogenerated_slips;
    });

    this.getActiveCarriers();
    this.getSupportedCountries();
  }

  getShippingIntegrations(): void {
    if (this.firstLoad) {
      this.shippingIntegrationService
      .getShippingIntegrations()
      .subscribe({
        next: (res) => {
          if (res != null) {
            this.shippingIntegrationService.setIntegrationsInformation(res);
            this.integrationEnabled = !!res.find(
              (integration) => integration.status === IntegrationStatusEnum.Active
            );
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed fetching integrations');
        },
        complete: () => {
          this.firstLoad = false;
          this.getValuesChanged();
        },
      });
    }

    this.shippingIntegrationService
      .getIntegrationsSubject()
      .subscribe((integrations) => {
        this.integrationEnabled = integrations.some(
          (integration) => integration.status === IntegrationStatusEnum.Active
        );
      });
  }

  getValuesChanged(): void {
    this.shippingIntegrationService
          .getValuesChanged()
          .subscribe((res: FormValues) => {
            this.formValueChanged = Object.keys(res).some(
              (key) => res[key as keyof FormValues] === true
            );
          });
  }

  getSupportedCountries(): void {
    this.shippingIntegrationService
      .getShippingIntegrationCountries(
        Object.values(ShippingPackageType).join()
      )
      .subscribe((shippingIntegrationSupportedCountries) => {
        if (shippingIntegrationSupportedCountries.length > 0) {
          this.shippingIntegrationService.setIntegationPackageSupportedCountriesSubject(
            shippingIntegrationSupportedCountries
          );
        }
      });
  }

  getActiveCarriers(): void {
      this.shippingIntegrationService
      .getShippingIntegrationsByStatus(IntegrationStatusEnum.Active)
      .subscribe({
        next: (res: ShippingIntegration[]) => {
          if (res != null) {
            const groupedItems = res.reduce(
              (acc: any, item: ShippingIntegration) => {
                const key = item.shipping_provider;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(item);
                return acc;
              },
              {}
            );
            this.shippingIntegrationService
              .setActiveCarriers(Object.keys(groupedItems).length);
            this.shippingIntegrationService
              .setActiveIntegrationsSubject(res);
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed fetching active integrations');
        },
      });
  }
}
