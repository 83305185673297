<app-settings-section
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [title]="title"
  [description]="description"
  [exludedInOnBoarding]="true"
>
  <h4>Supported languages</h4>
  <p>
    Please select which languages you support and which will be available for
    your customers to choose from inside the Digital Return Form.
  </p>

  <ul class="align-list">
    <li *ngFor="let lang of languageList">
      <app-checkbox
        [ngClass]="lang.name === 'English' ? 'disabled' : ''"
        size="small"
        [disabled]="isSaving"
        [checked]="lang.is_active"
        (checkedChange)="toggleLanguage($event, lang)"
        ><span class="mr-5 fi fi-{{ getCountryCode(lang) }}"></span
        >{{ lang.name }}
        {{ lang.country_code === "EN" ? "(US)" : "" }}
        <span class="default">{{
          lang.country_code === "EN" ? "Default" : ""
        }}</span>
      </app-checkbox>
    </li>
  </ul>

  <h4 class="d-flex align-i-center">Language selection for Digital Return Form</h4>
  <p>
    Select your default language for the Digital Return Form's display.
    Customers can switch to another supported language in the form.
  </p>

  <app-language-selector
    (setSelectLanguage)="setDRFLanguage($event)"
    [disabled]="isSaving || isLoading"
  ></app-language-selector>
</app-settings-section>
