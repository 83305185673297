<app-modal id="useful-keywords" [dismissable]="false" size="small">
  <h1 class="header">
    Useful keywords to include in your emails
    <mat-icon class="float-right" (click)="closeModal()">close</mat-icon>
  </h1>
  <p class="text">
    To be able to customize the emails even more, we support the use of
    so-called “keywords”. These keywords allow you to make your emails even more
    personal for the customer. Below we will list the supported keywords that
    you can include inside the text. Just copy and paste them in your email
    content with the curly brackets.
  </p>

  <div class="keyword-container" *ngFor="let keyword of keywordList">
    <div class="linkBox">
      <p class="link-formatting">
        <span class="mr-10">{{ keyword.text }}</span>

        <img
          class="pointer"
          src="assets/images/icons/copy.png"
          [cdkCopyToClipboard]="keyword.text"
        />
      </p>
    </div>
    <p>
      {{ keyword.description }}
    </p>
  </div>
</app-modal>
