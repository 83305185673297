export enum SelectedShippingOptionsEnum {
  PostNordVarubrev = 'PostNordVarubrev',
  PostNordReturnDropOff = 'PostNordReturnDropOff',
  GlsReturn = 'GlsReturn',
  DhlExpressWorldWide = 'DhlExpressWorldWide',
  DhlDeutschePostPacketReturn = 'DhlDeutschePostPacketReturn',
  DhlFreightServicePointReturn = 'DhlFreightServicePointReturn',
  DhlFreightParcelReturnConnect = 'DhlFreightParcelReturnConnect',
  DhlFreightHomeDeliveryReturn = 'DhlFreightHomeDeliveryReturn',
  DhlFreightPaket = 'DhlFreightPaket',
  DhlFreightEuroconnect = 'DhlFreightEuroconnect',
  DhlFreightEuroline = 'DhlFreightEuroline',
  NoShippingSlip = 'No shipping slip',
  AttachShippingSlip = 'Attach shipping slip',
  BringParcelDomesticReturnFromPickUpPoint = 'BringParcelDomesticReturnFromPickUpPoint',
  BringParcelDomesticReturnToBusiness = 'BringParcelDomesticReturnToBusiness',
  BringParcelDomesticReturnPackageInMailbox = 'BringParcelDomesticReturnPackageInMailbox',
  BringPickUpParcel = 'BringPickUpParcel',
  BringPickUpParcelBulk = 'BringPickUpParcelBulk',
  BringHomeDeliveryParcel = 'BringHomeDeliveryParcel',
  BringPickUpParcelReturn = 'BringPickUpParcelReturn',
  BringPickUpParcelReturnBulk = 'BringPickUpParcelReturnBulk',
  BringExpressInternational = 'BringExpressInternational',
  BringExpressEconomy = 'BringExpressEconomy',
  UpsStandard = 'UpsStandard',
  UpsExpress = 'UpsExpress',
  UpsWorldwideExpressPlus = 'UpsWorldwideExpressPlus',
  UpsWorldwideEconomy = 'UpsWorldwideEconomy',
}
