import { Shipping } from 'src/app/models/shipping.model';
import { SelectedShippingOptionsEnum } from 'src/app/shared/enums/selected-shipping-options.enum';

export const shippings: Shipping[] = [
  {
    name: SelectedShippingOptionsEnum.AttachShippingSlip,
    type: SelectedShippingOptionsEnum.AttachShippingSlip,
    info: '',
    track: '',
    logo: '',
    provider: '',
  },
  {
    name: 'Return Drop Off',
    type: SelectedShippingOptionsEnum.PostNordReturnDropOff,
    info: 'MyPack parcels may weigh up to 20 kg. Within the Nordics, maximum length is 150 cm and max length + perimeter is 300 cm. Your own PostNord agreement sets your pricing.',
    track: 'Here is your MyPack tracking number:',
    logo: 'postnord',
    provider: 'PostNord',
  },
  {
    name: 'Varubrev Return',
    type: SelectedShippingOptionsEnum.PostNordVarubrev,
    info: 'Your pricing and policies are set by your own PostNord agreement. Varubrev Return can weigh up to 2 kilograms. For technical reasons, they may not weigh less than 150 grams or be narrower than 1.5 centimetres. Varubrev cannot be sent as rolls or tubes.',
    track:
      'Varubrev Return cannot be tracked. PostNord will notify you when it has arrived.',
    logo: 'postnord',
    provider: 'PostNord',
  },
  {
    name: SelectedShippingOptionsEnum.NoShippingSlip,
    type: SelectedShippingOptionsEnum.NoShippingSlip,
    info: 'Use this option if you include a return slip with the original order. As customers may lose the slip, the safer option is to send a return slip again.',
    track: 'You have not attached any slip with this return.',
    logo: '',
    provider: '',
  },
  {
    name: 'Return',
    type: SelectedShippingOptionsEnum.GlsReturn,
    info: '',
    track: '',
    logo: 'gls',
    provider: 'GLS',
  },
  {
    name: 'WorldWide',
    type: SelectedShippingOptionsEnum.DhlExpressWorldWide,
    info: '',
    track: '',
    logo: 'dhl',
    provider: 'DHL Express',
  },
  {
    name: 'Packet Return',
    type: SelectedShippingOptionsEnum.DhlDeutschePostPacketReturn,
    info: '',
    track: '',
    logo: 'deutsche-post',
    provider: 'Deutsche Post',
  },
  {
    name: 'Service Point Return',
    type: SelectedShippingOptionsEnum.DhlFreightServicePointReturn,
    info: 'Service Point Return parcels may weigh up to 20 kg. Max length of 150 cm and max length + circumference = 300 cm. Delivers to most of Sweden within 1 to 2 days. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Parcel Return Connect',
    type: SelectedShippingOptionsEnum.DhlFreightParcelReturnConnect,
    info: 'For packages up to 31.5 kg from 24 countries to Sweden. Max length + circumference = 360 cm. C2B and traceable. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Home Delivery Return',
    type: SelectedShippingOptionsEnum.DhlFreightHomeDeliveryReturn,
    info: 'For packages up to 800kg within Sweden. Package is picked up from customer’s home. Delivers to most of Sweden within 1 to 2 days. C2B and traceable. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Paket',
    type: SelectedShippingOptionsEnum.DhlFreightPaket,
    info: 'For packages up to 35 kg within Sweden, door to door. 1 to 4 days delivery time. B2B and traceable. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Euroconnect',
    type: SelectedShippingOptionsEnum.DhlFreightEuroconnect,
    info: 'For packages up to 1000kgs to and from Sweden and 50 countries in Europa and Asia. B2B and traceable. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Euroline',
    type: SelectedShippingOptionsEnum.DhlFreightEuroline,
    info: 'Weight allowance varies by destination country. To and from Sweden and 50 countries within Europa and Asia. B2B. Your own DHL Freight agreement sets your pricing.',
    track: '',
    logo: 'dhl-freight',
    provider: 'DHL Freight'
  },
  {
    name: 'Parcel Norway domestic(Return from pick-up point)',
    type: SelectedShippingOptionsEnum.BringParcelDomesticReturnFromPickUpPoint,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Parcel Norway domestic(Return to business)',
    type: SelectedShippingOptionsEnum.BringParcelDomesticReturnToBusiness,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Parcel Norway domestic(Return package in mailbox)',
    type: SelectedShippingOptionsEnum.BringParcelDomesticReturnPackageInMailbox,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'PickUp Parcel',
    type: SelectedShippingOptionsEnum.BringPickUpParcel,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'PickUp Parcel Bulk',
    type: SelectedShippingOptionsEnum.BringPickUpParcelBulk,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Home Delivery Parcel',
    type: SelectedShippingOptionsEnum.BringHomeDeliveryParcel,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'PickUp Parcel Return',
    type: SelectedShippingOptionsEnum.BringPickUpParcelReturn,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'PickUp Parcel Return Bulk',
    type: SelectedShippingOptionsEnum.BringPickUpParcelReturnBulk,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Express International',
    type: SelectedShippingOptionsEnum.BringExpressInternational,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Express Economy',
    type: SelectedShippingOptionsEnum.BringExpressEconomy,
    info: '',
    track: '',
    logo: 'bring',
    provider: 'Bring',
  },
  {
    name: 'Standard',
    type: SelectedShippingOptionsEnum.UpsStandard,
    info: '',
    track: '',
    logo: 'ups',
    provider: 'UPS',
  },
  {
    name: 'Express',
    type: SelectedShippingOptionsEnum.UpsExpress,
    info: '',
    track: '',
    logo: 'ups',
    provider: 'UPS',
  },
  {
    name: 'Worldwide Express Plus',
    type: SelectedShippingOptionsEnum.UpsWorldwideExpressPlus,
    info: '',
    track: '',
    logo: 'ups',
    provider: 'UPS',
  },
  {
    name: 'Worldwide Economy',
    type: SelectedShippingOptionsEnum.UpsWorldwideEconomy,
    info: '',
    track: '',
    logo: 'ups',
    provider: 'UPS',
  },
];
