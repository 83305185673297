import { DeadlineTimePeriod } from 'src/app/shared/enums/deadline-time-period.enum';

export class ExchangeSettings {
  is_enabled = true;
  automatically_accept_requests = false;
  deadline = 0;
  deadline_time_period: DeadlineTimePeriod = DeadlineTimePeriod.Days;
  draft_order = false;
  store_credit = false;
}
