import { Component, Input } from '@angular/core';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { returnStatusesFilters } from 'src/app/returns/table-filters';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { ArchiveService } from '../../archive-modal/archive.service';

@Component({
  selector: 'app-sidenav-header',
  templateUrl: './sidenav-header.component.html',
  styleUrls: ['./sidenav-header.component.scss'],
})
export class SidenavHeaderComponent {
  @Input() return: ReturnRequest = new ReturnRequest();
  @Input() subtitle = '';
  returnStatuses = returnStatusesFilters;
  returnStatusEnum = ReturnStatusEnum;

  constructor(private archiveService: ArchiveService) {}

  openModal(): void {
    this.archiveService.setArchiveModal(true);
  }

  getStatusName(): string {
    return (
      this.returnStatuses.find((status: StatusFilter) =>
        status.status.includes(this.return.status)
      )?.nameTitle ?? ''
    );
  }

  getColorClass(): string {
    return (
      this.returnStatuses.find((status: StatusFilter) =>
        status.status.includes(this.return.status)
      )?.headerStatus.colorClass ?? ''
    );
  }

  getStatusIcon(): string {
    return (
      this.returnStatuses.find((status: StatusFilter) =>
        status.status.includes(this.return.status)
      )?.headerStatus.icon ?? ''
    );
  }
}
