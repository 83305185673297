import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MerchantComment } from 'src/app/models/merchant-comment.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private httpClient: HttpClient) {}

  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  getComments(id: string): Observable<MerchantComment[]> {
    return this.httpClient.get<MerchantComment[]>(
      `${environment.apiUrl}/api/return/${id}/comments`
    );
  }

  addComment(
    returnID: string,
    comment: MerchantComment
  ): Observable<MerchantComment> {
    return this.httpClient.post<MerchantComment>(
      `${environment.apiUrl}/api/return/${returnID}/comment`,
      comment
    );
  }

  deleteComment(
    returnID: string,
    commentID: string
  ): Observable<MerchantComment> {
    return this.httpClient.delete<MerchantComment>(
      `${environment.apiUrl}/api/return/${returnID}/comment/${commentID}`
    );
  }

  setLoading(loading: boolean): void {
    this.isLoading.next(loading);
  }

  getLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }
}
