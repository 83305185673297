<div class="container">
  <input
    class="{{ size }}"
    [type]="type"
    [disabled]="disabled"
    placeholder="{{ placeholder }}"
    [value]="value"
    [required]="required"
    (input)="contentUpdate($event)"
    [appRegExpValidator]="regExpValidator"
    min="0"
    [class.error]="error"
  />
  <span>{{ currency }}</span>
  <div *ngIf="showRequired()" class="alert">
    <p>Required</p>
  </div>
</div>
