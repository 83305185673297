import { ClipboardModule } from '@angular/cdk/clipboard';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { AlertComponent } from './alert/alert.component';
import { AnimatedDropdownComponent } from './animated-dropdown/animated-dropdown.component';
import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { ButtonComponent } from './button/button.component';
import { ChatComponent } from './chat/chat.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InputComponent } from './input/input.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LoaderComponent } from './loader/loader.component';
import { ModalComponent } from './modal/modal.component';
import { NewButtonComponent } from './new-button/new-button.component';
import { OverlayMenuComponent } from './overlay-menu/overlay-menu.component';
import { ProgressComponent } from './progress/progress.component';
import { RadioComponent } from './radio/radio.component';
import { SliderComponent } from './slider/slider.component';

@NgModule({
  declarations: [
    ArrowButtonComponent,
    ButtonComponent,
    DropdownComponent,
    InputComponent,
    LoaderComponent,
    CheckboxComponent,
    ModalComponent,
    RadioComponent,
    OverlayMenuComponent,
    LanguageSelectorComponent,
    ProgressComponent,
    AlertComponent,
    SliderComponent,
    DatePickerComponent,
    AnimatedDropdownComponent,
    NewButtonComponent,
    ChatComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    ClipboardModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    MatStepperModule,
    MatRadioModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule
  ],
  exports: [
    ArrowButtonComponent,
    ButtonComponent,
    DropdownComponent,
    InputComponent,
    LoaderComponent,
    CheckboxComponent,
    ModalComponent,
    RadioComponent,
    OverlayMenuComponent,
    MatButtonModule,
    MatIconModule,
    LanguageSelectorComponent,
    MatChipsModule,
    MatTableModule,
    MatMenuModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    ProgressComponent,
    MatStepperModule,
    MatRadioModule,
    MatTooltipModule,
    ClipboardModule,
    MatSlideToggleModule,
    AlertComponent,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    SliderComponent,
    DatePickerComponent,
    AnimatedDropdownComponent,
    NewButtonComponent,
    ChatComponent
  ],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ComponentsModule {}
