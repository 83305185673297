<app-settings-section
  [title]="title"
  [description]="description"
  [isLoading]="isSaving || isLoading"
  [hasContent]="!isLoading"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New feature"
>
  <div>
    <h3>Your logo</h3>
    <p class="sub-description">
      Recommended minimum resolution 512x512 px. The logo should be square
      shaped. Please check how the logo is displayed in your digital return form
      after uploading. Accepted file types: .jpg, .png.
    </p>

    <div class="dotted-border">
      <div class="inner-wrap">
        <div class="logo-upload">
          <div class="input-container d-flex">
            <mat-icon class="file-icon" fontSet="material-icons-outlined"
              >insert_drive_file</mat-icon
            >

            <input
              *ngIf="!fileName"
              class="file-input"
              type="file"
              id="logo-upload"
              (change)="fileSelected($event)"
            />

            <span *ngIf="fileName" class="file-text">{{ fileName }}</span>

            <span class="action-container">
              <mat-icon
                fontSet="material-icons-outlined"
                *ngIf="fileName"
                (click)="previewLogo()"
                >visibility</mat-icon
              >
              <mat-icon
                class="cancel-icon"
                *ngIf="fileName"
                (click)="removeLogo()"
                >close</mat-icon
              >
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="checkboxes">
      <mat-checkbox
        [disabled]="isSaving"
        color="accent"
        (change)="saveSettings()"
        [(ngModel)]="formSettings.show_logo"
        ><span>Show the logo in the digital return form.</span>
      </mat-checkbox>

      <mat-checkbox
        [disabled]="isSaving"
        color="accent"
        class="mt-14"
        (change)="saveSettings()"
        [(ngModel)]="formSettings.show_name"
        ><span>Show the name of the store in the digital return form.</span>
      </mat-checkbox>
      <p class="info">{{ info }}</p>
    </div>
  </div>

  <div class="mt-32">
    <app-alert
      *ngIf="!hasAccessToFormCustomization()"
      type="Note"
      [showCloseButton]="true"
      [text]="note"
      imgIcon="../assets/images/icons/alert-triangle.png"
      size="small"
    ></app-alert>
  </div>

  <!-- Returbo logo hide/show section -->
  <div>
    <h3>Returbo logo<span class="pro-badge ml-8">Pro plan</span></h3>

    <p class="sub-description">
      Here you can show or hide the Returbo logo in the Digital Return Form.
    </p>

    <div class="checkboxes">
      <mat-checkbox
        name="emblem"
        [disabled]="isSaving || !hasAccessToFormCustomization()"
        color="accent"
        (change)="saveSettings()"
        [(ngModel)]="formSettings.show_returbo_emblem"
        ><span
          >Show “Powered by Returbo” emblem at the bottom of the Digital Return
          Form.</span
        >
      </mat-checkbox>
    </div>
  </div>

  <!-- Primary colors picker and example -->
  <div class="wrapper">
    <div>
      <h3 class="mt-32">
        Digital Return Form color palette<span class="pro-badge ml-8"
          >Pro plan</span
        >
      </h3>
      <p class="sub-description">
        Customize and adjust the colors to tailor the Digital Return Form to
        your brand.
      </p>

      <div class="colors">
        <div class="color">
          <div>
            <input
              name="primary-color"
              class="box"
              type="color"
              [(ngModel)]="formSettings.primary_color"
              #primary="ngModel"
            />
          </div>
          <div class="text-wrap">
            <p class="title-text">Main primary color</p>
            <span class="sub-text"
              >Used for as background for primary buttons and as an action color
              for links, icons, etc.</span
            >
          </div>
        </div>

        <div class="color">
          <div>
            <input
              name="text-color"
              class="box"
              type="color"
              [(ngModel)]="formSettings.text_color"
              #text="ngModel"
            />
          </div>
          <div class="text-wrap">
            <p class="title-text">Text color</p>
            <span class="sub-text"
              >Used for as the text for buttons and as a check icon color for
              when the item is selected.</span
            >
          </div>
        </div>

        <div class="color">
          <div>
            <input
              name="secondary-background-color"
              class="box"
              type="color"
              [(ngModel)]="formSettings.secondary_color"
              #secondary="ngModel"
            />
          </div>
          <div class="text-wrap">
            <p class="title-text">Secondary background color</p>
            <span class="sub-text"
              >Used for as a background for secondary (less important) buttons
              and as a background color for badges.</span
            >
          </div>
        </div>

        <div class="save-actions">
          <app-button (click)="restoreDefault()" buttonStyle="secondary"
            >Back to default</app-button
          >
          <app-button
            (click)="applyColor()"
            buttonStyle="primary"
            [disabled]="
              checkDisabled(primary.touched, text.touched, secondary.touched) ||
              isSaving
            "
            class="ml-16"
            ><span class="d-flex">
              <app-loader
                *ngIf="isSaving"
                type="primary-thin"
                size="button-sized"
              ></app-loader>
              Save palette
            </span>
          </app-button>
        </div>
      </div>
    </div>

    <div class="title">
      <h3 class="mt-32">Preview</h3>
      <div class="example">
        <div
          [style.borderColor]="formSettings.primary_color"
          class="product-card"
        >
          <div class="title-container">
            <div>
              <h1 class="title">
                Organic Woollen Pullover
                <svg
                  class="ml-8 check"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10"
                    cy="10.5"
                    r="10"
                    [style.fill]="formSettings.primary_color"
                  />
                  <path
                    d="M5.58398 10.5L8.30621 13.2222"
                    [style.stroke]="formSettings.text_color"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.7507 7.77734L8.30622 13.2218"
                    [style.stroke]="formSettings.text_color"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </h1>
              <p class="text mb-0 mt-0">L / Ivory</p>
              <p class="text mb-0 mt-0">2 199,00 SEK</p>
            </div>

            <div>
              <img src="../assets/images/example-product.png" />
            </div>
          </div>

          <div class="actions">
            <svg
              class="mr-5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7634 5.22197L18.5542 7.01304C18.8527 7.31155 18.8527 7.79376 18.5542 8.09227L17.1537 9.49298L14.2838 6.62268L15.6843 5.22197C15.8297 5.07654 16.0211 5 16.22 5C16.419 5 16.6103 5.06889 16.7634 5.22197ZM5 18.7779V15.9076L13.4643 7.44214L16.3342 10.3124L7.86989 18.7779H5Z"
                [style.fill]="formSettings.primary_color"
              />
            </svg>
            <p
              [style.color]="formSettings.primary_color"
              class="mr-32 action-text"
            >
              Edit item
            </p>

            <svg
              class="mr-5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M10 11V17M14 11V17"
                [style.stroke]="formSettings.primary_color"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p [style.color]="formSettings.primary_color" class="action-text">
              Delete from request
            </p>
          </div>
        </div>
        <button
          class="example-button-primary"
          [style]="{
            color: formSettings.text_color,
            background: formSettings.primary_color
          }"
        >
          <svg
            class="mr-8"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5.73092 11.8755L3 3C9.61513 4.92165 15.7588 7.95149 21.2167 11.8752C15.7588 15.799 9.6152 18.8289 3.00009 20.7507L5.73092 11.8755ZM5.73092 11.8755L13.2312 11.8755"
              [style.stroke]="formSettings.text_color"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Send in request
        </button>

        <div class="badge-example">
          <h3>Color <span class="text-primary">*</span></h3>
          <div class="badges">
            <button class="default-button mr-16">Ivory</button>
            <button class="default-button mr-16">White</button>
            <button class="default-button mr-16">Peach</button>
            <button class="default-button mr-16">Navy blue</button>
            <button
              [style]="{
                borderColor: formSettings.primary_color,
                color: formSettings.primary_color,
                background: formSettings.secondary_color
              }"
              class="selected-button mt-16"
            >
              Calm pink</button
            ><svg
              class="selected-checkmark check"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10.5"
                r="10"
                [style.fill]="formSettings.primary_color"
              />
              <path
                d="M5.58398 10.5L8.30621 13.2222"
                [style.stroke]="formSettings.text_color"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.7507 7.77734L8.30622 13.2218"
                [style.stroke]="formSettings.text_color"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <button
          class="example-button-secondary"
          [style.color]="formSettings.primary_color"
          [style.background]="formSettings.secondary_color"
        >
          <svg
            class="mr-8"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M17 9L10.8261 15.1741L7.5 11.8482"
              [style.stroke]="formSettings.primary_color"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Confirm
        </button>
      </div>
    </div>
  </div>
</app-settings-section>

<app-modal id="logo" [dismissable]="true" size="small">
  <img class="company-logo" [src]="formSettings.form_logo_path" alt="Logo" />
</app-modal>

<app-modal id="missing-pro-plan" [dismissable]="true" size="x-small">
  <h1 class="title m-0">
    Feature not available with your current payment plan
  </h1>
  <p class="info-text mt-8 mb-16">
    Please note that this feature is only available with the
    <b>Pro payment plan</b>. If you want to access it – please upgrade to the
    <b>Pro plan</b> in <b>Account Settings</b>.
  </p>

  <div class="float-right">
    <app-button (click)="closeModal()" buttonStyle="secondary mr-16"
      >Go back</app-button
    >
    <app-button (click)="goToPage('price-plans')" buttonStyle="primary"
      >View plans</app-button
    >
  </div>
</app-modal>
