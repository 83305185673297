<table
  class="return-items__table font-14 font-normal mb-36"
  [ngClass]="shouldAddClass() ? 'disabled' : ''"
>
  <tr class="header-row">
    <th
      class="font-semiBold text-left pl-43"
      [ngClass]="{ 'checkbox-visible': isRequestReceived }"
    >
      Item
    </th>
    <th
      class="font-semiBold refund-padding"
      [ngClass]="hasComplaintRefundResolved ? 'text-center' : 'text-right'"
    >
      Price
    </th>
    <th class="font-semiBold text-left pl-30" *ngIf="displayDecisionColumn()">
      <span>
        {{ decisionReasonHeaderTitle() }}
      </span>
    </th>
    <th
      class="font-semiBold text-left"
      *ngIf="(isIncomingResolved && !hasComplaintRefundResolved) || isResolved"
      [ngClass]="{
        'complaint-status':
          hasComplaint(return.items) || !shouldDisplayReason(),
        'resolved-status-header': isResolved
      }"
    >
      Status
    </th>
    <th class="font-semiBold"></th>
  </tr>
  <ng-container *ngFor="let item of return.items">
    <tr>
      <!-- General data column -->
      <td class="d-flex general" [ngClass]="{ 'resolved-general': isResolved }">
        <span
          class="action"
          [ngClass]="{ 'pb-5': item.selected }"
          *ngIf="showDecisionCheckbox()"
        >
          <mat-checkbox
            class="checkbox"
            [disabled]="isAcceptedAutomatically()"
            [checked]="item.selected"
            color="accent"
            (change)="onChange(item)"
          ></mat-checkbox>
        </span>
        <mat-icon class="item-image mr-16" *ngIf="!item.image_source"
          >image</mat-icon
        >
        <img
          *ngIf="item.image_source"
          class="item-image mr-16"
          [src]="item.image_source"
          alt="Product image"
        />

        <span class="item-informations">
          <span class="name mb-4">{{ item.name }}</span>
          <p
            class="m-0"
            *ngIf="isNotReturnRequested || hasComplaint(return.items)"
          >
            <span class="font-500"
              >{{ item.return_reason.return_reason_type }}:</span
            >
            {{ getReason(item) }} <span *ngIf="getSubReason(item)"> - {{ getSubReason(item) }}</span>
          </p>
          <p class="variants-text">
            <ng-container
              *ngFor="
                let variant of getSplitVariant(item.variant_title);
                index as i
              "
            >
              <span *ngIf="i > 0">/</span>
              <span *ngIf="getVariant(i, item, styleTypeEnum.normal)">
                <span
                  class="text-line-through"
                  *ngIf="
                    isExchangeRequest &&
                    item.requested_variant_title &&
                    checkIfDifferentVariant(item, i)
                  "
                  >{{ getVariant(i, item, styleTypeEnum.crossed) }}</span
                >
                {{ getVariant(i, item, styleTypeEnum.normal) }}
              </span>
            </ng-container>
          </p>
        </span>
      </td>
      <!-- End of general data column -->

      <!-- Price/actions column -->
      <td
        [ngClass]="{
          'on-sale': checkOnSale(item) && isNotResolvedPackageReceived,
          price: !(checkOnSale(item) && isNotResolvedPackageReceived)
        }"
      >
        <div
          [ngClass]="{
            'edit-price':
              enabledEditIds.includes(item.name) && checkOnSale(item),
            'text-right':
              !enabledEditIds.includes(item.name) && compareAmounts(item),
            'changed-refund':
              showChangedToRefundText(item) ||
              (!enabledEditIds.includes(item.name) &&
                !isExchangeRequest &&
                !checkOnSale(item) && 
                !isNonRefundCompensationChoice(item.merchant_compensation_choice)),
            'editing-price': enabledEditIds.includes(item.name)
          }"
        >
          <div
            [ngClass]="{
              discount:
                checkOnSale(item) &&
                item.merchant_compensation_choice ===
                  compensationOptionEnum.Refund &&
                !enabledEditIds.includes(item.name)
            }"
            *ngIf="displayChangePrice(item)"
            class="d-flex"
          >
            <span
              *ngIf="
                item.merchant_compensation_choice ===
                  compensationOptionEnum.Refund &&
                !enabledEditIds.includes(item.name)
              "
              [ngClass]="{
                'gift-card': return.paid_via_gift_card
              }"
              >{{ getTotalPrice(item) }} {{ return.currency }} <br />
              <p class="refund-text" *ngIf="showChangedToRefundText(item)">
                Changed to refund
              </p></span
            >

            <span
              id="new-item-change-price"
              [ngClass]="{
                'store-credit':
                  item.merchant_compensation_choice ===
                  compensationOptionEnum.StoreCredit,
                'new-item':
                  item.merchant_compensation_choice ===
                  compensationOptionEnum.NewItem,
                'new-item-rejected':
                  !item.decision || !checkIfExchangeRejectedResolved(item)
              }"
              *ngIf="showNewItemRipple(item)"
            >
              <span>
                {{
                  item.merchant_compensation_choice ===
                  compensationOptionEnum.StoreCredit
                    ? "Store credit"
                    : "New item"
                }}
              </span>
            </span>

            <button
              mat-icon-button
              [matMenuTriggerFor]="actions"
              aria-label="Actions menu"
              class="edit-item-action first"
              *ngIf="displayActions(item)"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actions="matMenu">
              <button
                class="edit-menu-item"
                mat-menu-item
                (click)="setEdit(item)"
                *ngIf="displayEditPriceOption(item) && !isIssueStoreCredit(item)"
              >
                <mat-icon>edit</mat-icon>
                <span>Edit price of the product</span>
              </button>
              <button
                class="edit-menu-item"
                mat-menu-item
                *ngIf="
                  enabledEditIds.includes(item.name) &&
                  cancelEnabled(item) &&
                  isNotIncomingResolved &&
                  !isExchangeRequest
                "
                (click)="cancelEdit(item)"
              >
                <mat-icon>monetization_on</mat-icon>
                <span>Back to original price</span>
              </button>
              <button
                class="edit-menu-item"
                mat-menu-item
                *ngIf="item.show_attached && !isExchangeRequest"
                (click)="toggleAttachedView(item)"
              >
                <mat-icon>visibility_off</mat-icon>
                <span>Hide attached files</span>
              </button>
              <button
                class="edit-menu-item"
                mat-menu-item
                *ngIf="!item.show_attached && !isExchangeRequest"
                (click)="toggleAttachedView(item)"
              >
                <mat-icon>visibility_on</mat-icon>
                <span>Show attached files</span>
              </button>

              <button
                class="edit-menu-item"
                mat-menu-item
                (click)="issueRefund(item)"
                *ngIf="!isIssueRefund(item)"
              >
                <img src="../assets/images/icons/credit-card.png" />
                <span>Issue a refund instead</span>
              </button>

              <button
                class="edit-menu-item"
                mat-menu-item
                (click)="issueNewItem(item)"
                *ngIf="!isExchangeIssueNewItem(item)"
              >
                <img src="../assets/images/icons/box.png" />
                <span>Ship new item instead</span>
              </button>

              <button
                class="edit-menu-item"
                mat-menu-item
                (click)="issueStoreCredit(item)"
                *ngIf="!isIssueStoreCredit(item) && exchangeSettings.store_credit"
              >
                <img src="../assets/images/icons/store-credit.png" />
                <span>Issue store credit instead</span>
              </button>
            </mat-menu>
          </div>
          <ng-container *ngIf="displayEditPriceActions(item)">
            <div
              [ngClass]="{
                editing: enabledEditIds.includes(item.name)
              }"
            >
              <span class="refund-input" *ngIf="showEditInput(item)"
                ><input
                  name="currency"
                  [(ngModel)]="item.return_amount"
                  (ngModelChange)="updatePrice($event, item)"
                />
                <span>{{ return.currency }}</span></span
              >
              <span
                *ngIf="
                  isIncomingResolved ||
                  enabledEditIds.includes(item.name) ||
                  isComplaintItemRejectedPackageReceived(item)
                "
              >
                <span *ngIf="!enabledEditIds.includes(item.name)"
                  >{{ item.return_amount }} {{ return.currency }}</span
                >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="actions"
                  aria-label="Actions menu"
                  class="edit-item-action"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #actions="matMenu">
                  <button
                    class="edit-menu-item"
                    mat-menu-item
                    (click)="setEdit(item)"
                    *ngIf="
                      displayEditPriceOption(item) &&
                      !isComplaintItemRejectedPackageReceived(item)
                    "
                  >
                    <mat-icon>edit</mat-icon>
                    <span>Edit price of the product</span>
                  </button>
                  <button
                    class="edit-menu-item"
                    mat-menu-item
                    *ngIf="
                      enabledEditIds.includes(item.name) &&
                      cancelEnabled(item) &&
                      isNotIncomingResolved
                    "
                    (click)="cancelEdit(item)"
                  >
                    <mat-icon>monetization_on</mat-icon>
                    <span>Back to original price</span>
                  </button>
                  <button
                    class="edit-menu-item"
                    mat-menu-item
                    *ngIf="item.show_attached && !isExchangeRequest"
                    (click)="toggleAttachedView(item)"
                  >
                    <mat-icon>visibility_off</mat-icon>
                    <span>Hide attached files</span>
                  </button>
                  <button
                    class="edit-menu-item"
                    mat-menu-item
                    *ngIf="!item.show_attached && !isExchangeRequest"
                    (click)="toggleAttachedView(item)"
                  >
                    <mat-icon>visibility_on</mat-icon>
                    <span>Show attached files</span>
                  </button>
                  <button
                    class="edit-menu-item"
                    mat-menu-item
                    *ngIf="isExchangePackageReceived()"
                    (click)="exchangeIssueRefund(item)"
                  >
                    <img
                      [src]="
                        isIssueRefund(item)
                          ? '../assets/images/icons/box.png'
                          : '../assets/images/icons/credit-card.png'
                      "
                      alt="Refund icon"
                    />
                    <span>{{
                      isIssueRefund(item)
                        ? "Ship new item instead"
                        : "Issue a refund instead"
                    }}</span>
                  </button>
                </mat-menu></span
              >
            </div>

            <p
              *ngIf="isLowerPrice(item)"
              [ngClass]="
                item.decision && isPackageReceived
                  ? 'refund-price-edit'
                  : 'refund-price float-left'
              "
            >
              {{ getTotalPrice(item) }} {{ return.currency }}
            </p>
          </ng-container>
          <ng-container *ngIf="showRefundRejectedPriceText(item)">
            <p
              [class]="setRippleColor(item.merchant_compensation_choice)"
              *ngIf="
                !isPackageReceived &&
                isNonRefundCompensationChoice(item.merchant_compensation_choice)
              "
              [ngClass]="{
                'new-item-rejected': !checkIfExchangeRejectedResolved(item)
              }"
            >
              <span
                >{{
                  item.merchant_compensation_choice ===
                  CompensationOption.StoreCredit
                    ? "Store credit"
                    : "New item"
                }}
              </span>
            </p>
            <p
              class="original-price"
              *ngIf="showOriginalPrice(item)"
              [ngClass]="{
                'text-center': checkOnSale(item),
                'text-line-through':
                  item.merchant_compensation_choice !==
                  CompensationOption.StoreCredit,
                'float-left': isPackageReceived
              }"
            >
              {{ checkOnSale(item) === true && item.merchant_compensation_choice === CompensationOption.Refund ? getOriginalPrice(item) : getTotalPrice(item) }} {{ return.currency }}
              <span *ngIf="isNotPackageReceived && checkOnSale(item)">
                <mat-icon
                  [ngClass]="
                    isRefundDenied && checkOnSale(item)
                      ? 'final-step'
                      : 'help-icon'
                  "
                  class="help-icon pointer"
                  matTooltipPosition="above"
                  matTooltip="Bought with discount"
                  matTooltipClass="mat-tooltip"
                  >help_outline</mat-icon
                >
              </span>
            </p>
            <!-- <p class="refund-text" *ngIf="showChangedToRefundText(item)">
              Changed to refund
            </p> -->
          </ng-container>
        </div>
      </td>
      <!-- End of price column -->

      <!-- Reason/decision column -->
      <td
        class="reason"
        *ngIf="
          displayDecisionColumn() &&
          item.status !== ReturnItemStatusEnum.ItemDenied
        "
      >
        <span *ngIf="shouldDisplayReason(); else decision">
          <b>{{ item.return_reason.return_reason_type }}</b
          >: {{ getReason(item) }}
          <span *ngIf="getSubReason(item)"> - {{ getSubReason(item) }}</span>
        </span>
        <ng-template #decision>
          <mat-radio-group
            aria-label="Select decision"
            [(ngModel)]="item.decision"
            (change)="onDecisionChange(item)"
          >
            <mat-radio-button
              class="decision-radio mr-15"
              [ngClass]="{
                'visible-comment-row': amountChanged(item) && item.decision
              }"
              [value]="true"
              >Accept</mat-radio-button
            >
            <mat-radio-button
              class="decision-radio"
              [ngClass]="{
                'visible-comment-row': amountChanged(item) && item.decision
              }"
              [value]="false"
              [disabled]="isExchangeRefund(item)"
              >Reject</mat-radio-button
            >
          </mat-radio-group>
          <p class="m-0">
            <mat-checkbox
              *ngIf="!isComplaintRequest && returnSettings.use_manual_restock"
              color="accent"
              class="mb-15 mt-20"
              [(ngModel)]="item.should_restock"
            >
              Restock item
            </mat-checkbox>
          </p>
        </ng-template>
      </td>
      <!-- End of reason/decision column -->

      <!-- Status column -->
      <td
        class="status"
        *ngIf="
          isIncomingResolved || isComplaintItemRejectedPackageReceived(item)
        "
        [ngClass]="{
          'resolved-status': isResolved,
          'complaint-status':
            (hasComplaint(return.items) || !shouldDisplayReason()) &&
            !isComplaintItemRejectedPackageReceived(item),
          reason: isComplaintItemRejectedPackageReceived(item)
        }"
      >
        <span
          *ngIf="
            isIncoming &&
              !complaintReturnItemsStatus(
                item.status,
                return.items,
                complaintSettings.should_item_be_returned
              );
            else resolvedMessage
          "
          class="ripple"
          [ngClass]="item.reject_cause ? 'rejected' : 'incoming'"
          >{{ item.reject_cause ? "Rejected" : "Incoming" }}</span
        >
        <ng-template #resolvedMessage>
          <span
            class="ripple"
            [ngClass]="addResolvedClass(item.status, item)"
            >{{ displayResolvedMessage(item.status, item) }}</span
          >
          <p class="restocked">
            Item {{ item.should_restock ? "" : "not" }} restocked
          </p>
        </ng-template>
      </td>
      <!-- End of status column -->
    </tr>

    <!-- Attached files row -->
    <tr *ngIf="showAttached(item)">
      <td colspan="3" class="table-spacing">
        <div class="d-flex returns">
          <div class="images">
            <p class="font-500 font-14 text-almost-black mb-6">
              Uploaded images
            </p>
            <ng-container
              *ngIf="
                item.uploaded_images && item.uploaded_images.length > 0;
                else noImage
              "
            >
              <img
                *ngFor="let uploadedImg of item.uploaded_images"
                [src]="uploadedImg.url"
                class="uploaded-image pointer"
                (click)="openImagePreview(item, uploadedImg.url)"
              />
            </ng-container>

            <ng-template #noImage>
              <p class="no-content-text">No images were uploaded.</p>
            </ng-template>
          </div>
          <div class="comments">
            <p class="font-500 font-14 text-almost-black mb-6">
              Additional comments
            </p>
            <p
              class="additional-comment"
              *ngIf="item.additional_information; else noComment"
            >
              {{ item.additional_information }}
            </p>
            <ng-template #noComment>
              <p class="no-content-text">
                Nothing was written by the customer.
              </p>
            </ng-template>
          </div>
        </div>
      </td>
    </tr>
    <!-- End of attached files row -->

    <!-- Amount change reason/Rejection reason comment display row -->
    <tr *ngIf="showRejectCommentRow(item)">
      <ng-container
        *ngIf="
          item.reject_cause &&
            (isNotRequestedPackageReceived ||
              isComplaintItemRejectedPackageReceived(item));
          else rejectionReason
        "
      >
        <td colspan="3" class="pr-0">
          <p class="font-14 font-500 text-almost-black mb-0">
            {{ amountChangeTitle(item) }}
          </p>
          <p class="font-14 font-normal text-almost-black mt-0">
            {{ item.reject_cause }}
          </p>
        </td>
        <td></td>
      </ng-container>
      <ng-template #rejectionReason>
        <td colspan="3" class="pr-0 input-spacing">
          <div class="rejection-field">
            <span class="comment">Rejection reason</span>
            <span
              class="font-12 font-500 ml-75"
              [ngClass]="
                !item.reject_cause ? 'required-input' : 'completed-input'
              "
            >
              {{ !item.reject_cause ? "Required" : "" }}
              <ng-container *ngIf="item.reject_cause">
                <img src="../assets/images/icons/completed.png" />
              </ng-container>
            </span>
            <br />

            <input
              class="rejection-input"
              [(ngModel)]="item.reject_cause"
              (ngModelChange)="updateReturn()"
              placeholder="Write rejection reason.."
            />
          </div>
        </td>
      </ng-template>
    </tr>
    <!-- End of amount change/Rejection reason comments row -->

    <!-- Comment inputs row -->
    <tr *ngIf="showAmountInputsRow(item)">
      <td colspan="4" class="table-spacing">
        <div class="rejection-field">
          <span class="comment">{{ commentInputTitle(item) }}</span>
          <span
            class="font-12 font-500"
            [ngClass]="
              !item.reject_cause ? 'required-input' : 'completed-input'
            "
          >
            {{ !item.reject_cause ? "Required" : "" }}
            <ng-container *ngIf="item.reject_cause">
              <img src="../assets/images/icons/completed.png" />
            </ng-container>
          </span>
          <br />

          <input
            class="rejection-input"
            [(ngModel)]="item.reject_cause"
            (ngModelChange)="updateReturn()"
            [placeholder]="commentInputPlaceholder(item)"
          />
        </div>
      </td>
    </tr>
    <!-- End of comment inputs row -->

    <!-- HR row -->
    <tr>
      <td class="border" colspan="5">
        <hr />
      </td>
    </tr>
  </ng-container>
</table>

<app-images-preview
  [selectedImage]="selectedImage"
  [imgSrc]="selectedImgSrc"
></app-images-preview>
