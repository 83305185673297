<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New integration"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="Bring"
      logo="bring"
      [shippingCompany]="shippingCarrier.Bring"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <ng-container *ngIf="showActivationSettings">
      <hr />
      <div class="section">
        <h4 class="section-title">Your agreement</h4>
        <p class="section section-description">
          Activate Bring today and start to autogenerate your return shipping
          slips. When your customers have registered their return they will
          automatically receive an email with a return shipping slip as a PDF.
          Your customers can then print the label at home.
        </p>
        <div class="d-flex">
          <div class="slip-info">
            <div class="d-flex">
              <img src="assets/images/icons/slip.png" alt="Barcode" />
              <div>
                <p class="section section-subtitle m-0">
                  Autogenerated return slips
                </p>
                <p class="section slip-description mt-0">
                  Fully automate the return process with autogeneration.
                </p>
              </div>
            </div>
            <p class="section section-subtitle">
              <b>These services will be available to you:</b>
            </p>

            <div class="d-flex" *ngFor="let service of availableServices">
              <mat-icon class="mr-10">done</mat-icon>
              <div>
                <p class="section section-subtitle m-0">
                  {{ service.title }}
                </p>
                <p class="section slip-description mt-0">
                  {{ service.description }}
                </p>
              </div>
            </div>
          </div>
          <!-- Currently we don't have a contract with Bring for this, so this is hidden until later -->
          <!-- <app-request-quote [shippingCarrierName]="'Bring'"
          requestQuote="Placeholder"></app-request-quote> -->
        </div>
      </div>

      <div class="section">
        <h4 class="section-title">Your credentials</h4>
        <p class="section section-description">
          Please fill in this information, as we need it to connect the shipping
          carrier. Guide on how to find those can be accessed below.
        </p>

        <app-alert
          *ngIf="showAlert(alertType.Credentials)"
          type="Note"
          [showCloseButton]="true"
          [text]="note"
          imgIcon="../assets/images/icons/alert-triangle.png"
        ></app-alert>

        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Api Uid</p>
            <input
              type="text"
              name="portalKey"
              class="section-input"
              [(ngModel)]="serviceInformation.apiUserId"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect
            </p>
          </div>
          <div class="w-half">
            <p class="section section-input-label">Api Key</p>
            <div class="d-flex">
              <input
                [type]="showPassword ? 'text' : 'password'"
                type="password"
                name="secret"
                class="section-input"
                [(ngModel)]="serviceInformation.apiSecretKey"
                placeholder="*******"
                (change)="onValueChanged()"
                [disabled]="isLoading || isSaving"
                [ngClass]="{ error: isInformationInvalid() }"
              />
              <img
                (click)="showPassword = !showPassword"
                class="section-password-eye"
                src="../assets/images/svg/password-eye.svg"
                alt="EYE"
              />
            </div>
          </div>
          <p class="validation-message" *ngIf="isInformationInvalid()">
            The information is incorrect
          </p>
        </div>
        <div class="d-flex">
          <div class="w-half">
            <p class="section section-input-label">Customer Number</p>
            <input
              type="text"
              name="key"
              class="section-input"
              [(ngModel)]="serviceInformation.apiCustomerNumber"
              placeholder="e.g. 01010101"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect
            </p>
          </div>
          <div class="w-half">
            <p class="section section-input-label">Phone Number</p>
            <input
              type="text"
              name="key"
              class="section-input"
              [(ngModel)]="serviceInformation.apiPhoneNumber"
              placeholder="e.g. +46 000 000 000"
              (change)="onValueChanged()"
              [disabled]="isLoading || isSaving"
              [ngClass]="{ error: isInformationInvalid() }"
            />
            <p class="validation-message" *ngIf="isInformationInvalid()">
              The information is incorrect.
            </p>
          </div>
        </div>

        <app-credentials-guide provider="Bring"></app-credentials-guide>
      </div>

      <app-return-address
        [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
        [note]="note"
        [updateMode]="hasProccessStarted()"
        (newReturnAddressIdEvent)="newLocation($event)"
      ></app-return-address>

      <app-available-carrier-services
        [availableServices]="availableServices"
        description="Please select which Bring services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
        (infoModalOpened)="openInfoModal()"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-available-carrier-services>

      <app-paperless-trade
        (selectedValue)="proformaSelectedChange($event)"
        [paperlessTrade]="serviceInformation.apiProforma"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [carrier]="shippingCarrier.Bring"
        [isReturboGenerated]="true"
        [apiOrgNumber]="serviceInformation.apiOrgNumber"
        (orgNumberValue)="newOrgNumber($event)"
      ></app-paperless-trade>

      <app-enable-countries
        [supportedCountries]="supportedCountries"
        [availableServices]="availableServices"
        [carrier]="shippingCarrier.Bring"
        description="Create optional country rules for your current carriers. For example, if you only want to use Bring in Norway, choose Norway as a selected market. You can also see which countries the carrier is available in."
        (selectedMarkets)="addMarketsToIntegration($event)"
        [disabled]="isLoading || isSaving"
        [updateMode]="hasProccessStarted()"
        [note]="note"
      ></app-enable-countries>

      <app-carrier-actions
        [shippingIntegrationInfo]="shippingIntegrationInfo"
        [customerNumber]="serviceInformation.apiCustomerNumber"
        description="The Bring's activation will be instant and it will be ready to be used."
        [provider]="shippingCarrier.Bring"
        (saving)="onSaveUpdate($event)"
        [availableServices]="availableServices"
        [serviceInformations]="serviceInformation"
        [returnLocationAddressId]="returnAddressId"
      ></app-carrier-actions>
    </ng-container>
  </div>
</app-settings-section>

<app-modal id="bring-services">
  <div>
    <h1 class="font-semiBold font-22 text-almost-black mt-0">Bring services</h1>
    <p class="font-normal font-14 text-almost-black"></p>
    <p class="font-normal font-14 text-almost-black">
      <b>Parcel Norway Domestic (Return from pick-up point)</b> - Return via a
      PickUp location within Norway. For parcel up to 35 kgs. The shipment can
      be tracked. <br />Sender location: NO. Delivery location: NO.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Parcel Norway Domestic (Return to business)</b> - Return via a PickUp
      location within Norway. For parcel up to 35 kgs. The shipment can be
      tracked. <br />Sender location: NO. Delivery location: NO.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Parcel Norway Domestic (Return package in mailbox)</b> - For smaller
      parcels, up to 5kgs. Return via a mailbox or by handling-in the parcel at
      a PickUp location. The shipment can be tracked. <br />Sender location: NO.
      Delivery location: NO.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>PickUp Parcel</b> - Customer sends the item you and you need to pick it
      up from a PickUp point. Up to 20 kgs. The shipment can be tracked.
      <b>Not recommended.</b> <br />Sender location: NO, SE, DK, FI. Delivery
      location: All countries except Russia and Belarus.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>PickUp Parcel Bulk</b> - Customer sends the up to 20 item you and you
      need to pick it up from a PickUp point. Up to 20 kgs. The shipment can be
      tracked. <b>Not recommended.</b> <br />Sender location: All countries.
      Delivery location: NO, SE, DK, FI, DE, EE, FO, GB, IS, NL
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Home Delivery Parcel</b> - The return is delivered to your door. Up to
      35 kgs. The shipment can be tracked. <b>Not recommended.</b> <br />Sender
      location: All countries. Delivery location: NO, SE, DK, FI.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>PickUp Parcel Return</b> - For returns within the Nordics. For parcel
      up to 20 kgs. The parcel can be tracked. <br />Sender location: NO, SE,
      DK, FI. Delivery location: NO, SE, DK, FI.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>PickUp Parcel Return Bulk</b> - For returns within the Nordics. For
      parcel up to 20 kgs. The parcel can be tracked. <br />Sender location: NO,
      SE, DK, FI. Delivery location: NO, SE, DK, FI.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Express International</b> - Same day delivery within Nordics. The
      parcel can be tracked. <br />Sender location: NO, SE, DK. Delivery
      location: All countries.
    </p>
    <p class="font-normal font-14 text-almost-black">
      <b>Express Economy</b> - The parcel can be tracked. <br />Sender location:
      SE, DK. Delivery location: All countries.
    </p>
    <p class="font-normal font-14 text-almost-black">
      For more information please visit
      <a
        class="text-primary"
        href="https://www.bring.com/services"
        target="_blank"
      >
        Bring's official website</a
      >.
    </p>
  </div>
</app-modal>
