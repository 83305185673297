<div class="dialog-header">
  <h1 class="font-24 text-almost-black font-700 mt-0">
    Free carrier limit reached
  </h1>
</div>

<mat-dialog-content>
  <p class="font-normal font-14 text-almost-black">
    Based on your current <b>Pay-as-you-go</b> plan, you only have one carrier
    included in your plan.
  </p>
  <p class="font-normal font-14 text-almost-black">
    If you want to activate more than 1 carrier – you can do so for
    <b>an additional monthly charge of US $15</b>. We will include this charge
    and bill you the amount at the end of your monthly billing cycle.
  </p>
  <p class="font-normal font-14 text-almost-black">
    We will <b>only start billing you after you have activated the carrier</b>.
    Do you want to proceed?
  </p>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <button class="btn btn-tertiary btn-md mr-16" mat-dialog-close>Back</button>
  <button class="btn btn-primary btn-md mr-0" mat-dialog-close="proceed">
    Proceed
  </button>
</mat-dialog-actions>
