import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PopupService } from 'src/app/shared/services/popup.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(public dialog: MatDialog, private popupService: PopupService) {}

  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    return component.canDeactivate() || this.openConfirmDialog();
  }

  openConfirmDialog(): boolean {
    this.popupService.setNotActivatedPopup(true);

    return false;
  }
}
