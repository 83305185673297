import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { BillingService } from '../shared/services/billing.service';
import { ErrorService } from '../shared/services/error.service';
import { FeatureService } from '../shared/services/feature.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private billingService: BillingService,
    private featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const hostQuery = params.get('host');
      console.log('Initiating authentication process...');
      this.authService.initAuthentication(hostQuery || '');
      this.init();
    });
  }

  private init(): void {
    this.authService.isClientAuthenticated().subscribe((isAuthenticated) => {
      console.log('Authenticated', isAuthenticated);

      if (!isAuthenticated) {
        return;
      }

      this.billingService.getAccountStatus().subscribe({
        next: (isActive) => {
          this.billingService.setAccountStatus(isActive);
          this.router.navigate(['returns']);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load account status');
        },
      });
    });

    this.featureService.getNewFeatures().subscribe();
  }
}
