<app-modal id="preview-images" size="preview-slip" [dismissable]="false">
  <ng-container *ngIf="selectedImage">
    <h2 class="font-700 font-24 text-almost-black mb-20 mt-0">
      {{ selectedImage.item.name }}
      <mat-icon class="float-right" (click)="closeModal()">close</mat-icon>
    </h2>

    <div class="d-flex w-full">
      <div class="navigation" (click)="prev()">
        <mat-icon>chevron_left</mat-icon>
      </div>
      <div class="big-image-container">
        <img [src]="selectedImage.img" alt="Selected image" />
      </div>
      <div class="navigation navigation-right" (click)="next()">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>

    <div class="d-flex images-list">
      <img
        class="pointer"
        (click)="selectImage(img.url)"
        *ngFor="let img of selectedImage.item.uploaded_images"
        [src]="img.url"
        [ngClass]="{ selected: selectedImage.img === img.url }"
      />
    </div>
  </ng-container>
</app-modal>
