<div class="dialog-header">
  <span mat-dialog-title class="dialog-title"
    >Reject {{ data.itemsCount }} item(s) in the request?</span
  >
  <button mat-icon-button mat-dialog-close>
    <mat-icon class="mb-8">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p class="font-normal font-14 text-almost-black">
    You are about to reject {{ data.itemsCount }} item(s) in this request. Would
    you like to proceed with your decision?
  </p>

  <div class="d-flex actions">
    <button class="btn cancel-btn mr-16" mat-dialog-close>Go back</button>
    <button class="btn btn-primary" (click)="confirmReject()">Confirm</button>
  </div>
</mat-dialog-content>
