<app-modal id="hide-email" [dismissable]="false" size="x-small">
  <ng-container *ngIf="selectedEmail.type === 'deactivate'; else activateEmail">
    <div>
      <h1 class="header">You are about to deactivate this email</h1>
      <p class="text">
        You are about to deactivate the "{{ selectedEmail.title }}" email. This
        means it will not be automatically sent to your customers. Do you wish
        to proceed?
      </p>
    </div>
  </ng-container>

  <ng-template #activateEmail>
    <h1 class="header">You are activating this email</h1>
    <p class="text">
      You are about to activate the "{{ selectedEmail.title }}" email. This
      means that your customers will start automatically receiving this email
      when the return has been delivered to your warehouse. Do you wish to
      proceed?
    </p>
  </ng-template>

  <div class="actions">
    <app-button buttonStyle="secondary" (click)="closeModal()"
      >Cancel</app-button
    >
    <app-button
      class="ml-16 button-text"
      buttonStyle="primary"
      (click)="toggleEmail()"
      >{{
        selectedEmail.type === "deactivate"
          ? "Deactivate email"
          : "Activate email"
      }}</app-button
    >
  </div>
</app-modal>
