import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-integration-settings-dashboard',
  templateUrl: './integration-settings-dashboard.component.html',
  styleUrls: ['./integration-settings-dashboard.component.scss']
})
export class IntegrationSettingsDashboardComponent implements OnInit {
  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((item) => {
      const element = document.querySelector("#" + item)
      if (element) element.scrollIntoView()
    })
  }
}
