import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-credentials-guide',
  templateUrl: './credentials-guide.component.html',
  styleUrls: ['./credentials-guide.component.scss'],
})
export class CredentialsGuideComponent {
  @Input() provider = '';

  openGuidePdf(): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = `assets/credential-guides/${this.provider} credentials guide.pdf`;
    document.body.appendChild(link);
    link.target = '_blank';
    link.click();
    link.remove();
  }
}
