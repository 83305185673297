import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { Language } from 'src/app/settings/models/language.model';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  title = 'Customer notification emails customization';
  description =
    'Here you can personalize your email communication. These are the messages your customers will receive as soon as the status is updated. Please remember that if you have more than one communication language selected - make sure to write the same communication emails in another language.';
  noteText =
    'Since you have selected that you will not be sending shipping slips through Returbo, we suggest that you write instructions on how customers can send a return back to your store in the first email field below.';
  usefulKeywordsText = '{ } Useful keywords';
  isLoading = true;
  isSaving = false;
  sendThroughReturbo = false;
  automaticRequestApproval = false;
  activeLanguage: Language = new Language();
  languages: Language[] | undefined = [];

  languageSettings: LanguageSettings = new LanguageSettings();

  activeTab = 'return-flow-emails';
  tabs = [
    { name: 'return-flow-emails', title: 'Return flow' },
    { name: 'complaint-flow-emails', title: 'Complaint flow' },
    { name: 'exchange-flow-emails', title: 'Exchange flow' },
  ];
  returnReasonTypeEnum = ReturnReasonType;

  constructor(
    private errorService: ErrorService,
    private notificationsService: NotificationsService,
    private complaintSettingsService: ComplaintSettingsService,
    private exchangesSettingsService: ExchangeSettingsService,
    private languageSettingsService: LanguageSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.getNotificationEmails();

    const languageSettings = this.languageSettingsService.getSubjectLanguageSettings();
    const returnSettings = this.returnSettingsService.getSubjectReturnSettings();
    const complaintSettings = this.complaintSettingsService.getComplaintSettings();
    const exchangeSettings = this.exchangesSettingsService.getExchangeSettings();

    combineLatest([languageSettings, returnSettings, complaintSettings, exchangeSettings]).subscribe(
      ([languageSettings, returnSettings, complaintSettings, exchangeSettings]) => {

        if (this.languageSettingsService.hasLanguageSettingsLoaded() &&
            this.returnSettingsService.hasReturnSettingsLoaded()
        ) {
          this.sendThroughReturbo = returnSettings.printed_return_slips;
          this.automaticRequestApproval = returnSettings.automatically_accept_requests;
          this.languageSettings = languageSettings;
  
          this.getLanguageList();
          this.setActiveLanguage();
          this.isLoading = false;
        }

        if (complaintSettings.deadline && !complaintSettings.is_enabled) {
          this.tabs = this.tabs.filter(
            (tab) => tab.name !== 'complaint-flow-emails'
          );
        }
        if (exchangeSettings.deadline &&!exchangeSettings.is_enabled) {
          this.tabs = this.tabs.filter(
            (tab) => tab.name !== 'exchange-flow-emails'
          );
        }
      }
    );

    this.notificationsService
      .getIsSavingNotification()
      .subscribe((isSaving) => {
        this.isSaving = isSaving;
    });

    this.notificationsService.getSavedNotification().subscribe((value) => {
      if (value) {
        this.getNotificationEmails();
      }
    });
  }

  private setActiveLanguage() {
    this.activeLanguage = this.getLanguage(
      this.languageSettings.default_language ?? ''
    );
    this.notificationsService.setNotificationLanguage(this.activeLanguage);
  }

  private getLanguageList() {
    this.languages = this.languageSettings?.languages;
  }

  public getActiveLanguages(): Language[] {
    return (
      this.languageSettings.languages.filter(
        (x: Language) => x.is_active === true
      ) ?? []
    );
  }

  public selectLanguage(language: Language) {
    this.activeLanguage = language;
    this.notificationsService.setNotificationLanguage(this.activeLanguage);
  }

  private getLanguage(id: string): Language {
    let language: Language =
      this.languageSettings?.languages[0] ?? new Language();

    this.languageSettings?.languages.forEach((lang: Language) => {
      if (lang.id === id) {
        language = lang;
        return;
      }
    });

    return language;
  }

  public getCountryCode(langName: string | undefined): string {
    const countryCode = this.languages?.filter(
      (language) => language.name === langName
    )[0]?.country_code;
    return countryCode?.toLowerCase() ?? '';
  }

  showKeywordsModal(): void {
    this.notificationsService.toggleUsefulKeywordModal(true);
  }

  switchTab(type: string): void {
    this.activeTab = type;
  }

  getNotificationEmails(): void {
    this.notificationsService.getNotificationEmails().subscribe({
      next: (emails) => {
        this.notificationsService.setNotificationsEmails(emails);
        this.notificationsService.setSavedNotification(false);
      },
      error: () => {
        this.errorService.showErrorSnackBar(
          'Failed to get notification emails'
        );
      },
    });
  }
}
