import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ReturnReason } from 'src/app/settings/models/return-reason.model';
import { SubReason } from 'src/app/settings/models/sub-reason.model';

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  private reasonOnEdit: BehaviorSubject<ReturnReason> = new BehaviorSubject(
    new ReturnReason()
  );
  private subReasonOnEdit: BehaviorSubject<SubReason> = new BehaviorSubject(
    new SubReason()
  );

  setReasonOnEditSubject(reason: ReturnReason): void {
    this.reasonOnEdit.next(reason);
  }
  getReasonOnEditSubject(): Observable<ReturnReason> {
    return this.reasonOnEdit.asObservable();
  }

  setSubReasonOnEditSubject(subReason: SubReason): void {
    this.subReasonOnEdit.next(subReason);
  }
  getSubReasonOnEditSubject(): Observable<SubReason> {
    return this.subReasonOnEdit.asObservable();
  }
}
