import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { ModalService } from 'src/app/components/modal/modal.service';
import { ColumnList } from 'src/app/models/column-list.model';
import { ReturnsResponse } from 'src/app/models/return-request.model';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { FeatureTracker } from 'src/app/settings/models/features-tracker.model';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { StatusName } from 'src/app/shared/enums/status-name.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { ReturnsService } from '../returns.service';
import { ItemsTableService } from '../sidenav/left-content/items-table/items-table.service';
import {
  defaultTableColumns,
  displayedColumnsList,
  returnStatusesFilters
} from '../table-filters';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { SupportSettingsService } from 'src/app/shared/services/settings/support-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { WarehouseSettingsService } from 'src/app/shared/services/settings/warehouse-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';
import { ReasonSettingsService } from 'src/app/shared/services/settings/reason-settings.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { ListViewRequestUpdate } from 'src/app/models/list-view-request-update.model';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit {
  returnStatuses = returnStatusesFilters;
  returnsResponse: ReturnsResponse = {
    returns: [],
    total_count: 0,
    incoming_returns_count: 0,
    pending_approval_returns_count: 0,
    requested_returns_count: 0,
  } as ReturnsResponse;

  tableColumns = defaultTableColumns;
  displayedColumns = displayedColumnsList;
  ReturnStatusEnum = ReturnStatusEnum;
  isLoading = true;

  status: StatusFilter = {
    title: '',
    amount: 0,
    selected: false,
    status: [],
    name: '',
    nameTitle: '',
    class: '',
    updateTitle: '',
    noReturn: {
      title: '',
      description: '',
    },
    headerStatus: {
      colorClass: '',
      icon: '',
      subtitle: '',
    },
  };

  seenNewsModal = false;
  date = 'September 2024';
  featureTracker: FeatureTracker[] = [];
  startDate = '';
  endDate = '';

  returnData: string[] = [];

  constructor(
    private returnsService: ReturnsService,
    private returnSettingsService: ReturnSettingsService,
    private accountSettingsService: AccountSettingsService,
    private supportSettingsService: SupportSettingsService,
    private formSettingsService: FormSettingsService,
    private reasonSettingsService: ReasonSettingsService,
    private shopifySettingsService: ShopifySettingsService,
    private warehouseSettingsService: WarehouseSettingsService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private itemsTableService: ItemsTableService,
    private errorService: ErrorService,
    private modalService: ModalService,
    private featureService: FeatureService
  ) {}

  ngOnInit(): void {
    this.featureService
      .getNewFeatures()
      .subscribe((features: FeatureTracker[]) => {
        this.featureTracker = features;
        if (this.featureTracker.length && 
            !this.newFeaturesSeen() && 
            !this.seenNewsModal) {
          setTimeout(() => {
            this.modalService.open('new-features');
          }, 100);
          this.seenNewsModal = true;
        }
      });

    this.returnsService
      .getReturnsDataSubject()
      .subscribe((data: ReturnsResponse) => {
        this.returnsResponse = data;
      });

    this.itemsTableService
      .getRequestToUpdate()
      .subscribe((res: ListViewRequestUpdate) => {
        const currentRequest = this.returnsResponse?.returns?.find(
          (request) => request.id === res.requestId
        );

        if (currentRequest && currentRequest.status !== res.newStatus) {
          this.returnsService.switchStatusOnRequest(res);
        }
    });

    this.getReturns();
    this.displayedColumns = [
      ...this.displayedColumns,
      { name: '', checked: true, columnName: 'actions' },
    ];
    this.itemsTableService.setDisplayedColumns(this.displayedColumns);

    this.getReturboSettings();
    this.getShopifySettings();
  }

  newFeaturesSeen(): boolean {
    return this.featureTracker.every((feature) => feature.is_seen);
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  getReturns(statuses?: string[], skip?: number, query?: string): void {
    this.isLoading = true;
    this.returnsService.getReturnsList(statuses, skip, query).subscribe({
      next: (res) => {
        this.returnsService.setReturnsDataSubject({
          ...res,
        });
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load returns');
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  getReturboSettings(): void {
    this.returnSettingsService
      .getReturnSettings()
      .pipe(first((x) => x.deadline != 0))
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load return settings');
        },
    });
    
    this.supportSettingsService
      .getSupportSettings()
      .pipe(first((x) => x.merchant_support_email != ''))
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load support settings');
        }
    });

    this.formSettingsService
      .getFormSettings()
      .pipe(first((x) => x.primary_color != ''))
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load form settings');
        }
      });

    this.reasonSettingsService
      .getReasonSettings()
      .pipe(first())
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load reasons');
        }
      });

    this.accountSettingsService
      .getAccountSettings()
      .pipe(first((x) => x.company_name != ''))
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load account settings');
        }
    });
  }

  getShopifySettings(): void {
    this.shopifySettingsService
      .getShopifySettings()
      .pipe(first((x) => x.account_domain != ''))
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load Shopify settings');
        }
    });

    this.warehouseSettingsService
      .getWarehouseSettings()
      .pipe(first())
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load warehouse settings');
        }
    });

    this.pricePlanSettingsService
      .getSelectedPricePlan()
      .pipe(first())
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load selected price plan');
        }
    });
  }

  checkTypeLengths(name: string) {
    if (name === StatusName.Requests) {
      return this.returnsResponse.requested_returns_count;
    }
    if (name === StatusName.Incoming) {
      return this.returnsResponse.incoming_returns_count;
    }
    if (name === StatusName.PendingApproval) {
      return this.returnsResponse.pending_approval_returns_count;
    }
    return 0;
  }

  setSelectedType(selectedStatus: StatusFilter): void {
    this.status = selectedStatus;
    this.getReturns(selectedStatus.status);
    selectedStatus.selected = true;
    this.returnStatuses.forEach((status) => {
      status.selected = status.name === selectedStatus.name;
    });
    this.itemsTableService.setSelectedStatus(selectedStatus);
    this.returnsService.setReturnsDataSubject(this.returnsResponse);
  }

  filterTableColumns(): void {
    const columns: ColumnList[] = [];
    const filteredColumns = [...this.tableColumns];
    filteredColumns.forEach((column: ColumnList) => {
      if (column.checked) {
        columns.push(column);
      }
    });
    this.displayedColumns = [...columns];

    this.itemsTableService.setDisplayedColumns(this.displayedColumns);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.returnsService.setFilter(filterValue.trim().toLowerCase());
  }

  getStatusClass(selectedStatus: StatusFilter): string {
    return this.returnStatuses.filter(
      (status: StatusFilter) =>
        selectedStatus.selected && selectedStatus.status[0] === status.status[0]
    ).length > 0
      ? this.returnStatuses.filter(
          (status: StatusFilter) =>
            selectedStatus.selected &&
            selectedStatus.status[0] === status.status[0]
        )[0].class
      : '';
  }

  getMessage() {
    return this.returnStatuses.filter(
      (status: StatusFilter) => status.selected
    )[0].noReturn;
  }

  openDownloadModal(): void {
    this.modalService.open('download-csv');
  }

  createCSV(): void {
    const csvContent =
      'data:text/csv;charset=utf-8,' + this.returnData.join('\n');
    const encodedUri = encodeURI(csvContent);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', encodedUri);
    downloadLink.setAttribute(
      'download',
      `returbo_data_${new Date().toLocaleDateString()}.csv`
    );
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  downloadCSV(): void {
    this.isLoading = true;

    this.returnsService
      .getUserInfo(this.startDate, this.endDate)
      .pipe(first((date) => date !== null))
      .subscribe({
        next: (info) => {
          this.returnData = info;
        },
        error: () => {
          this.errorService.showErrorSnackBar("Couldn't get user information");
        },
        complete: () => {
          this.isLoading = false;
          this.createCSV();
          this.closeModal();
        },
      });
  }

  setStartDate(date: Date | null): void {
    if (date) {
      this.startDate = this.adjustDateFormat(date);
    }
  }

  setEndDate(date: Date | null): void {
    if (date) {
      this.endDate = this.adjustDateFormat(date);
    }
  }

  adjustDateFormat(date: Date): string {
    return date
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(/\//g, '-');
  }

  checkDownload(): boolean {
    if (this.startDate && this.endDate) {
      return true;
    }
    return false;
  }
}
