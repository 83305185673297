<h1 class="font-36 title mb-10">
  Request {{ return.order_name }}
  <mat-icon
    *ngIf="return.status !== returnStatusEnum.Archived"
    class="float-right pointer"
    fontSet="material-icons-outlined"
    (click)="openModal()"
    >inventory_2</mat-icon
  >
</h1>
<p class="d-flex font-normal font-14 subtitle mt-0" *ngIf="return.created_at">
  <span class="text"
    >{{ subtitle }}
    {{ return.created_at | customDateFormat : "fullDate" }}</span
  >

  <span class="status-ripple" [ngClass]="getColorClass()">
    <img
      src="assets/images/icons/{{ getStatusIcon() }}"
      alt="{{ getStatusName() }}"
    />
    {{ getStatusName() }}</span
  >
</p>
