import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDateFormat' })
export class CustomDateFormat implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(date: Date | string, type: string): string | null {
    const dateString = this.getFormattedDate(date);
    const timeString = this.getFormattedTime(date);

    if (type === 'shortDate' && !['today', 'yesterday'].includes(dateString)) {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    return `${
      type === 'shortDate' ? this.capitalizeFirstLetter(dateString) : dateString
    } at ${timeString}`;
  }

  private getFormattedDate(date: Date | string): string {
    const todayYMD = this.toYMD(new Date());
    const yesterdayYMD = this.toYMD(new Date(Date.now() - 24 * 60 * 60 * 1000));
    const givenDateYMD = this.toYMD(new Date(date));

    switch (givenDateYMD) {
      case todayYMD:
        return 'today';
      case yesterdayYMD:
        return 'yesterday';
      default:
        return this.datePipe.transform(date, 'EEEE, d MMMM yyyy') ?? '';
    }
  }

  private getFormattedTime(date: Date | string): string {
    return this.datePipe.transform(date, 'HH:mm') ?? '';
  }

  toYMD(date: Date): string {
    const y = date.getFullYear();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    return `${y}-${m}-${d}`;
  }

  capitalizeFirstLetter(text: string): string {
    return text[0].toUpperCase() + text.slice(1);
  }
}
