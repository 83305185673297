import { FilterStatusTypeEnum } from '../shared/enums/filter-status-type.enum';
import { ReturnStatusEnum } from '../shared/enums/return-status.enum';

export function filterOnStatus(
  statusType: FilterStatusTypeEnum,
  value: boolean,
  returnStatus: string
): boolean {
  let statuses: string[] = [];
  switch (statusType) {
    case FilterStatusTypeEnum.returnRequested:
      statuses = [
        ReturnStatusEnum.RequestReceived,
        ReturnStatusEnum.AwaitingShippingSlip,
      ];
      break;
    case FilterStatusTypeEnum.requestReceived:
      statuses = [ReturnStatusEnum.RequestReceived];
      break;
    case FilterStatusTypeEnum.incoming:
      statuses = [
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.ShippingSlipSkipped,
        ReturnStatusEnum.ManualShippingSlipAttached,
      ];
      break;
    case FilterStatusTypeEnum.incomingPackageReceived:
      statuses = [
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.ShippingSlipSkipped,
        ReturnStatusEnum.ManualShippingSlipAttached,
        ReturnStatusEnum.PackageReceived,
      ];
      break;
    case FilterStatusTypeEnum.resolved:
      statuses = [
        ReturnStatusEnum.RefundApproved,
        ReturnStatusEnum.RequestDenied,
        ReturnStatusEnum.RefundDenied,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.RequestManaged,
      ];
      break;
    case FilterStatusTypeEnum.resolvedPackageReceived:
      statuses = [
        ReturnStatusEnum.PackageReceived,
        ReturnStatusEnum.RefundApproved,
        ReturnStatusEnum.RequestDenied,
        ReturnStatusEnum.RefundDenied,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.RequestManaged,
      ];
      break;
    case FilterStatusTypeEnum.packageReceived:
      statuses = [ReturnStatusEnum.PackageReceived];
      break;
    case FilterStatusTypeEnum.incomingResolved:
      statuses = [
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.ShippingSlipSkipped,
        ReturnStatusEnum.ManualShippingSlipAttached,
        ReturnStatusEnum.RefundApproved,
        ReturnStatusEnum.RequestDenied,
        ReturnStatusEnum.RefundDenied,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.RequestManaged,
      ];
      break;
    case FilterStatusTypeEnum.refundDenied:
      statuses = [ReturnStatusEnum.RefundDenied];
      break;
    case FilterStatusTypeEnum.requestedPackageReceived:
      statuses = [
        ReturnStatusEnum.RequestReceived,
        ReturnStatusEnum.AwaitingShippingSlip,
        ReturnStatusEnum.PackageReceived,
      ];
      break;
    case FilterStatusTypeEnum.requestResolved:
      statuses = [
        ReturnStatusEnum.RequestReceived,
        ReturnStatusEnum.AwaitingShippingSlip,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.RequestManaged,
        ReturnStatusEnum.RequestDenied,
      ];
      break;
    case FilterStatusTypeEnum.requestSlipSkipped:
      statuses = [
        ReturnStatusEnum.RequestReceived,
        ReturnStatusEnum.AwaitingShippingSlip,
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.ShippingSlipSkipped,
      ];
      break;
    case FilterStatusTypeEnum.slipSkippedAttached:
      statuses = [
        ReturnStatusEnum.ShippingSlipSkipped,
        ReturnStatusEnum.ManualShippingSlipAttached,
      ];
      break;
    case FilterStatusTypeEnum.packageSentDropff:
      statuses = [
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.AwaitingDropOff,
      ];
      break;
    case FilterStatusTypeEnum.withShippingSlip:
      statuses = [
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.ManualShippingSlipAttached,
      ];
      break;
    case FilterStatusTypeEnum.shippingSlipSkipped:
      statuses = [ReturnStatusEnum.ShippingSlipSkipped];
      break;
  }
  return statuses.filter((status: string) => returnStatus === status).length > 0
    ? value
    : !value;
}
