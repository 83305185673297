import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-reject-items-modal',
  templateUrl: './reject-items-modal.component.html',
  styleUrls: ['./reject-items-modal.component.scss'],
})
export class RejectItemsModalComponent {
  constructor(
    public rejectItemsModal: MatDialogRef<RejectItemsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialog
  ) {}

  confirmReject(): void {
    this.rejectItemsModal.close('confirm');
  }
}
