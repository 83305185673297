<app-settings-section
  title="Shipping carriers ranking"
  description="Rank your carrier(s) and their respective services. Use the dropdown to rearrange the services to the desired position."
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
>
  <div class="carriers-ranking">
    <div *ngFor="let carrier of carriers; let i = index">
      <div class="rank-container">
        <span class="rank">{{ i + 1 }}</span>
        <mat-form-field appearance="outline" class="carrier-selector">
          <mat-select
            [(ngModel)]="rankings[i].value"
            placeholder="Select"
            (selectionChange)="onUpdateRanking($event, i)"
            [disabled]="disabled || isLoading || isSaving"
            [name]="carrier.shipping_package_type"
          >
            <mat-select-trigger class="selected-values">
              <img
                class="mr-5 icon-selected"
                src="assets/images/icons/{{
                  getShippingPackageType(carrier.shipping_package_type)?.logo
                }}-icon.png"
                [alt]="
                  getShippingPackageType(carrier.shipping_package_type)?.text
                "
              />
              <span class="font-bold mr-5"
                >{{
                  getShippingPackageType(carrier.shipping_package_type)?.text
                }}
              </span>
              {{
                getShippingPackageType(carrier.shipping_package_type)
                  ?.packageType
              }}
            </mat-select-trigger>
            <mat-option
              *ngFor="let carrier of carriers"
              [value]="carrier.shipping_package_type"
            >
              <img
                class="icon"
                src="assets/images/icons/{{
                  getShippingPackageType(carrier.shipping_package_type)?.logo
                }}-icon.png"
                [alt]="
                  getShippingPackageType(carrier.shipping_package_type)?.text
                "
              />
              <span class="font-bold"
                >{{
                  getShippingPackageType(carrier.shipping_package_type)?.text
                }}
              </span>
              {{
                getShippingPackageType(carrier.shipping_package_type)
                  ?.packageType
              }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <p class="mt-3 ml-60">
        <span>Ships to {{ getNumberOfCountries(carrier) }}.</span>
        <span *ngIf="i === index"> {{ getOriginCountriesList(carrier) }}.</span>
        <span class="pointer link" (click)="expandCountries(i)">
          {{ i === index ? "See less" : "See more" }}
        </span>
      </p>
    </div>
  </div>
</app-settings-section>
