import { ReturnReasonType } from '../shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from '../shared/enums/return-status.enum';
import { Item } from './item.model';
import { Timeline } from './timeline.model';
export class ReturnRequest {
  id = '';
  account_id = '';
  domain = '';
  order_number = '';
  order_name = '';
  order_fulfilled_at: string | undefined = '';
  order_created_at = '';
  shopify_order_id = 0;
  currency = '';
  status: ReturnStatusEnum = ReturnStatusEnum.Undefined;
  created_at = '';
  updated_at = '';
  shipping_cost = 0;
  return_shipping_cost = '';
  charge_return_shipping = false;
  refund_original_shipping = false;
  return_opened = false;
  paid_via_gift_card = false;
  language = '';
  total_price = 0;
  type: ReturnReasonType = ReturnReasonType.Return;
  new_shopify_order_link = '';
  draft_order_id? = 0;
  draft_order_link? = '';
  customer = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  };
  billing_address = {
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    zip: '',
    country_code: '',
    company: '',
    phone: '',
    province: '',
    province_code: '',
  };
  shipping_address = {
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    zip: '',
    country_code: '',
    company: '',
    phone: '',
    province: '',
    province_code: '',
  };
  items: Item[] = [];
  timeline: Timeline[] = [];

  gift_card_id = 0;
  gift_card_link = '';
  gift_card_last_characters = '';
}

export interface ReturnsResponse {
  total_count: number;
  returns: ReturnRequest[];
  requested_returns_count: number;
  incoming_returns_count: number;
  pending_approval_returns_count: number;
}
