import { Component, OnInit } from '@angular/core';
import { ShopifySettings } from 'src/app/settings/models/shopify-settings.model';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { ShopifySettingsService } from 'src/app/shared/services/settings/shopify-settings.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-activate-returbo',
  templateUrl: './activate-returbo.component.html',
  styleUrls: ['./activate-returbo.component.scss'],
})
export class ActivateReturboComponent implements OnInit {
  title = 'Activate Returbo';
  description =
    'Select whether you want the digital return form embedded in your store and/or as a standalone link.';
  embeddedURL = `<div style="position: relative; width: 100%; height: 100%;"><iframe src="{returnFormUrl}" style="width: 100%; min-height: 800px; border: none;" allowtransparency="true"></iframe></div>`;
  returnURL = `https://${environment.appLink}`;
  warningText =
    'Customers won’t be able to make a return until they access the return form. Therefore, please embed and/or copy and paste the link above where you want your customers to access it (i.e., on your store).';

  isSaving = false;
  isLoading = true;
  firstLoad = true;
  isSettingsLoaded = false;
  onBoardingCompleted = false;

  copiedReturnLink = false;
  copiedEmbedLink = false;

  activateReturboLink =
    'https://returbo.io/en/blog-how-to-embed-return-form-to-shopify-store/';

  formSettings: ReturnFormSettings = new ReturnFormSettings();
  domainSettings: ShopifySettings = new ShopifySettings();

  constructor(
    private errorService: ErrorService,
    private shopifySettingsService: ShopifySettingsService,
    private formSettingsService: FormSettingsService
  ) {}

  ngOnInit(): void {
    this.formSettingsService
      .getSubjectFormSettings()
      .subscribe((formSettings: ReturnFormSettings) => {
        this.formSettings = formSettings;
        this.checkOnBoarding();
      });

    this.shopifySettingsService
      .getSubjectShopifySettings()
      .subscribe((domain: ShopifySettings) => {
        if (domain.account_domain && this.firstLoad) {
          this.domainSettings = domain;
          this.setReturnFormUrls(domain.account_domain);
          this.isLoading = false;
        }
      });
  }

  setReturnFormUrls(account_domain: string): void {
    const accountDomainParts = account_domain.split('.');
    if (account_domain && accountDomainParts.length > 0) {
      this.returnURL += `/${accountDomainParts[0]}/my-returns/search-order`;
      this.embeddedURL = this.embeddedURL.replace(
        '{returnFormUrl}',
        this.returnURL
      );
      this.firstLoad = false;
    }
  }

  acceptTerms(): void {
    this.formSettings.is_form_embedded = !this.formSettings.is_form_embedded;
    this.checkOnBoarding();
    this.saveSettings();
  }

  checkOnBoarding(): void {
    this.formSettings.is_form_embedded
      ? (this.onBoardingCompleted = true)
      : (this.onBoardingCompleted = false);
  }

  showCopiedBox(successful: boolean, link: string): void {
    if (successful && link === this.returnURL) {
      this.copiedReturnLink = true;
    }
    if (successful && link === this.embeddedURL) {
      this.copiedEmbedLink = true;
    }
    setTimeout(() => {
      this.copiedReturnLink = false;
      this.copiedEmbedLink = false;
    }, 2000);
  }

  saveSettings(): void {
    this.isSaving = true;

    this.formSettingsService.saveFormSettings(this.formSettings).subscribe({
      next: (saved: boolean) => {
        if (saved) {
          this.formSettingsService.setFormSettingsSubject(this.formSettings);
        }
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed to save settings');
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }
}
