import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { ModalService } from 'src/app/components/modal/modal.service';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { Settings } from 'src/app/settings/models/settings';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { AccessScopeType } from 'src/app/shared/enums/access-scope-type.enum';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { FeatureEnum } from 'src/app/shared/enums/feature.enum';

@Component({
  selector: 'app-exchanges',
  templateUrl: './exchanges.component.html',
  styleUrls: ['./exchanges.component.scss'],
})
export class ExchangesComponent implements OnInit {
  title = 'Exchanges';
  description =
    'Unlock Upsell Potential: Activate Exchanges! Turn returns into opportunities. Enable exchanges now for increased sales!';
  isLoading = false;
  isSaving = false;
  settings: Settings = new Settings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();

  bannerStats = {
    text: '',
    icon: '',
    imgIcon: '',
    type: '',
    size: 'small',
    showClose: true,
    boldWords: ['Return Reasons'],
  };
  firstLoad = true;
  currentPricePlan: PricePlan = new PricePlan();
  showNote = true;

  AccessScopeType = AccessScopeType;
  draftOrderPermission = false;
  storeCreditPermission = false;
  storeCreditAccess = false;

  constructor(
    private exchangeSettingsService: ExchangeSettingsService,
    private errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private featureService: FeatureService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getAccessScopes();
    this.getExchangeSettings();
    this.getStoreCreditFeatureAccess();
  }

  getExchangeSettings(): void {
    this.exchangeSettingsService.getExchangeSubject().subscribe({
      next: (res) => {
        if (res.deadline > 0) {
          this.exchangeSettings = res;
          this.bannerStats.text =
            'Please make sure you add exchange reasons in the Return Reasons block.';
          this.bannerStats.type = 'Note';
        }
      }
    });
  }

  getAccessScopes(): void {
    this.isLoading = true;
    this.authenticationService.getAccessScope(AccessScopeType.DraftOrder).subscribe({
      next: (res) => {
        this.draftOrderPermission = res;
        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to get access scope for draft order');
        this.isLoading = false;
      },
    });

    this.authenticationService.getAccessScope(AccessScopeType.StoreCredit).subscribe({
      next: (res) => {
        this.storeCreditPermission = res;
        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to get access scope for store credit');
        this.isLoading = false;
      },
    });
  }

  getStoreCreditFeatureAccess() {
    this.isLoading = true;
    this.featureService.getFeatureAccess(FeatureEnum.StoreCredit).subscribe({
      next: (res) => {
        this.storeCreditAccess = res;
        this.isLoading = false;
      },
      error: () => {
        this.errorService.showErrorSnackBar('Unable to check access for store credit');
        this.isLoading = false;
      }
    })
  }

  toggleExchanges() {
    this.exchangeSettings.is_enabled = !this.exchangeSettings.is_enabled;
    this.saveSettings();
  }

  hideNote(): void {
    this.showNote = false;
  }

  redirectToUpdate(accessScope: AccessScopeType): void {
    this.authenticationService
      .setAccessScope(accessScope)
      .pipe(first())
      .subscribe({
        next: (res) => {
          if (window.top) {
            window.top.location.href = res;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Unable to set access scope');
        },
      });
  }

  saveSettings(): void {
    if (
      this.exchangeSettings.draft_order &&
      this.settings.shopify_settings?.warehouse_settings
        ?.send_from_warehouse_id < 0
    ) {
      this.modalService.open('missing-send-from');
      return;
    }

    this.isSaving = true;
    this.exchangeSettingsService.saveSettings(this.exchangeSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Unable to save exchange settings');
      },
      complete: () => {
        this.exchangeSettingsService.setSettings(this.exchangeSettings);
        this.isSaving = false;
      },
    });
  }

  goToLocations(): void {
    this.modalService.dismiss();
    this.exchangeSettings.draft_order = false;
    const locationsComponent = document.getElementById('locations-component');
    locationsComponent?.scrollIntoView({ behavior: 'smooth' });
  }

  closeModal(): void {
    this.modalService.dismiss();
    this.exchangeSettings.draft_order = false;
  }
}
