import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input()
  disabled = false;
  @Input()
  placeholder = '0';
  @Input()
  value: string | undefined = '';
  @Input()
  required = false;
  @Input()
  type = 'text';
  @Input()
  size = 'medium';
  @Input()
  currency = '';
  @Input()
  regExpValidator = '';
  @Input()
  error = false;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  contentUpdate(event: any) {
    this.valueChange.emit(event.target.value);
  }

  showRequired(): boolean {
    if (this.required && (this.value ?? '').length == 0) {
      return true;
    }
    return false;
  }
}
