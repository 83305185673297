<div class="wrap">
  <main>
    <nav>
      <div
        class="link"
        [routerLink]="['.', 'request-settings']"
        routerLinkActive="active"
        (click)="setAsSeen(featureTrackerEnum.ReturnSettings)"
      >
        <img src="../assets/images/icons/request-settings-icon.svg" />Request
        Settings
        <span
          class="new-feature"
          *ngIf="!newFeaturesSeen(featureTrackerEnum.ReturnSettings)"
          >New</span
        >
      </div>

      <div
        class="link"
        [routerLink]="['.', 'account']"
        routerLinkActive="active"
        (click)="setAsSeen(featureTrackerEnum.AccountSettings)"
      >
        <img src="../assets/images/icons/account-icon.svg" />Account Settings
        <span
          class="new-feature"
          *ngIf="!newFeaturesSeen(featureTrackerEnum.AccountSettings)"
          >New</span
        >
      </div>

      <div
        class="link"
        [routerLink]="['.', 'customization']"
        routerLinkActive="active"
        (click)="setAsSeen(featureTrackerEnum.CustomizationSettings)"
      >
        <img src="../assets/images/icons/customization-icon.svg" />Customization
        <span
          class="new-feature"
          *ngIf="!newFeaturesSeen(featureTrackerEnum.CustomizationSettings)"
          >New</span
        >
      </div>

      <div
        class="link"
        [routerLink]="['.', 'integrations']"
        routerLinkActive="active"
        (click)="setAsSeen(featureTrackerEnum.IntegrationSettings)"
      >
        <img src="../assets/images/icons/integrations-icon.svg" />Integrations
        <span
          class="new-feature"
          *ngIf="!newFeaturesSeen(featureTrackerEnum.IntegrationSettings)"
          >New</span
        >
      </div>
    </nav>
    <router-outlet></router-outlet>
  </main>
</div>
