<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [exludedInOnBoarding]="true"
  [hasNewFeatures]="false"
  feature="New integration"
>
  <div class="integration-container">
    <app-carrier-header
      carrier="UPS"
      logo="ups"
      [shippingCompany]="shippingCarrier.Ups"
      (activationToggled)="toggleActivation($event)"
      [disabled]="disabled"
    ></app-carrier-header>

    <div class="show-all" [ngClass]="{ expanded: showActivationSettings }">
      <ng-container *ngIf="showActivationSettings">
        <hr />
        <div class="section">
          <h4 class="section-title">Your agreement</h4>
          <p class="section section-description">
            Activate UPS today and start to autogenerate your return shipping
            slips. When your customers have registered their return they will
            automatically receive an email with a return shipping slip as a PDF.
            Your customers can then print the label at home.
          </p>
          <div class="d-flex">
            <div class="slip-info mt-16 mb-0">
              <div class="d-flex">
                <img src="assets/images/icons/slip.png" alt="Barcode" />
                <div>
                  <p class="section section-subtitle m-0">
                    Autogenerated return slips
                  </p>
                  <p class="section slip-description mt-0">
                    Fully automate the return process with autogeneration.
                  </p>
                </div>
              </div>
              <p class="section section-subtitle">
                <b>These services will be available to you:</b>
              </p>

              <div class="d-flex" *ngFor="let service of availableServices">
                <mat-icon class="mr-10">done</mat-icon>
                <div>
                  <p class="section section-subtitle m-0">
                    {{ service.title }}
                  </p>
                  <p class="section slip-description mt-0">
                    {{ service.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section mt-40">
          <h4 class="section-title">Your credentials</h4>
          <p class="section section-description">
            Please fill in this information, as we need it to connect the
            shipping carrier. Guide on how to find those can be accessed below.
          </p>
          <app-alert
            *ngIf="showAlert()"
            type="Note"
            [showCloseButton]="true"
            [text]="note"
            imgIcon="../assets/images/icons/alert-triangle.png"
          ></app-alert>
          <div class="d-flex">
            <div class="w-half">
              <p class="section section-input-label">Client ID</p>
              <input
                type="text"
                name="clientID"
                class="section-input"
                [(ngModel)]="serviceInformation.clientID"
                placeholder="e.g. 00000001"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
              <p class="validation-message" *ngIf="isInformationInvalid()">
                The information is incorrect. Wrong country?
              </p>
            </div>
            <div class="w-half">
              <p class="section section-input-label">Client secret</p>
              <div class="d-flex">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  name="secret"
                  class="section-input"
                  [(ngModel)]="serviceInformation.clientSecret"
                  placeholder="*******"
                  (change)="onValueChanged()"
                  [ngClass]="{ error: isInformationInvalid() }"
                  [disabled]="isLoading || isSaving"
                />
                <img
                  (click)="showPassword = !showPassword"
                  class="section-password-eye"
                  src="../assets/images/svg/password-eye.svg"
                  alt="EYE"
                />
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-half">
              <p class="section section-input-label">Account number</p>
              <input
                type="text"
                name="accountNumber"
                class="section-input"
                [(ngModel)]="serviceInformation.accountNumber"
                placeholder="e.g. 01010101"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
              <p class="validation-message" *ngIf="isInformationInvalid()">
                The information is incorrect.
              </p>
            </div>

            <div class="w-half">
              <p class="section section-input-label">Phone number</p>
              <input
                type="text"
                name="phone"
                class="section-input"
                [(ngModel)]="serviceInformation.phoneNumber"
                placeholder="e.g. +46 000 000 000"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
            </div>
          </div>

          <div class="d-flex">
            <div class="w-half">
              <p class="section section-input-label">
                State Province Code (USA only) *
              </p>
              <input
                type="text"
                name="state"
                class="section-input"
                [(ngModel)]="serviceInformation.stateProvince"
                placeholder="e.g. CA (for California)"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
            </div>

            <div class="w-half">
              <p class="section section-input-label">Organization number</p>
              <input
                type="text"
                name="org-number"
                class="section-input"
                [(ngModel)]="serviceInformation.orgNumber"
                placeholder="e.g. 01010101"
                (change)="onValueChanged()"
                [ngClass]="{ error: isInformationInvalid() }"
                [disabled]="isLoading || isSaving"
              />
            </div>
          </div>
          <p class="text-primary mt-2">* Required for USA based merchants</p>
          <app-credentials-guide provider="UPS"></app-credentials-guide>
        </div>

        <app-return-address
          [shippingIntegrationInfo]="getCheckedServicesIntegrationInfo()"
          [note]="note"
          [updateMode]="hasProccessStarted()"
          (newReturnAddressIdEvent)="newLocation($event)"
        ></app-return-address>

        <app-available-carrier-services
          [availableServices]="availableServices"
          description="Please select which UPS services you wish to use. These services will be activated instantly and can be later unselected at any time in this section."
          (infoModalOpened)="openInfoModal()"
          [updateMode]="hasProccessStarted()"
          [note]="note"
          [disabled]="isLoading || isSaving"
        >
        </app-available-carrier-services>

        <app-paperless-trade
          (selectedValue)="paperlessTradeSelectedChange($event)"
          [paperlessTrade]="serviceInformation.paperlessTrade"
          [disabled]="isLoading || isSaving"
          [updateMode]="hasProccessStarted()"
          [carrier]="shippingCarrier.Ups"
        ></app-paperless-trade>

        <app-enable-countries
          [supportedCountries]="supportedCountries"
          [availableServices]="availableServices"
          title="Markets you accept returns from"
          [carrier]="shippingCarrier.Ups"
          description="In this section you can (optionally) assign countries/markets to the current carrier. For example, you want to only use UPS for requests from USA, so you then assign USA as a market to this carrier and when the slips are being autogenerated, the system only uses UPS to generate slips from the selected country."
          (selectedMarkets)="addMarketsToIntegration($event)"
          [updateMode]="hasProccessStarted()"
          [note]="note"
          [disabled]="isLoading || isSaving"
        >
        </app-enable-countries>

        <div id="store-url-section">
          <h1>Your store name</h1>
          <p>
            You’ll need to paste your store name in the next step so we have
            provided it here. Please paste it exactly as shown below.
          </p>

          <h4>Store name</h4>
          <div class="linkBox">
            <p class="link-formatting">
              <span class="mr-10">{{ getStoreLink() }}</span>
            </p>
            <img
              src="assets/images/icons/copy.png"
              [cdkCopyToClipboard]="getStoreLink()"
              (cdkCopyToClipboardCopied)="showCopiedBox()"
            />
          </div>
          <span
            class="popup-alignment"
            [ngClass]="copiedLink ? 'popup' : 'popup-hidden'"
            >Copied!</span
          >
        </div>

        <app-carrier-actions
          [shippingIntegrationInfo]="shippingIntegrationInfo"
          [customerNumber]="serviceInformation.clientID"
          description="UPS activation will be instant and it will be ready to use straight away."
          [provider]="shippingCarrier.Ups"
          (saving)="onSaveUpdate($event)"
          [availableServices]="availableServices"
          [serviceInformations]="serviceInformation"
          [returnLocationAddressId]="returnAddressId"
        ></app-carrier-actions>
      </ng-container>
    </div>
  </div>
</app-settings-section>

<app-modal size="one-third" id="ups-services">
  <div>
    <h1>UPS services</h1>
    <p class="mt-16">
      <b>UPS Standard</b> - Package returns can be tracked for shipments
      weighing up to 25 kg, and special weight parcels up to 70 kg. Delivery
      times typically range from 1 to 5 days. Only available within Europe.
    </p>
    <p class="mt-24">
      <b>UPS Express</b> - Traceable next-day delivery, guaranteed by
      mid-afternoon, available in over 210 countries.
    </p>
    <p class="mt-24">
      <b>UPS Worldwide Express Plus</b> - Traceable next day delivery,
      guaranteed by early morning, available in over 210 countries.
    </p>
    <p class="mt-24">
      <b>UPS Worldwide Economy</b> - Traceable delivery in 5 to 12 working days,
      for shipments weighing up to 25 kg, and special weight parcels up to 70
      kg. Available in 210+ countries.
    </p>

    <p class="mt-24">
      For more information please
      <a class="link" href="https://www.ups.com/">visit UPS' official website</a
      >.
    </p>
  </div>
</app-modal>
