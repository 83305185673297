import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnboardingStage } from 'src/app/shared/enums/onboarding-stage.enum';
import { ShopifySettingsService } from  'src/app/shared/services/settings/shopify-settings.service';
import { Language } from '../../models/language.model';
import { ShopifySettings } from '../../models/shopify-settings.model';

@Component({
  selector: 'app-settings-section',
  templateUrl: './settings-section.component.html',
  styleUrls: ['./settings-section.component.scss'],
})
export class SettingsSectionComponent implements OnInit {
  @Input()
  public logoSource = '';
  @Input()
  public title = '';
  @Input()
  public description = '';
  @Input()
  public isLoading = false;
  @Input()
  public hasContent = false;
  @Input()
  public component = '';
  @Input()
  onBoardingStepCompleted = false;
  @Input()
  exludedInOnBoarding = false;
  @Input()
  hasNewFeatures = false;
  @Input()
  feature = '';
  @Input()
  planRestrictions: string[] = [];

  @Input()
  bannerStats = {
    text: '',
    icon: '',
    imgIcon: '',
    type: '',
    size: '',
    showClose: false,
    boldWords: [''],
  };

  @Input()
  switchStatus = false;

  @Input()
  isToggleable = false;
  @Input()
  isSliderDisabled = false;
  @Output()
  sectionEnabled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public language: Language = new Language();
  @Input()
  public languageOptions: Language[] = [];
  @Output()
  public languageChange: EventEmitter<Language> = new EventEmitter<Language>();

  OnBoardingStage = OnboardingStage;
  onboardingCompleted = OnboardingStage.Uncompleted;

  constructor(
    private shopifySettingsService: ShopifySettingsService
  ) {}

  ngOnInit(): void {
    this.shopifySettingsService
      .getSubjectShopifySettings()
      .subscribe((res: ShopifySettings) => {
        this.onboardingCompleted = res.onboarding_stage;
      });

    if (this.language.id == null && this.languageOptions.length > 0) {
      this.language = this.languageOptions[0];
    }
  }

  public getLanguageOptions(): string[] {
    const options: string[] = [];

    this.languageOptions.forEach((lang: Language) => {
      options.push(lang.name ?? '');
    });

    return options;
  }

  public languageChanged(value: string): void {
    const match = this.languageOptions.find((x) => x.name == value);

    if (match == null) {
      return;
    }

    this.language = match;
    this.languageChange.next(match);
  }

  toggleFeature(event: boolean): void {
    this.switchStatus = !this.switchStatus;
    this.sectionEnabled.emit(event);
  }

  bolden(text: string, chosenWords: string[]): string {
    chosenWords.forEach((word) => {
      text = text.replace(word, `<b>${word}</b>`);
    });

    return text;
  }
}
