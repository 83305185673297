import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { BillingService } from 'src/app/shared/services/billing.service';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-account-dashboard',
  templateUrl: './account-dashboard.component.html',
  styleUrls: ['./account-dashboard.component.scss'],
})
export class AccountDashboardComponent implements OnInit {
  constructor(
    private billingService: BillingService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.billingService
      .getPricePlans()
      .pipe(first((x) => x.length != 0))
      .subscribe({
        next: (pricePlans) => {
          this.billingService.setPricePlans(pricePlans);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load price plans');
        },
      });
  }
}
