<div class="dialog-header">
  <h1 class="font-24 text-almost-black font-700 mt-0">Carrier limit reached</h1>
</div>

<mat-dialog-content>
  <p class="font-normal font-14 text-almost-black">
    Based on your current Essentials price plan, you can only have 3 shipping
    carriers active. If you want to activate more than 3 carriers – please
    upgrade your plan in Settings
    <span class="pointer text-primary" (click)="goToPlans()">here</span>.
    Additionally, if you want to switch to a new carrier, deactivate the current
    carrier first.
  </p>
</mat-dialog-content>

<mat-dialog-actions class="dialog-buttons">
  <button class="btn btn-secondary btn-md mr-16" (click)="goToPlans()">
    See plans
  </button>
  <button class="btn btn-primary btn-md mr-0" mat-dialog-close>
    Understood
  </button>
</mat-dialog-actions>
