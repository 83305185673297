import { AccountSettings } from './account-settings.model';
import { LanguageSettings } from './language-settings.model';
import { NotificationSettings } from './notification-settings.model';
import { ReturnFormSettings } from './return-form-settings.model';
import { ReturnSettings } from './return-settings.model';
import { ShippingSettings } from './shipping-settings.model';
import { SupportSettings } from './support-settings.model';

export class ReturboSettings {
  public account_settings: AccountSettings = new AccountSettings();
  public language_settings: LanguageSettings = new LanguageSettings();
  public notification_settings: NotificationSettings =
    new NotificationSettings();
  public return_settings: ReturnSettings = new ReturnSettings();
  public return_form_settings: ReturnFormSettings = new ReturnFormSettings();
  public shipping_settings: ShippingSettings = new ShippingSettings();
  public support_settings: SupportSettings = new SupportSettings();
}
