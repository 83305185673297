<div class="left pr-20">
  <p
    class="font-14 font-semiBold text-almost-black pointer w-fit-content"
    (click)="dismiss()"
  >
    <mat-icon class="back-icon">arrow_back_ios</mat-icon> Back
  </p>

  <app-sidenav-header
    [return]="return"
    [subtitle]="subtitle"
  ></app-sidenav-header>

  <app-alert
    *ngIf="shouldShowApproveAlert()"
    icon="warning_amber"
    size="large"
    type="Caution"
    text="This step is not approving the refund. You are approving
  which items get sent back for you to inspect and then decide whether to
  refund them."
  ></app-alert>

  <div
    class="received-notification text-almost-black font-14"
    *ngIf="isIncomingPackageReceived"
    [ngClass]="{ 'package-received-notification': isPackageReceived }"
  >
    <ng-container
      *ngIf="
        isSlipSkippedAttached ||
        (isPackageSentDropff && !shippingSettings.automatically_track_returns)
      "
    >
      <span class="font-700 received-return-query"
        >Have you physically received this request?</span
      >
      <button
        class="btn btn-primary btn-md mr-16 float-right"
        (click)="mark('received')"
        *ngIf="!isLoading"
      >
        Mark as received
      </button>
    </ng-container>

    <ng-container *ngIf="isPackageReceived">
      <span class="font-normal"
        >{{ autoSlip ? "The system has" : "You have" }} marked this return as
        received.</span
      >
      <a
        class="font-normal float-right text-primary mr-16 pointer undo-text"
        *ngIf="!isLoading"
        (click)="mark('undo')"
        >{{ automatic ? "I haven't received it" : "Undo" }}</a
      >
    </ng-container>

    <ng-container
      *ngIf="
        isPackageSentDropff && shippingSettings.automatically_track_returns
      "
    >
      <div class="d-flex automatic-notification">
        <span class="font-normal w-80"
          >The system will automatically mark the return as received when it
          arrives to your warehouse. If it did not - please click on the button
          to the right.</span
        >
        <a
          class="font-normal text-primary w-20 pointer"
          (click)="mark('received')"
          >I have received it</a
        >
      </div>
    </ng-container>
  </div>
  <app-alert
    *ngIf="showAutoAcceptAlert()"
    icon="help_outline"
    type="Information"
    size="small"
    text="The items in this request have been accepted automatically."
  ></app-alert>

  <div class="new-order-alert-exchange" *ngIf="showNewOrderAlertExchange()">
    <img
      class="alert-icon"
      src="/assets/images/icons/lightbulb.png"
      alt="Tip icon"
    />
    <span><b>Tip:</b> {{ exchangeTipAlert() }}</span>
    <button class="btn btn-tertiary btn-md" (click)="goToOrder()">
      {{ return.new_shopify_order_link ? "Go to order" : "See draft order" }}
    </button>
  </div>

  <div
    class="received-notification text-almost-black font-14"
    *ngIf="(isExchange() && isResolved) || showNewOrderAlertComplaint()"
  >
    <span class="font-normal received-return-query">{{
      getResolvedExchangeAlertInfo()
    }}</span>
    <button
      class="btn btn-primary btn-md mr-16 ml-16 float-right"
      (click)="goToOrder()"
      *ngIf="showGoToOrderAlertButton()"
    >
      Go to order
    </button>
  </div>

  <h2 class="font-24 items-title mb-10">
    {{ getTableTitle() }}
    <span class="not-return" *ngIf="hasComplaintReason(return.items)">{{
      getTableSubtitleText()
    }}</span>
  </h2>

  <app-items-table [amountSelected]="amountSelected"></app-items-table>

  <app-return-shipping
    *ngIf="isComplaintReturnItems || !hasComplaintReason(return.items)"
    [errorAutoGeneratingSlip]="errorAutoGeneratingSlip"
  ></app-return-shipping>

  <ng-container *ngIf="showRefundAmountComponent()">
    <app-refund-amount></app-refund-amount>
  </ng-container>

  <ng-container *ngIf="return.gift_card_id > 0">
    <app-gift-card
      title="Gift card"
      [code]="return.gift_card_last_characters"
      [link]="return.gift_card_link"
    >
    </app-gift-card>
  </ng-container>

  <div class="text-right">
    <p
      class="font-14 font-normal text-almost-black"
      *ngIf="displayAcceptedItemsText('exchange')"
    >
      {{ getAcceptedExchangeItemsText() }}
    </p>
    <p
      class="font-14 font-normal text-almost-black"
      *ngIf="displayAcceptedItemsText('refund')"
    >
      {{ getReturnText() }}
    </p>

    <button
      class="btn btn-secondary btn-md mr-16 btn-loader"
      (click)="resendEmail(return.id)"
      *ngIf="showEmailButton()"
      [disabled]="hasResentEmail"
      [ngClass]="{ disabled: hasResentEmail }"
    >
      <app-loader
        class="mr-5"
        size="medium"
        *ngIf="isResendingEmail"
      ></app-loader>
      {{ !hasResentEmail ? "Re-send e-mail" : "Email sent!" }}
    </button>
    <button
      class="btn btn-primary btn-md"
      [disabled]="isDisabled()"
      *ngIf="isRequestedPackageReceived"
      (click)="checkRejectedItems()"
    >
      {{
        isReturnRequested && !isComplaintRequestedNoReturn()
          ? "Confirm and generate label"
          : "Confirm your decision"
      }}
    </button>
    <button
      class="btn btn-primary btn-md order-btn"
      *ngIf="isExchange() && isResolved && return.new_shopify_order_link"
      (click)="goToOrder()"
    >
      <img
        class="btn-icon"
        src="assets/images/svg/external.svg"
        alt="External link"
      />
      Go to new order
    </button>
  </div>
</div>

<app-modal id="confirm-no-slip" size="x-small-rectangle" [dismissable]="false">
  <h1 class="m-0">No slip attached</h1>
  <p class="mt-8 mb-16">
    You have not attached any shipping slip to this request. We assume that you
    send the slips either with the original order or in another way. Please bear
    in mind that no slip will be generated with this request on our end.<br />
    Do you wish to continue?
  </p>

  <div class="float-right">
    <app-button (click)="closeModal()" buttonStyle="secondary mr-16"
      >Go back</app-button
    >
    <app-button (click)="confirm('confirm')" buttonStyle="primary"
      >Continue</app-button
    >
  </div>
</app-modal>

<app-archive-modal></app-archive-modal>
