<div
  *ngIf="shopifySettings.onboarding_stage === 'Uncompleted'"
  class="accordion-container"
  [ngClass]="{
    'd-none': !isSettingsLoaded
  }"
>
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <p class="mb-0 font-700 font-14">Setting up Returbo</p>
          <p
            *ngIf="completedLength !== 8 || decision || firstLoad"
            class="mt-0 font-normal font-12"
          >
            {{ completedLength }} out of 8 required tasks completed
          </p>
          <p
            *ngIf="completedLength === 8 && !decision && !firstLoad"
            class="mt-0 font-normal font-12"
          >
            Completed. Looking at optional settings.
          </p>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="completedLength < 8 || !doneWithOnboarding">
        <div class="checklist">
          <div
            *ngFor="let task of getTasks()"
            class="pl-16 pr-16"
            [ngClass]="{ 'optional': task.optional }"
          >
            <p class="checklist-title">{{ task.title }}</p>
            <p class="checklist-description">
              {{ task.description }}
            </p>
            <p
              class="checklist-item"
              *ngFor="let item of task.items"
              [ngClass]="{ 'bullet-point': task.optional }"
            >
              <svg
                viewBox="0 0 120 120"
                *ngIf="!task.optional && !item.completed"
              >
                <circle cx="55" cy="55" r="50" class="dashed" />
              </svg>
              <img
                *ngIf="!task.optional && item.completed"
                src="assets/images/icons/check.png"
                alt="Completed"
                class="mr-8"
              />
              <span [ngClass]="{ completed: item.completed }">
                {{ item.title }}
              </span>
            </p>
          </div>
        </div>
        <mat-action-row>
          <ng-container *ngIf="completedLength < 8">
            <p class="checklist-title">Feeling stuck or unsure?</p>
            <p class="checklist-description">
              Contact support at 
              <a href="mailto:support@returbo.io">
                support@returbo.io
              </a>
            </p>
          </ng-container>
          <div
            *ngIf="completedLength === 8 && !decision && !firstLoad"
            class="my-12"
          >
            <app-button
              class="my-12"
              buttonStyle="primary"
              size="small"
              (click)="finishOnboarding()"
            >
              Close the window for good
            </app-button>
          </div>
        </mat-action-row>
      </ng-container>

      <ng-container *ngIf="completedLength === 8 && !decision && firstLoad">
        <img
          src="assets/images/well-done-illustration.png"
          alt="Well Done"
          class="done-img"
        />
        <h2 class="font-700 font-16 text-center mb-40">
          Well done! You are all set.
        </h2>
      </ng-container>

      <ng-container *ngIf="completedLength === 8 && decision">
        <h2 class="font-700 font-16 mb-20 px-14">
          Would you like to keep this window a bit more to look at optional settings?
        </h2>
        <div class="mb-10 px-14">
          <app-button
            buttonStyle="secondary"
            size="small"
            (click)="finishOnboarding()"
          >
            No, close it
          </app-button>
          <app-button
            class="m-lg-10"
            buttonStyle="primary"
            size="small"
            (click)="continueOnBoarding()"
          >
            Yes, keep it
          </app-button>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>
