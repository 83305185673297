<app-alert
  *ngIf="pageDisabled"
  type="Note"
  text="{{ noteText }}"
  link="{{ link }}"
  linkText="{{ linkText }}"
  imgIcon="../assets/images/icons/alert-triangle.png"
  size="large"
></app-alert>
<br />
<app-price-plan-carriers></app-price-plan-carriers>
<app-automatic-tracking
  *ngIf="integrationEnabled"
  [disabled]="pageDisabled"
  [ngClass]="{ hidden: pageDisabled }"
></app-automatic-tracking>
<app-shipping-slips
  *ngIf="integrationEnabled"
  [disabled]="pageDisabled"
  [ngClass]="{ hidden: pageDisabled }"
></app-shipping-slips>
<app-shipping-carriers-ranking
  *ngIf="integrationEnabled && autoGeneration"
  [disabled]="pageDisabled"
  [ngClass]="{ hidden: pageDisabled }"
></app-shipping-carriers-ranking>
<app-postnord
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-postnord>
<app-gls
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-gls>
<app-dhl-express
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-dhl-express>
<app-dhl-freight
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
>
</app-dhl-freight>
<app-bring
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-bring>
<app-ups
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-ups>

<app-deutsche-post
  [ngClass]="{ hidden: pageDisabled }"
  [disabled]="pageDisabled"
></app-deutsche-post>

<app-not-activated-popup></app-not-activated-popup>
