import { Component, Input, OnInit } from '@angular/core';
import { Notification } from 'src/app/settings/models/notification.model';
import { SelectedEmail } from 'src/app/settings/models/selected-email.model';
import { NotificationEmailTypeEnum } from 'src/app/shared/enums/notification-email-type.enum';
import { ErrorService } from 'src/app/shared/services/error.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { NotificationsService } from '../notifications.service';
import { statusMessages } from '../status-messages';

@Component({
  selector: 'app-email-content',
  templateUrl: './email-content.component.html',
  styleUrls: ['./email-content.component.scss'],
})
export class EmailContentComponent implements OnInit {
  @Input() notification: Notification = new Notification();

  isOnEdit = false;
  statusMessagesList = statusMessages;
  selectedEmail: SelectedEmail = new SelectedEmail();
  isSaving = false;

  constructor(
    private notificationsService: NotificationsService,
    private errorService: ErrorService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    
    this.notificationsService.getToggleEmailAction().subscribe((action) => {
      if (action) {
        this.toggleEmail();
      }
    });
  }

  toggleEdit(): void {
    this.isOnEdit = !this.isOnEdit;
  }

  getStatusMessage(status: string): any {
    const filteredMessage = this.statusMessagesList.find(
      (res: any) => res.type === status
    );
    return filteredMessage
      ? filteredMessage.message
      : {
          text: '',
          status: '',
        };
  }

  openEmailModal(
    id: string,
    title: string,
    type: string,
    stage: NotificationEmailTypeEnum
  ): void {
    this.selectedEmail.id = id;
    this.selectedEmail.title = title;
    this.selectedEmail.type = type;
    this.selectedEmail.stage = stage;

    this.notificationsService.setSelectedEmail(this.selectedEmail);
    this.notificationsService.toggleHideEmailModal(true);
  }

  toggleEmail(): void {
    this.notificationsService
      .getSelectedEmail()
      .subscribe((selectedEmail: SelectedEmail) => {
        if (selectedEmail.stage === this.selectedEmail.stage) {
          this.notification.is_enabled = this.selectedEmail.type === 'activate';
          this.notificationsService.setToggleEmailAction(false);
          this.saveNotification();
        }
      });
  }

  public saveNotification(): void {
    this.isSaving = true;
    this.notificationsService.setIsSavingNotification(true);
    this.notificationsService
      .saveNotificationEmails(this.notification)
      .subscribe({
        next: () => {
          this.popupService.showMessageSnackBar('Notification email saved');
          this.notificationsService.setSavedNotification(true);
        },
        error: () => {
          this.errorService.showErrorSnackBar(
            'Failed to save notification emails'
          );
        },
        complete: () => {
          this.notificationsService.setIsSavingNotification(false);
          this.isSaving = false;
          this.toggleEdit();
        },
      });
  }
}
