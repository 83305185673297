import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input()
  disabled = false;
  @Input()
  options: string[] = [];
  @Input()
  selected = '';
  @Input()
  placeholder = 'Select...';
  @Input()
  size = 'medium';
  @Input()
  blur = false;

  @Output()
  selectedChange: EventEmitter<string> = new EventEmitter<string>();

  setSelected(event: any) {
    this.selected = event.target.value;
    this.selectedChange.emit(this.selected);
  }
}
