import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExchangeSettingsService {
  private exchangeSettingsSubject: BehaviorSubject<ExchangeSettings> =
    new BehaviorSubject(new ExchangeSettings());
  private isLoadingExchangeSettings = false;
  constructor(private httpClient: HttpClient) {}

  public getExchangeSettings(): Observable<ExchangeSettings> {
    if (
      this.exchangeSettingsSubject.value === null ||
      (!this.exchangeSettingsSubject.value.deadline &&
      !this.isLoadingExchangeSettings)
    ) {
      this.isLoadingExchangeSettings = true;
      this.httpClient.get<ExchangeSettings>(
        `${environment.apiUrl}/settings/exchange`
      )
      .subscribe((exchangeSettings: ExchangeSettings) => {
        this.isLoadingExchangeSettings = false;
        this.exchangeSettingsSubject.next(exchangeSettings);
      });
    }
    return this.exchangeSettingsSubject;
  }

  public saveSettings(
    exchangeSettings: ExchangeSettings
  ): Observable<ExchangeSettings> {
    return this.httpClient.put<ExchangeSettings>(
      `${environment.apiUrl}/settings/exchange`,
      exchangeSettings
    );
  }

  setSettings(exchangeSettings: ExchangeSettings): void {
    this.exchangeSettingsSubject.next(exchangeSettings);
  }

  getExchangeSubject(): Observable<ExchangeSettings> {
    return this.exchangeSettingsSubject.asObservable();
  }
}
