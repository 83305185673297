import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';

@Component({
  selector: 'app-accepting-return-requests',
  templateUrl: './accepting-return-requests.component.html',
  styleUrls: ['./accepting-return-requests.component.scss'],
})
export class AcceptingReturnRequestsComponent implements OnInit {
  title = 'Accepting requests';
  description =
    'Select “automatic” if you want all requests to be automatically pre-approved.  Please note that this question is related to the return request and not the refund. Manually accept/reject can be a good option if you have certain items you do not automatically accept as a return (e.g. underwear).';
  isLoading = true;
  isSaving = false;
  onBoardingCompleted = false;

  returnAndComplaintOptions: string[] = [
    'Automatically pre-accept return requests but manually handle complaints',
    'Manually pre-accept/reject all requests',
  ];

  exchangeOptions: string[] = [
    'Automatically pre-accept exchange requests',
    'Manually pre-accept/reject all exchange requests',
  ];

  returnSettings: ReturnSettings = new ReturnSettings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();

  constructor(
    private errorService: ErrorService,
    private exchangeSettingsService: ExchangeSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit() {
    this.isLoading = true;

    const returnSettings = this.returnSettingsService.getSubjectReturnSettings();
    const exchangeSettings = this.exchangeSettingsService.getExchangeSubject();

    combineLatest([returnSettings, exchangeSettings]).subscribe(
      ([returnSettings, exchangeSettings]) => {
        if (this.returnSettingsService.hasReturnSettingsLoaded() &&
            exchangeSettings.deadline > 0) {
              this.returnSettings = returnSettings;
              this.exchangeSettings = exchangeSettings;
              this.isLoading = false;
        }
      }
    );
  }

  checkOnBoarding() {
    this.returnSettings.automatically_accept_requests !== undefined
      ? (this.onBoardingCompleted = true)
      : (this.onBoardingCompleted = false);
  }

  getComplaintReturnOption(): string {
    const option: boolean = this.returnSettings.automatically_accept_requests;
    this.checkOnBoarding();

    if (option === undefined) {
      return '';
    }

    return option
      ? this.returnAndComplaintOptions[0]
      : this.returnAndComplaintOptions[1];
  }

  getExchangeOption(): string {
    return this.exchangeSettings.automatically_accept_requests
      ? this.exchangeOptions[0]
      : this.exchangeOptions[1];
  }

  setExchangeOption(value: string): void {
    this.exchangeSettings.automatically_accept_requests =
      value === this.exchangeOptions[0];
    this.saveExchangeSettings();
  }

  setComplaintReturnOption(value: string): void {
    this.returnSettings.automatically_accept_requests =
      value === this.returnAndComplaintOptions[0];
    this.onBoardingCompleted = true;
    this.saveSettings();
  }

  saveExchangeSettings(): void {
    this.isSaving = true;
    this.exchangeSettingsService.saveSettings(this.exchangeSettings).subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Unable to save exchange settings');
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }

  saveSettings(): void {
    this.isSaving = true;

    this.returnSettingsService
      .saveReturnSettings(this.returnSettings)
      .subscribe({
        next: (saved: boolean) => {
          if (saved) {
            this.isSaving = false;
          }
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to save settings.');
          this.isSaving = false;
        },
      });
  }
}
