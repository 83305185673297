import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-proforma-not-available',
  templateUrl: './proforma-not-available.component.html',
  styleUrls: ['./proforma-not-available.component.scss'],
})
export class ProformaNotAvailableComponent implements OnInit {
  currentPricePlaneName = '';

  constructor(
    private oneCarrierDialog: MatDialogRef<ProformaNotAvailableComponent>,
    private router: Router,
    private pricePlanSettingsService: PricePlanSettingsService
  ) {}

  ngOnInit() {
    this.pricePlanSettingsService.getSubjectSelectedPricePlan().subscribe((pricePlan) => {
      this.currentPricePlaneName = pricePlan?.name;
    })
  }

  goToPlans(): void {
    this.oneCarrierDialog.close();
    this.router.navigate(['/settings/account']);
  }
}
