<div class="wrap" (click)="toggle($event)">
    <div 
        class="checkbox {{size}}"
        [ngClass]="{'checked': checked, 'disabled': disabled}"
    >
        <i 
            class="fas"
            [ngClass]="{
                'fa-check': type === 'Check',
                'fa-minus': type === 'Minus'
            }"
        ></i>
    </div>
    <label
        class="{{size}}" 
        onselectstart="return false"
        [ngClass]="{'disabled': disabled}"
    >
        <ng-content></ng-content>
    </label>
</div>
