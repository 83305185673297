import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { Item } from 'src/app/models/item.model';
import {
  ReturnRequest,
  ReturnsResponse
} from 'src/app/models/return-request.model';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { UpdateItem } from 'src/app/models/update-item.model';
import { UpdateReturnRequest } from 'src/app/models/update-return-request.model';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { ReturnsService } from '../../returns.service';
import { ItemsTableService } from '../../sidenav/left-content/items-table/items-table.service';
import { SidenavService } from '../../sidenav/sidenav.service';
import {
  displayedColumnsNames,
  returnStatusesFilters
} from '../../table-filters';
import { iconSources, imageSources } from './image-sources';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';
import { LocationSettingsService } from 'src/app/shared/services/settings/location-settings.service';

@Component({
  selector: 'app-return-table',
  templateUrl: './return-table.component.html',
  styleUrls: ['./return-table.component.scss'],
})
export class ReturnTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  selectedStatus: StatusFilter = {} as StatusFilter;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = displayedColumnsNames;
  ReturnStatusEnum = ReturnStatusEnum;
  returns: ReturnRequest[] = [];
  total_count = 0;
  sortOrder: any = {
    items: true,
    dateInitiated: true,
    lastUpdate: true,
  };
  returnSelected = false;
  isLoadingMoreData = false;
  returnStatusesFilters = returnStatusesFilters;
  keywordValue = '';
  imgSrc = imageSources;
  iconSrc = iconSources;
  dialogRef: MatDialogRef<LoaderComponent> | undefined;

  constructor(
    private returnsService: ReturnsService,
    private sidenavService: SidenavService,
    private itemsTableService: ItemsTableService,
    private errorService: ErrorService,
    private complaintSettingsService: ComplaintSettingsService,
    private exchangeSettingsService: ExchangeSettingsService,
    private shippingSettingsService: ShippingSettingsService,
    private locationSettingsService: LocationSettingsService
  ) {}

  ngOnInit(): void {
    this.getComplaintsSettings();
    this.getExchangeSettings();
    this.getShippingSettings();

    this.getReturns();

    let firstLoad = true;
    this.returnsService
      .getFilterValue()
      .pipe(debounceTime(200))
      .subscribe((searchKeyWord: string) => {
        this.keywordValue = searchKeyWord;
        if (!firstLoad || this.keywordValue !== '') {
          firstLoad = false;
          this.getQueriedReturns(this.keywordValue);
        }
      });

    this.itemsTableService
      .getSelectedStatus()
      .subscribe((status: StatusFilter) => {
        this.selectedStatus = status;
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  setReturnIcon(className: string): string | undefined {
    return this.imgSrc.get(className);
  }

  setIconClass(icon: string): string {
    return this.iconSrc.get(icon) ?? '';
  }

  getReturns(): void {
    this.returnsService
      .getReturnsDataSubject()
      .subscribe((returnsResponse: ReturnsResponse) => {
        this.returns = returnsResponse.returns;
        this.total_count = returnsResponse.total_count;
        this.dataSource = new MatTableDataSource(this.returns);
      });
  }

  getQueriedReturns(query: string): void {
    this.isLoadingMoreData = true;
    this.returnsService
      .getReturnsList(this.selectedStatus.status, 0, query)
      .subscribe({
        next: (data: ReturnsResponse) => {
          this.returns = data.returns;
          this.total_count = data.total_count;
          this.dataSource = new MatTableDataSource(this.returns);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load more returns');
        },
        complete: () => {
          this.isLoadingMoreData = false;
        },
      });
  }

  loadMoreItems(): void {
    this.isLoadingMoreData = true;
    this.returnsService
      .getReturnsList(
        this.selectedStatus.status,
        this.returns.length,
        this.keywordValue
      )
      .subscribe({
        next: (data: ReturnsResponse) => {
          this.returns.push(...data.returns);
          this.total_count = data.total_count;
          const allDataCopy: ReturnRequest[] = [...this.returns];
          this.dataSource = new MatTableDataSource(allDataCopy);
          data.returns = allDataCopy;
          this.returnsService.setReturnsDataSubject(data);
        },
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load more returns');
        },
        complete: () => {
          this.isLoadingMoreData = false;
        },
      });
  }

  openSidenav(selectedReturn: ReturnRequest): void {
    this.returnSelected = true;
    this.returnsService.setSelectedReturnSubject(selectedReturn, true);
    this.returnsService.setLoading(true);
    this.sidenavService.open();

    this.returnsService.getReturnById(selectedReturn.id).subscribe({
      next: (returnReq: ReturnRequest) => {
        this.returnsService.setSelectedReturnSubject(returnReq, true);
        this.returnsService.setLoading(false);
      },
    });
  }

  getStatusName(statusType: string): string {
    return this.returnStatusesFilters.filter((status: StatusFilter) =>
      status.status.includes(statusType)
    )[0].nameTitle;
  }

  getColorClass(selectedReturn: ReturnRequest): string {
    return this.returnStatusesFilters.filter((status: StatusFilter) =>
      status.status.includes(selectedReturn.status)
    )[0].class;
  }

  updateRequest(returnRequest: ReturnRequest, status: string): void {
    const items: UpdateItem[] = [];
    returnRequest.items.forEach((item: Item) => {
      const itemObj = {
        id: item.id,
        status: item.status,
        reject_cause: item.reject_cause,
        refund_amount: item.return_amount,
        merchant_compensation_choice: item.merchant_compensation_choice,
      };
      items.push(itemObj);
    });

    const requestObj: UpdateReturnRequest = {
      id: returnRequest.id,
      shopify_order_id: returnRequest.shopify_order_id,
      account_id: returnRequest.account_id,
      domain: returnRequest.domain,
      status: status,
      return_shipping_cost: Number(returnRequest.return_shipping_cost),
      charge_return_shipping: returnRequest.charge_return_shipping,
      refund_original_shipping: returnRequest.refund_original_shipping,
      items: items,
    };

    this.returnsService.updateReturn(requestObj).subscribe((res: boolean) => {
      if (res) {
        this.itemsTableService.setRequestToUpdate(returnRequest.id, status as ReturnStatusEnum);
      }
    });
  }

  getComplaintsSettings(): void {
    this.complaintSettingsService.getComplaintSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load complaints');
      }
    });
  }

  getExchangeSettings(): void {
    this.exchangeSettingsService.getExchangeSettings().subscribe({
      error: () => {
        this.errorService.showErrorSnackBar('Failed to load exchange settings');
      }
    });
  }

  getShippingSettings(): void {
    this.shippingSettingsService
      .getShippingSettings()
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load shipping settings');
        }
    });

    this.shippingSettingsService
      .getShippingCostSettings()
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load shipping cost settings');
        }
    });

    this.locationSettingsService
      .getLocationSettings()
      .subscribe({
        error: () => {
          this.errorService.showErrorSnackBar('Failed to load location settings');
        }
    });
  }
}
