import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { AccountSettings } from 'src/app/settings/models/account-settings.model';
import { Currency } from 'src/app/settings/models/currency.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingSettings } from 'src/app/settings/models/shipping-settings.model';
import { CurrencyService } from 'src/app/shared/services/currency.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { AccountSettingsService } from 'src/app/shared/services/settings/account-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';

@Component({
  selector: 'app-shipping-cost',
  templateUrl: './shipping-cost.component.html',
  styleUrls: ['./shipping-cost.component.scss'],
})
export class ShippingCostComponent implements OnInit {
  isSaving = false;
  isSavingReturnSettings = false;
  isSavingShippingSettings = false;

  isLoadingSettings = false;
  isLoadingCurrencies = false;

  title = 'Return shipping cost';
  description = 'How are return shipping costs managed?';
  helpMsg =
    'You can change the amount as needed as you manage individual returns. If the customer has paid in another currency, you need to calculate the shipping charge in said currency. Only the currencies supported in your store are visible here.';
  alert =
    'Please make sure you mention this in your return policy documents in Returbo Settings.';

  currencyList: string[] = [];
  selectedCurrencies: Currency[] = [];
  preSelected: string[] = [];
  currencies = new FormControl('');

  shippingSettings: ShippingSettings = new ShippingSettings();
  returnSettings: ReturnSettings = new ReturnSettings();

  constructor(
    private currencyService: CurrencyService,
    private errorService: ErrorService,
    private shippingSettingsService: ShippingSettingsService,
    private accountSettingsService: AccountSettingsService,
    private returnSettingsService: ReturnSettingsService
  ) {}

  ngOnInit(): void {
    this.isLoadingSettings = true;
    this.isLoadingCurrencies = true;

    this.accountSettingsService
      .getSubjectAccountSettings()
      .subscribe((accountSettings: AccountSettings) => {
        if (accountSettings.company_name) {
          this.returnSettingsService
            .getSubjectReturnSettings()
            .subscribe((res: ReturnSettings) => {
              this.returnSettings = res;
              this.isLoadingSettings = false;
            });

          this.shippingSettingsService
            .getShippingSettings()
            .subscribe((res: ShippingSettings) => {
              this.shippingSettings = res;

              this.shippingSettingsService
                .getShippingCostSettings()
                .subscribe((shippingCosts) => {
                  this.selectedCurrencies = shippingCosts;
                  this.shippingSettings.shipping_cost = shippingCosts;
                });

              this.currencyService
                .getCurrencySubject()
                .subscribe((currencies) => {
                  this.currencyList = currencies;
                  this.setPreSelected();
                  this.isLoadingCurrencies = false;
                });
            });
        }
      });
  }

  saveSettings(): void {
    this.isSaving = true;

    const returnSettings = this.returnSettingsService.saveReturnSettings(
      this.returnSettings
    );
    const shippingSettings = this.shippingSettingsService.saveShippingSettings(
      this.shippingSettings
    );
    const shippingCost = this.shippingSettingsService.saveShippingCost(
      this.shippingSettings.shipping_cost
    );

    combineLatest([returnSettings, shippingSettings, shippingCost]).subscribe({
      next: ([returnSaved, shippingSaved, shippingCostSaved]: [boolean, boolean, boolean]) => {
        if (returnSaved && shippingSaved && shippingCostSaved) {
          this.isSaving = false;
        }
      },
      error: () => {
        this.isSaving = false;
        this.errorService.showErrorSnackBar('Failed to save settings.');
      },
    });
  }

  setPreSelected(): void {
    if (this.preSelected.length === 0) {
      this.shippingSettings.shipping_cost.forEach((currencies) => {
        this.preSelected.push(currencies.key);
        this.currencies.patchValue(this.preSelected);
      });
    }
  }

  getCurrencyValue(currency: Currency) {
    const foundCurrency = this.selectedCurrencies.find((c) => c === currency);
    return foundCurrency ? foundCurrency.value : 0;
  }

  setCurrencyValue(currency: Currency, number: any): void {
    const foundCurrency = this.selectedCurrencies.find((c) => c === currency);
    if (foundCurrency) {
      foundCurrency.value = number.target.value;
      this.shippingSettings.shipping_cost = this.selectedCurrencies;
    }
  }

  selectCurrency(currency: string): void {
    const foundCurrency = this.selectedCurrencies.find(
      (c) => c.key === currency
    );
    if (foundCurrency) {
      const index = this.selectedCurrencies.indexOf(foundCurrency);
      this.selectedCurrencies.splice(index, 1);
    }

    if (!foundCurrency) {
      const tempCur: Currency = {
        key: currency,
        value: 0,
      };
      this.selectedCurrencies.push(tempCur);
    }
    this.shippingSettings.shipping_cost = this.selectedCurrencies;
  }

  getCheckedUserPays(): boolean {
    if (this.shippingSettings?.customer_pays_shipping_by_default) {
      return true;
    }
    return false;
  }

  getCheckedRefunded(): boolean {
    if (this.returnSettings?.should_original_shipping_be_refunded) {
      return true;
    }
    return false;
  }

  toggleUserPays(checked: boolean) {
    this.shippingSettings.customer_pays_shipping_by_default = checked
      ? true
      : false;

    this.saveSettings();
  }

  toggleRefundShipping(checked: boolean) {
    this.returnSettings.should_original_shipping_be_refunded = checked
      ? true
      : false;

    this.saveSettings();
  }
}
