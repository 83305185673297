import { Component, Input } from '@angular/core';
import { StatusFilter } from 'src/app/models/status-filter.model';
import { returnStatusesFilters } from 'src/app/returns/table-filters';
import { Notification } from 'src/app/settings/models/notification.model';
import { statusMessages } from '../status-messages';

@Component({
  selector: 'app-status-title',
  templateUrl: './status-title.component.html',
  styleUrls: ['./status-title.component.scss'],
})
export class StatusTitleComponent {
  @Input() notification: Notification = new Notification();

  returnStatuses = returnStatusesFilters;
  statusMessages = statusMessages;
  showInfo = false;

  getColorClass(status: string): string {
    return (
      this.returnStatuses.find((returnStatus: StatusFilter) =>
        returnStatus.nameTitle.includes(status)
      )?.headerStatus.colorClass ?? ''
    );
  }

  getStatusIcon(status: string): string {
    return (
      this.returnStatuses.find((returnStatus: StatusFilter) =>
        returnStatus.nameTitle.includes(status)
      )?.headerStatus.icon ?? ''
    );
  }

  getStatusMessage(status: string): any {
    const filteredMessage = this.statusMessages.find(
      (res: any) => res.type === status
    );
    return filteredMessage
      ? filteredMessage.message
      : {
          text: '',
          status: '',
        };
  }

  toggleInfo(): void {
    this.showInfo = !this.showInfo;
  }
}
