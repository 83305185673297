import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagePreviewService {
  private showImagePreview = new BehaviorSubject(false);

  setImagePreview(value: boolean): void {
    this.showImagePreview.next(value);
  }

  getImagePreview(): Observable<boolean> {
    return this.showImagePreview.asObservable();
  }
}
