import { TimelineStepper } from 'src/app/models/timeline-stepper.model';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';

export const timelineValues: TimelineStepper[] = [
  {
    title: 'Order created',
    dateProperty: 'order_created_at',
    statuses: [],
    step: 0,
    showOnRefund: true,
    showOnReturn: true,
  },
  {
    title: 'Order fullfilled',
    dateProperty: 'order_fulfilled_at',
    statuses: [],
    step: 1,
    showOnRefund: true,
    showOnReturn: true,
  },
  {
    title: 'Complaint requested',
    dateProperty: 'return requested',
    statuses: [
      ReturnStatusEnum.RequestReceived,
      ReturnStatusEnum.AwaitingShippingSlip,
    ],
    step: 2,
    showOnRefund: true,
    showOnReturn: true,
  },
  {
    title: 'requested',
    dateProperty: 'return requested',
    statuses: [
      ReturnStatusEnum.RequestReceived,
      ReturnStatusEnum.AwaitingShippingSlip,
    ],
    step: 2,
    showOnRefund: false,
    showOnReturn: false,
  },
  {
    title: 'Complaint incoming',
    dateProperty: 'return incoming',
    statuses: [
      ReturnStatusEnum.PackageSent,
      ReturnStatusEnum.AwaitingDropOff,
      ReturnStatusEnum.ShippingSlipSkipped,
      ReturnStatusEnum.ManualShippingSlipAttached,
    ],
    step: 3,
    showOnRefund: false,
    showOnReturn: true,
  },
  {
    title: 'Complaint received',
    dateProperty: 'return received',
    statuses: [ReturnStatusEnum.PackageReceived],
    step: 4,
    showOnRefund: true,
    showOnReturn: true,
  },
  {
    title: 'Complaint resolved',
    dateProperty: 'return resolved',
    statuses: [
      ReturnStatusEnum.RefundApproved,
      ReturnStatusEnum.RequestDenied,
      ReturnStatusEnum.RefundPartiallyApproved,
      ReturnStatusEnum.RequestManaged,
      ReturnStatusEnum.RefundDenied,
    ],
    step: 4,
    showOnRefund: true,
    showOnReturn: true,
  },
  {
    title: 'incoming',
    dateProperty: 'return incoming',
    statuses: [
      ReturnStatusEnum.PackageSent,
      ReturnStatusEnum.AwaitingDropOff,
      ReturnStatusEnum.ShippingSlipSkipped,
      ReturnStatusEnum.ManualShippingSlipAttached,
    ],
    step: 3,
    showOnRefund: false,
    showOnReturn: false,
  },
  {
    title: 'received',
    dateProperty: 'return received',
    statuses: [ReturnStatusEnum.PackageReceived],
    step: 4,
    showOnRefund: false,
    showOnReturn: false,
  },
  {
    title: 'resolved',
    dateProperty: 'return resolved',
    statuses: [
      ReturnStatusEnum.RefundApproved,
      ReturnStatusEnum.RequestDenied,
      ReturnStatusEnum.RefundPartiallyApproved,
      ReturnStatusEnum.RequestManaged,
      ReturnStatusEnum.RefundDenied,
    ],
    step: 5,
    showOnRefund: false,
    showOnReturn: false,
  },
];
