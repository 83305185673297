<app-settings-section
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [(language)]="activeLanguage"
  [title]="title"
  [description]="description"
  [onBoardingStepCompleted]="onBoardingCompleted"
  [hasNewFeatures]="false"
  feature="Updated feature"
>
  <div class="toggle-view" [ngClass]="{ 'adjust-margin': !showContent }">
    <p (click)="showContent = !showContent" class="expand-text">
      {{
        showContent
          ? "Collapse to hide the full list"
          : "Expand to show the full list"
      }}
    </p>
    <img
      [ngClass]="showContent ? 'arrow' : 'rotate-arrow'"
      src="../assets/images/icons/chevron-up.png"
    />
  </div>

  <ng-container *ngIf="showContent">
    <div class="restock-options">
      <h4 class="mt-16">Restock options</h4>
      <p class="subtitle mt-4 mb-16">
        Select whether you want to restock items manually or automate the
        process. Note that complaints will never be restocked.
      </p>

      <mat-radio-group
        color="primary"
        class="restock-radio"
        aria-label="Restock options"
        [(ngModel)]="
          returnSettings.use_manual_restock
        "
        (change)="saveSettings()"
      >
        <mat-radio-button [value]="false">
          <b>Automatically</b> restock or not restock items based on the request
          reason used
        </mat-radio-button>
        <mat-radio-button [value]="true">
          <b>Manually</b> decide to restock or not restock items during the
          quality check in <b>Pending for Approval</b> stage
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <app-alert
      *ngIf="getNumberOfMissingTranslationsInActiveLanguage() > 0"
      type="Warning"
      text="You have {{
        getNumberOfMissingTranslationsInActiveLanguage()
      }} custom return reason(s) that aren't translated to {{
        activeLanguage.name
      }}"
      imgIcon="../assets/images/icons/alert-octagon.png"
      size="large"
    ></app-alert>

    <app-language-selector
      [disabled]="isSaving || isLoading"
      (setSelectLanguage)="selectLanguage($event)"
      [defaultLanguage]="activeLanguage"
    ></app-language-selector>

    <div class="return-reasons">
      <p
        class="table_title"
        [ngClass]="!showReturns ? ['mb-40', 'hidden-reasons'] : 'mb-12'"
      >
        Returns
        <span class="ml-16"
          ><app-new-button
            buttonStyle="cancel-thin"
            (click)="toggleSectionView('Returns')"
            >{{ !showReturns ? "Show " : "Hide " }}reasons</app-new-button
          ></span
        >
      </p>

      <ng-container *ngIf="showReturns">
        <div
          *ngFor="
            let return of reasons
          "
        >
          <div
            class="return_reason"
            *ngIf="
              return.return_reason_type === 'Return' &&
              return.is_enabled &&
              !return.is_archived
            "
          >
            <div class="flex">
              <p
                *ngIf="getDescription(return) === 'N/A'"
                class="missing-translation"
              >
                Translation missing
              </p>
              <p>
                {{ getDescription(return)
                }}<i class="is_custom ml-5" *ngIf="return.is_custom"
                  >| Custom</i
                >
              </p>
            </div>

            <p
              class="status"
              *ngIf="!checkIfActiveSubReasons(return.sub_reasons)"
            >
              {{ getRestockOrNot(return.id) }}
            </p>
          </div>

          <div *ngFor="let subReason of return.sub_reasons">
            <div
              class="return_reason"
              *ngIf="
                return.return_reason_type === 'Return' &&
                subReason.is_enabled &&
                !return.is_archived
              "
            >
              <div class="flex">
                <p
                  *ngIf="getDescription(subReason) === 'N/A'"
                  class="missing-translation"
                >
                  Translation missing
                </p>
                <p class="adjust_spacing">
                  <img src="assets/images/icons/sub-task.png" class="filter" />
                  {{ getDescription(subReason) }}
                  <i class="ml-5 is_custom" *ngIf="subReason.is_custom"
                    >| Custom</i
                  >
                </p>
              </div>
              <p class="status">
                {{ getRestockOrNotSubReason(subReason) }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="complaintsEnabled">
        <p
          class="table_title mb-12"
          [ngClass]="!showComplaints ? ['mb-40', 'hidden-reasons'] : 'mb-12'"
        >
          Complaints
          <span class="ml-16"
            ><app-new-button
              buttonStyle="cancel-thin"
              (click)="toggleSectionView('Complaints')"
              >{{
                !showComplaints ? "Show " : "Hide "
              }}complaints</app-new-button
            ></span
          >
        </p>
        <ng-container *ngIf="showComplaints">
          <div
            *ngFor="
              let complaint of reasons
            "
          >
            <div
              class="return_reason"
              *ngIf="
                complaint.return_reason_type === 'Complaint' &&
                complaint.is_enabled &&
                !complaint.is_archived
              "
            >
              <div class="flex">
                <p
                  *ngIf="getDescription(complaint) === 'N/A'"
                  class="missing-translation"
                >
                  Translation missing
                </p>
                <p>
                  {{ getDescription(complaint) }}
                  <i class="is_custom" *ngIf="complaint.is_custom">| Custom</i>
                </p>
              </div>
              <p class="status" *ngIf="complaint.sub_reasons.length === 0">
                {{ getRestockOrNot(complaint.id) }}
              </p>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="exchangesEnabled">
        <p
          class="table_title mb-12"
          [ngClass]="!showExchanges ? ['mb-40', 'hidden-reasons'] : 'mb-12'"
        >
          Exchanges
          <span class="ml-16"
            ><app-new-button
              buttonStyle="cancel-thin"
              (click)="toggleSectionView('Exchanges')"
              >{{ !showExchanges ? "Show " : "Hide " }}exchanges</app-new-button
            ></span
          >
        </p>
        <ng-container *ngIf="showExchanges">
          <div
            *ngFor="
              let exchange of reasons
            "
          >
            <div
              class="return_reason"
              *ngIf="
                exchange.return_reason_type === 'Exchange' &&
                exchange.is_enabled &&
                !exchange.is_archived
              "
            >
              <div class="flex">
                <p
                  *ngIf="getDescription(exchange) === 'N/A'"
                  class="missing-translation"
                >
                  Translation missing
                </p>
                <p>
                  {{ getDescription(exchange)
                  }}<i class="is_custom ml-5" *ngIf="exchange.is_custom"
                    >| Custom</i
                  >
                </p>
              </div>

              <p
                class="status"
                *ngIf="!checkIfActiveSubReasons(exchange.sub_reasons)"
              >
                {{ getRestockOrNot(exchange.id) }}
              </p>
            </div>

            <div *ngFor="let subReason of exchange.sub_reasons">
              <div
                class="return_reason"
                *ngIf="
                  exchange.return_reason_type === 'Exchange' &&
                  subReason.is_enabled &&
                  !exchange.is_archived
                "
              >
                <div class="flex">
                  <p
                    *ngIf="getDescription(subReason) === 'N/A'"
                    class="missing-translation"
                  >
                    Translation missing
                  </p>
                  <p class="adjust_spacing">
                    <img
                      src="assets/images/icons/sub-task.png"
                      class="filter"
                    />
                    {{ getDescription(subReason) }}
                    <i class="ml-5 is_custom" *ngIf="subReason.is_custom"
                      >| Custom</i
                    >
                  </p>
                </div>
                <p class="status">
                  {{ getRestockOrNotSubReason(subReason) }}
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="actions">
      <app-new-button
        buttonStyle="confirm"
        (click)="editReturnReason(undefined, $event)"
        [disabled]="isLoading"
      >
        Edit reasons
      </app-new-button>
    </div>
  </ng-container>
</app-settings-section>

<app-modal id="return-reason-edit" [dismissable]="false">
  <ng-container *ngIf="!showArchive && activeReturnReason !== null">
    <div class="edit-return-reason">
      <h4 class="modal-title">
        Return reasons<app-loader size="medium" *ngIf="isSaving"></app-loader>
      </h4>
      <p class="modal-description">{{ description }}</p>

      <div class="language-archive">
        <span class="language">
          <app-language-selector
            [disabled]="isSaving || isLoading"
            (setSelectLanguage)="selectLanguage($event)"
            [defaultLanguage]="activeLanguage"
          ></app-language-selector>
        </span>

        <span matRipple (click)="toggleArchive()" class="icon"
          ><mat-icon fontSet="material-icons-outlined">inventory_2</mat-icon
          ><span class="icon-text">Archived reasons</span>
        </span>
      </div>

      <div class="title_spacing">
        <p
          class="table_title mb-12"
          [ngClass]="{ 'hidden-reasons': !showReturns }"
        >
          Returns
          <span class="ml-16"
            ><app-new-button
              buttonStyle="cancel-thin"
              (click)="toggleSectionView('Returns')"
              >{{ !showReturns ? "Show " : "Hide " }}reasons</app-new-button
            ></span
          >
        </p>
        <p
          class="table_title restock"
          *ngIf="
            showReturns &&
            !returnSettings.use_manual_restock
          "
        >
          Restock
          <mat-icon (click)="toggleHelp()" class="ml-10">help_outline</mat-icon>
        </p>
      </div>

      <div *ngIf="showHelp" class="mt-20 mb-20">
        <app-alert
          type="Information"
          size="large"
          [text]="infoText"
          icon="help_outline"
          [showCloseButton]="false"
        ></app-alert>
      </div>

      <ng-container *ngIf="showReturns">
        <div
          *ngFor="
            let return of reasons;
            let i = index
          "
        >
          <div
            class="return_reason"
            [ngClass]="{ editing: isEditing && return.id === editID }"
            *ngIf="
              return.return_reason_type === 'Return' && !return.is_archived
            "
          >
            <p>
              <app-checkbox
                [ngClass]="{ 'd-none': isEditing && return.id === editID }"
                size="medium"
                (checkedChange)="toggleVisibility(return, $event)"
                [checked]="return.is_enabled"
              ></app-checkbox>
              <span *ngIf="isEditing && return.id === editID; else description">
                <input
                  tabindex="-1"
                  id="focus-input"
                  class="input"
                  type="text"
                  [(ngModel)]="inputValue"
                />
              </span>

              <ng-template #description>
                {{ getDescription(return) }}
              </ng-template>

              <i
                class="is_custom"
                [ngClass]="{ 'd-none': isEditing && return.id === editID }"
                *ngIf="return.is_custom"
                >| Custom</i
              >
              <app-overlay-menu
                [ngClass]="{ 'd-none': isEditing && return.id === editID }"
              >
                <!-- Change all toggle input to open the edit modal, send in something. Maybe i (index) or the reason itself -->
                <button mat-menu-item (click)="newSubReason(return)">
                  <img src="assets/images/icons/sub-task.png" class="filter" />
                  <span class="alignment">Add sub-reason</span>
                </button>
                <button
                  *ngIf="return.is_custom"
                  mat-menu-item
                  (click)="deleteReason('return', return.id)"
                >
                  <img src="assets/images/icons/delete.png" class="filter" />
                  <span class="alignment">Delete reason</span>
                </button>
                <button
                  (click)="handleArchive(return, 'archive')"
                  mat-menu-item
                >
                  <span class="item">
                    <img
                      class="unarchive-icon filter"
                      src="assets/images/icons/unarchive.png"
                    />
                    <span>Archive reason</span>
                  </span>
                </button>
                <!-- Change all toggle input to open the edit modal, send in something. Maybe i (index) or the reason itself -->
                <button
                  *ngIf="return.is_custom"
                  mat-menu-item
                  (click)="editReason(return)"
                >
                  <img src="assets/images/icons/edit.png" class="filter" />
                  <span class="alignment">Edit reason</span>
                </button>
              </app-overlay-menu>
            </p>

            <div
              *ngIf="
                !checkIfEnabledSubReasons(return) &&
                return.is_enabled &&
                !returnSettings.use_manual_restock
              "
              [ngClass]="{ 'd-none': isEditing && return.id === editID }"
            >
              <app-slider
                (toggleChange)="setShouldRestock($event, return.id)"
                [checked]="return.should_restock"
                [disableRipple]="true"
              ></app-slider>
            </div>
          </div>

          <div *ngFor="let subReason of return.sub_reasons; let i = index">
            <div
              class="return_reason"
              [ngClass]="{ editing: isEditing && subReason.id === editID }"
              *ngIf="
                return.return_reason_type === 'Return' && !return.is_archived
              "
            >
              <p class="adjust_spacing_edit_field">
                <img
                  src="assets/images/icons/sub-task.png"
                  class="filter"
                  [ngClass]="{ 'd-none': isEditing && subReason.id === editID }"
                />
                <i class="ml-5">
                  <app-checkbox
                    [ngClass]="{
                      'd-none': isEditing && subReason.id === editID
                    }"
                    size="medium"
                    (checkedChange)="
                      toggleVisibilitySubReason(return, subReason, $event)
                    "
                    [checked]="subReason.is_enabled"
                  ></app-checkbox>

                  <span
                    *ngIf="
                      isEditing && subReason.id === editID;
                      else description
                    "
                  >
                    <input
                      tabindex="-1"
                      id="focus-input"
                      class="input"
                      type="text"
                      [(ngModel)]="inputValue"
                    />
                  </span>

                  <ng-template #description>
                    {{ getDescription(subReason) }}
                  </ng-template>

                  <i
                    class="is_custom"
                    *ngIf="subReason.is_custom"
                    [ngClass]="{
                      'd-none': isEditing && subReason.id === editID
                    }"
                    >| Custom</i
                  >
                  <app-overlay-menu
                    *ngIf="subReason.is_custom"
                    class="vertical_align"
                    [ngClass]="{
                      'd-none': isEditing && subReason.id === editID
                    }"
                  >
                    <button
                      mat-menu-item
                      (click)="deleteSubReason(return, subReason.id)"
                    >
                      <img
                        src="assets/images/icons/delete.png"
                        class="filter"
                      />
                      <span class="alignment">Delete Sub-reason</span>
                    </button>
                    <button
                      *ngIf="subReason.is_custom"
                      mat-menu-item
                      (click)="editSubReason(subReason)"
                    >
                      <img src="assets/images/icons/edit.png" class="filter" />
                      <span class="alignment">Edit Sub-reason</span>
                    </button>
                  </app-overlay-menu>
                </i>
              </p>
              <div
                class="restock_button"
                [ngClass]="{ 'd-none': isEditing && subReason.id === editID }"
                *ngIf="
                  subReason.is_enabled &&
                  !returnSettings.use_manual_restock
                "
              >
                <app-slider
                  (toggleChange)="setShouldRestockSubReason(subReason, $event)"
                  [checked]="subReason.should_restock"
                  [disableRipple]="true"
                ></app-slider>
              </div>
            </div>
          </div>
        </div>
        <p class="custom" (click)="newReason(ReturnReasonType.Return)">
          + Add a custom return reason
        </p>
      </ng-container>

      <div class="title_spacing">
        <p
          class="table_title mb-12"
          [ngClass]="{ 'hidden-reasons': !showComplaints }"
        >
          Complaints
          <span class="ml-16"
            ><app-new-button
              buttonStyle="cancel-thin"
              (click)="toggleSectionView('Complaints')"
              >{{
                !showComplaints ? "Show " : "Hide "
              }}complaints</app-new-button
            ></span
          >
        </p>
        <p
          *ngIf="
            showComplaints &&
            !returnSettings.use_manual_restock
          "
          class="table_title restock"
        >
          Restock
        </p>
      </div>

      <ng-container *ngIf="showComplaints">
        <div
          *ngFor="
            let complaint of reasons
          "
        >
          <div
            [ngClass]="{ editing: isEditing && complaint.id === editID }"
            class="return_reason"
            *ngIf="
              complaint.return_reason_type === 'Complaint' &&
              !complaint.is_archived
            "
          >
            <p>
              <app-checkbox
                [ngClass]="{ 'd-none': isEditing && complaint.id === editID }"
                size="medium"
                (checkedChange)="toggleVisibility(complaint, $event)"
                [checked]="complaint.is_enabled"
              ></app-checkbox>

              <span
                *ngIf="isEditing && complaint.id === editID; else description"
              >
                <input
                  tabindex="-1"
                  id="focus-input"
                  class="input"
                  type="text"
                  [(ngModel)]="inputValue"
                />
              </span>

              <ng-template #description>
                {{ getDescription(complaint) }}
              </ng-template>

              <ng-container>
                <i
                  class="is_custom"
                  [ngClass]="{ 'd-none': isEditing && complaint.id === editID }"
                  *ngIf="complaint.is_custom"
                  >| Custom</i
                >
                <app-overlay-menu
                  [ngClass]="{ 'd-none': isEditing && complaint.id === editID }"
                >
                  <button
                    *ngIf="complaint.is_custom"
                    mat-menu-item
                    (click)="deleteReason('complaint', complaint.id)"
                  >
                    <img src="assets/images/icons/delete.png" class="filter" />
                    <span class="alignment">Delete complaint</span>
                  </button>
                  <button
                    (click)="handleArchive(complaint, 'archive')"
                    mat-menu-item
                  >
                    <span class="item">
                      <img
                        class="unarchive-icon filter"
                        src="assets/images/icons/unarchive.png"
                      />
                      <span>Archive complaint</span>
                    </span>
                  </button>

                  <button
                    *ngIf="complaint.is_custom"
                    mat-menu-item
                    (click)="editReason(complaint)"
                  >
                    <img src="assets/images/icons/edit.png" class="filter" />
                    <span class="alignment">Edit reason</span>
                  </button>
                </app-overlay-menu>
              </ng-container>
            </p>

            <div
              [ngClass]="{ 'd-none': isEditing && complaint.id === editID }"
              class="restock_button"
              *ngIf="
                complaint.is_enabled &&
                !returnSettings.use_manual_restock
              "
            >
              <app-slider
                (toggleChange)="setShouldRestock($event, complaint.id)"
                [checked]="complaint.should_restock"
                [disabled]="true"
                [disableRipple]="true"
              ></app-slider>
            </div>
          </div>
        </div>
        <p class="custom" (click)="newReason(ReturnReasonType.Complaint)">
          + Add a custom complaint
        </p>
      </ng-container>

      <ng-container *ngIf="exchangesEnabled">
        <div class="title_spacing">
          <p
            class="table_title mb-12"
            [ngClass]="{ 'hidden-reasons': !showExchanges }"
          >
            Exchanges
            <span class="ml-16"
              ><app-new-button
                buttonStyle="cancel-thin"
                (click)="toggleSectionView('Exchanges')"
                >{{ !showReturns ? "Show " : "Hide " }}exchanges</app-new-button
              ></span
            >
          </p>
          <p
            *ngIf="
              showExchanges &&
              !returnSettings.use_manual_restock
            "
            class="table_title restock"
          >
            Restock
          </p>
        </div>
        <ng-container *ngIf="showExchanges">
          <div
            *ngFor="
              let exchange of reasons;
              let i = index
            "
          >
            <div
              class="return_reason"
              [ngClass]="{ editing: isEditing && exchange.id === editID }"
              *ngIf="
                exchange.return_reason_type === 'Exchange' &&
                !exchange.is_archived
              "
            >
              <p>
                <app-checkbox
                  [ngClass]="{ 'd-none': isEditing && exchange.id === editID }"
                  size="medium"
                  (checkedChange)="toggleVisibility(exchange, $event)"
                  [checked]="exchange.is_enabled"
                ></app-checkbox>
                <span
                  *ngIf="isEditing && exchange.id === editID; else description"
                >
                  <input
                    tabindex="-1"
                    id="focus-input"
                    class="input"
                    type="text"
                    [(ngModel)]="inputValue"
                  />
                </span>

                <ng-template #description>
                  {{ getDescription(exchange) }}
                </ng-template>

                <i
                  class="is_custom"
                  [ngClass]="{ 'd-none': isEditing && exchange.id === editID }"
                  *ngIf="exchange.is_custom"
                  >| Custom</i
                >
                <app-overlay-menu
                  [ngClass]="{ 'd-none': isEditing && exchange.id === editID }"
                >
                  <button mat-menu-item (click)="newSubReason(exchange)">
                    <img
                      src="assets/images/icons/sub-task.png"
                      class="filter"
                    />
                    <span class="alignment">Add sub-reason</span>
                  </button>
                  <button
                    *ngIf="exchange.is_custom"
                    mat-menu-item
                    (click)="deleteReason('exchange', exchange.id)"
                  >
                    <img src="assets/images/icons/delete.png" class="filter" />
                    <span class="alignment">Delete reason</span>
                  </button>
                  <button
                    (click)="handleArchive(exchange, 'archive')"
                    mat-menu-item
                  >
                    <span class="item">
                      <img
                        class="unarchive-icon filter"
                        src="assets/images/icons/unarchive.png"
                      />
                      <span>Archive reason</span>
                    </span>
                  </button>
                  <button
                    *ngIf="exchange.is_custom"
                    mat-menu-item
                    (click)="editReason(exchange)"
                  >
                    <img src="assets/images/icons/edit.png" class="filter" />
                    <span class="alignment">Edit reason</span>
                  </button>
                </app-overlay-menu>
              </p>

              <div
                *ngIf="
                  !checkIfEnabledSubReasons(exchange) &&
                  exchange.is_enabled &&
                  !returnSettings.use_manual_restock
                "
                [ngClass]="{ 'd-none': isEditing && exchange.id === editID }"
              >
                <app-slider
                  (toggleChange)="setShouldRestock($event, exchange.id)"
                  [checked]="exchange.should_restock"
                  [disableRipple]="true"
                ></app-slider>
              </div>
            </div>
            <div *ngFor="let subReason of exchange.sub_reasons; let i = index">
              <div
                class="return_reason"
                [ngClass]="{ editing: isEditing && subReason.id === editID }"
                *ngIf="
                  exchange.return_reason_type === 'Exchange' &&
                  !exchange.is_archived
                "
              >
                <p class="adjust_spacing_edit_field">
                  <img
                    src="assets/images/icons/sub-task.png"
                    class="filter"
                    [ngClass]="{
                      'd-none': isEditing && subReason.id === editID
                    }"
                  />
                  <i class="ml-5">
                    <app-checkbox
                      [ngClass]="{
                        'd-none': isEditing && subReason.id === editID
                      }"
                      size="medium"
                      (checkedChange)="
                        toggleVisibilitySubReason(exchange, subReason, $event)
                      "
                      [checked]="subReason.is_enabled"
                    ></app-checkbox>

                    <span
                      *ngIf="
                        isEditing && subReason.id === editID;
                        else description
                      "
                    >
                      <input
                        tabindex="-1"
                        id="focus-input"
                        class="input"
                        type="text"
                        [(ngModel)]="inputValue"
                      />
                    </span>

                    <ng-template #description>
                      {{ getDescription(subReason) }}
                    </ng-template>

                    <i
                      class="is_custom"
                      *ngIf="subReason.is_custom"
                      [ngClass]="{
                        'd-none': isEditing && subReason.id === editID
                      }"
                      >| Custom</i
                    >
                    <app-overlay-menu
                      *ngIf="subReason.is_custom"
                      class="vertical_align"
                      [ngClass]="{
                        'd-none': isEditing && subReason.id === editID
                      }"
                    >
                      <button
                        mat-menu-item
                        (click)="deleteSubReason(exchange, subReason.id)"
                      >
                        <img
                          src="assets/images/icons/delete.png"
                          class="filter"
                        />
                        <span class="alignment">Delete Sub-reason</span>
                      </button>
                      <button
                        *ngIf="subReason.is_custom"
                        mat-menu-item
                        (click)="editSubReason(subReason)"
                      >
                        <img
                          src="assets/images/icons/edit.png"
                          class="filter"
                        />
                        <span class="alignment">Edit Sub-reason</span>
                      </button>
                    </app-overlay-menu>
                  </i>
                </p>
                <div
                  class="restock_button"
                  [ngClass]="{ 'd-none': isEditing && subReason.id === editID }"
                  *ngIf="
                    subReason.is_enabled &&
                    !returnSettings.use_manual_restock
                  "
                >
                  <app-slider
                    (toggleChange)="
                      setShouldRestockSubReason(subReason, $event)
                    "
                    [checked]="subReason.should_restock"
                    [disableRipple]="true"
                  ></app-slider>
                </div>
              </div>
            </div>
          </div>

          <p class="custom" (click)="newReason(ReturnReasonType.Exchange)">
            + Add a custom exchange reason
          </p>
        </ng-container>
      </ng-container>

      <div class="actions">
        <app-new-button
          (click)="closeWindow('return-reason-edit')"
          [disabled]="isSaving"
          buttonStyle="cancel"
        >
          Cancel
        </app-new-button>
        <app-new-button
          (click)="saveSettings()"
          [disabled]="isSaving"
          buttonStyle="confirm"
        >
          <app-loader
            *ngIf="isSaving"
            size="button-sized"
            loader="new-loader"
          ></app-loader>
          Save changes
        </app-new-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showArchive">
    <h4 class="modal-title">Return reasons</h4>
    <p class="modal-description">{{ description }}</p>

    <div class="language-archive">
      <span class="language">
        <app-language-selector
          [disabled]="isSaving || isLoading"
          [defaultLanguage]="activeLanguage"
          (setSelectLanguage)="selectLanguage($event)"
        ></app-language-selector>
      </span>

      <span class="archive-icon"
        ><mat-icon fontSet="material-icons-outlined">inventory_2</mat-icon
        ><span class="icon-text">Archived reasons</span>
        <mat-icon matRipple (click)="toggleArchive()" class="filter close-icon"
          >close</mat-icon
        >
      </span>
    </div>

    <div class="return-reasons">
      <p class="table_title">Archived returns</p>

      <ng-container *ngIf="archivedReturns.length === 0">
        <p class="info-message">There are no archived return reasons here.</p>
      </ng-container>

      <div *ngFor="let return of archivedReturns">
        <div class="return_reason">
          <p>
            {{ getDescription(return)
            }}<i class="is_custom ml-5" *ngIf="return.is_custom">| Custom</i>

            <span class="archive-menu">
              <app-overlay-menu>
                <button
                  (click)="handleArchive(return, 'unarchive')"
                  mat-menu-item
                >
                  <span class="item">
                    <img
                      class="unarchive-icon filter"
                      src="assets/images/icons/unarchive.png"
                    />
                    <span>Unarchive reason</span>
                  </span>
                </button>
              </app-overlay-menu>
            </span>
          </p>
          <p class="status" *ngIf="return.sub_reasons.length === 0">
            {{ getRestockOrNot(return.id) }}
          </p>
        </div>

        <div *ngFor="let subReason of return.sub_reasons">
          <div class="return_reason">
            <p class="adjust_spacing ml-10">
              <img src="assets/images/icons/sub-task.png" class="filter" />
              {{ getDescription(subReason) }}
              <i class="ml-5 is_custom" *ngIf="subReason.is_custom">| Custom</i>
            </p>
            <p class="status">
              {{ getRestockOrNotSubReason(subReason) }}
            </p>
          </div>
        </div>
      </div>

      <ng-container *ngIf="complaintsEnabled">
        <p class="table_title">Archived complaints</p>

        <ng-container *ngIf="archivedComplaints.length === 0">
          <p class="info-message">There are no archived complaints here.</p>
        </ng-container>

        <div *ngFor="let complaint of archivedComplaints">
          <div class="return_reason">
            <p>
              {{ getDescription(complaint) }}
              <i class="is_custom" *ngIf="complaint.is_custom">| Custom</i>

              <span class="archive-menu">
                <app-overlay-menu>
                  <button
                    (click)="handleArchive(complaint, 'unarchive')"
                    mat-menu-item
                  >
                    <span class="item">
                      <img
                        class="unarchive-icon filter"
                        src="assets/images/icons/unarchive.png"
                      />
                      <span>Unarchive complaint</span>
                    </span>
                  </button>
                </app-overlay-menu>
              </span>
            </p>
            <p class="status" *ngIf="complaint.sub_reasons.length === 0">
              {{ getRestockOrNot(complaint.id) }}
            </p>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="exchangesEnabled">
        <p class="table_title">Archived exchanges</p>

        <ng-container *ngIf="archivedExchanges.length === 0">
          <p class="info-message">
            There are no archived exchange reasons here.
          </p>
        </ng-container>

        <div *ngFor="let exchange of archivedExchanges">
          <div class="return_reason">
            <p>
              {{ getDescription(exchange)
              }}<i class="is_custom ml-5" *ngIf="exchange.is_custom"
                >| Custom</i
              >

              <span class="archive-menu">
                <app-overlay-menu>
                  <button
                    (click)="handleArchive(exchange, 'unarchive')"
                    mat-menu-item
                  >
                    <span class="item">
                      <img
                        class="unarchive-icon filter"
                        src="assets/images/icons/unarchive.png"
                      />
                      <span>Unarchive exchange</span>
                    </span>
                  </button>
                </app-overlay-menu>
              </span>
            </p>
            <p class="status" *ngIf="exchange.sub_reasons.length === 0">
              {{ getRestockOrNot(exchange.id) }}
            </p>
          </div>

          <div *ngFor="let subReason of exchange.sub_reasons">
            <div class="return_reason">
              <p class="adjust_spacing ml-10">
                <img src="assets/images/icons/sub-task.png" class="filter" />
                {{ getDescription(subReason) }}
                <i class="ml-5 is_custom" *ngIf="subReason.is_custom"
                  >| Custom</i
                >
              </p>
              <p class="status">
                {{ getRestockOrNotSubReason(subReason) }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-modal>

<app-modal
  id="custom-reason"
  size="small"
  customWidth="800px"
  [dismissable]="false"
>
  <h1>Add a custom reason and its translations</h1>
  <p>
    Add a custom reason for your store, and ensure it's translated into multiple
    languages to make it accessible to all your customers in the Digital Return
    Form. Changes are reflected automatically but will not be saved until you
    click “save changes” in the screen with all the reasons.
  </p>

  <div class="d-flex flex-wrap">
    <div
      *ngFor="let description of getReasonDescriptions(editType)"
      class="mr-24"
    >
      <p class="input-label">
        Custom reason in {{ description.language }}
        <span *ngIf="description.language === 'English'"> (US)</span>
        <span
          class="fi fi-{{ getCountryFlag(description.language) }} ml-5"
        ></span>
      </p>
      <input
        [ngClass]="{ error: description.description.length === 0 }"
        [(ngModel)]="description.description"
        [placeholder]="getPlaceholder(description.language)"
        type="text"
      />
      <p *ngIf="description.description.length === 0" class="error-text">
        Missing translation.
      </p>
    </div>
  </div>

  <p class="mt-24 mb-0">Please try to keep the reasons short and simple.</p>

  <div class="mt-24 mr-20 actions">
    <app-new-button
      *ngIf="!isEditing"
      buttonStyle="cancel-white"
      (click)="closeAndReset('custom-reason')"
    >
      Cancel
    </app-new-button>
    <app-new-button
      [disabled]="isSaving || checkDisabled()"
      (click)="closeWindow('custom-reason')"
    >
      Confirm
    </app-new-button>
  </div>
</app-modal>
