import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PricePlanSubscription } from 'src/app/settings/models/price-plan-subscription.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private pricePlansSubject: BehaviorSubject<PricePlan[]> = new BehaviorSubject<
    PricePlan[]
  >([]);

  private accountStatusSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isPricePlanUpdatedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private pricePlanSubscriptionsSubject: BehaviorSubject<PricePlanSubscription> =
    new BehaviorSubject<PricePlanSubscription>(new PricePlanSubscription());

  constructor(private httpClient: HttpClient) {}

  public getPricePlans(): Observable<PricePlan[]> {
    if (
      this.pricePlansSubject.value == null ||
      this.pricePlansSubject.value.length == 0
    ) {
      this.httpClient
        .get<PricePlan[]>(`${environment.apiUrl}/api/billing/price-plans`)
        .subscribe((pricePlans) => {
          this.pricePlansSubject.next(pricePlans);
        });
    }
    return this.pricePlansSubject;
  }

  setPricePlans(pricePlans: PricePlan[]): void {
    this.pricePlansSubject.next(pricePlans);
  }

  public getSubjectPricePlans(): Observable<PricePlan[]> {
    return this.pricePlansSubject.asObservable();
  }

  public updatePricePlan(pricePlanId: string): Observable<string> {
    return this.httpClient.put<string>(
      `${environment.apiUrl}/api/billing/${pricePlanId}`,
      null
    );
  }

  public getAccountStatus(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${environment.apiUrl}/api/billing/account-active`
    );
  }

  public getSubjectAccountStatus(): Observable<boolean> {
    return this.accountStatusSubject.asObservable();
  }

  public setAccountStatus(status: boolean): void {
    this.accountStatusSubject.next(status);
  }

  hasPricePlanLoaded(): boolean {
    return !(
      this.pricePlansSubject.value == null ||
      this.pricePlansSubject.value.length == 0
    );
  }

  getPricePlanSubscriptions(): Observable<PricePlanSubscription> {
    return this.httpClient.get<PricePlanSubscription>(
      `${environment.apiUrl}/api/billing/subscriptions`
    );
  }

  cancelSubscription(addonId: string): Observable<boolean> {
    return this.httpClient.post<boolean>(
      `${environment.apiUrl}/api/billing/addon/${addonId}/cancel`,
      {}
    );
  }

  setIsPricePlanUpdated(isUpdated: boolean): void {
    this.isPricePlanUpdatedSubject.next(isUpdated);
  }

  getIsPricePlanUpdated(): Observable<boolean> {
    return this.isPricePlanUpdatedSubject.asObservable();
  }

  getSubjectPricePlanSubscriptions(): Observable<PricePlanSubscription> {
    return this.pricePlanSubscriptionsSubject.asObservable();
  }

  setPricePlanSubscriptions(
    pricePlansSubscriptions: PricePlanSubscription
  ): void {
    this.pricePlanSubscriptionsSubject.next(pricePlansSubscriptions);
  }
}
