export class ShippingLocation {
  public id?: string;
  public receiver = '';
  public street = '';
  public door_number = '';
  public city = '';
  public zip_code = '';
  public country_code = '';
  public additional_instructions = '';
  public is_default_shipping_location = false;
  public is_restock_to_location_id = false;
  public is_send_from_location_id = false;
  public shopify_location_id = 0;
}
