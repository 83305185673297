import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Item } from 'src/app/models/item.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { filterOnStatus } from 'src/app/returns/filter-on-status';
import { ReturnsService } from 'src/app/returns/returns.service';
import { returnStatusesFilters } from 'src/app/returns/table-filters';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { Currency } from 'src/app/settings/models/currency.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { ShippingSettings } from 'src/app/settings/models/shipping-settings.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { FilterStatusTypeEnum } from 'src/app/shared/enums/filter-status-type.enum';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import { ShippingSettingsService } from 'src/app/shared/services/settings/shipping-settings.service';
import {
  complaintRefund,
  complaintReturnItems,
  complaintReturnItemsStatus,
  hasComplaintReason,
} from '../../complaint-return-items';
import { refundStatusValuesText } from '../../refund-status-values';

@Component({
  selector: 'app-refund-amount',
  templateUrl: './refund-amount.component.html',
  styleUrls: ['./refund-amount.component.scss'],
})
export class RefundAmountComponent implements OnInit {
  return: ReturnRequest = new ReturnRequest();
  showDeductInfo = false;
  returnStatusEnum = ReturnStatusEnum;

  complaintSettings: ComplaintSettings = new ComplaintSettings();
  returnSettings: ReturnSettings = new ReturnSettings();
  shippingSettings: ShippingSettings = new ShippingSettings();
  shippingCosts: Currency[] = [];

  totalRefundAmount = 0;
  shippingCost = 0;
  shippingCostInput = 0;
  refundAmount = 0;
  numberOfItemsInOrder = 0;
  returnStatusesFilters = returnStatusesFilters;
  firstLoad = true;
  edited = false;
  refundSet = false;
  refundSettingsOpened = false;
  showOptions = true;
  hasComplaintReason = hasComplaintReason;
  complaintRefund = complaintRefund;
  complaintReturnItems = complaintReturnItems;
  complaintReturnItemsStatus = complaintReturnItemsStatus;
  isComplaintRefund = false;
  isComplaintReturnItems = false;
  isComplaintReturnItemsStatus = false;
  isResolved = false;
  filterOnStatus = filterOnStatus;
  refundStatusText = refundStatusValuesText;
  isEditingDeductCharge = false;

  constructor(
    private returnsService: ReturnsService,
    private complaintSettingsService: ComplaintSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private shippingSettingsService: ShippingSettingsService
  ) {}

  ngOnInit(): void {
    this.refundSet = false;
    this.getSettingsSubjects();
    this.getSelectedReturn();
    this.firstLoad = false;
  }

  getSelectedReturn(): void {
    this.returnsService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
        this.totalRefundAmount = 0;
        this.refundAmount = 0;
        res.items.forEach((item: Item) => {
          if (
            item.decision &&
            item.merchant_compensation_choice === CompensationOption.Refund
          ) {
            this.totalRefundAmount += Number(item.return_amount);
            this.refundAmount +=
              Number(item.price) * item.quantity - Number(item.total_discount);
            this.numberOfItemsInOrder = item.quantity;
          }
        });

        this.return = res;
        this.getShouldTimelineUpdate();
        this.isComplaintRefund = this.complaintRefund(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned,
          this.complaintSettings.should_send_instant_refund
        );
        this.isComplaintReturnItems = this.complaintReturnItems(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        );
        this.isComplaintReturnItemsStatus = this.complaintReturnItemsStatus(
          this.return.status,
          this.return.items,
          this.complaintSettings.should_item_be_returned
        );

        this.showOptions = true;
        if (
          ([
            ReturnStatusEnum.PackageSent,
            ReturnStatusEnum.AwaitingDropOff,
            ReturnStatusEnum.ShippingSlipSkipped,
            ReturnStatusEnum.ManualShippingSlipAttached,
          ].includes(res.status) &&
            this.isComplaintReturnItems) ||
          this.isComplaintReturnItemsStatus ||
          ([
            ReturnStatusEnum.RefundApproved,
            ReturnStatusEnum.RefundDenied,
            ReturnStatusEnum.RequestDenied,
            ReturnStatusEnum.RefundPartiallyApproved,
            ReturnStatusEnum.RequestManaged,
          ].includes(res.status) &&
            !this.hasComplaintReason(res.items))
        ) {
          this.showOptions = false;
        }
        this.isResolved = this.filterOnStatus(
          FilterStatusTypeEnum.resolved,
          true,
          this.return.status
        );
        this.setRefundAmount();
        this.setIfEdited();
      });
  }

  getRefundAmount(): string {
    if (this.totalRefundAmount < 0) {
      return "0.00";
    }
    return this.totalRefundAmount.toFixed(2);
  }

  setIfEdited(): void {
    const foundCurrency = this.shippingCosts.find(
      (currency) => currency.key === this.return.currency
    );

    this.edited =
      foundCurrency?.value !== Number(this.return.return_shipping_cost) &&
      !this.hasComplaintReason(this.return.items);
  }

  getShippingCost(): number {
    const foundCurrency = this.shippingCosts.find(
      (currency) => currency.key === this.return.currency
    );

    return foundCurrency?.value ?? 0;
  }

  setRefundAmount(): void {
    if (
      Number(this.return.return_shipping_cost) === 0 &&
      this.return.status === ReturnStatusEnum.PackageReceived &&
      !this.refundSet
    ) {
      this.return.return_shipping_cost = this.getShippingCost().toString();
      this.refundSet = true;
    }

    if (
      this.return.type === ReturnReasonType.Complaint &&
      filterOnStatus(
        FilterStatusTypeEnum.returnRequested,
        true,
        this.return.status
      )
    ) {
      this.return.return_shipping_cost = this.return.charge_return_shipping
        ? this.getShippingCost().toString()
        : '0';
    }

    if (Number(this.return.return_shipping_cost) !== 0) {
      this.shippingCost = Number(this.return.return_shipping_cost);
    }

    if (
      this.return.charge_return_shipping &&
      this.return.refund_original_shipping
    ) {
      this.totalRefundAmount =
        this.refundAmount -
        this.shippingCost +
        this.return.shipping_cost -
        this.getReducedPrice();
    } else if (this.return.charge_return_shipping) {
      this.totalRefundAmount =
        this.refundAmount - this.shippingCost - this.getReducedPrice();
    } else if (this.return.refund_original_shipping) {
      this.totalRefundAmount =
        this.refundAmount + this.return.shipping_cost - this.getReducedPrice();
    }

    this.shippingCostInput = this.shippingCost;
  }

  getReducedPrice(): number {
    let reducedPrice = 0;
    this.return.items.forEach((item: Item) => {
      if (
        item.decision &&
        Number(item.price) - Number(item.total_discount) >
          Number(item.return_amount)
      ) {
        reducedPrice += Number(item.price) - Number(item.return_amount);
      }
    });
    return reducedPrice;
  }

  disableShipping(): boolean {
    const foundReturnStatus = this.returnStatusesFilters.find(
      (status) => status.name === 'resolved'
    );

    if (
      foundReturnStatus &&
      foundReturnStatus.status.includes(this.return.status)
    ) {
      return true;
    }
    return false;
  }

  updateReturn(): void {
    this.setRefundAmount();
    this.returnsService.setSelectedReturnSubject(this.return, false);
  }

  updateShippingCost(value: string): void {
    const parsedValue = Number(value);

    if (parsedValue >= 0 && parsedValue <= this.return.total_price) {
      this.return.return_shipping_cost = value;
      this.shippingCost = parsedValue;
    } else if (parsedValue > this.return.total_price) {
      this.return.return_shipping_cost = this.return.total_price.toString();
      this.shippingCost = this.return.total_price;
    } else {
      this.return.return_shipping_cost = '0';
      this.shippingCost = 0;
    }
    this.shippingCostInput = this.shippingCost;
    this.setRefundAmount();
    this.updateReturn();
  }

  isRefundIssued(): boolean {
    return (
      this.hasComplaintReason(this.return.items) &&
      [
        ReturnStatusEnum.RefundApproved,
        ReturnStatusEnum.RefundPartiallyApproved,
        ReturnStatusEnum.RefundDenied,
        ReturnStatusEnum.RequestManaged,
        ReturnStatusEnum.PackageSent,
        ReturnStatusEnum.AwaitingDropOff,
        ReturnStatusEnum.ShippingSlipSkipped,
        ReturnStatusEnum.ManualShippingSlipAttached,
      ].includes(this.return.status)
    );
  }

  allItemsDeclined(): boolean {
    return this.return.items.every(
      (item: Item) =>
        !item.decision || item.status === ReturnItemStatusEnum.RefundDenied
    );
  }

  getSettingsSubjects(): void {
    combineLatest([
      this.complaintSettingsService.getComplaintSettingsSubject(),
      this.returnSettingsService.getSubjectReturnSettings(),
      this.shippingSettingsService.getSubjectShippingSettings(),
      this.shippingSettingsService.getSubjectShippingCostSettings(),
    ]).subscribe(
      ([complaintSettings, returnSettings, shippingSettings, shippingCosts]: [
        ComplaintSettings,
        ReturnSettings,
        ShippingSettings,
        Currency[]
      ]) => {
        this.complaintSettings = complaintSettings;
        this.returnSettings = returnSettings;
        this.shippingSettings = shippingSettings;
        this.shippingCosts = shippingCosts;
        this.shippingSettings.shipping_cost = shippingCosts;
      }
    );
  }

  isExchange(): boolean {
    return this.return.type === ReturnReasonType.Exchange;
  }

  hasItemWithStoreCredit(): boolean {
    return (
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.StoreCredit
      )
    );
  }

  showAmountDeductedAlert(): boolean {
    return (
      !this.showDeductInfo &&
      this.return.charge_return_shipping &&
      !this.disableShipping() &&
      !this.isComplaintRefund &&
      this.showOptions &&
      !this.isExchange()
    );
  }

  refundItemsLength(): number {
    return this.return.items.filter(
      (item) => item.merchant_compensation_choice === CompensationOption.Refund
    ).length;
  }

  allItemsForRefund(): boolean {
    return this.refundItemsLength() === this.return.items.length;
  }

  newItemsCount(): number {
    if (this.isResolved) {
      return this.return.items.filter(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.NewItem &&
          item.status === ReturnItemStatusEnum.RefundApproved
      ).length;
    }
    const newItems = this.return.items.filter(
      (item) =>
        item.merchant_compensation_choice === CompensationOption.NewItem &&
        item.decision
    ).length;
    if (newItems === 0 && !this.isExchange()) {
      return this.return.items.filter((item) => item.decision).length;
    }
    return newItems;
  }

  showRefundOptions(): boolean {
    if (!this.showOptions || this.isResolved) {
      return false;
    }
    return (
      (this.shippingSettings.customer_pays_shipping_by_default ||
        this.isComplaintRefund) &&
      (!this.isExchange() ||
        (this.refundItemsLength() > 0 || this.hasItemWithStoreCredit()))
    );
  }

  getShouldTimelineUpdate(): void {
    this.returnsService.getShouldTimelineUpdate().subscribe((res: boolean) => {
      if (res) {
        this.shippingCost = this.getShippingCost();
        this.shippingCostInput = this.getShippingCost();
        this.firstLoad = true;
        this.refundSet = false;
      }
    });
  }

  exchangeRejectedItems(): number {
    if (this.isResolved) {
      return this.return.items.filter(
        (item) => item.status === ReturnItemStatusEnum.RefundDenied
      ).length;
    }
    return this.return.items.filter((item) => !item.decision).length;
  }

  allExchangeItemsRejected(): boolean {
    return (
      this.return.items.length ===
        this.return.items.filter(
          (item) => item.status === ReturnItemStatusEnum.RefundDenied
        ).length && this.isResolved
    );
  }

  getExchangeRefundAmount(): number {
    return this.return.items.reduce((acc, item) => {
      if (item.merchant_compensation_choice === CompensationOption.Refund) {
        return acc + Number(item.return_amount);
      }
      return acc + 0;
    }, 0);
  }

  getStoreCreditRefundAmount(): number {
    const storeCreditRefundAmount = this.return.items.reduce((acc, item) => {
    if (
      item.merchant_compensation_choice === CompensationOption.StoreCredit &&
      item.status !== ReturnItemStatusEnum.ItemDenied
    ) {
      return acc + Number(item.return_amount);
    }
    return acc + 0;
    }, 0);

    const leftoverDeductions = (
      this.return.charge_return_shipping && 
      this.totalRefundAmount <= 0) ? 
        this.totalRefundAmount : 0;

    return storeCreditRefundAmount + leftoverDeductions;
  }

  hasBothExchangeAndRefund(): boolean {
    return (
      this.isExchange() &&
      this.refundItemsLength() > 0 &&
      this.refundItemsLength() !== this.return.items.length
    );
  }

  hasComplaintNewItem(): boolean {
    return (
      this.hasComplaintReason(this.return.items) &&
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.NewItem
      )
    );
  }

  getComplaintDeductedPrice(): number {
    return this.return.items.reduce((acc, item) => {
      if (item.merchant_compensation_choice === CompensationOption.NewItem) {
        return acc + Number(item.return_amount);
      }
      return acc + 0;
    }, 0);
  }

  editDeductCharge(): void {
    this.isEditingDeductCharge = !this.isEditingDeductCharge;
    if (!this.isEditingDeductCharge) {
      this.updateShippingCost(this.shippingCostInput.toString());
    }
  }

  isRefundReplacedByStoreCredit(): boolean {
    return this.totalRefundAmount <= 0 && this.hasItemWithStoreCredit() && !(
      this.return.items.some(
        (item) =>
          item.merchant_compensation_choice === CompensationOption.Refund
      )
    );
  }
}
