<div class="list-view__container p-10 pt-15 w-90">
  <div class="list-view__header d-flex mb-20">
    <mat-chip-list aria-label="Returns type">
      <mat-chip
        *ngFor="let status of returnStatuses"
        class="list-view__header-chip transparent font-normal"
        [ngClass]="[
          status.selected ? 'selected' : '',
          status.title === ReturnStatusEnum.Archived ? 'archived' : ''
        ]"
        (click)="setSelectedType(status)"
      >
        <span [ngClass]="{ 'selected-text': status.selected }">
          {{ status.title }}
        </span>
        <span
          *ngIf="
            status.title !== 'All' &&
            status.title !== 'Archived' &&
            status.title !== 'Resolved' &&
            checkTypeLengths(status.name) !== 0
          "
          class="list-view__header-chip__amount ml-5"
          [ngClass]="getStatusClass(status)"
          >{{ checkTypeLengths(status.name) }}</span
        >
      </mat-chip>

      <mat-chip
        (click)="openDownloadModal()"
        class="list-view__header-chip transparent font-normal data-chip"
      >
        <span>
          <mat-icon class="data-icon pointer" fontSet="material-icons-outlined"
            >save_alt</mat-icon
          >
        </span>
      </mat-chip>
    </mat-chip-list>

    <div class="list-view__header-right d-flex">
      <input
        class="list-view__header-search font-14 font-normal pl-35"
        type="text"
        placeholder="Search orders, customer details, etc."
        (keyup)="applyFilter($event)"
      />
    </div>
  </div>
  <div
    class="list-view__content"
    *ngIf="returnsResponse.returns && returnsResponse.returns.length > 0"
  >
    <!-- Will be enabled in a later version -->
    <!-- <button
      mat-button
      [matMenuTriggerFor]="menu"
      aria-label="Filter menu"
      class="list-view__content-select mb-0 pl-10 font-14 font-normal"
    >
      <mat-icon> format_list_bulleted </mat-icon>
      View
      <mat-icon class="float-right pt-5 text-dark-gray"> expand_more </mat-icon>
    </button>
    <p class="font-normal font-12 text-dark-gray mt-5 mb-20">
      You can select up to 7 filters for your table.
    </p>
    <mat-menu #menu="matMenu" class="mt-10">
      <span *ngFor="let filter of tableColumns">
        <mat-checkbox
          class="mat-menu-item"
          color="accent"
          (click)="$event.stopPropagation()"
          (change)="filterTableColumns(filter)"
          [(ngModel)]="filter.checked"
          >{{ filter.name }}</mat-checkbox
        >
        <br />
      </span>
    </mat-menu> -->
    <app-return-table></app-return-table>
  </div>
  <div
    class="list-view__message text-almost-black"
    *ngIf="returnsResponse.returns && returnsResponse.returns.length === 0"
  >
    <div
      class="list-view__message-content text-almost-black"
      *ngIf="!isLoading"
    >
      <img src="assets/images/no-return.png" alt="No returns" />
      <p class="font-700 font-20 mb-16">{{ getMessage().title }}</p>
      <p class="font-normal font-14">
        {{ getMessage().description }}
      </p>
    </div>

    <app-loader size="large" *ngIf="isLoading"></app-loader>
  </div>
</div>

<app-modal id="new-features" size="new-features" [dismissable]="false">
  <div class="header">
    <div class="title">
      <h1>Updates and new features</h1>
      <mat-icon class="close-icon" (click)="closeModal()">close</mat-icon>
    </div>
    <p class="date">{{ date }}</p>
  </div>

  <!-- Replace image with new design & update text for each release -->
  <div class="body">
    <img
      class="features-logo"
      src="../assets/images/new-features-logo.png"
      alt="New feature logo"
    />
    <h1 class="mt-24">New Integration!</h1>
    <iframe width="500" height="315" src="https://app.vaam.io/embed/EvPs9FX" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen></iframe>
    <br/> &nbsp; <br/>
    <h4>Bundles.app</h4>
    <p>
      Bundles.app is now available for Essential and PRO payment plans.
      We are happy to announce our new integration: Bundles.app, <a href="https://apps.shopify.com/bundles?search_id=26c7d666-d1bf-4298-9c2f-454177a2f580&surface_detail=bundles&surface_inter_position=1&surface_intra_position=18&surface_type=search" target="_blank">found here!</a> 
      Your customers can now return and exchange individual items or the whole bundle!
      To activate the integration, go to the Returbo App <b>Settings → Integrations → Other</b> 
      and you will see Bundles.app. After you have set up the Bundles app 
      (or if you already have it), go the Bundles.app <b>Settings → Integrations → Returbo</b> 
      and click on the slider to give Returbo access to your bundles.
      <br/> &nbsp; <br/>
      And of course, some minor UI and bug fixes. 
    </p>
  </div>
</app-modal>

<app-modal id="download-csv" size="x-small-rectangle" [dismissable]="false">
  <div>
    <h1>Download your return data</h1>
    <p>
      With this function you can now keep better track of your returns amount,
      which reasons are getting used the most, etc.
    </p>
    <h6>Download data</h6>
  </div>

  <app-date-picker
    (startDate)="setStartDate($event)"
    (endDate)="setEndDate($event)"
  ></app-date-picker>

  <div class="actions">
    <button (click)="closeModal()" class="back">Go back</button>
    <button
      (click)="downloadCSV()"
      [disabled]="!checkDownload()"
      class="download"
    >
      <app-loader
        *ngIf="isLoading"
        size="button-sized"
        loader="new-loader"
      ></app-loader>
      Download CSV file
    </button>
  </div>
</app-modal>
