import { Component, Input, OnInit } from '@angular/core';
import { Item } from 'src/app/models/item.model';
import { ListViewRequestUpdate } from 'src/app/models/list-view-request-update.model';
import { ReturnRequest } from 'src/app/models/return-request.model';
import { SelectedImage } from 'src/app/models/selected-image.model';
import { StatusStyling } from 'src/app/models/status-styling.model';
import { filterOnStatus } from 'src/app/returns/filter-on-status';
import { ReturnsService } from 'src/app/returns/returns.service';
import { resolvedItemStatuses } from 'src/app/returns/table-filters';
import { ComplaintSettings } from 'src/app/settings/models/complaint-settings.model';
import { ExchangeSettings } from 'src/app/settings/models/exchange-settings.model';
import { ReturnSettings } from 'src/app/settings/models/return-settings.model';
import { CompensationOption } from 'src/app/shared/enums/compensation-option.enum';
import { FilterStatusTypeEnum } from 'src/app/shared/enums/filter-status-type.enum';
import { ItemDecisionEnum } from 'src/app/shared/enums/item-decision.enum';
import { ReturnItemStatusEnum } from 'src/app/shared/enums/return-item-status.enum';
import { ReturnReasonType } from 'src/app/shared/enums/return-reason-type.enum';
import { ReturnStatusEnum } from 'src/app/shared/enums/return-status.enum';
import { StyleTypeEnum } from 'src/app/shared/enums/style-type-enum';
import { Calculations } from 'src/app/shared/helpers/calculations';
import { ComplaintSettingsService } from 'src/app/shared/services/complaint-settings.service';
import { ExchangeSettingsService } from 'src/app/shared/services/exchange-settings.service';
import { ReturnSettingsService } from 'src/app/shared/services/settings/return-settings.service';
import {
  complaintRefund,
  complaintRefundResolved,
  complaintReturnItems,
  complaintReturnItemsStatus,
  hasComplaintReason,
} from '../../complaint-return-items';
import { ImagePreviewService } from '../../images-preview/image-preview.service';
import { ItemsTableService } from './items-table.service';
import { LanguageSettings } from 'src/app/settings/models/language-settings.model';
import { LanguageSettingsService } from 'src/app/shared/services/settings/language-settings.service';

@Component({
  selector: 'app-items-table',
  templateUrl: './items-table.component.html',
  styleUrls: ['./items-table.component.scss'],
})
export class ItemsTableComponent implements OnInit {
  @Input() amountSelected = 0;

  return: ReturnRequest = new ReturnRequest();
  enabledEditIds: string[] = [];
  ReturnStatusEnum = ReturnStatusEnum;
  CompensationOption = CompensationOption;
  ReturnItemStatusEnum = ReturnItemStatusEnum;
  resolvedItemStatuses = resolvedItemStatuses;
  selectedImage: SelectedImage = new SelectedImage();
  currentIndex = 0;
  selectedImgSrc = '';
  complaintRefund = complaintRefund;
  complaintReturnItems = complaintReturnItems;
  complaintReturnItemsStatus = complaintReturnItemsStatus;
  complaintRefundResolved = complaintRefundResolved;
  hasComplaint = hasComplaintReason;
  hasComplaintRefundResolved = false;
  hasComplaintRefund = false;
  filterOnStatus = filterOnStatus;
  isReturnRequested = false;
  isPackageReceived = false;
  isResolvedPackageReceived = false;
  isNotResolved = false;
  isNotIncoming = false;
  isNotPackageReceived = false;
  isRequestReceived = false;
  isIncomingResolved = false;
  isResolved = false;
  isNotIncomingPackageReceived = false;
  isNotReturnRequested = false;
  isNotResolvedPackageReceived = false;
  isNotIncomingResolved = false;
  isRequestSlipSkipped = false;
  isRefundDenied = false;
  isIncoming = false;
  isNotRequestedPackageReceived = false;
  isRequestResolved = false;
  returnReasonType = ReturnReasonType;
  compensationOptionEnum = CompensationOption;
  styleTypeEnum = StyleTypeEnum;

  languageSettings: LanguageSettings = new LanguageSettings();
  complaintSettings: ComplaintSettings = new ComplaintSettings();
  exchangeSettings: ExchangeSettings = new ExchangeSettings();
  returnSettings: ReturnSettings = new ReturnSettings();
  isExchangeRequest = false;
  isComplaintRequest = false;

  constructor(
    private returnsService: ReturnsService,
    private imagePreviewService: ImagePreviewService,
    private languageSettingsService: LanguageSettingsService,
    private complaintSettingsService: ComplaintSettingsService,
    private exchangeSettingsService: ExchangeSettingsService,
    private returnSettingsService: ReturnSettingsService,
    private itemsTableService: ItemsTableService
  ) {}

  ngOnInit(): void {
    this.getComplaintSettingsSubject();
    this.getSelectedReturn();
    this.getExchangeSettingsSubject();
    this.getLanguageSettingsSubject();

    this.itemsTableService
      .getRequestToUpdate()
      .subscribe((res: ListViewRequestUpdate) => {
        if (res.requestId !== this.return.id) {
          this.enabledEditIds = [];
        }
      });
    this.getReturnSettingsSubject();
  }

  getSelectedReturn(): void {
    this.returnsService
      .getSelectedReturnSubject()
      .subscribe((res: ReturnRequest) => {
        this.return = res;
        this.hasComplaintRefundResolved = this.complaintRefundResolved(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned
        );
        this.hasComplaintRefund = this.complaintRefund(
          this.return.items,
          this.return.status,
          this.complaintSettings.should_item_be_returned,
          this.complaintSettings.should_send_instant_refund
        );
        this.return.items.forEach((item: Item) => {
          if (item.decision === undefined) {
            item.decision = true;
          }
          if (item.show_attached === undefined) {
            item.show_attached = true;
          }
        });
        this.assignStatus();
        this.isExchangeRequest = this.return.type === ReturnReasonType.Exchange;
        this.isComplaintRequest =
          this.return.type === ReturnReasonType.Complaint;
      });
  }

  checkOnSale(item: Item): boolean {
    return Number(item.total_discount) > 0 ? true : false;
  }

  shouldAddClass(): boolean {
    return (
      this.returnSettings &&
      this.returnSettings.automatically_accept_requests &&
      this.return.status === ReturnStatusEnum.RequestManaged
    );
  }

  onChange(changedItem: Item): void {
    this.amountSelected = 0;
    const val = !changedItem.selected;
    this.return.items.map((item: Item, i: number) => {
      if (item.id === changedItem.id) {
        this.return.items[i].selected = val;
      }
      if (item.selected) {
        this.amountSelected += 1;
      }
    });
    this.updateReturn();
  }

  getReason(item: Item): string {
    const english = this.languageSettings.languages.find(lang => lang.name === 'English');
    let reasonLanguage = english?.name;
    
    if (!english?.is_active) {
      const defaultLanguage = this.languageSettings.languages.find(
        lang => lang.id === this.languageSettings.default_language
      );
      reasonLanguage = defaultLanguage?.name;
    }

    const reason = item.return_reason.descriptions.find(
      (description) => description.language === reasonLanguage
    );
    return reason ? reason.description : '';
  }

  getSubReason(item: Item): string {
    if (!item.sub_return_reason_id || item.sub_return_reason_id === 0) {
      return '';
    }
    const subReason = item.return_reason.sub_reasons.find(
      (res) => res.id === item.sub_return_reason_id
    );

    const english = this.languageSettings.languages.find(lang => lang.name === 'English');
    let reasonLanguage = english?.name;
    
    if (!english?.is_active) {
      const defaultLanguage = this.languageSettings.languages.find(
        lang => lang.id === this.languageSettings.default_language
      );
      reasonLanguage = defaultLanguage?.name;
    }

    const englishSubReason = subReason?.descriptions.find(
      (res) => res.language === reasonLanguage
    );

    return englishSubReason ? englishSubReason.description : '';
  }

  getSplitVariant(variantTitle: string): string[] {
    return variantTitle.split('/');
  }

  getVariant(index: number, item: Item, styleType: string): string {
    let variant = item.variant_title;
    if (
      this.isExchangeRequest &&
      styleType !== StyleTypeEnum.crossed &&
      item.requested_variant_title
    ) {
      variant = item.requested_variant_title;
    }
    return this.getSplitVariant(variant)[index];
  }

  checkIfDifferentVariant(item: Item, index: number): boolean {
    const variant = this.getSplitVariant(item.variant_title)[index];
    const requestedVariant = this.getSplitVariant(item.requested_variant_title)[
      index
    ];

    if (variant !== requestedVariant) {
      return true;
    }
    return false;
  }

  setEdit(item: Item): void {
    this.enabledEditIds.push(item.name);
  }

  cancelEdit(item: Item): void {
    item.return_amount = Number(this.getTotalPrice(item));
    this.enabledEditIds = this.enabledEditIds.filter(
      (x: string) => x !== item.name
    );
    this.updateReturn();
  }

  addResolvedClass(status: string, item: Item): string {
    let resolvedStatusClass = this.resolvedItemStatuses.find(
      (resolved: StatusStyling) => resolved.status.includes(status)
    )?.class;

    if (
      this.isExchangeRequest &&
      item.status === ReturnItemStatusEnum.RefundApproved &&
      item.merchant_compensation_choice === CompensationOption.Refund
    ) {
      return 'partially';
    }

    if (
      this.hasComplaintRefundResolved ||
      this.hasComplaintReturnItemStatus(status)
    ) {
      const refundApprovedStatuses = [
        ReturnItemStatusEnum.RefundApproved,
        ReturnItemStatusEnum.ItemReceived,
        ReturnItemStatusEnum.Requested,
      ];

      resolvedStatusClass = refundApprovedStatuses.includes(
        status as ReturnItemStatusEnum
      )
        ? 'approved'
        : 'rejected';
    }
    return resolvedStatusClass || 'incoming';
  }

  displayResolvedMessage(status: string, item: Item): string {
    let resolvedStatusTitle = this.getResolvedStatusTitle(status);

    if (
      this.isExchangeRequest &&
      item.status === ReturnItemStatusEnum.RefundApproved &&
      item.merchant_compensation_choice === CompensationOption.Refund
    ) {
      return 'Partially accepted';
    }

    if (
      this.hasComplaintRefundResolved ||
      this.hasComplaintReturnItemStatus(status)
    ) {
      resolvedStatusTitle = this.getRefundItemDecision(status);
    }
    return resolvedStatusTitle || 'Incoming';
  }

  private getResolvedStatusTitle(status: string): string | undefined {
    return this.resolvedItemStatuses.find(
      (resolved: StatusStyling) =>
        resolved.status.includes(status) &&
        resolved.returnType.includes(this.return.type)
    )?.title;
  }

  private hasComplaintReturnItemStatus(status: string): boolean {
    return this.complaintReturnItemsStatus(
      status,
      this.return.items,
      this.complaintSettings.should_item_be_returned
    );
  }

  private getRefundItemDecision(status: string): string {
    const refundApprovedStatuses = [
      ReturnItemStatusEnum.RefundApproved,
      ReturnItemStatusEnum.ItemReceived,
      ReturnItemStatusEnum.Requested,
    ];

    return refundApprovedStatuses.includes(status as ReturnItemStatusEnum)
      ? ItemDecisionEnum.Accepted
      : ItemDecisionEnum.Rejected;
  }

  rejectItem(item: Item): void {
    item.return_amount = 0;
    item.status = ReturnItemStatusEnum.RefundDenied;
  }

  restoreItemPrice(item: Item): void {
    Number(item.total_discount) > 0
      ? (item.return_amount = Number(item.price) - Number(item.total_discount))
      : (item.return_amount = Number(item.price));
    item.status = ReturnItemStatusEnum.RefundApproved;
    item.reject_cause = '';
  }

  updatePrice(input: string, item: Item): void {
    Number(input) >= 0 && Number(input) <= Number(item.price)
      ? (item.return_amount = Number(input))
      : (item.return_amount = Number(item.price));

    this.updateReturn();
  }

  updateReturn(): void {
    this.returnsService.setSelectedReturnSubject(this.return, false);
  }

  getOriginalPrice(item: Item): number | string {
    if (
      this.return.status === ReturnStatusEnum.PackageReceived &&
      !item.decision
    ) {
      return 0;
    }
    return parseFloat(item.price).toFixed(2);
  }

  getTotalPrice(item: Item): number | string {
    if (
      (this.return.status === ReturnStatusEnum.PackageReceived &&
        !item.decision &&
        !this.isExchangeRequest) ||
      this.isComplaintItemRejected(item)
    ) {
      return 0;
    }
    let itemPrice = parseFloat(item.price);
    if (
      this.isExchangeRequest &&
      item.merchant_compensation_choice === CompensationOption.Refund &&
      !this.compareAmounts(item)
    ) {
      itemPrice = item.return_amount;
    }

    const total = itemPrice * item.quantity - parseFloat(item.total_discount);
    return Calculations.stringifyNumber(total);
  }

  isLowerPrice(item: Item): boolean {
    return Number(item.return_amount) < Number(item.price);
  }

  cancelEnabled(item: Item): boolean {
    return Number(item.price) > 0 && item.decision;
  }

  amountChanged(item: Item): boolean {
    const price = Number(Number(item.price).toFixed(2));
    const totalDiscount = Number(
      Calculations.stringifyNumber(Number(item.total_discount))
    );

    const refundAmount = Number(
      Calculations.stringifyNumber(item.return_amount)
    );
    const refundableAmount = Number(
      Calculations.stringifyNumber(price - totalDiscount)
    );

    return (
      (this.return.status === ReturnStatusEnum.PackageReceived ||
        (this.isReturnRequested && this.hasComplaint(this.return.items))) &&
      refundAmount < refundableAmount
    );
  }

  compareAmounts(item: Item): boolean {
    if (Number(item.total_discount) > 0) {
      const returnAmount = Number(Number(item.return_amount).toFixed(2));
      const totalDiscount = Number(Number(item.total_discount).toFixed(2));

      return (
        Number(Number(item.price).toFixed(2)) ===
        Number((returnAmount + totalDiscount).toFixed(2))
      );
    }

    return Number(item.price) === Number(item.return_amount);
  }

  toggleAttachedView(item: Item): void {
    item.show_attached = !item.show_attached;
  }

  openImagePreview(item: Item, imgSrc: string): void {
    this.selectedImgSrc = imgSrc;
    this.selectedImage = {
      item: item,
      img: imgSrc,
    };
    setTimeout(() => {
      this.imagePreviewService.setImagePreview(true);
    }, 200);
  }

  shouldDisplayReason(): boolean {
    const isNotPackageReceivedOrIncoming =
      this.isNotPackageReceived &&
      !this.hasComplaint(this.return.items) &&
      this.isNotIncoming;

    const isComplaintRequestedReturn =
      this.hasComplaint(this.return.items) &&
      this.isReturnRequested &&
      this.complaintSettings.should_item_be_returned;

    return isNotPackageReceivedOrIncoming || isComplaintRequestedReturn;
  }

  displayDecisionColumn(): boolean {
    if (!this.isNotResolved) {
      return false;
    }
    const shouldDisplayReason =
      !this.hasComplaint(this.return.items) && this.shouldDisplayReason();

    const hasComplaintRefundResolvedValid =
      this.hasComplaintRefundResolved && this.hasComplaint(this.return.items);

    const isReturnRequestedValid =
      this.isReturnRequested && this.hasComplaint(this.return.items);

    return (
      shouldDisplayReason ||
      hasComplaintRefundResolvedValid ||
      this.isPackageReceived ||
      isReturnRequestedValid
    );
  }

  assignStatus(): void {
    this.isNotResolved = this.filterOnStatus(
      FilterStatusTypeEnum.resolved,
      false,
      this.return.status
    );
    this.isNotIncoming = this.filterOnStatus(
      FilterStatusTypeEnum.incoming,
      false,
      this.return.status
    );
    this.isNotPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.packageReceived,
      false,
      this.return.status
    );
    this.isReturnRequested = this.filterOnStatus(
      FilterStatusTypeEnum.returnRequested,
      true,
      this.return.status
    );
    this.isIncomingResolved = this.filterOnStatus(
      FilterStatusTypeEnum.incomingResolved,
      true,
      this.return.status
    );
    this.isResolved = this.filterOnStatus(
      FilterStatusTypeEnum.resolved,
      true,
      this.return.status
    );
    this.isNotIncomingPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.incomingPackageReceived,
      false,
      this.return.status
    );

    this.isNotReturnRequested = this.filterOnStatus(
      FilterStatusTypeEnum.returnRequested,
      false,
      this.return.status
    );
    this.isNotResolvedPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.resolvedPackageReceived,
      false,
      this.return.status
    );
    this.isNotIncomingResolved = this.filterOnStatus(
      FilterStatusTypeEnum.incomingResolved,
      false,
      this.return.status
    );
    this.isRequestSlipSkipped = this.filterOnStatus(
      FilterStatusTypeEnum.requestSlipSkipped,
      true,
      this.return.status
    );
    this.isRefundDenied = this.filterOnStatus(
      FilterStatusTypeEnum.refundDenied,
      true,
      this.return.status
    );
    this.isIncoming = this.filterOnStatus(
      FilterStatusTypeEnum.incoming,
      true,
      this.return.status
    );
    this.isNotRequestedPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.requestedPackageReceived,
      false,
      this.return.status
    );
    this.isRequestResolved = this.filterOnStatus(
      FilterStatusTypeEnum.requestResolved,
      true,
      this.return.status
    );

    this.isPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.packageReceived,
      true,
      this.return.status
    );

    this.isResolvedPackageReceived = this.filterOnStatus(
      FilterStatusTypeEnum.resolvedPackageReceived,
      true,
      this.return.status
    );
    this.isRequestReceived = this.filterOnStatus(
      FilterStatusTypeEnum.requestReceived,
      true,
      this.return.status
    );
  }

  decisionReasonHeaderTitle(): string {
    const complaintNoItemBack =
      this.isReturnRequested && !this.complaintSettings.should_item_be_returned;
    const complaintPackageReceived =
      this.isPackageReceived && this.hasComplaint(this.return.items);

    return this.isPackageReceived ||
      complaintNoItemBack ||
      complaintPackageReceived ||
      (this.isExchangeRequest && this.isNotReturnRequested)
      ? 'Decision'
      : 'Reason';
  }

  showAttached(item: Item): boolean {
    return (item.show_attached ?? false) && !this.isExchangeRequest;
  }

  showRejectCommentRow(item: Item): boolean {
    return (
      (item.reject_cause && this.isNotRequestedPackageReceived) ||
      (!item.selected && this.isRequestResolved) ||
      (this.isComplaintItemRejectedPackageReceived(item) && item.reject_cause
        ? true
        : false)
    );
  }

  showAmountChangedRow(item: Item): boolean {
    return (
      this.amountChanged(item) &&
      item.decision &&
      item.status !== ReturnItemStatusEnum.ItemDenied
    );
  }

  showDeclinedRefundRow(item: Item): boolean {
    return (
      (this.isPackageReceived ||
        this.hasComplaintRefund ||
        this.displayDecisionColumn()) &&
      !item.decision
    );
  }

  isExchangeRefund(item: Item): boolean {
    return (
      (this.isExchangeRequest &&
        item.merchant_compensation_choice === CompensationOption.Refund &&
        this.isPackageReceived) ||
      this.isComplaintNewItem(item)
    );
  }

  getLanguageSettingsSubject(): void {
    this.languageSettingsService
      .getLanguageSettings()
      .subscribe((languageSettings) => {
        this.languageSettings = languageSettings;
      });
  }

  getComplaintSettingsSubject(): void {
    this.complaintSettingsService
      .getComplaintSettingsSubject()
      .subscribe((complaintOptions) => {
        this.complaintSettings = complaintOptions;
      });
  }

  getExchangeSettingsSubject(): void {
    this.exchangeSettingsService
      .getExchangeSubject()
      .subscribe((exchangeOptions) => {
        this.exchangeSettings = exchangeOptions;
      });
  }

  getReturnSettingsSubject(): void {
    this.returnSettingsService
      .getSubjectReturnSettings()
      .subscribe((returnSettings) => {
        this.returnSettings = returnSettings;
      });
  }

  isAcceptedAutomatically(): boolean {
    if (!this.isReturnRequested) return false;
    if (this.hasComplaint(this.return.items)) return false;
    const autoAcceptRequests =
      this.returnSettings.automatically_accept_requests;

    const isAutoAcceptReturn = autoAcceptRequests && !this.isExchangeRequest;
    const isAutoAcceptExchange =
      this.isExchangeRequest &&
      this.exchangeSettings.automatically_accept_requests;

    return isAutoAcceptReturn || isAutoAcceptExchange;
  }

  displayChangePrice(item: Item): boolean {
    const isEditAmountValid =
      !this.enabledEditIds.includes(item.name) &&
      this.compareAmounts(item) &&
      !this.isExchangeRequest;

    const isRefund =
      item.merchant_compensation_choice === CompensationOption.Refund;
    const isExchangeRefund =
      this.isExchangeRequest &&
      (this.isPackageReceived || (this.isResolved && isRefund));
    return (
      isEditAmountValid ||
      isExchangeRefund ||
      this.isComplaintItemRejected(item)
    );
  }

  exchangeIssueRefund(item: Item): void {
    if (
      item.merchant_compensation_choice === CompensationOption.NewItem ||
      item.merchant_compensation_choice === CompensationOption.StoreCredit
    ) {
      item.merchant_compensation_choice = CompensationOption.Refund;
      return;
    }
    item.merchant_compensation_choice =
      item.customer_compensation_wish === CompensationOption.NewItem
        ? CompensationOption.NewItem
        : CompensationOption.StoreCredit;

    this.updateReturn();
  }

  issueRefund(item: Item): void {
    item.merchant_compensation_choice = CompensationOption.Refund;
    this.updateReturn();
  }

  issueStoreCredit(item: Item): void {
    item.merchant_compensation_choice = CompensationOption.StoreCredit;
    this.updateReturn();
  }

  issueNewItem(item: Item): void {
    item.merchant_compensation_choice = CompensationOption.NewItem;
    this.updateReturn();
  }

  isIssueRefund(item: Item): boolean {
    return item.merchant_compensation_choice === CompensationOption.Refund;
  }

  isIssueStoreCredit(item: Item): boolean {
    return item.merchant_compensation_choice === CompensationOption.StoreCredit;
  }

  isExchangeIssueNewItem(item: Item): boolean {
    return item.merchant_compensation_choice === CompensationOption.NewItem;
  }

  displayEditPriceOption(item: Item): boolean {
    if (
      this.enabledEditIds.includes(item.name) ||
      !item.decision ||
      (this.isExchangeRequest &&
        item.merchant_compensation_choice === CompensationOption.NewItem)
    ) {
      return false;
    }

    return (
      this.isPackageReceived ||
      (this.hasComplaint(this.return.items) &&
        this.isReturnRequested &&
        !this.complaintSettings.should_item_be_returned)
    );
  }

  displayActions(item: Item): boolean {
    if (
      this.isResolved ||
      this.enabledEditIds.includes(item.name)
    ) {
      return false;
    }
    return true;
  }

  showAmountInputsRow(item: Item): boolean {
    if (this.isComplaintNewItem(item)) {
      return false;
    }
    return (
      this.showAmountChangedRow(item) ||
      this.isExchangeRefund(item) ||
      this.showDeclinedRefundRow(item)
    );
  }

  commentInputTitle(item: Item): string {
    if (this.showAmountChangedRow(item)) {
      return 'Comment for amount change';
    } else if (this.isExchangeRefund(item)) {
      return 'Comment for switch to refund';
    } else {
      return `Comment for declined ${
        this.isExchangeRequest ? 'item' : 'refund'
      }`;
    }
  }

  commentInputPlaceholder(item: Item): string {
    if (this.showAmountChangedRow(item)) {
      return 'Write a comment for an amount change.';
    } else if (this.isExchangeRefund(item)) {
      return 'Explain the reason behind your decision. Visible to the customer.';
    } else {
      return `Write a comment for a declined ${
        this.isExchangeRequest ? 'item' : 'refund'
      }.`;
    }
  }

  checkIfExchangeRejectedResolved(item: Item): boolean {
    if (
      item.status === ReturnItemStatusEnum.RefundDenied &&
      this.isExchangeRequest &&
      this.isResolved
    ) {
      return false;
    }
    return true;
  }

  amountChangeTitle(item: Item): string {
    if (!item.decision || item.status === ReturnItemStatusEnum.ItemDenied) {
      return 'Rejection reason';
    } else if (
      item.status === ReturnItemStatusEnum.RefundDenied &&
      this.isExchangeRequest &&
      this.isResolved
    ) {
      return 'Comment for a declined item';
    } else {
      return 'Amount change reason';
    }
  }

  displayEditPriceActions(item: Item): boolean {
    const isExchangeRejectedResolved =
      !this.compareAmounts(item) &&
      this.checkIfExchangeRejectedResolved(item) &&
      !(this.isIssueRefund(item) && this.isResolved);

    const isItemEditedOrDiscount =
      !this.compareAmounts(item) &&
      !this.isExchangeRequest &&
      this.isResolved &&
      item.status !== ReturnItemStatusEnum.RefundDenied;

    return (
      this.enabledEditIds.includes(item.name) ||
      isExchangeRejectedResolved ||
      isItemEditedOrDiscount
    );
  }

  isExchangePackageReceived(): boolean {
    return this.isExchangeRequest && this.isPackageReceived;
  }

  showOriginalPrice(item: Item): boolean {
    if (
      item.merchant_compensation_choice ===
        this.compensationOptionEnum.NewItem ||
        item.merchant_compensation_choice ===
          this.compensationOptionEnum.StoreCredit ||
        !this.compareAmounts(item) &&
      item.decision
    ) {
      return true;
    }
    return !this.isExchangeRequest || this.isComplaintNewItem(item);
  }

  showChangedToRefundText(item: Item): boolean {
    return (
      this.isExchangePackageReceived() &&
      item.merchant_compensation_choice ===
        this.compensationOptionEnum.Refund &&
      !this.enabledEditIds.includes(item.name)
    );
  }

  showRefundActions(): boolean {
    const isComplaintRefundable =
      this.hasComplaint(this.return.items) &&
      (this.isPackageReceived || this.isComplaintRequestedNoReturn());

    return this.isExchangePackageReceived() || isComplaintRefundable;
  }

  isComplaintNewItem(item: Item): boolean {
    return (
      this.hasComplaint(this.return.items) &&
      item.merchant_compensation_choice === CompensationOption.NewItem
    );
  }

  isComplaintItemRejected(item: Item): boolean {
    return (
      this.hasComplaint(this.return.items) &&
      this.isResolved &&
      item.status === ReturnItemStatusEnum.RefundDenied
    );
  }

  showRefundRejectedPriceText(item: Item): boolean {
    return (
      ((this.isRequestSlipSkipped || this.isResolved) &&
        this.checkOnSale(item)) ||
      this.isExchangeRequest ||
      this.isComplaintNewItem(item) ||
      this.isComplaintItemRejected(item) ||
      ((this.isResolved || this.isPackageReceived) && 
        item.merchant_compensation_choice !== CompensationOption.Refund)
    );
  }

  isComplaintRequestedNoReturn(): boolean {
    return (
      this.hasComplaint(this.return.items) &&
      this.isReturnRequested &&
      !this.complaintSettings.should_item_be_returned
    );
  }

  showNewItemRipple(item: Item): boolean {
    if (
      item.merchant_compensation_choice === CompensationOption.NewItem ||
      item.merchant_compensation_choice === CompensationOption.StoreCredit
    ) {
      return (
        this.isPackageReceived ||
        (this.isComplaintNewItem(item) && this.isResolved) ||
        this.isComplaintRequestedNoReturn()
      );
    }
    return false;
  }

  setRippleColor(type: string): string {
    return type === 'NewItem' ? 'new-item' : 'store-credit';
  }

  isNonRefundCompensationChoice(type: string): boolean {
    return type === 'NewItem' || type === 'StoreCredit' ? true : false;
  }

  showDecisionCheckbox(): boolean {
    return (
      this.isReturnRequested &&
      (!this.hasComplaint(this.return.items) ||
        (this.hasComplaint(this.return.items) &&
          this.complaintSettings.should_item_be_returned))
    );
  }

  onDecisionChange(item: Item): void {
    this.updateReturn();
    if (!item.decision) {
      this.cancelEdit(item);
      this.rejectItem(item);
      return;
    }
    this.restoreItemPrice(item);
  }

  isComplaintItemRejectedPackageReceived(item: Item): boolean {
    return (
      item.status === ReturnItemStatusEnum.ItemDenied && this.isPackageReceived
    );
  }

  showEditInput(item: Item): boolean {
    return (
      this.isNotIncomingResolved &&
      item.decision &&
      item.status !== ReturnItemStatusEnum.ItemDenied
    );
  }
}
