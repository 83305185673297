<div class="wrap">
  <ng-container *ngIf="sentInURL; else input">
    <div class="loader-wrap">
      <app-loader size="x-large"></app-loader>
      <p class="mt-32 mb-0 text">Activating carrier</p>
      <p class="mt-0 sub-text">this may take a few seconds</p>
    </div>
  </ng-container>

  <ng-template #input>
    <div class="loader-wrap">
      <div class="input-box">
        <div>
          <h1 class="title">Please paste your store’s name</h1>
          <p class="description">
            To continue with the UPS activation, please paste your store’s name
            below.
          </p>
        </div>

        <div>
          <h4 class="sub-title">Store’s name</h4>
          <input
            [(ngModel)]="storeName"
            placeholder="Paste it here"
            type="text"
          />
        </div>

        <div class="actions">
          <app-new-button
            (click)="activateUPS()"
            [disabled]="storeName.length === 0"
            >Proceed</app-new-button
          >
        </div>
      </div>
    </div>
  </ng-template>
</div>
