import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input()
  checked = false;

  @Input()
  disableRipple = false;

  @Input()
  hideText = false;

  @Input()
  disabled = false;

  @Output()
  toggleChange = new EventEmitter<boolean>();

  toggle(): void {
    this.checked = !this.checked;
    this.toggleChange.emit(this.checked);
  }
}
