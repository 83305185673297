import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { from, lastValueFrom, Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
 
    constructor(
        private authService: AuthenticationService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = this.authService.getAccessToken();
        const helper = new JwtHelperService();
        const route = window.location.href;

        if(accessToken == null || helper.isTokenExpired(accessToken) || route.includes('/ups-activation')) {
            return from(this.handleTokenRenewal(req, next));
        }
        else {
            req = req.clone({
                headers: req.headers.append("Authorization", `Bearer ${accessToken}`)
            })
    
            return next.handle(req);
        }


    }

    private async handleTokenRenewal(req: HttpRequest<any>, next: HttpHandler) {

        const token = await this.authService.refreshToken();

        req = req.clone({
            headers: req.headers.append("Authorization", `Bearer ${token}`)
        });

        return await lastValueFrom(next.handle(req));
    }

}