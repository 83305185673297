import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ArchiveService {
  private archiveModal = new BehaviorSubject<boolean>(false);

  setArchiveModal(value: boolean): void {
    this.archiveModal.next(value);
  }

  getArchiveModal(): Observable<boolean> {
    return this.archiveModal.asObservable();
  }
}
