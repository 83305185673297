<div class="dropdown-menu">
  <button
    [disabled]="disabled"
    (click)="toggleMenu()"
    mat-button
    [matMenuTriggerFor]="menu"
    class="select {{ size }}"
    id="menu"
  >
    {{ selectedOption.length > 0 ? selectedOption : placeholder }}
    <span class="ml-10">
      <mat-icon [ngClass]="menuOpened ? 'rotate' : 'arrow'">
        expand_more
      </mat-icon>
    </span>
  </button>
  <mat-menu #menu="matMenu" class="mt-10">
    <span
      class="mat-menu-item spacing"
      *ngFor="let option of options"
      (click)="select(option)"
    >
      {{ option }}
    </span>
  </mat-menu>
</div>
