import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ReturnSettings } from "src/app/settings/models/return-settings.model";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class ReturnSettingsService {
  private returnSettingsSubject: BehaviorSubject<ReturnSettings> = new BehaviorSubject(
      new ReturnSettings()
  );
  private isLoadingReturnSettings = false;
  constructor(private httpClient: HttpClient) {}

  getReturnSettings(): Observable<ReturnSettings> {
    if (
      this.returnSettingsSubject.value === null ||
      (!this.returnSettingsSubject.value.deadline &&
      !this.isLoadingReturnSettings)
    ) {
      this.isLoadingReturnSettings = true;
      this.httpClient
        .get<ReturnSettings>(`${environment.apiUrl}/settings/return`)
        .subscribe((settings) => {
          this.isLoadingReturnSettings = false;
          this.returnSettingsSubject.next(settings);
        });
    }
    return this.returnSettingsSubject;
  }

  saveReturnSettings(returnSettings: ReturnSettings): Observable<boolean> {
    this.returnSettingsSubject.next(returnSettings);
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/settings/return`,
      returnSettings
    );
  }

  setReturnSettings(settings: ReturnSettings): void {
    this.returnSettingsSubject.next(settings);
  }
  
  hasReturnSettingsLoaded(): boolean {
    const returnSettingsPopulated = this.returnSettingsSubject.value !== null;
    const returnSettingsLoading = (!this.returnSettingsSubject.value.deadline &&
      this.isLoadingReturnSettings);
    return returnSettingsPopulated && !returnSettingsLoading;
  }
  
  getSubjectReturnSettings(): Observable<ReturnSettings> {
    return this.returnSettingsSubject.asObservable();
  }
}