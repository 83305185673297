<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoadingSettings && !isLoadingCurrencies"
  [isLoading]="isLoadingSettings || isLoadingCurrencies || isSaving"
  [exludedInOnBoarding]="true"
>
  <div class="warning mt-16 mb-16">
    <img src="assets/images/icons/alert-triangle.png" class="mr-15" />
    <p class="font-bold mr-5">Caution:</p>
    <p>{{ alert }}</p>
  </div>

  <div class="checkbox-section">
    <app-checkbox
      size="small"
      [checked]="getCheckedUserPays()"
      (checkedChange)="toggleUserPays($event)"
      [disabled]="isSaving"
      ><p class="checkbox-text">
        Deduct standard return shipping cost from the customer
      </p>
    </app-checkbox>
  </div>
  <p class="help-msg">{{ helpMsg }}</p>

  <div class="currency">
    <h4 class="mb-0 mt-20">Currencies</h4>
    <mat-form-field appearance="outline" class="currency-selector">
      <mat-select
        #currencySelect
        [formControl]="currencies"
        multiple
        placeholder="Select"
        [disabled]="!getCheckedUserPays() || isSaving"
        disableOptionCentering
      >
        <mat-select-trigger>
          {{currencies.value?.[0] || ''}}
          <span
            *ngIf="(currencies.value?.length || 0) > 1"
            class="additional-selection"
          >
            (+{{ (currencies.value?.length || 0) - 1 }}
            {{ currencies.value?.length === 2 ? "other" : "others" }})
          </span>
        </mat-select-trigger>
        <mat-option
          *ngFor="let currency of currencyList"
          (click)="selectCurrency(currency)"
          [value]="currency"
          >{{ currency }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div
      class="selected-currencies"
      *ngIf="currencies.value.length > 0 && getCheckedUserPays()"
    >
      <p>Selected currencies:</p>
      <p>{{ currencies.value }}</p>
    </div>
  </div>

  <div class="currency-list">
    <ol>
      <li *ngFor="let currency of selectedCurrencies">
        <div class="list-styling">
          In
          <p class="currency-box">{{ currency.key }}</p>
          deduct
          <input
            type="number"
            [disabled]="!getCheckedUserPays() || isSaving"
            placeholder="0"
            [value]="getCurrencyValue(currency)"
            (change)="setCurrencyValue(currency, $event)"
          />
        </div>
      </li>
    </ol>
  </div>

  <div class="checkbox-section">
    <app-checkbox
      size="small"
      [checked]="getCheckedRefunded()"
      (checkedChange)="toggleRefundShipping($event)"
      [disabled]="isSaving"
      ><p class="checkbox-text">Refund the original shipping</p>
    </app-checkbox>
  </div>
  <p class="help-msg-bottom">
    Choose whether you would like to refund the original shipping back to the
    customer.
  </p>

  <div class="actions">
    <app-new-button [disabled]="isSaving" (click)="saveSettings()"
      >Save changes</app-new-button
    >
  </div>
</app-settings-section>
