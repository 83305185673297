import { Component, OnInit } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { combineLatest, first } from 'rxjs';
import { PricePlanFeature } from 'src/app/settings/models/price-plan-feature.model';
import { PricePlan } from 'src/app/settings/models/price-plan.model';
import { ReturnFormSettings } from 'src/app/settings/models/return-form-settings.model';
import { BillingService } from 'src/app/shared/services/billing.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FormSettingsService } from 'src/app/shared/services/settings/form-settings.service';
import { PricePlanSettingsService } from 'src/app/shared/services/settings/price-plan-settings.service';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
})
export class SelectPlanComponent implements OnInit {
  title = 'Select a plan';
  description =
    'We currently offer 3 pricing plans, all of which are billed monthly. When you have selected a plan, you will be redirected to Shopify to confirm your subscription.';

  isSaving = false;
  isLoadingSettings = false;
  isLoadingPricePlans = false;
  onboardingCompleted = false;

  pricePlans: PricePlan[] = [];
  numberOfAvailablePlans = 0;
  currentPricePlanId = '';
  currentPricePlan = '';
  disabled = false;
  feature = 'New feature';
  showFullContent = false;

  formSettings: ReturnFormSettings = new ReturnFormSettings();
  featureTitles: string[] = [];
  payAsYouGoFeatures: PricePlanFeature[] = [];
  essentialsFeatures: PricePlanFeature[] = [];
  proFeatures: PricePlanFeature[] = [];
  fragment = '';

  constructor(
    private billingService: BillingService,
    private errorService: ErrorService,
    private pricePlanSettingsService: PricePlanSettingsService,
    private formSettingsService: FormSettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoadingSettings = true;
    this.isLoadingPricePlans = true;

    const pricePlanSettings = this.pricePlanSettingsService.getSubjectSelectedPricePlan();
    const formSettings = this.formSettingsService.getSubjectFormSettings();

    combineLatest([pricePlanSettings, formSettings]).subscribe({
      next: ([pricePlanSettings, formSettings]) => {
        if (formSettings.secondary_color) {
          this.currentPricePlanId = pricePlanSettings?.id;
          this.currentPricePlan = this.getPricePlanName(
            this.currentPricePlanId
          );
          this.formSettings = formSettings;

          this.checkOnBoarding();
          this.isLoadingSettings = false;
        }
      },
      error: () => {
        this.isLoadingSettings = false;
        this.errorService.showErrorSnackBar('Failed to load settings.');
      },
    });

    this.billingService.getSubjectPricePlans().subscribe((pricePlans) => {
      if (pricePlans.length != 0) {
        this.pricePlans = pricePlans.sort(
          (a, b) => a.cost_per_extra_return - b.cost_per_extra_return
        );
        this.numberOfAvailablePlans = pricePlans.length;
        this.setFeatures();
        this.isLoadingPricePlans = false;
      }
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof Scroll && event.anchor) {
        setTimeout(() => {
          this.scroll(`#${event.anchor}`);
        }, 200);
      }
    });
  }

  private scroll(query: string) {
    const targetElement = document.querySelector(query);
    if (!targetElement) {
      window.scrollTo(0, 0);
    } else if (!this.isInViewport(targetElement)) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private isInViewport = (elem: any) => {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  getDescription(feature: PricePlanFeature): string {
    return feature?.description;
  }

  getImgSource(icon?: string): string {
    return `../assets/images/icons/${icon}.png` ?? '';
  }

  getPricePlanName(id: string): string {
    const pricePlan = this.pricePlans.find((plan) => plan.id === id);
    return pricePlan?.name ?? '';
  }

  setFeatures(): void {
    this.featureTitles = [];

    this.payAsYouGoFeatures =
      this.pricePlans.find((pricePlan) => pricePlan.name === 'Pay-as-You-Go')
        ?.features ?? [];
    this.featureTitles.push(
      ...this.payAsYouGoFeatures.map((feature) => feature.feature)
    );
    this.essentialsFeatures =
      this.pricePlans.find((pricePlan) => pricePlan.name === 'Essentials')
        ?.features ?? [];
    this.proFeatures =
      this.pricePlans.find((pricePlan) => pricePlan.name === 'Pro')?.features ??
      [];
  }

  getDisplayPrice(pricePlan: PricePlan): number {
    if (pricePlan.cost_per_month) {
      return pricePlan.cost_per_month;
    }
    return pricePlan.cost_per_extra_return ?? 0;
  }

  getPriceUnit(pricePlan: PricePlan): string {
    return pricePlan.cost_per_month ? '/ month' : '/ return';
  }

  getDisclaimer(pricePlan: PricePlan): string {
    if (pricePlan.cost_per_month && pricePlan.cost_per_extra_return) {
      return `* After the limit — $${pricePlan.cost_per_extra_return} / return`;
    }
    return '';
  }

  checkOnBoarding(): void {
    this.currentPricePlanId
      ? (this.onboardingCompleted = true)
      : (this.onboardingCompleted = false);
  }

  setPricePlan(pricePlan: PricePlan) {
    if (pricePlan.id) {
      if (this.currentPricePlanId !== pricePlan.id && !this.disabled) {
        this.isSaving = true;
        this.disabled = true;
        this.billingService
          .updatePricePlan(pricePlan.id)
          .pipe(first())
          .subscribe({
            next: (confirmationLink) => {
              if (window.top) {
                window.top.location.href = confirmationLink;
              }
            },
            error: () => {
              this.errorService.showErrorSnackBar('Failed set price plan.');
            },
            complete: () => {
              this.isSaving = false;
              this.disabled = false;
            },
          });
        this.checkOnBoarding();
        this.restoreCustomization(pricePlan.name);
      }
    }
  }

  restoreCustomization(pricePlan: string): void {
    if (pricePlan !== 'Pro') {
      this.formSettings.primary_color = '#DE4066';
      this.formSettings.text_color = '#FFFFFF';
      this.formSettings.secondary_color = '#FFF5F7';
      this.formSettings.show_returbo_emblem = true;
      this.saveFormSettings();
    }
  }

  checkForActivePlans(): boolean {
    return this.currentPricePlanId ? true : false;
  }

  saveFormSettings() {
    this.isSaving = true;

    this.formSettingsService.saveFormSettings(this.formSettings).subscribe({
      next: () => {
        this.formSettingsService.setFormSettingsSubject(this.formSettings);
      },
      error: () => {
        this.errorService.showErrorSnackBar('Failed save settings.');
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
      },
    });
  }
}
