<app-settings-section
  [title]="title"
  [description]="description"
  [hasContent]="!isLoading"
  [isLoading]="isLoading || isSaving"
  [onBoardingStepCompleted]="onBoardingCompleted"
>
  <ng-container>
    <div class="section">
      <h6>Company contact</h6>
      <p>
        We'll use this address to send any notifications about incoming returns,
        other updates about the application or your account.
      </p>

      <div class="controls">
        <div class="group">
          <label>Company name</label>
          <app-input
            placeholder="Company AB"
            [(value)]="accountSettings.company_name"
            (change)="saveIfValidInput()"
            [error]="!accountSettings.company_name"
            [disabled]="isLoading || isSaving"
          >
          </app-input>
          <p class="error-message">{{ getErrorMessage("Company name") }}&nbsp;</p>
        </div>
        <div class="group">
          <label>Company email</label>
          <app-input
            placeholder="company@company.com"
            [(value)]="supportSettings.merchant_support_email"
            (change)="saveIfValidInput()"
            [error]="!isEmailValid(supportSettings.merchant_support_email)"
            [disabled]="isLoading || isSaving"
          ></app-input>
          <p class="error-message">{{ getErrorMessage("Company email") }}</p>
        </div>
      </div>
    </div>

    <div class="section">
      <h6>Customer service</h6>
      <p>
        This information will be displayed in your return form and used to
        direct your customers in case they need support.
      </p>
      <div class="controls">
        <div class="group">
          <label>Email</label>
          <app-input
            placeholder="support@example.com"
            [(value)]="supportSettings.customer_support_email"
            (change)="saveIfValidInput()"
            [error]="!isEmailValid(supportSettings.customer_support_email)"
            [disabled]="isLoading || isSaving"
          ></app-input>
          <p class="error-message">{{ getErrorMessage("Support email") }}&nbsp;</p>
        </div>
        <div class="group">
          <label>Phone</label>
          <app-input
            placeholder="000-000 00 00"
            [(value)]="supportSettings.customer_support_phone"
            (change)="saveIfValidInput()"
            [error]="!supportSettings.customer_support_phone"
            [disabled]="isLoading || isSaving"
          ></app-input>
          <p class="error-message">{{ getErrorMessage("Support phone") }}</p>
        </div>
      </div>
    </div>
  </ng-container>
</app-settings-section>
