import { IntegrationInformation } from "./integration-information.model";

export class BringIntegrationInformation extends IntegrationInformation {
    apiUserId = '';
    apiSecretKey = '';
    apiCustomerNumber = '';
    apiPhoneNumber = '';
    apiProforma = false;
    apiOrgNumber = '';
  }
  