import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { AvailableService } from 'src/app/settings/models/available-service.model';
import { BringIntegrationInformation } from 'src/app/settings/models/bring-integration-information.model';
import { BringIntegrationRequest } from 'src/app/settings/models/bring-integration-request.model';
import { FormValues } from 'src/app/settings/models/form-values.model';
import {
  IntegrationPackageSupportedCountries,
  SupportedCountry,
} from 'src/app/settings/models/integration-package-supported-countries.model';
import { ShippingIntegration } from 'src/app/settings/models/shipping-integration.model';
import { AlertSectionType } from 'src/app/shared/enums/alert-section-type.enum';
import { IntegrationStatusEnum } from 'src/app/shared/enums/integration-status.enum';
import { ShippingPackageType } from 'src/app/shared/enums/package-type.enum';
import { ShippingCarrier } from 'src/app/shared/enums/shipping-carrier.enum';
import { ShippingIntegrationService } from 'src/app/shared/services/shipping-integration.service';
import { bringServices } from '../carrier-services';
import { IntegrationAlertService } from '../integration-alert.service';

@Component({
  selector: 'app-bring',
  templateUrl: './bring.component.html',
  styleUrls: ['./bring.component.scss'],
})
export class BringComponent implements OnInit {
  @Input()
  disabled = false;
  showPassword = false;
  isLoading = false;
  isSaving = false;
  title = 'Bring';
  description =
    'Autogenerate you returns slip easily within EU and the rest of the world.';
  note =
    'Since you made changes to the carrier settings please click the “save” button below so that the information is saved correctly.';

  showActivationSettings = false;
  showPayAsYouGoInfo = false;
  availableServices = bringServices;
  shippingIntegrationInfo: ShippingIntegration[] | undefined = [];
  shippingCarrier = ShippingCarrier;
  formValueChanged = false;
  serviceInformation: BringIntegrationInformation =
    new BringIntegrationInformation();

  supportedCountries = new Map<ShippingPackageType, SupportedCountry[]>();
  alertType = AlertSectionType;
  returnAddressId = '';

  constructor(
    private modalService: ModalService,
    private shippingIntegrationsService: ShippingIntegrationService,
    private integrationAlertService: IntegrationAlertService
  ) {}

  ngOnInit(): void {
    this.getShippingIntegrationInfo();

    this.shippingIntegrationsService
      .getValuesChanged()
      .subscribe((res: FormValues) => {
        this.formValueChanged = res.bring;
      });

    this.getSupportedCountries();
  }

  getShippingIntegrationInfo(): void {
    this.isSaving = true;
    this.shippingIntegrationsService
      .getIntegrationsSubject()
      .subscribe((res: ShippingIntegration[]) => {
        this.shippingIntegrationInfo = res.filter(
          (shippingIntegration: ShippingIntegration) =>
            shippingIntegration.shipping_carrier === ShippingCarrier.Bring &&
            shippingIntegration.status === IntegrationStatusEnum.Active
        );
        if (
          this.shippingIntegrationInfo &&
          this.shippingIntegrationInfo.length > 0
        ) {
          this.shippingIntegrationInfo.forEach(
            (integration: ShippingIntegration) => {
              this.checkServices(integration);

              this.assignFormProperties(integration);
            }
          );
        }

        this.isSaving = false;
        this.isLoading = false;
      });
  }

  closeModal(): void {
    this.modalService.dismiss();
  }

  onSaveUpdate(isSaving: boolean): void {
    this.isSaving = isSaving;
  }

  toggleActivation(value: boolean): void {
    this.showActivationSettings = value;
  }

  proformaSelectedChange(selectedValue: boolean): void {
    this.serviceInformation.apiProforma = selectedValue;
  }

  openInfoModal(): void {
    this.modalService.open('bring-services');
  }

  isInformationInvalid(): boolean {
    const invalidIntegration = this.shippingIntegrationInfo?.filter(
      (integration: ShippingIntegration) =>
        integration.status === IntegrationStatusEnum.ActivationError &&
        integration.shipping_carrier === ShippingCarrier.Bring
    );

    return invalidIntegration && invalidIntegration.length > 0 ? true : false;
  }

  checkServices(integration: ShippingIntegration): void {
    this.availableServices.forEach((service: AvailableService) => {
      if (
        integration.shipping_package_type === service.packageType &&
        integration.status !== IntegrationStatusEnum.Deactivated
      ) {
        service.checked = true;
      }
    });
  }

  assignFormProperties(integration: ShippingIntegration): void {
    const bringIntegation =
      integration.integration_info as BringIntegrationRequest;
    this.serviceInformation = {
      apiSecretKey: bringIntegation.api_secret_key ?? '',
      apiUserId: bringIntegation.api_user_id ?? '',
      apiCustomerNumber: bringIntegation.api_customer_number ?? '',
      apiPhoneNumber: bringIntegation.api_phone_number ?? '',
      apiProforma: bringIntegation.api_proforma ?? false,
      apiOrgNumber: bringIntegation.api_org_number ?? '',
      enabledCountries: new Map([
        [
          bringIntegation.shipping_package_type as ShippingPackageType,
          bringIntegation.enabled_origin_countries ?? [],
        ],
      ]),
    };
  }

  onValueChanged(): void {
    if (!this.formValueChanged) {
      this.formValueChanged = true;
      let previousValues: FormValues = new FormValues();

      this.shippingIntegrationsService
        .getValuesChanged()
        .subscribe((res: FormValues) => {
          previousValues = res;
        });
      this.shippingIntegrationsService.setValuesChanged({
        ...previousValues,
        bring: true,
      });
    }
    this.integrationAlertService.setAlertSectionForCarrier(
      ShippingCarrier.Bring,
      AlertSectionType.Credentials
    );
  }

  addMarketsToIntegration(
    enabledCountries: Map<ShippingPackageType, string[]>
  ): void {
    this.serviceInformation.enabledCountries = enabledCountries;
  }

  hasProccessStarted(): boolean {
    const startedIntegrations = this.shippingIntegrationInfo?.find(
      (integration: ShippingIntegration) =>
        [
          IntegrationStatusEnum.Active,
          IntegrationStatusEnum.Activating,
          IntegrationStatusEnum.InfoCompleted,
        ].includes(integration.status)
    );
    return startedIntegrations && startedIntegrations.status ? true : false;
  }

  showAlert(alertType: AlertSectionType): boolean {
    const visibleAlertType =
      this.integrationAlertService.getAlertSectionForCarrier(
        ShippingCarrier.Bring
      );
    return alertType === visibleAlertType && this.hasProccessStarted();
  }

  getSupportedCountries(): void {
    this.shippingIntegrationsService
      .getIntegationPackageSupportedCountriesSubject()
      .subscribe((res: IntegrationPackageSupportedCountries[]) => {
        if (res && res.length > 0) {
          const availableCountries = res.filter(
            (shippingIntegration: IntegrationPackageSupportedCountries) =>
              shippingIntegration.shipping_carrier === ShippingCarrier.Bring
          );
          availableCountries.forEach((availableCountry) =>
            this.supportedCountries.set(
              availableCountry.id,
              availableCountry.countries
            )
          );
        }
      });
  }

  newLocation(id: string): void {
    this.returnAddressId = id;
  }

  newOrgNumber(orgNumber: string): void {
    this.serviceInformation.apiOrgNumber = orgNumber;
  }

  getCheckedServicesIntegrationInfo(): ShippingIntegration[] {
    const checkedServices = this.availableServices.filter(
      (service) => 
        service.checked && 
        service.shipping_carrier === ShippingCarrier.Bring
    );
    const checkedPackageTypes = checkedServices.map(x => x.packageType);
    const checkedIntegrationInformations = this.shippingIntegrationInfo?.filter(
      (integrationInfo) => 
        checkedPackageTypes.includes(integrationInfo.shipping_package_type)
    );
    return checkedIntegrationInformations ?? []
  }
}
