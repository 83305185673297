import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  days: string = '0';
  hours: string = '0';
  minutes: string = '0';
  seconds: string = '0';

  targetDate: Date = new Date('October 1, 2024 00:00:00');

  private intervalId: any;
  
  ngOnInit(): void {
    this.startCountDown();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startCountDown() {
    this.updateTimeRemaining();

    this.intervalId = setInterval(() => {
      this.updateTimeRemaining();
    }, 1000);
  }

  updateTimeRemaining() {
    const now = new Date().getTime();
    const target = this.targetDate.getTime();

    const difference = target - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      this.days = this.formatNumber(days);
      this.hours = this.formatNumber(hours);
      this.minutes = this.formatNumber(minutes);
      this.seconds = this.formatNumber(seconds);
    } else {
      this.days = this.hours = this.minutes = this.seconds = '0';
    }
  }

  formatNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }  

}
