export const imageSources = new Map<string, string>([
  ['background-return-resolved', '../assets/images/icons/return-check.png'],
  ['background-return-archived', '../assets/images/icons/return-archived.png'],
  ['background-return-pending', '../assets/images/icons/pending-approval.png'],
  ['background-return-red', '../assets/images/icons/return-requested.png'],
  ['background-return-incoming', '../assets/images/icons/incoming-arrow.png'],
]);

export const iconSources = new Map<string, string>([
  ['background-return-archived', 'archived-icon'],
  ['background-return-pending', 'pending-approval-icon'],
  ['background-return-red', 'request-icon'],
  ['background-return-incoming', 'status-icon'],
  ['background-return-resolved', 'status-icon'],
]);
