import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/components/modal/modal.service';
import { SelectedEmail } from 'src/app/settings/models/selected-email.model';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-hide-email-modal',
  templateUrl: './hide-email-modal.component.html',
  styleUrls: ['./hide-email-modal.component.scss'],
})
export class HideEmailModalComponent implements OnInit {
  selectedEmail: SelectedEmail = new SelectedEmail();

  constructor(
    private modalService: ModalService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.notificationsService.getHideEmailModal().subscribe((opened) => {
      if (opened) {
        this.openModal();
      }
    });
    this.notificationsService.getSelectedEmail().subscribe((email) => {
      this.selectedEmail = email;
    });
  }

  openModal(): void {
    this.modalService.open('hide-email');
  }

  closeModal(): void {
    this.modalService.dismiss();
    this.notificationsService.toggleHideEmailModal(false);
    this.notificationsService.setToggleEmailAction(false);
  }

  toggleEmail(): void {
    this.notificationsService.setToggleEmailAction(true);
    this.closeModal();
  }
}
